import Modal from 'react-modal';
import {useDispatch, useSelector} from 'react-redux';
import {openUploadDocument, openUploadMainDocument} from 'store/modals/actions';
import TextField from "ui/textfield";
import clsx from 'clsx';
import {useState, useEffect} from 'react';
import UploadFile from 'themes/default/svg/uploadicon';
import strings from 'utils/strings';
import {updateSnackBar} from "store/common/actions";
import {addDocumentName} from "services/account";
import {updateToggleDocumentsList} from "store/account/actions";
import Button from "@/template/small_ui/button";
import SelectDropDown from "ui/selectDropDown";
import {validationTypes} from 'utils/constants';
import {useRouter} from 'next/router';
import ModalLayout from "@/template/ui/modal_layout";
import useAuth from "@/AbsoluteComponents/hooks/auth";

const UploadDocument = () => {
    const [image, setImage] = useState(null);
    const [name, setName] = useState<string>('');
    const [updateCount, setUpdateCount] = useState(0);
    const [errors, setErrors] = useState<any>({});
    const [uploading, setUploading] = useState<boolean>(false);
    const user_docs = useSelector((state:any) => state.account.user_docs);
    const auth = useAuth()
    const dispatch = useDispatch();
    const router = useRouter();
    const is_acc_validation_page = router.asPath.search("account-validation") > -1
    const isRegular = useSelector((state:any) => state.modals.openUploadDocument);
    const isMain = useSelector((state:any) => state.modals.openUploadMainDocument);
    const open = isRegular || isMain;
    const rules = [
        strings["pronoun"],
        strings["surname"],
        strings["data_nasterii"],
        strings["available_till"],
        strings["complete_address"],
        strings["idnp_series"],
    ]
    
    const onSubmit = async (e:any) => {
        e.preventDefault();
        
        setErrors({});
        
        const form = new FormData(e.target);
        const body = new FormData();
        const document_name:string = form.get('document_name')?.toString() || '';
        const document_type:string = form.get('document_type')?.toString() || '';
        const imageFile:FormDataEntryValue | null | any = form.get('image');
        const fields = {};
        
        if (imageFile?.size > 0) {
            body.append('file', imageFile);
        }
        
        if (imageFile?.size >= 9437184) {
            dispatch(updateSnackBar('Dimensiunea fișierului ar trebui să fie mai mică decât 9 MB', 'error'))
            return;
        }
        
        if (!document_name.length && isRegular) {
            setErrors((err:any) => ({
                ...err,
                name: 'Acest câmp trebuie completat'
            }));
            return;
        }
        
        if (!document_type.length && !isRegular) {
            setErrors((err:any) => ({
                ...err,
                type: 'Acest câmp trebuie completat'
            }));
            return;
        }
        
        setUploading(true);
        const request = await addDocumentName(document_name?.toString() || '', body, document_type);
        setUploading(false);
        
        if(!request.error) {
            dispatch(updateSnackBar('Documentul a fost încărcat cu succes', 'success'));
            dispatch(updateToggleDocumentsList());
            
            setName('');
            setImage(null);
            setUpdateCount(updateCount + 1);
            
            closeHandle();
            
            if (isMain) {
                router.push('/account/my-documents');
            }
        } else {
            dispatch(updateSnackBar( request.message || '', 'error'));
        }
    }
    
    const chooseFile = (e:any) => {
        if (Object.keys(e.target.files).length) {
            const file = e.target.files[0];

            const reader = new FileReader();
            reader.onload = (e:any) => {
                setImage(e.target.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const fileOptions = {
        onChange: chooseFile
    };

    const closeHandle = () => {
        dispatch(openUploadDocument(false));
        dispatch(openUploadMainDocument(false));

        const root =  document.getElementsByTagName('html')[0];
        root.removeAttribute('style');
    };

    useEffect(() => {
        if (!open) {
            setName('');
            setImage(null);
            setUpdateCount(updateCount + 1);
        }
    }, [open]);
    
    return(

        <Modal
            isOpen={open}
            onRequestClose={closeHandle}
            contentLabel="Example Modal"
            ariaHideApp={false}
            className={clsx('upload-modal', 'modal_dark')}
        >
            <ModalLayout onClose={closeHandle}>
                <>
                    <div className="ReactModal__Inner__Header">
                        <h1 className={'ReactModal__Title'}>{strings['incarca_document']}</h1>
                    </div>

                    <div className="ReactModal__Inner__Content">
                        <div className={clsx('blockPopUp')}>
                            <div className={'blockPopUp-wrapper'}>

                                {isRegular &&
                                    <p className={'blockPopUp-text'}>
                                        Pentru a încărca un fișier, te rugăm să alegi tipul documentului și să-i faci o poză cu telefonul tău mobil. De asemenea, poți încărca documentul din fișierele tale.
                                    </p>
                                }

                                {isMain &&
                                    <p className={'blockPopUp-text'}>
                                        Vă rugăm să ne furnizați documentele necesare pentru validarea contului
                                    </p>
                                }

                                <div className={'blockPopUp-content'}>
                                    <form onSubmit={onSubmit}>
                                        
                                        {isMain &&
                                            <>
                                                <div className={'mb-3'}>
                                                    <SelectDropDown
                                                        id='dropdown'
                                                        label={'Tipul documentului'}
                                                        name={'document_type'}
                                                        defaultValue={'identification_card'}
                                                        onChange={(e) => {
                                                        
                                                        }}
                                                        options={validationTypes}
                                                    />
                                                </div>
                                                
                                                {errors['type'] && <p style={{marginTop: -12}} className="errorMsg">{errors['type']}</p>}
                                            </>
                                        }
                                        
                                        {isRegular &&
                                            <>
                                                <TextField
                                                    label={strings['document_name']}
                                                    type={'text'}
                                                    {...{
                                                        name: 'document_name',
                                                        value: name,
                                                        onChange: (e:any) => setName(e.target.value),
                                                        // required: isRegular
                                                    }}
                                                    placeholder={strings['document_name']}
                                                />
                                                
                                                {errors['name'] && <p style={{marginTop: -12}} className="errorMsg">{errors['name']}</p>}
                                            </>
                                        }
                                        
                                        {isMain ? (
                                            <div className={"upload_rules"} style={{textAlign: "start"}}>
                                                <span >
                                                    {strings["doc_upload_mkshure"]}
                                                </span>
                                                
                                                <ul>
                                                    {rules.map((x, i) => {
                                                        return (
                                                            <li key={"rule"+i}>
                                                                {x}
                                                            </li>
                                                        )
                                                    })}
                                                </ul>
                                            </div>
                                        ) : null}
                                        
                                        {image &&
                                            <div className={'mb-20'} style={{maxHeight: "230px"}}>
                                                <img style={{maxHeight: "130px", objectFit: "contain"}} className={'blockPopUp__preview'} src={image} alt={'Document'}/>
                                                <button
                                                    className={clsx('btn__connection', 'ReactModal__btnResend')}
                                                    onClick={() => {
                                                        setImage(null);
                                                        setUpdateCount(updateCount + 1);
                                                    }}
                                                    type={'button'}
                                                    style={{
                                                        display: "block !important"
                                                    }}
                                                >
                                                    {strings['retrimite']}
                                                </button>
                                            </div>
                                        }

                                        <label className={'blockPopUp-uploadFile'} key={'updateCount-'+updateCount} style={{display: image ? 'none' : ''}}>
                                            <input type="file" name={'image'} accept={'application/pdf,image/jpeg,image/png'} {...fileOptions}/>

                                            <div style={{display:'flex', alignItems: 'center'}}>
                                                <UploadFile />
                                                <span className={'blockPopUp-subText'}>
                                                {strings['incarcati_documente']}
                                            </span>
                                            </div>
                                            {image &&
                                                <button
                                                    className={clsx('btn__connection', 'ReactModal__btnResend', 'btn-minWidth')}
                                                    onClick={() => {
                                                        setImage(null);
                                                        setUpdateCount(updateCount + 1);
                                                    }}
                                                    type={'button'}
                                                >
                                                    {strings['retrimite']}
                                                </button>
                                            }

                                        </label>

                                        <Button loading={uploading} type={'submit'} text={strings['incarca_documente']} disabled={image === null}/>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            </ModalLayout>

        </Modal>
    )
}
export default UploadDocument;