import Head from 'next/head';
import clsx from 'clsx';
import Link from 'next/link';
import strings from 'utils/strings';
import Image from 'next/image';

const Page404View = () => {

  return (
      <div className={'container'}>
          <Head>
              <title>Cashpot - 404 Not Found</title>
          </Head>

          <div className="row">
              <div className={clsx('col-md-12')}>
                  <Image className={'errorBlock__image'} src={'/default/icons/404.png'} alt={'img'} width={1050} height={540}/>

                  <div className={'errorBlock'}>

                      <h1 className={'errorBlock__title'}>
                          Ooops!
                      </h1>

                      <p className={'errorBlock__description'}>
                          {strings['nu_am_gasit_pagina']}

                          <Link
                              className={'errorBlock__support'}
                              href={'/account/support'}
                          >

                              <button>
                                  {strings['contactati_asistenta']}
                              </button>

                          </Link>

                          {strings['pentru_clienti']}.
                      </p>

                  </div>

              </div>

          </div>
      </div>
  )
}

export default Page404View;