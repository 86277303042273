import React, {useEffect, useState} from 'react';
import clsx from 'clsx';
import {openWheelModal} from 'store/modals/actions';
import {useDispatch, useSelector} from 'react-redux'
import style from '../../../styles/wheel.module.scss';
import {useRouter} from 'next/router'
import Modal from 'react-modal';
import WheelSpinningElements from "./components/Wheel";
import {
    Chevrone,
    WheelContainer,
    Header,
    CloseBtn,
    WheelConstruction,
    Overlayed,
    CenteredImages,
    ArrowImage,
    LoadingContainer,
    ToggleSound,
    GlowImageUpper,
    GlowImageBottom,
    BgImage,
    Front,
    SpinButton, SpinBtnCenter,
    Absolute,Relative,
    GlowsContainer
} from "../../styledWheel/StyledWheel";
import useAuth from "hooks/auth";
import PriseContainer from "./components/priseContainer";
import NoCash from "./components/noCash";
import HaveParticipated from './components/haveParticipated';
import SoundPlayer from "./components/soundPlayer";
import CircularProgress from '@mui/material/CircularProgress';
import Volume from '../../../svg/Volume';
import VolumeOff from '../../../svg/VolumeOff';
import Bottom from "./components/bottom/bottom";
import {updateProfileAttributes} from "services/account";
import {setEnableWheel} from "store/common/actions";


const WheelModal = () => {
    const dispatch = useDispatch()
    const open = useSelector((state:any) => state.modals.openWheel);
    const auth = useAuth()
    //States
    const [hoverCount, setHoverCount] = useState<boolean | null>(false)
    const [clickedCount, setClickedCount] = useState<number>(0)
    const [clickEnd, setClickEnd] = useState<boolean>(false)
    const [scenario, setScenario] = useState<string>('')
    const [playSound, setPlaySound] = useState<string>('')
    const [isLoggingIn, setIsLoggingIn] = useState<boolean>(false)
    const [imagesLoaded, setImagesLoaded] = useState<boolean>(false)
    const [isSpinning, setIsSpinning] = useState<boolean>(false);
    const [isStartSpinning, setIsStartSpinning] = useState<boolean>(false);
    const [isEndSpinning, setIsEndSpinning] = useState<boolean>(false);
    const [spinEnded, setSpinEnded] = useState<boolean>(false)
    const [selectedWheel, setSelectedWheel] = useState<number>(0)
    const [rotateTo, setRotateTo] = useState<number>(0);
    const [audioLoaded, setaudioIsLoaded] = useState<boolean>(false);
    const startAnimationTime = 1000;
    const fixedDegrees = [0, 30, 60, 90, 120, 150, 180, 210, 240, 270, 300, 330, 360];
    const [initialPosition, setInitialPosition] = useState<number | null>(fixedDegrees[Math.ceil(Math.random() * (fixedDegrees.length - 1))])
    const [showPrisePopup, setShowPrisePopup ] = useState<boolean>(false)
    const [muted, setMuted] = useState<boolean>(false)
    const [prise, setPrise] = useState<any>({})
    const [enableSpin, setEnableSpin] = useState<boolean>(false)
    const [spinnedToday, setSpinnedToday] = useState<boolean >(false)
    const [prizeValue, setPrizeValue] = useState<string>('')
    const [dataLoaded, setDataLoaded] = useState<boolean>(false)
    const [loadAnim, setLoadAnim] = useState<boolean>(false)
    const [toggleLights,setToggleLights] = useState<boolean>(false)
    const [openCount, setOpenCount] = useState<number>(0)
    const [prizes, setPrizes] = useState<Array<any>>([])
    const [isDefault, setIsDefault] = useState<boolean | null>(null)
    const [currentSpinning, setCurrentSpinning] = useState<boolean>(false)

    const wheelStatus:any = useSelector((state:any) => state.account.spinStatus);
    const depositNum:number = useSelector((state:any) => state.account.depositNum);

    const startSpinDeg:number = 30;

    const router = useRouter()
    const modal = router.query.modal

    const audioLoadedHandle = () => {
        setaudioIsLoaded(true)
    }

    const closePrisePopup = () => {
        setShowPrisePopup(false)
    }

    const wheels = [
        {
            id: 0,
            name: 'wheel1',
            images: [
                {
                    name: 'mainImage',
                    url: '/default/images/Wheel/Castigurile.webp'
                },
                {
                    name: 'borderImage',
                    url: '/default/images/Wheel/Border.webp'
                },
                {
                    name: 'arrowImage',
                    url: '/default/images/Wheel/Arrow.svg'
                },
                {
                    name: 'lineImage',
                    url: '/default/images/Wheel/Line.svg'
                },
                {
                    name: 'reflexImage',
                    url: '/default/images/Wheel/Reflex.svg'
                },
                {
                    name: 'spinnImage',
                    url: '/default/images/Wheel/Spin-Button.png'
                },
                {
                    name: 'glowImage',
                    url: '/default/images/Wheel/Glow.svg'
                },

                {
                    name: 'frontImage1',
                    url: '/default/images/Wheel/Front1.webp'
                },

                {
                    name: 'frontImage2',
                    url: '/default/images/Wheel/Front2.webp'
                },

                {
                    name: 'backImage',
                    url: '/default/images/Wheel/Bg-Forest.webp'
                },

                {
                    name: 'label',
                    url: '/default/images/Wheel/Label.png'
                }

            ],

            prises: prizes
        },

    ]

    const enableSpinning = (x: boolean) => {
        setEnableSpin(x)
    }

    const others: Array<any> = [
        {
            name: 'shine2',
            url: '/default/images/Wheel/prizeAnimBg2.jpg'
        },

        {
            name: 'shine1',
            url: '/default/images/Wheel/prizeAnimBg1.jpg'
        },

        {
            name: 'gift',
            url: '/default/images/Wheel/prizeBox.webp'
        },

        {
            name: 'platform',
            url: '/default/images/Wheel/prizePlatform.webp'
        },
    ]

    const imageLoaded = (src:string) => {
        return new Promise((resolve, reject) => {
            const img = document.createElement('img');
            img.src = src;

            img.onload = () => {
                resolve(true);
            }

            img.onerror = () => {
                resolve(false);
            }
        })
    }

    const loadImages = async () => {
        let wheelImages = wheels[selectedWheel].images
        let allImages = [...wheelImages, ...others]
        let arr = []
        for (let i = 0; i <= allImages.length - 1; i++) {
            await imageLoaded(allImages[i].url)
            arr.push(allImages[i].url)

            if(arr.length === allImages.length) {
                setImagesLoaded(true)
            }
        }
    }


    let images: any[] = wheels[selectedWheel].images

    //Images
    let mainImage = images.find((img) => img.name === 'mainImage');
    let borderImage = images.find((img) => img.name === 'borderImage');
    let arrowImage = images.find((img) => img.name === 'arrowImage');
    let lineImage = images.find((img) => img.name === 'lineImage');
    let reflexImage = images.find((img) => img.name === 'reflexImage');
    let spinnImage = images.find((img) => img.name === 'spinnImage');
    let glowImage = images.find((img) => img.name === 'glowImage');
    let backImage = images.find((img) => img.name === 'backImage');
    let frontImage1 = images.find((img) => img.name === 'frontImage1');
    let frontImage2 = images.find((img) => img.name === 'frontImage2');
    let label = images.find((img) => img.name === 'label');

    const setPlay = (type: 'spin' | 'tada'): void => {
        setPlaySound(type)
    }
    //
    // useEffect(() => {
    //   const update = async () => {
    //       await updateProfileAttributes('NotTriggered')
    //   }
    //   update()
    // }, [])

    const spin = async() => {
        if(auth.isLoggedIn() && !auth.isUpdating()) {

            // set isSpinned today

            setIsStartSpinning(true);
            setPlay('spin')
            setCurrentSpinning(true)

            setTimeout(() => {
                setIsStartSpinning(false);
                setIsSpinning(true);
            }, startAnimationTime);
        }

    };

    const fetchData =  () => {

        let selectedPrises = wheels[selectedWheel].prises
        let degreeRate = 360 / 12;
        // let priseIndex = Math.ceil(Math.random() * selectedPrises.length - 1);
        let prize:any = selectedPrises.find((x) => {
            return x.name === prizeValue
        })

        let priseIndex = selectedPrises.indexOf(prize);

        setRotateTo(priseIndex * degreeRate);
        setPrise(selectedPrises[priseIndex]);

        setTimeout(async() => {
            let attributes: any[]
            await updateProfileAttributes('Triggered')
                .then((res) => {
                    attributes = res.response.attributes

                    let triggered = attributes.find((attr: any) => {
                        return attr.attributeName === 'WOFTriggered'
                    })

                    if(triggered.value === 'Triggered') {
                        setSpinnedToday(true)
                        dispatch(setEnableWheel('disable'))
                    }

                    let prizes = attributes.find((attr: any) => {
                        return attr.attributeName === 'WOFPrizes'
                    })
                })
            setIsSpinning(false);
            setIsEndSpinning(true);
        }, 4000);

        setTimeout( () => {
            // alert(selectedPrises[priseIndex].name);

            setIsSpinning(false)
            setIsEndSpinning(false)
            setIsStartSpinning(false)
            setRotateTo(0)
            setShowPrisePopup(true)
            setInitialPosition(priseIndex * degreeRate)
            setSpinEnded(true)
            setCurrentSpinning(false)
        }, 8000);
    };


    useEffect(() => {
        if(modal === 'wheel') {
            setScenario('wheel')
            dispatch(openWheelModal(true))
        } else if (modal === 'wheelNoCash' && auth.isLoggedIn()) {
            dispatch(openWheelModal(true))
            setScenario('noCash')
        } else if (modal === 'wheelHaveParticipated' && auth.isLoggedIn()) {
            dispatch(openWheelModal(true))
            setScenario('haveParticipated')
        } else if (modal === 'wheelSoundInquire' && auth.isLoggedIn()) {
            setScenario('soundInquire')
        }
    }, [router.query.modal, auth.isLoggedIn()])

    useEffect(() => {
        if (isStartSpinning) {
            fetchData();
        }
    }, [isStartSpinning]);

    useEffect(() => {
        if(auth.isLoggedIn() && isLoggingIn) {
            dispatch(openWheelModal(true))
            setIsLoggingIn(false)
        }
        if(!auth.isLoggedIn()) {
            setDataLoaded(false)

        }
    }, [auth.isLoggedIn()])

    // useEffect(() => {
    //     const setInitial = () => {
    //         setInitialPosition()
    //     }
    //
    //     setInitial()

    // }, [open])



    // TO DECOMENT START TO DECOMENT START TO DECOMENT START TO DECOMENT START

    useEffect(() =>   {
        const spinCheck = async () => {
            let attributes: any
            let wasTriggered: string


            if(wheelStatus.length) {
                attributes = wheelStatus

                let triggered = attributes.find((attr: any) => {
                    return attr.attributeName === 'WOFTriggered'
                })
                let wof_prizes = attributes.find((attr: any) => {
                    return attr.attributeName === 'WOFPrizes'
                })
                
                wasTriggered = triggered.value
                //test functionality
                // setSpinnedToday(false);
                // setIsDefault(false)

                //propper functionality
                if(wasTriggered === 'NotTriggered') {
                    setSpinnedToday(false)
                }
                if (wasTriggered === 'Triggered') {
                    setSpinnedToday(true)
                    setSpinEnded(true)
                }

                if (wof_prizes.value === 'Default') {
                    setIsDefault(true)
                } else {
                    setIsDefault(false)
                }

                // alert(attributes[1].value)
                // propper functionality


                let prizes = attributes.find((attr: any) => {
                    return attr.attributeName === 'WOFPrizes'
                })

                let prizesList = prizes.possibleValues;
                const defaultPrizes:any[] = []
                const baseUrl:string = '/default/images/Wheel/prizes/'

                prizesList.map((x: string) => {
                    switch (x) {
                        case '2500 RON Bonus':
                            defaultPrizes[0] = {
                                name:x,
                                image: baseUrl +'2500RON.png'
                            };
                            break;
                        case '500 RON Bonus':
                            defaultPrizes[1] = {
                                name:x,
                                image: baseUrl +'500RON.png'
                            };
                            break;
                        case '250 RON Bonus':
                            defaultPrizes[2] = {
                                name:x,
                                image: baseUrl +'250RON.png'
                            };
                            break;
                        case '100 RON Bonus':
                            defaultPrizes[8] = {
                                name:x,
                                image: baseUrl +'100RON.png'
                            };
                            break;
                        case '50 RON Bonus':
                            defaultPrizes[4] = {
                                name:x,
                                image: baseUrl +'50RON.png'
                            };
                            break;

                        case '5 RON Bonus':
                            defaultPrizes[10] = {
                                name:x,
                                image: baseUrl +'5RON.png'
                            };
                            break;

                        case '100 Rotiri Gratuite':
                            defaultPrizes[11] = {
                                name:x,
                                image: baseUrl +'100FSpins.png'
                            };
                            break;

                        case '10 Rotiri Gratuite':
                            defaultPrizes[9] = {
                                name:x,
                                image: baseUrl +'10FSpins.png'
                            };
                            break;

                        case 'Default':
                            defaultPrizes[12] = {
                                name:x,
                                image: ''
                            };
                            break;

                        case '25 RON Bonus ':
                            defaultPrizes[5] = {
                                name:x,
                                image: baseUrl +'25RON.png'
                            };
                            break;

                        case '10 RON Bonus':
                            defaultPrizes[7] = {
                                name:x,
                                image: baseUrl +'10RON.png'
                            };
                            break;

                        case '50 Rotiri Gratuite':
                            defaultPrizes[3] = {
                                name:x,
                                image: baseUrl +'50FSpins.png'
                            };
                            break;

                        case '25 Rotiri Gratuite':
                            defaultPrizes[6] = {
                                name:x,
                                image: baseUrl +'25FSpins.png'
                            };
                            break;

                        default:
                            break;
                    }
                })

                setPrizes(defaultPrizes)
                setPrizeValue(prizes.value)
            }
        }

        if(auth.isLoggedIn()) {
            spinCheck()
        } else {
            setDataLoaded(true)
            setSpinEnded(false)
        }

    }, [auth.isLoggedIn(), wheelStatus, /*auth.getUserBalance()*/])

    // TO DECOMENT END TO DECOMENT END TO DECOMENT END TO DECOMENT END


    useEffect(() => {
        if(auth.isUpdating()) {
            setDataLoaded(false)
        }
    }, [auth.isUpdating()])

    //Anim USE EFFECT

    useEffect(() => {
        setTimeout(() => {
            setToggleLights(!toggleLights)
        },1000)

    }, [toggleLights])

    const onLoadAnimate = () => {
        const glow = document.getElementById('glow');
        const border = document.getElementById('border');
        const bgImage = document.getElementById('bgImage');
        const relfect = document.getElementById('relfect');
        const prize = document.getElementById('prize');
        const wheel = document.getElementById('wheel');

        setTimeout(() => {
            setLoadAnim(true)

            if(glow && border && bgImage && relfect && prize && wheel) {
                glow.classList.add('appear');
                border.classList.add('appear');
                bgImage.classList.add('appear');
                relfect.classList.add('appear');
                prize.classList.add('appear');
                wheel.classList.add('appear');

                setTimeout(() => {
                    glow.classList.remove('appear');
                    border.classList.remove('appear');
                    bgImage.classList.remove('appear');
                    relfect.classList.remove('appear');
                    prize.classList.remove('appear');
                    wheel.classList.remove('appear');

                }, 1000)
            }

        }, 1000)
    }


    useEffect(() => {
        if(imagesLoaded && dataLoaded && audioLoaded && !auth.isUpdating() && !loadAnim) {
            onLoadAnimate()
        }
    }, [imagesLoaded, dataLoaded, audioLoaded, auth.isUpdating(), open, openCount])


    return (
        <Modal
            className={clsx(style.modal, 'wheel-modal')}
            onAfterClose={() => {
                setIsSpinning(false)
                setIsEndSpinning(false)
                setIsStartSpinning(false)
                setHoverCount(null)
                setClickedCount(0)
                setClickEnd(false)
            }}
            ariaHideApp={false}
            onAfterOpen={() => {
                loadImages();
            }}
            isOpen={open}
            contentLabel="Example Modal"
        >

            <div style={{
                width: "100%",
                minHeight: '100%',
                display: "flex",
                justifyContent: 'space-between',
                flexDirection: "column",
                padding: "50px 70px !important",
                overflow: 'hidden',

            }}>

                <WheelContainer >

                    <Header>

                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            gap: '10px'
                        }}>
                            <ToggleSound  onClick={() => {setMuted(!muted)}} >
                                {muted? <VolumeOff/> : <Volume/> }

                            </ToggleSound>

                            <CloseBtn onClick={() => dispatch(openWheelModal(false))} className={style.closeBtn}>
                                <img src={'/default/icons/close.svg'} width={15} height={15} alt={'close'}/>
                            </CloseBtn>
                        </div>

                    </Header>

                    <SoundPlayer
                        clickEnd={clickEnd}
                        click={clickedCount}
                        hover={hoverCount}
                        muted={muted}
                        isLoaded={() => {
                            audioLoadedHandle()
                        }}
                        play={playSound}
                    />

                    <Relative>
                        <Absolute>

                            <WheelConstruction className={clsx((!imagesLoaded || !audioLoaded || auth.isUpdating() || !dataLoaded || !loadAnim) && 'hidden')}  >

                                <BgImage id='bgImage' src={backImage.url} alt={backImage.name} />

                                <Chevrone >
                                    <ArrowImage

                                        className={clsx(
                                            isStartSpinning && 'startTick',
                                            isSpinning && 'spinTick',
                                            isEndSpinning && 'endTick'
                                        )}
                                        src={arrowImage.url}
                                    />
                                </Chevrone>

                                <WheelSpinningElements
                                    initialPosition={ initialPosition}
                                    rotateBefore={typeof initialPosition === 'number' ?  (initialPosition - 100) : 0}
                                    startSpinDeg={startSpinDeg}
                                    rotateTo={rotateTo}
                                    isSpinning={isSpinning}
                                    isEndSpinning={isEndSpinning}
                                    isStartSpinning={isStartSpinning}
                                    lineImage={lineImage}
                                    mainImage={mainImage}
                                    imagesLoaded = {imagesLoaded}
                                    audioLoaded = {audioLoaded}
                                    dataLoaded ={dataLoaded}
                                    // loadHandler={loadHandler}
                                />

                                <Overlayed>

                                    <CenteredImages
                                        id='relfect'
                                        src={reflexImage.url}
                                        alt="reflect"
                                        className='spin-border'
                                    />

                                    <CenteredImages
                                        id='border'
                                        width='400px'
                                        height='400px'
                                        src={borderImage.url}
                                        alt="border"
                                        className='spin-border'
                                    />

                                    <GlowsContainer id='glow'>
                                        <GlowImageUpper src={glowImage.url} alt={glowImage.name}/>
                                        <GlowImageBottom src={glowImage.url} alt={glowImage.name}/>
                                    </GlowsContainer>



                                    <SpinButton >
                                        <CenteredImages
                                            width='232px'
                                            height='232px'
                                            src={spinnImage.url}
                                            alt="spinn"
                                        />

                                        <CenteredImages
                                            width='150px'
                                            height='78.55px'
                                            src={label.url}
                                            alt="label"
                                            className={clsx((enableSpin && isDefault === false) && 'enabledSpin', hoverCount && 'active', (spinnedToday || isStartSpinning || isSpinning) && 'spinnedToday')}
                                        />

                                        <SpinBtnCenter
                                            onMouseEnter={() => {
                                                setHoverCount(true);
                                            }}
                                            onMouseLeave={() => {
                                                setHoverCount(false);

                                            }}
                                            disabled={(isStartSpinning === true || isSpinning === true || isEndSpinning === true || enableSpin === false || spinnedToday === true || isDefault === null || isDefault === true)}
                                            onClick={() => {
                                                if(enableSpin) {
                                                    setClickedCount(clickedCount + 1)
                                                    spin()
                                                }
                                            }}>
                                        </SpinBtnCenter>
                                    </SpinButton>

                                    {toggleLights? (
                                        <Front id='prize' src={frontImage1.url}  alt={frontImage1.name}/>
                                    ) : (
                                        <Front id='prize' src={frontImage2.url}  alt={frontImage2.name}/>
                                    )}

                                </Overlayed>



                            </WheelConstruction>

                        </Absolute>
                    </Relative>

                    {(!imagesLoaded || !audioLoaded || auth.isUpdating() || !dataLoaded || !loadAnim)  && (
                        <LoadingContainer>
                            <CircularProgress />
                        </LoadingContainer>
                    )}

                </WheelContainer>

                <div className={style.bottomSection}>
                    {!currentSpinning  && (
                        <Bottom
                            isDefault={isDefault}
                            spinEnded={spinEnded}
                            audioLoaded={audioLoaded}
                            setDataLoaded={(x: boolean) => {setDataLoaded(x)}}
                            imagesLoaded={imagesLoaded}
                            isSpinnedToday={spinnedToday}
                            setSpinnedToday={(x:boolean) => { setSpinnedToday(x)}}
                            setEnable={(x: boolean): void => {enableSpinning(x)}}
                            setIsLoggingIn={(x:boolean) => {setIsLoggingIn(x)}}
                        />
                    )}


                </div>

            </div>
            {scenario === 'haveParticipated' && <HaveParticipated closePopup={closePrisePopup}/>}
            {scenario === 'noCash' && <NoCash closePopup={closePrisePopup}/>}
            {/*{(auth.isLoggedIn() && (scenario === 'soundInquire' || inquireSound)) && <SoundInquire soundOn = {() => {setInquireSound(!inquireSound)}} soundOff = {() => {setMuted(true); setInquireSound(!inquireSound)}} />}*/}
            {/*@ts-ignore*/}
            {showPrisePopup && <PriseContainer clickEnd={(x: boolean) => {setClickEnd(x)}} setPlay={(type):void => {
                {/*@ts-ignore*/}
                setPlay(type)
            }} closePopup={closePrisePopup} prise={prise}/>}

        </Modal>

    );
}



export default WheelModal