import SmarticoMiniGame from "@/AbsoluteComponents/interfaces/smartico_minigames";

declare global {
    interface Window {
        _smartico?: any;
        _smartico_callback?:any
    }
}

class SmarticoClass {

    static stack:any = []
    
    static WidgetTypes = {
        "Missions": "missions",
        "Tournaments": "tournaments",
        "Leaderboards": "leaderboards",
        "Minigames": "mini-game",
        "Store": "store",
        "Levels": "levels",
        "Achievements":"achievements",
        "FWPopup":"fw-popup",
        "CustomSection":"custom-section"
    }
    
    static saw_game_types = {
        "giftbox":"giftbox",
        "matchx":"matchx",
        "wheel":"wheel",
        "scratch":"scratch"
    }
    
    static events = {
        "login":"login",
        "identify":"identify",
        "props_change": "props_change",
        "smartico_update": "smartico_update",
        "gf_closing":"gf_closing"
    }
    
    static miniGamesFactory = (json:any):SmarticoMiniGame => {
        return {
            id:typeof json["id"] === "number" ? json["id"] : 0,
            saw_game_type: typeof json["saw_game_type"] === "string" ? json["saw_game_type"] : ""
        }
    }

    static showWidget = (widget_type:string, iframe_id: string, widget_id:string) => {
        
        if(typeof window === "undefined") return;
        
        if(typeof window._smartico === "undefined") {
            this.stack.push(this.showWidget.bind(this, widget_type, iframe_id, widget_id))
            return
        }
        
        window._smartico?.showWidget(widget_type, {iframe:iframe_id, id:widget_id})
        
    }
    
    // static showVisitorGame = (iframe_id:string, game_id:string) => {
    //     if(typeof window === "undefined") return;
    //     if(typeof window._smartico === "undefined") {
    //         this.stack.push(this.showVisitorGame.bind(this, iframe_id, game_id))
    //         return
    //     }
    //
    //     if(typeof window._smartico.showVisitorGame === "function"){
    //         window._smartico?.showVisitorGame({
    //             template_id:game_id,
    //             frame_id:iframe_id,
    //         })
    //     }
    // }
    //
    
    static openInlineMiniGame = (widget_id:string, iframe_id:string, ) => {
        if(typeof window === "undefined") return;
        
        if(typeof window._smartico === "undefined") {
            this.stack.push(this.openInlineMiniGame.bind(this, widget_id, iframe_id))
            return
        }
        
        window._smartico?.miniGame(widget_id, { iframe:iframe_id, inline: true, height: 'auto'})
        
    }
    
    static openCustomInlineSection = (type:string, iframe_id:string, height:number, customSectionID:string) => {
        if(typeof window === "undefined") return;
        
        if(typeof window._smartico === "undefined") {
            this.stack.push(this.openCustomInlineSection.bind(this, type, iframe_id, height,  customSectionID))
            return
        }
        
        window._smartico?.showWidget(type, { iframe:iframe_id, height: height, customSectionID: customSectionID })
    }
    
    static showMiniGame = (iframe_id:string, game_id:string) => {
        if(typeof window === "undefined") return;
        if(typeof window._smartico === "undefined") {
            this.stack.push(this.showMiniGame.bind(this, iframe_id, game_id))
            return
        }
        
        window._smartico?.miniGame(game_id, {
            iframe: iframe_id,
            zoom: 1,
            height: 'auto'
        })
    }
    
    static fwPopupMiniGame = (game_id:string) => {
        if(typeof window === "undefined") return;
        if(typeof window._smartico === "undefined") {
            this.stack.push(this.fwPopupMiniGame.bind(this, game_id))
            return
        }
        
        window._smartico.dp(`dp:gf_saw&id=${game_id}&standalone=true`)
    }
    
    static openDashboard = () => {
        if(typeof window === "undefined") return;
        
        if(typeof window._smartico === "undefined") {
            this.stack.push(this.openDashboard)
            return
        }
        
        return window._smartico.dp("dp:gf");
    }
    
    static openDashboardMiniGame = (id:any) => {
        if(typeof window === "undefined") return;
        
        if(typeof window._smartico === "undefined") {
            this.stack.push(this.openDashboardMiniGame)
            return
        }
        
        return window._smartico.dp(`dp:gf_saw&id=${id}`);
    }
    
    static changeAvatar = () => {
        if(typeof window === "undefined") return;
        
        if(typeof window._smartico === "undefined") {
            this.stack.push(this.changeAvatar)
            return
        }
        
        return window._smartico.dp("dp:gf_change_avatar&hide_main=true");
    }
    
    static openCustomSection = (id:string) => {
        if(typeof window === "undefined") return;
        
        if(typeof window._smartico === "undefined") {
            this.stack.push(this.openCustomSection.bind(this, id))
            return
        }
        
        return window._smartico.dp(`dp:gf_section&id=${id}&standalone=true`);
    }
    
    static getMiniGames = () => {
        if(typeof window === "undefined") return;
        
        if(typeof window._smartico === "undefined") {
            let resolve = (value:any) => {}
            let reject = (reason?:any) => {}
            
            const returnPromise = new Promise<any>((res, rej) => {
                resolve = res
                reject = rej
            })
            
            this.stack.push(() => {
                this.getMiniGames()?.then(resolve).catch(reject)
            })
            
            return returnPromise;
        }
        
        if(typeof window._smartico.api === null) {
            return new Promise(resolve => {
                const interval = setInterval(() => {
                    if(window._smartico.api) {
                        window._smartico.api.getMiniGames().then(resolve);
                        clearInterval(interval)
                    }
                }, 1000);
            })
        }
        
        return window._smartico.api.getMiniGames();
    }
    
    static getPublicProps = () => {
        if(typeof window === "undefined") return;
        
        if(typeof window._smartico === "undefined") {
            this.stack.push(this.getPublicProps);
            return
        }
        
        return window._smartico.getPublicProps();
    }
    
    
    static triggerLoad = () => {
        while(this.stack.length > 0) {
            const element = this.stack.pop()
            
            if(typeof element === "function") {
                element()
            }
        }
    }

}

export default SmarticoClass