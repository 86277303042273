import styles from "@/template/styles/Ui.module.scss";
import Image from "next/image";
import StandartCountdown from "@/template/small_ui/standart_countdown";
import React, {useEffect, useState} from "react";
import CMS from "@/AbsoluteComponents/utils/cms";
import MaintenanceIcon from "@/template/svg/MaintenanceIcon";

const MaintenancePage = () => {
    
    const [loaded, set_loaded] = useState<boolean>(false)
    const raw_gen_settings = CMS.getGenSettingsJSON()
    const general_settings = CMS.getAllFields(raw_gen_settings)
    const {
        maintenance_finish,
    } = general_settings
    
    useEffect(() => {
        set_loaded(true)
    }, []);
    
    return (
        <div className={styles.maintenance_page}>
            
            <Image className={'first-logo'} src={"/default/icons/gif-logo.gif"} alt={'logo'} width={200} height={100}/>
            
            <div className={styles.message}>
                <MaintenanceIcon/>
                
                <strong>
                    CashPot este în mentenanță
                </strong>
            </div>
            
            <div className={styles.cd_container}>
                
                <span>
                    Revenim în
                </span>
                
                {loaded && (
                    <StandartCountdown
                        dark
                        className={styles.countdown}
                        endTime={new Date(maintenance_finish)}
                        
                    />
                )}
                
            </div>
        
        </div>
    )
}

export default MaintenancePage