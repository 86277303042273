import Particles from 'react-particles'
import type { Container, Engine } from "tsparticles-engine";
import {loadFull} from 'tsparticles'
import React, {useCallback} from  'react'




const Fireworks = () => {
    const particlesInit = useCallback(async (engine: Engine) => {
        await loadFull(engine);
    }, []);

    const particlesLoaded = useCallback(async (container: Container | undefined) => {

    }, []);

    return (

        <>
            <Particles
                id="tsparticles"
                init={particlesInit}
                loaded={particlesLoaded}
                options={{

                    fullScreen: {
                        enable: true,
                        zIndex: -1
                    },
                    interactivity: {
                        detectsOn: "window"
                    },
                    emitters: [
                        {

                            position: {
                                x: 0,
                                y: 30
                            },
                            rate: {
                                quantity: 5,
                                delay: 0.15
                            },
                            particles: {
                                move: {
                                    direction: "top-right",
                                    outModes: {
                                        top: "none",
                                        left: "none",
                                        default: "destroy"
                                    }
                                }
                            }
                        },
                        {


                            position: {
                                x: 100,
                                y: 30
                            },
                            rate: {
                                quantity: 5,
                                delay: 0.15
                            },
                            particles: {
                                move: {
                                    direction: "top-left",
                                    outModes: {
                                        top: "none",
                                        right: "none",
                                        default: "destroy"
                                    }
                                }
                            }
                        }
                    ],
                    particles: {
                        color: {
                            value: ["#1E00FF", "#FF0061", "#E1FF00", "#00FF9E"]
                        },
                        move: {
                            decay: 0.05,
                            direction: "top",
                            enable: true,
                            gravity: {
                                enable: true,
                                maxSpeed: 150
                            },
                            outModes: {
                                top: "none",
                                default: "destroy"
                            },
                            speed: { min: 25, max: 50 }
                        },
                        number: {
                            value: 0
                        },
                        opacity: {
                            value: 1
                        },
                        rotate: {
                            value: {
                                min: 0,
                                max: 360
                            },
                            direction: "random",
                            animation: {
                                enable: true,
                                speed: 30
                            }
                        },
                        tilt: {
                            direction: "random",
                            enable: true,
                            value: {
                                min: 0,
                                max: 360
                            },
                            animation: {
                                enable: true,
                                speed: 30
                            }
                        },
                        size: {
                            value: 8
                        },
                        roll: {
                            darken: {
                                enable: true,
                                value: 25
                            },
                            enable: true,
                            speed: {
                                min: 5,
                                max: 15
                            }
                        },
                        wobble: {
                            distance: 30,
                            enable: true,
                            speed: {
                                min: -7,
                                max: 7
                            }
                        },
                        shape: {
                            type: [
                                "circle",
                                "square",
                                "polygon",
                            ],
                            options: {

                                polygon: [
                                    {
                                        sides: 5
                                    },
                                    {
                                        sides: 6
                                    }
                                ],

                            }
                        }
                    }
                }}
            />

        </>
    )
}

export default Fireworks