const strings:any = {
    'document-condition': 'Pentru a putea depune liber, fără limite, și pentru a putea realiza retrageri, îți poți valida contul aici. Avem nevoie de două tipuri de documente: dovada identității și a adresei.',
    'thank_you_document': 'Mulțumim că ne-ai trimis dovada identității tale. Cererea e în curs de procesare, iar tu poți verifica statusul acesteia',
    'unknown_error': 'Eroare necunoscută',
    'promotions' : 'Promoții',
    'menu': 'Meniu',
    'sots' : 'Slots',
    'side_menu_quest': 'spyder quest',
    'cazino_live': 'Cazino Live',
    'jackpots' : 'Jackpots',
    'conectare' : 'Conectare',
    'copy_text':'Copiați referința',
    'copied': 'Copiat',
    'logo': 'logo',
    'cont_nou' : 'Cont nou',
    'afla_mai_multe': 'AFLĂ MAI MULTE',
    'slider' : 'slider',
    'card': 'card',
    "nu_aveti_sold_cash":"Nu aveți un sold cash ce poate fi retras.",
    "nu_aveti_depuneri":"Pentru a putea retrage trebuie să efectuați cel putin o depunere.",
    'autentificare_esuata': 'Autentificarea a eșuat, te rugăm să verifici datele de logare, logarea cu date greșite poate duce la blocarea contului',
    'despre_noi': 'Despre Noi',
    'politica_de_confidențialitate': 'Politica de confidențialitate',
    'joc_responsabil': 'Joc responsabil',
    'termeni_și_condiții': 'Termeni și condiții',
    'want_to_validate': 'Pentru a-ți dovedi identitatea, te rugăm să încarci cartea de identitate, pașaportul sau carnetul de conducere. Dacă optezi pentru pașaport sau carnet de conducere, trebuie sa ne trimiți împreună cu documentul de identitate și dovada adresei.',
    'intrebari_frecvente': 'Întrebări frecvente',
    'sali_de_joc': 'Săli de Joc',
    'contact': 'Contact',
    'cautare': 'Căutare',
    'sports': 'Sport',
    'loto': 'Loto',
    'bine_ai_revenit_la_cashpot': 'Bine ai revenit la CashPot',
    'ai_uitat_parola':'Ai uitat parola',
    'nu_ai_cont': 'Nu ai un cont?',
    'inregistreaza_te': 'Înregistrează-te',
    'vezi_toate': 'Vezi toate',
    "găsește-ți_jocul": 'Găsește-ți jocul',
    'cazino': 'Cazino',
    'live_cazino': 'Live Cazino',
    'assistance': 'Asistență',
    'create_account': 'Creare cont',
    'account_details': 'Detalii cont',
    'personal_details': 'Informații personale',
    'util_details': 'Informații utile',
    'checking': 'Verificare',
    'unverified_profile': 'Cont neverificat',
    'verification': 'Verificare',
    "verification_account": "Verificarea contului",
    "ver_identity":"Verificarea identității",
    "ver_email":"Verificarea SMS/EMAIL",
    "not_approved_yet": 'Contul tău încă nu a fost verificat.',
    "not_approved_yet_ident": 'Identitatea ta încă nu a fost verificată.',
    "not_approved_yet_email": 'Email-ul/numărul tău încă nu a fost verificat.',
    'email': 'E-mail',
    'numar_de_telefon': 'Număr de telefon',
    'parola': 'Parola',
    "wrong_pwd": "Parola greșită",
    'declar_ca_am_majoratul':'Declar pe propria răspundere că am peste 18 ani și că am citit și acceptat',
    ' precum_si':'precum si',
    'parola_ste_obligatoriu.': 'Parola este obligatoriu.',
    'telefonul_este_obligatoriu.': 'Telefonul este obligatoriu.',
    'email_is_required.': 'Cimpul este obligaoriu.',
    'nume_de_utilizator': 'Nume de utilizator',
    'numele_de_familie': 'Numele de familie',
    'localitate': 'Localitate',
    'prenumele': 'Prenumele',
    "pronoun":"Prenume",
    "surname":"Nume de familie",
    "available_till":"Valabil până la",
    'adresa_strazii': 'Adresa Străzii',
    "idnp_series":"Seria și numărul documentului de identitate",
    "complete_address":"Adresa completă",
    'cod_numeric_personal': 'Cod numeric personal',
    'doresc_sa_primesc_bonusuri.':'Doresc să primesc bonusuri exclusive prin e-mail (te poți dezabona în orice moment).',
    'doresc_bonusuri_prin_SMS.': 'Doresc să primesc bonusuri exclusive prin SMS.',
    'inapoi': 'Inapoi',
    'urmatorul_pas': 'URMATORUL PAS',
    'conecteaza_te': 'Conecteaza-Te',
    'deja_ai_cont': 'Ai deja cont?',
    'cel_putin_6_caractere': 'Cel puțin  6 caractere',
    '6_caractere': '6 caractere',
    // 'cel_putin_o_litera_mica': 'Cel putin o litera mica',
    'cel_putin_o_litera_mica': 'Cel puțin o literă mică',
    'o_majuscula': 'O majusculă',
    'o_litera_mica': 'O literă mică',
    // 'cel_putin_o_litera_mare': 'Cel putin o litera mare',
    'cel_putin_o_litera_mare': 'Cel puțin o majusculă',
    // 'un_caracter_sau_un_numar': 'Cel putin un caracter special sau un numar',
    'un_caracter_sau_un_numar': 'Cel puțin o cifră',
    'o_cifra': 'O cifră',
    'profilul_meu': 'Profilul meu',
    'info_profilul_meu': '(profilul meu)',
    'validarea_contului': 'Validarea contului',
    'account_verification': 'Verificarea contului',
    'documentele_mele': 'Documentele mele',
    'schimbare_parola': 'Schimbare parolă',
    'activitate': 'Activitate',
    'inchidere_cont':'Închidere Cont',
    'retragere': 'Retragere',
    'depunere': 'Depunere',
    'balanta': 'Balanță',
    "balanta_totala": "Balanță",
    'balanta-pariuri': 'Pariuri în așteptare',
    'speciale_gratuite':'Speciale Gratuite',
    'campanii': 'Campanii',
    'tranzactii': 'Tranzacții',
    'oferte_disponibile': 'Oferte Disponibile',
    'limite_de_depunere': 'Limite De Depunere',
    'pauza': 'Pauză',
    'auto_excludere': 'Auto-Excludere',
    'chestionar_joc': 'Chestionar Joc Responsabil',
    'joc': 'Joc',
    'companii': 'Companii',
    'documents': 'Documente',
    'incarca': 'Încarcă alte documente',
    'document': 'Document',
    'adaugat': 'adăugat',
    "adauga_favorit": "Adaugă la favorite",
    "delete_favorit": " Șterge din favorite",
    'status': 'Status',
    'ultima_actualizare': 'Ultima actualizare',
    'salvare': 'Salvare',
    'prenume': 'Prenume',
    'cod_postal': 'Cod Poștal',
    'nume_de_familie': 'Numele de familie',
    'porecla': 'Poreclă',
    'iesire_din_cont': 'Ieșire din cont',
    // 'incarca_doc': 'Încărcați din documente',
    'incarca_document': 'Încărcare document',
    'parola_actuala': 'Parola actuală',
    'noua_parola': 'Parola nouă',
    'noua_parola_contine': ' O parolă nouă trebuie să îndeplinească următoarele condiții:',
    'deacord': 'Da, sunt de acord',
    'continutul_parolei': 'Parola trebuie să conțină cel puțin 6 caractere, o literă mare, o  literă mică și un număr',
    'timp_de_creare': 'Timp de creare',
    'adresa_ip': 'Adresa IP',
    'tip_de_activitate':'Tip de activitate',
    'timpul_activitatii': 'Timpul activității',
    'miza': 'Miza',
    'cistig': 'Câștig',
    'referinta': 'Referință',
    'nu_sunt_date': 'Nu există date de afișat',
    'metoda': 'Metodă',
    'suma': 'Sumă',
    'bonus': 'Bonus',
    'data_crearii': 'Data Creării',
    'actiune': ' Acțiune',
    'tip': 'Tip',
    'cash': 'Cash',
    'rotiri_gratuite': 'Rotiri Gratuite',
    'pariuri_gratuite': 'Pariuri Gratuite',
    'deposition': 'Depunere',
    'deposit': 'Depune',
    'bonusuri_disponibile': 'Bonusuri Disponibile',
    'termeni_and_conditii': 'Termeni & conditii',
    'ce_zici_de_o_pauza': 'Ce Zici De O Pauză?',
    'scurta_pauza': 'Dacă vrei să iei o scurtă pauză de la jocurile de noroc, poți activa un interval cuprins între 1 zi și 6 săptămâni în care nu-ți vei mai putea accesa contul. După trecerea acestei perioade de timp, te vei putea conecta și juca din nou.',
    'status_utilizator': 'Status Utilizator',
    'auto_excludere_conditii': 'Auto-excluderea presupune o pauză mai mare de la jocurile de noroc. Dacă vei opta pentru auto-excludere, îți vei închide contul pentru un minimum de 30 de zile.\n' +
        'Dacă vei dori să îți reaccesezi contul, trebuie să aștepți să treacă perioada selectată (vei primi oricum o notificare de la noi la finalul perioadei de auto-excludere).\n' +
        '\n' +
        'Apoi, e suficient să contactezi departamentul nostru de Suport , de la care vei afla ce pași trebuie să urmezi în continuare.',
    'alege_perioada_de_pauza' : 'Alege perioada de pauză',
    'pina_la_o_data': 'Până la o anumită dată',
    'permanent': 'Permanent',
    'optiuni_suspendare': 'Opțiuni pentru suspendarea contului:',
    'luni': 'Luni',
    'an': 'An',
    'ani': 'Ani',
    'alege_peropada_de_excludere': 'Alege perioada de excludere',
    'limita_depunerilor_cumulate': 'Limita Depunerilor Cumulate',
    'limita_depunerilor_cumulate_reprezinta': 'Limita Depunerilor Cumulate reprezintă suma maximă care poate fi depusă în timpul unei zile, a unei săptămâni sau a unei luni. Limita poate fi modificată oricând.',
    'zilnic': 'Zilnic',
    'limita_zilnica': 'Limită zilnică',
    'saptaminal': 'Săpt.',
    'limita_saptaminal': 'Limită săpt.',
    'lunar': 'Lunar',
    'limita_lunar': 'Limită Lunară',
    'revenire_la_limita': 'Revenire la limita',
    // 'elimina_limita': 'Revenire la limită',
    'elimina_limita': 'Renunță la schimbarea limitei',
    'anuleaza': 'Anulează',
    'da_sunt_de_acord': 'Da, sunt de acord',
    'da_elimina_limita': 'Da, elimină limita',
    'esti_sigur_ca_revii_la_limita_zilnica': 'Ești sigur că vrei să revii la Limita zilnica?',
    // 'limita_de_depunere_zilnica': 'Ești sigur că vrei să revii la Limita zilnică?',
    'limita_de_depunere_zilnica': 'Ești sigur că vrei să revii la Limita de depunere zilnică? Această acțiune nu poate fi anulată',
    'limita_saptaminala': 'Ești sigur că vrei să revii la Limita săptămânală?',
    // 'limita_de_depunere_saptaminala': 'Ești sigur că vrei să revii la Limita săptămânală?',
    'limita_de_depunere_saptaminala': 'Ești sigur că vrei să revii la Limita de depunere săptămânală? Această acțiune nu poate fi anulată',
    'elimini_limita_lunara': 'Ești sigur că vrei să elimini Limita lunară?',
    // 'elimina_limita_de_depunere_lunara': 'Ești sigur că vrei să revii la Limita lunară?',
    'elimina_limita_de_depunere_lunara': 'Ești sigur că vrei să revii la Limita de depunere lunară? Această acțiune nu poate fi anulată',
    'chestionar_joc_responsabil': 'Chestionar Joc Responsabil',
    'investesti_in_jocuri_mai_mult': 'Investești în jocurile de noroc mai mult decât îți permiți?',
    'niciodata': 'Niciodata',
    'uneori': 'Uneori',
    'deseori': 'Deseori',
    'intotdeauna': 'Întotdeauna',
    'veiw_more': 'Vezi mai multe',
    'bani_cash': 'Bani Cash',
    'rulaj_necesar': 'Rulaj necesar',
    'play': 'Joacă',
    'casino': 'Casino',
    'detalii': 'Detalii',
    'contactati_asistenta': 'Contactati asistența',
    'nu_am_gasit_pagina': ' Ne pare rău, dar nu am găsit pagina pe care o căutați. Încercați să mergeți înapoi sau să',
    'pentru_clienti': 'pentru clienți',
    'incarca_documente': 'Încarcă documente',
    'trimite': 'Trimite',
    'mesaj': 'Mesaj',
    'identification_card': 'Card de identitate',
    'other_document': 'Alt document',
    'error': 'Erroare',
    'loss': 'Pierzătoare',
    'rejected': 'Respins',
    'cimp_obligatoriu': 'Acest cîmp este obligatoriu.',
    'reseteaza': 'Resetează',
    'selectate': 'selectate',
    'search': 'Caută',
    'favorite': 'Favorite',
    'last-played': 'Jucate recent',
    'oferte_active': 'Oferte active',
    'life_rtp': 'Live RTP',
    'asistenta': 'Asistență',
    'informatii': 'Informații',
    "furnizori":'Furnizori',
    'cont': 'Cont',
    "sorteaza": "Sortează",
    'informatii_personale': 'Informații personale',
    'utilizeaza_butonul': 'Utilizează butonul pentru a adaugă mai multe documente.',
    'adauga_documente': 'Adaugă documente.',
    'istoric': 'Istoric',
    'tranzactii_joc': 'Tranzacții casino',
    'tranzactii_pariuri': 'Tranzacții pariuri',
    'depuneri': 'Depuneri',
    'setari': 'Setări',
    'confirma_cu_parola': 'Confirmă cu parola',
    'choose_bonus': 'Alege-ți Bonusul',
    'deposit_method': 'Metoda de depunere',
    'pick_method': "Alege metoda",
    'withoutBonus': 'Fără bonus',
    'cardToCard': 'Card bancar',
    'haveToPay': 'Ai de plată',
    'willGet': 'Primești',
    'verificarea_contului': 'Verificarea contului',
    'pentru_a_depune_liber': 'Pentru a putea depune liber, fără limite, și pentru a putea realiza retrageri, îți poți valida contul aici. Avem nevoie de două tipuri de documente: dovada identității și a adresei.',
    'limita_depunerei_maxime': 'Limita de depuneri cumulate reprezintă maximul sumei care poate fi depusă pe zi, pe săptămână sau pe lună. Această limită poate fi schimbată oricând.',
    'disponibil': 'Disponibil',
    'limita_curenta': 'Limită curentă',
    'pauza_de_joc': 'Pauză de joc',
    'auto_exclussion': 'Auto-Excludere',
    'poti_alege_un_interval': 'Poți alege un interval între o zi și 6 săptămâni în care nu vei putea să-ți accesezi contul de joc.',
    'salveaza':'Salvează',
    'limita_depunerilor_auto_excludere': 'Limita depunerilor cumulate reprezintă suma maximă care poate fi depusă în timpul unei zile, a unei săptămâni sau a unei luni. Limita poate fi modificată oricând.',
    'lorem': '<p>On the other hand,<p> we denounce with righteous indignation and dislike men who are so beguiled and demoralized by the charms of pleasure of the moment, so blinded by desire, that they cannot foresee the pain and trouble that are bound to ensue; and equal blame belongs to those who fail in their duty through weakness of will, which is the same as saying through shrinking from toil and pain. These cases are perfectly simple and easy to distinguish. In a free hour, when our power of choice is untrammelled and when nothing prevents our being able to do what we like best, every pleasure is to be welcomed and every pain avoided. But in certain circumstances and owing to the claims of duty or the obligations of business it will frequently occur that pleasures have to be repudiated and annoyances accepted. The wise man therefore always holds in these matters to this principle of selection: he rejects pleasures to secure other greater pleasures, or else he endures pains to avoid worse pains',
    'support': 'Ajutor',
    'inchide_contul': 'Închidere cont',
    'daca_nu_e_tirziu_contacteaza': 'Dacă nu e prea târziu, contactează-ne și vom încerca să găsim o soluție',
    'comunicare': 'Comunicare',
    'bonusuri_prin_sms': 'Bonusuri exclusive prin sms',
    'bonusuri_prin_sms2': 'Doresc să primesc bonusuri exclusive prin SMS.',
    'bonusuri_pin_email': 'Bonusuri personalizate prin email',
    'bonusuri_pin_email2': 'Doresc să primesc bonusuri exclusive prin e-mail.',
    'descrie_problema': 'Descrie-ne problema aici',
    'depune': 'Depune',
    'document_aprobat': `Documentul de identitate a <br/>fost aprobat.`,
    'Player account is frozen': 'Autentificarea a eșuat. Contul este blocat, vă rugăm să contactați serviciul de asistență',
    'close': 'Închide',
    'contacteaza_ne': 'Contactează-ne',
    'inchide_contul_definitiv': 'Închide contul',
    'favorite_game_added': 'Jocul a fost adăugat cu succes la favorite!',
    'favorite_game_removed': 'Jocul a fost șters de la favorite!',
    'upload': 'Încarcă',
    'documentele_incărcate': 'Documentele încărcate.',
    'utilizeaza_buttonul': 'Utilizează butonul pentru a vedea toate documentele încărcate si verifica statusurile lor.',
    'nu_ai_incarcat_document': 'Nu ai încărcat nici un document.',
    'ai_incarcat': 'Ai  încărcat',
    'acestea_vor_fi_verificate': ' document, acestea vor fi verificate de echipa noastră în cel mai scurt timp posibil. Poți verifica statusul lor oricând.',
    'revendica_bonusul': 'Revendica bonusul',
    'conditii_bonus': 'Condiții bonus',
    'profita_de_pachet': 'Profita acum de incredibelul Pachet de Bun Venit de pana la 5.000 RON + 1.000 Rotiri Gratuite',
    'toate_detaliile': 'Toate detaliile',
    'valideaza_contul': 'validează contul',
    'are_you_shure': 'Ești sigur?',
    "processing":"procesare",


    'password_required': 'Parola este obligatorie',
    'closeAccountErrorDescription': 'Am întâmpinat o eroare în procesul de inchidere a contului. Te rugăm să încerci mai târziu sau să contactezi Echipa de Asistență Clienți.',
    'accountClosed': 'Contul tău a fost închis',
    "No particular reason": 'Fără motiv',
    "Technical issues": 'Probleme tehnice',
    "Do not like the bonuses": 'Nu îmi plac ofertele',
    'Responsible Gaming issues': 'Nu mai am timp de jocuri',
    'I am not happy with your service': 'Nu îmi plac serviciile oferite',
    'I want to play on other sites': 'Vreau să joc pe alte site-uri',
    'I don’t have time to play at the moment. I might come back later': 'Nu am timp să joc acum. Poate revin mai târziu',
    'Do not like the website': 'Nu-mi place site-ul',
    'joaca_acum': 'joacă acum',
    'joaca': 'Joacă ',
    'depune_acum':'depune acum',
    'resetare_parola': 'Resetare parolă',
    'pentru_resetare': 'Pentru a-ți reseta parola, introdu data de naștere și emailul cu care te-ai înregistrat',
    'trimite_email': 'Trimite email',
    'data_nasterii': 'Data nașterii',
    'reseteaza_parola': 'Resetează parola',
    'confirma_parola': 'Confirmă parola',
    'confirma_parola_noua': 'Confirmă parola nouă',
    'codul_de_activare': 'Codul de activare',
    'vezi_mai_multe': 'Vezi mai multe',
    'filtreaza': 'Filtrează',
    "filtre": "Filtre",
    'contul_meu': 'Contul meu',
    'incarcati_documente': 'Încarcă din documente',
    'drag_document': 'Trageți și plasați fișiere aici sau',
    'choose_file': 'Alegeți fișierul',
    'retrimite': 'Retrimite',
    'platinum': 'Platinum',
    'gold': 'Gold',
    'silver': 'Silver',
    'bronze': 'Bronze',
    "new_games": "Jocuri Noi",
    'jocuri_recomandate': 'Jocuri recomandate',
    'upload_date': 'Data încărcării',
    'document_name': 'Numele documentului',
    'save': 'Salvează',

    //TOMBOLA PAGE
    'tombola-title': 'Premii de milioane - câștigă 3x Ranger Rover Velar la CashPot Online Casino',
    'particip': 'Cum particip?',
    'deschide cont-heading': 'Deschide cont?',
    'deschide cont-text': 'Ca să poți intra în extrageri e suficient să te înregistrezi pe CashPot.ro, să-ți validezi contul și să începi să joci.',

    'joaca-heading': 'Joacă',
    'joaca-text': 'Fiecare rulaj de 200 RON în casino îți aduce un tichet în tombolă.',
    'tichete-heading': 'Colectează tichete și crește-ți șansele',
    'tichete-text': 'Pariază și câștigă mii de premii în extragerile zilnice și săptămânale.',

    'premii ramase-footer' : 'premii rămase',
    'premii acordate-footer' : 'premii acordate',
    'premii auto-footer' : 'premii auto',

    'premii ramase' : `5,289x premii <br/> în valoare totală de`,
    'premii acordate' : `9,729x premii <br/>  în valoare totală de`,

    //TICKETS

    'tickets-heading': 'tichete',
    'tickets-connect1': 'Conectează-te',
    'tickets-connect2': ' pentru a afla dacă ești calificat în extrageri.',
    'tickets-create-acc1': 'Nu ai un cont? ',
    'tickets-create-acc2': 'Deschide acum',
    'tickets-create-acc3': ' și obține 100 Rotiri Gratuite fără depunere!',
    'tickets-how-to': 'Cum particip?',

    'tickets-info': 'Joacă și  obține tichete! Mai multe tichete - mai multe șanse de câștig.',
    'tickets-awards-daily': 'Premii zilnice',
    'tickets-awards-weekly': 'Premii săptămânale',
    'tickets-awards-auto': 'Premii auto',
    'istoric_retrageri': 'Istoric  Retrageri',
    'get_tickets': 'obține tichete',
    



    'tickets-awards-weekly-sm': 'săptămânale',
    'tickets-awards-auto-sm': 'auto',

    'casierie': 'Casierie',
    'conditii_generale_bonus': 'Condiții generale pentru bonus',

    'Player account is cool off': 'Autentificarea a eșuat. Contul este în repaus!',
    'Player account is deactivated - self excluded': 'Autentificarea a eșuat. Contul de jucător este dezactivat - autoexclus!',
    'Unauthorized': 'Autentificarea a eșuat, te rugăm să verifici datele de logare, logarea cu date greșite poate duce la blocarea contului',
    'Player account is deactivated - self closed' : 'Autentificarea a eșuat. Contul este închis!',

    // 'This \"userName\" is already registered': 'Acest \"userName\" este deja înregistrat',

    'username_used': 'Acest nume de utilizator este deja înregistrat',

    'remove_limit': 'Elimină Limita',
    'remove_daily_limit': 'Ești sigur că vrei să elimini limita de depunere zilnică',
    'remove_weekly_limit': 'Ești sigur că vrei să elimini limita de depunere săptămânală',
    'remove_monthly_limit': 'Ești sigur că vrei să elimini limita de depunere lunară',
    'yes_im_agree': 'Da, sunt de acord',
    'no': 'Nu',
    'freeSpins': 'Rotiri Gratuite',
    'freeBonus': 'Specială Gratuită',
    'freeBets': 'Pariuri gratuite',
    'transactions_details': 'Detalii tranzacție',




//    STRINGS FOM QUEST
    'quest-header-text': 'Avansează pe Insula Comorii pentru a obține premii individuale de până la 15.000 RON. Fii cel mai rapid jucător care atinge nivelul 25 și câștigă o Barcă de Lux sau nivelul 30 pentru a obține marele premiu - BMW Z4!',
    'Player "withdrawals" is blocked': 'Retragerile sunt blocate. Te rugăm să contactezi echipa de asistență!',

// STRINGS FOR BET TRANSACTIONS
    'winBet':'câștigat',
    'lostBet':'pierdut',
    'pendingBet':'în curs',
    'paidBet': 'încasat',
    'canceledBet': 'Anulat',

    'cashout_error' : 'Eroare. Biletul nu a fost încasat',

    'cashout_config': 'Configurația preferată de cashout',
    'exact': 'exact',
    'any_modification': 'orice modificare',
    'share_ticket': 'trimite bilet',
    'cupon_id':'id cupon',
    'miza_diacritice': 'Miză',
    'cote':'Cote',
    'winning':'Câștig',
    'possible_winning':'Câștig potențial',
    'selections':'Selecții',
    'sport_page_title': 'Pariuri Sportive Online | CashPot',
    'sport_page_title_spins': 'Pariuri Sportive Online | SPIN.ro',
    'open_footer':'deschide footer',
    'close_footer':'închide footer',

//     STRINGS FOR PROMOTIONS
    'expired_promotions':'Promoții Expirate',
    'old_promotions': 'Alte Promoții',

//     CANDY QUEST CANDY QUEST CANDY QUEST CANDY QUEST CANDY QUEST CANDY QUEST
    'want_to_play':'vreau să particip',
    'journey_ends_in': 'Aventura se termină în',
    "activity_history": "Istoric activitate",
    "limit_settings":"Setări limite",
    "as_well_as":"precum și",
    "declare_on_my_own":" Declar pe propria răspundere că am peste 18 ani și că am citit și acceptat",
    "bonus_email": 'Doresc să primesc bonusuri exclusive prin e-mail (te poți dezabona în orice moment)',
    "bonus_sms": 'Doresc să primesc bonusuri exclusive prin SMS',
    "agree":'Da, sunt de acord',
    "next_step": "Următorul pas",

    "wheel_success": 'Roata norocului',
    "adventure": 'Aventura',
    "participate":"Participă",
    "retragerea_balantei":"În cazul în care veți efectua o retragere, balanța dvs. bonus în valoare de",
    "se_anuleaza":"va fi anulat.",
    "retragere_din_balanta":"Retragerea se va face numai din balanța cash, nu și din cea bonus",
    "tax_paid":"Va fi aplicată o taxă de",
    "max_retragere":"Max retragere",
    "limita_maxima_retragere":"Exceeded the max limit",
    "limita_maxima_retragereRO":"Limita maximă de retragere este",
    "eroare_necunoscuta":"Eroare necunoscută",
    "retragere_cu_success":"Retragerea a fost completată cu succes și va fi procesată câteva momente",
    "runde_gratuite":"runde gratuite",
    "spaciala_gratuita":"specială gratuită",
    "retragere_bani":"Retragere bani",
    "depunere_minima":"Depunere minimă",
    "max_dep": "Depunere maximă",
    "suma_depusa_disponibila":"Suma depusă disponibilă este",
    "datorita_limitei_acumulate":"datorită limitei cumulate",
    "access":"Accesează",
    "acest_link":"acest link",
    "info_limita_ta":"pentru mai multe informații despre limitele tale",
    "adauga_fonduri":"Adaugă fonduri",
    "limita_de_depunere":"Limita de depunere",
    "ai_atins_limita_de_depuneri":"Ai atins limita de depuneri de",
    "trebuie_sa":"Trebuie să",
    "validezi_contul":"validezi contul",
    "pentru_depunere":"pentru a putea depune în continuare",
    "mai_poti_depune":"Mai poți depune",
    "fara_validare":"fără a fi necesară validarea contului",
    "incarca_dovada_document":"Te rugăm să încarci dovada identității",
    "aici":"aici",
    "pentru_eliminare_depunerei":"pentru a elimina limita de depunere",
    "cum_folosim_feebdack":"Cum folosești un Freebet",
    "404pagetext":"Hopa! ceva n-a mers bine.",
    "acc":"cont",
    "history":"istoric",
    "settings":"setari",
    "help":"ajutor",
    "congrats":"Felicitări!\n" + "Ai câștigat",
    "bingo":"Bingo",
    "doc_upload_mkshure": "Asigurați-vă că imaginea conține întregul document (toate cele 4 colțuri) și următoarele detalii:",
    "next_extraction": "Următoarea extragere în",
    "tickets":"Tichete",
    "points":"Puncte",
    "optIn":"Vreau să particip",
    "not_existing_nr": "Număr de telefon inexistent",
    "not_existing_user": "Utilizator inexistent",
    "one_attempt_left": "Mai ai 1 încercare până la blocarea contului",
    "insert_nr":"Introdu numărul tău de telefon ",
    "insert_nr1":" pentru a accesa contul acum.",
    "send_via_sms":"Trimite codul prin sms",
    "require_phone_err":"Introduceti un număr de telefon",
    "code_sent": "Codul a fost transmis la numărul de telefon ",
    "account_is_blocked":"Autentificarea a eșuat. Contul este blocat.",
    "mobile_check": "Verificare telefon",
    "play_hub": "play arena",
    "like_to_win": "Îți place SĂ CÂȘTIGI?",
    "play_real": "Joacă pe bani reali!",
    "mobile_app": "Aplicația mobilă",
    "dwn_app":"Descarcă acum"
    
}

export default strings

























