import Modal from 'react-modal';
import {useDispatch, useSelector} from 'react-redux';
import {openVerificationModal} from 'store/modals/actions';
import useAuth from 'hooks/auth';
import {useState, useEffect} from 'react';
import {useRouter} from 'next/router';
import {getVerificationStatus} from "utils/cookies";
import {updateVerificationStatus} from "store/account/actions";
import VerificationStatus from "interfaces/VerificationStatus";
import styles from "@/template/styles/shareModal.module.scss";
import ModalLayout from "@/template/ui/modal_layout";
import clsx from 'clsx';
import strings from 'utils/strings';
import Form from './Form'


function VerificationModal() {
    const [tab, setTab] = useState(1);
    const router = useRouter();

    const dispatch = useDispatch();
    const auth = useAuth();
    const open = useSelector((state:any) => state.modals.openVerification);
    const verificationStatus:Array<VerificationStatus> = useSelector((state:any) => state.account.verificationStatus);
    const logged:boolean = auth.isLoggedIn();

    useEffect(() => {
        dispatch(updateVerificationStatus(auth.verificationStatusFactory(getVerificationStatus())));
    }, []);

    useEffect(() => {
        const {modal} = router.query;
        let allVerified:boolean = true;

        verificationStatus.map(item => {
            if (!item.verified) allVerified = false;
        });

        if (logged && modal === 'verification' && !open && !allVerified) {
            dispatch(openVerificationModal(true));
        }
    }, [logged, verificationStatus, router.query]);

    const phoneVerification:VerificationStatus | undefined = verificationStatus.find(item => item.method === 'phone' && !item.verified);
    const emailVerification:VerificationStatus | undefined = verificationStatus.find(item => item.method === 'email' && !item.verified);

    const phoneHeaderText = 'Bun venit la CashPot Casino! Pentru a începe să joci, te rugăm să îți verifici numărul de telefon.';
    const emailHeaderText = 'Bun venit la CashPot Casino! Pentru a începe să joci, te rugăm să îți verifici e-mailul. Ți-am trimis deja un cod de verificare prin e-mail, introdu-l mai jos și apoi apasă "Verificare".';


    return (
        <Modal
            ariaHideApp={false}
            isOpen={(open && (phoneVerification || emailVerification))}
            // isOpen={true}
            onRequestClose={() => dispatch(openVerificationModal(false))}
            contentLabel="Example Modal"
            className={clsx(styles.modal, 'theme_box_bg_shaded3')}
        >

            <ModalLayout onClose={() => dispatch(openVerificationModal(false))}>

                <>
                    <div className="ReactModal__Inner__Header">
                        <h2 className="ReactModal__Title">{strings['verification']}</h2>
                    </div>

                    {open &&
                        <div className={clsx("ReactModal__Inner__Content", styles.modalContent)}>
                            {(phoneVerification && emailVerification) ?
                                (
                                    <>
                                        {tab === 1 && <p className={styles.paragraph}>{phoneHeaderText}</p>}
                                        {tab === 2 && <p className={styles.paragraph}>{emailHeaderText}</p>}

                                        <div className={styles.tabs}>
                                            {phoneVerification && (
                                                <button
                                                    type={'button'}
                                                    className={clsx(
                                                        styles.tabs__item,
                                                        tab === 1 && styles.tabs__item__active
                                                    )}
                                                    onClick={() => setTab(1)}
                                                >
                                                    Codul primit prin SMS
                                                </button>
                                            )}
                                            {emailVerification && <button type={'button'} className={clsx(styles.tabs__item, tab === 2 && styles.tabs__item__active)} onClick={() => setTab(2)}>Codul primit prin email</button>}
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        {phoneVerification !== undefined && <p className={styles.paragraph}>{phoneHeaderText}</p>}
                                        {emailVerification !== undefined && <p className={styles.paragraph}>{emailHeaderText}</p>}
                                    </>
                                )}

                            {(phoneVerification && emailVerification) ? (
                                <>
                                    <div style={{display: tab === 1 ? 'block' : 'none'}}><Form data={phoneVerification}/></div>
                                    <div style={{display: tab === 2 ? 'block' : 'none'}}><Form data={emailVerification}/></div>
                                </>
                            ) : (
                                <>
                                    {phoneVerification && <Form data={phoneVerification}/>}
                                    {emailVerification && <Form data={emailVerification}/>}
                                </>
                            )}
                        </div>
                    }
                </>

            </ModalLayout>

        </Modal>
    )
}

export default VerificationModal