import styles from 'themes/default/styles/card.module.scss';
import clsx from 'clsx';
import CloseIcon from "themes/default/svg/closeicon";
import React, {useState, useEffect} from 'react';
import GameInterface from "interfaces/game";
import strings from "utils/strings";
import {useDispatch, useSelector} from 'react-redux';
import {openMiniGameModal, openFullGameModal} from "store/modals/actions";
import { useRouter } from 'next/router';
import useAuth from "hooks/auth";
import FavoriteButton from "./favoriteButton";


const PopUpMini = () => {

    const auth = useAuth();

    const hide:any = () => {
        dispatch(openMiniGameModal(undefined))
    }
    const keepOverlay:any = (e:any) => {
        e.stopPropagation();
    }

    const [isTransition, setIsTransition] = useState<boolean>(false)

    useEffect(() => {
        setTimeout(() => {
            setIsTransition(true)
        }, 700)
    }, [])

    const router = useRouter();

    const game:GameInterface | undefined = useSelector((state:any) => state.modals.openMiniGame);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(openMiniGameModal(undefined))

    }, [router.pathname])

    useEffect(() => {
        let scrollPosition = 0;
        if(game){
            const root =  document.getElementsByTagName('html')[0]

            scrollPosition = window.pageYOffset;
            root.style.overflow = 'hidden';
            root.style.top = `-${scrollPosition}px`
            root.classList.add('bodyScrollHide')

        } else if(!game) {
            const root =  document.getElementsByTagName('html')[0]
            root.classList.remove('bodyScrollHide')

            root.style.removeProperty('overflow');

            }

        }, [game])


    return (
        <div style={{transition: `${isTransition && 'all 0.3s ease'}`}} className={clsx(styles.overlay, game && styles.active, )} onClick={hide}>
            <div style={{transition: `${isTransition && 'all 0.3s ease'}`}} className={clsx(styles.popup,'clickedBtn', Boolean(game) && styles.active)} onClick={keepOverlay}>
                <div className={styles.popup__closeicon}
                     onClick={() => dispatch(openMiniGameModal(undefined))}>

                    <CloseIcon />
                </div>
                <div className={styles.popup__wrapper}>
                    <div className={styles.popup__imageWrapper}>
                        <div><img src={game?.thumbnail} alt={game?.friendlyName}/></div>
                        <div className={styles.popup__providerImage}><img src={game?.providerImage} alt=""/></div>
                    </div>

                    <div className={styles.popup__gameNameBlock}>
                        <div className={styles.popup__gameName}>
                            {/*{game !== undefined &&  <FavoriteButton game={game} styledIcon />}*/}
                            { auth.isLoggedIn()  && (game !== undefined &&  <FavoriteButton game={game} styledIcon />)}
                            <div>{game?.friendlyName}</div>
                            {/*{(typeof game?.rtp === "string" && Number(game.rtp) > 0 && !game?.hotRTP && !game?.coldRTP) &&*/}
                            {/*    <div className={styles.card__subtitle}>RTP {(Number(game?.rtp) * 100).toFixed(2)}%</div>*/}
                            {/*}*/}
                        </div>

                        {game &&
                            <button onClick={() => auth.runGame(game, false)}
                                    className={clsx("btn__primary", styles.popup__btnSeeMore)}>
                                {strings['joaca_acum']}
                            </button>
                        }

                        {(game?.demoAvailable) && <div onClick={() => auth.runGame(game, true)}>
                            <button
                                    className={styles.popup__demoBtn}
                                    type={'button'}>Demo
                                    <img className={styles.popup__demoBtnArrow}
                                    src="/default/icons/menuArrow.svg" alt="img"/>
                            </button>
                        </div>}
                    </div>
                </div>

                <div className={styles.popup__seeMoreWrapper}
                     onClick={() => {
                         dispatch(openFullGameModal(game));
                         dispatch(openMiniGameModal(undefined))
                     }}
                >
                    <button

                        className={styles.popup__seeMoreBtn}>{strings['vezi_mai_multe']}
                        <img className={clsx(styles.popup__demoBtnArrow, styles.popup__seeMore)} src="/default/icons/menuArrow.svg" alt="img"/></button>
                </div>

            </div>
        </div>
    )
}
export default PopUpMini;