import {FC} from 'react';
import strings from "utils/strings";
import Link from 'next/link';
import  styles from 'themes/default/styles/sidebarMenu.module.scss';
import clsx from 'clsx';
import SidebarMenuViewInterface from "interfaces/ui-view-interfaces/sidebar_menu_view_interface";


const DefaultSideBarMenuView:FC<SidebarMenuViewInterface> = ({ ... sidebarProps }) =>  {

    const {
        currentRoute
    } = sidebarProps

    return (
        <div className={styles.sidebarMenu}>
             <ul className={styles.sidebarMenu__list}>
                 <li><Link   className={clsx(currentRoute === '/about-us' && styles.active)} href={'/about-us'} >{strings['despre_noi']}</Link></li>
                 <li><Link   className={clsx(currentRoute === '/privacy-policy' && styles.active)} href={'/privacy-policy'}>{strings['politica_de_confidențialitate']}</Link></li>
                 <li><Link   className={clsx(currentRoute === '/responsible-gambling' && styles.active)} href={'/responsible-gambling'}>{strings['joc_responsabil']}</Link></li>
                 <li><Link   className={clsx(currentRoute === '/terms-and-conditions' && styles.active)} href={'/terms-and-conditions'}>{strings['termeni_și_condiții']}</Link></li>
                 <li><Link   className={clsx(currentRoute === '/bonus-conditions' && styles.active)} href={'/bonus-conditions'}>{strings['conditii_generale_bonus']}</Link></li>

                 <li ><Link   className={clsx(currentRoute === '/banking' && styles.active)} href={'/banking'}>{strings['casierie']}</Link></li>
                 <li><Link   className={clsx(currentRoute === '/faq' && styles.active)} href={'/faq'}>{strings['intrebari_frecvente']}</Link></li>
                 <li><Link   className={clsx(currentRoute === '/https://cash-pot.ro/' && styles.active)} href={'https://cash-pot.ro/'} target={'_blank'}>{strings['sali_de_joc']}</Link></li>
                 <li><Link   className={clsx(currentRoute === '/contact' && styles.active)} href={'/contact'}>{strings['contact']}</Link></li>

                 <li><Link className={clsx(currentRoute === '/freebet' && styles.active)} href={'/freebet'}>Cum folosești un Freebet</Link></li>
                 <li><Link className={clsx(currentRoute === '/bet_offer' && styles.active)} href={'/bet_offer'}>Ofertă de pariuri</Link></li>
                 <li><Link className={clsx(currentRoute === '/bet_rules' && styles.active)} href={'/bet_rules'}>Regulament pariuri</Link></li>
                 <li><Link className={clsx(currentRoute === '/number_bets' && styles.active)} href={'/number_bets'}>Pariuri pe numere</Link></li>
                 <li><Link className={clsx(currentRoute === '/virtual_games_rules' && styles.active)} href={'/virtual_games_rules'}>Regulament Jocuri Virtuale</Link></li>
             </ul>
        </div>
    )
}

export default DefaultSideBarMenuView;