import { FC } from 'react';
import Head from 'next/head';
import CMS from "utils/cms";

interface Props {
    json: object;
}

const Seo:FC<Props> = ({json}) => {
    const data = CMS.jsonSeo(json);

    return (
        <Head>

            {data.title.length > 0 && (
                <>
                    <title>{data.title}</title>
                    <meta name="title" content={data.title} />
                    <meta property="og:title" content={data.title} />
                </>
            )}

            {data.description.length > 0 && (
                <>
                    <meta name="description" content={data.description} />
                    <meta property="og:description" content={data.description} />
                </>
            )}
            {data.keywords.length > 0 && <meta name="keywords" content={data.keywords} />}

        </Head>
    )
}

export default Seo