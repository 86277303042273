import {FC} from 'react';
import CMS from "utils/cms";
import styles from 'themes/default/styles/card.module.scss';
import {useState, useEffect} from 'react';
import GameInterface from "interfaces/game";
import Game from "utils/game";
import CircularProgress from '@mui/material/CircularProgress';
import clsx from 'clsx';
import GameGrid from "themes/default/ui/game/grid";
import OutlinedSelector from "@/template/small_ui/OutlinedSelector";
import Filter from "themes/default/svg/filter";
import {
    featuresFilterSlugPage,
    providerFilterSlugPage,
    themeFilterSlugPage,
    volatilityFilterSlugPage
} from "store/common/actions";
import { useRouter } from 'next/router'
import { useSelector, useDispatch } from 'react-redux'
import Button from "@/template/small_ui/button";
import Close from 'themes/default/svg/close';
import { STATIC_PATHS } from 'utils/cms'
import GamesFilterGrid from "@/template/cms-views/pageTypeCategoryGames/GamesFilter";


interface Props {
    json:any;
    api_id:string;
}

const PageTypeCategoryGames:FC<Props> = ({json, api_id}) => {
    const data = CMS.jsonCategoryGamesPage(json);
    const allGames:Array<GameInterface> = Game.getAll();
    const providers:Array<any> = CMS.jsonAllProvidersPages().providers;

    const [games, setGames] = useState<Array<GameInterface>>(allGames.filter((g:any) => g.categories.includes(data.category_id)));
    const [loading, setLoading] = useState(false);
    const [isCateg, setIsCateg] = useState<boolean | undefined>(undefined);
    const [searchSort, setSearchSort] = useState<string>('')
    const [overlayActive, setOverlayActive] = useState<boolean>(false)
    const [elementRemoved, setElementRemoved] = useState<boolean>(true)
    const [openProviderFilter, setOpenProviderFilter] = useState<boolean>(false)
    const [openFeatureFilter, setOpenFeatureFilter] = useState<boolean>(false)
    const [openThemeFilter, setOpenThemeFilter] = useState<boolean>(false)
    const [openVolFilter, setOpenVolFilter] = useState<boolean>(false)

    const activeProviders:Array<any> = useSelector((state: any) => state.common.slugSearchProviders);
    const activeFeatures: Array<any> = useSelector((state: any) => state.common.slugSearchFeatures);
    const activeThemes: Array<any> = useSelector((state: any) => state.common.slugSearchTheme);
    const activeVolatility: Array<any> = useSelector((state: any) => state.common.slugSearchVolatility);
    const dispatch = useDispatch()
    const route = useRouter();
    const static_path = Array(STATIC_PATHS);

    let volatility = Game.getFilters().volatilityFilters;
    let allThemes = Game.getFilters().themesFilters;
    let features = Game.getFilters().featuresFilters;

    const categIsProvider:boolean = providers?.some((prov) => prov.category_id === data.category_id);


    const tempMatchGames = ():Array<GameInterface> => {
        let result:Array<GameInterface> = [];
        games?.forEach((game:GameInterface) => {
            const isProvider = activeProviders.length === 0 || game.categories.some(category => activeProviders.includes(category));
            const isFeature = activeFeatures.length === 0 || activeFeatures.some((feature) => game.features?.includes(feature));
            //@ts-ignore
            const isTheme = activeThemes.length === 0 || activeThemes.some((theme) => game.themes?.includes(theme));
            const isVolatility = activeVolatility.length === 0 || activeVolatility.includes(game.volatility);
            if(isProvider && isFeature && isTheme && isVolatility) {
                result.push(game);
            }
        })

        if (searchSort === 'a-z') {
            result.sort((a:any, b:any):number => {
                const prevName = a.friendlyName;
                const nextName = b.friendlyName;

                if (prevName > nextName) {
                    return 1;
                }

                if (prevName < nextName) {
                    return -1;
                }

                return 0;
            });

        } else if(searchSort === 'z-a') {
            result.sort((a:any, b:any):number => {
                const prevName = a.friendlyName;
                const nextName = b.friendlyName;

                if (prevName > nextName) {
                    return -1;
                }

                if (prevName < nextName) {
                    return 1;
                }

                return 0;
            });
        }

        return result;
    }

    const availableGames = tempMatchGames();

    const selectFilters = (provider_id:string , action:(arg:any)=> any) => {
        let list:Array<any> = JSON.parse(JSON.stringify(activeProviders));

        if (list.filter((id:any) => id === provider_id).length) {
            list = list.filter((id:any) => id !== provider_id)
        } else {
            list.push(provider_id);
        }

        dispatch(action(list));
    };

    const selectMultipleFilters = (item: any, initialArray:Array<any>): Array<any> => {
        let list: Array<any> = [ ... initialArray];

        if(list.filter((x:any) => x === item).length) {
            list = list.filter((x:any) => x !== item)
        } else {
            list.push(item);
        }

        return list
    }

    let areProviders:boolean = activeProviders.length > 0
    let areVolatilities:boolean = activeVolatility.length > 0
    let areThemes:boolean = activeThemes.length > 0
    let areFeatures:boolean = activeFeatures.length > 0

    const emptyStates = () => {
        dispatch(providerFilterSlugPage([]));
        dispatch(featuresFilterSlugPage([]));
        dispatch(themeFilterSlugPage([]));
        dispatch(volatilityFilterSlugPage([]));
    }

    useEffect(() => {
        const gamesFound:Array<GameInterface> = allGames.filter((g:any) => g.categories.includes(data.category_id));

        gamesFound.sort((a:any, b:any) => {
            if (a.categoryOrder[data.category_id] > b.categoryOrder[data.category_id]) {
                return 1;
            }

            if (a.categoryOrder[data.category_id] < b.categoryOrder[data.category_id]) {
                return -1;
            }

            return 0;
        });

        setGames(gamesFound);

        const timeout = setTimeout(() => {
            setElementRemoved(false)
        }, 700)

        return ()=> {
            clearTimeout(timeout)
        }

    }, []);

    useEffect(() => {
        //@ts-ignore
        if (!static_path.includes(route.query.slug)) {
            setIsCateg(true)
        } else {
            setIsCateg(false)
        }

        dispatch(providerFilterSlugPage([]))

    }, [route.query.slug])


    return (
        <div data-section='page-type-categories-slide' >

            {isCateg === undefined && (<></>)}

            <div className={styles.header}>
                <h1 className={styles.page_title}>{data.title}<span className={styles.page_title__count}>{games.length}</span></h1>

                <button onClick={() => {setOverlayActive(true)}} className={clsx(styles.filtersBtn, styles.pageCategs)}>
                    <Filter />
                </button>

                <div className={styles.filters}>
                    {!categIsProvider && (
                        <OutlinedSelector
                            label={'furnizori'}
                            options={[{key: '', label: areProviders? '' : 'toți' }]}
                            providers={providers}
                            selectedCount={
                                //@ts-ignore
                                areProviders? activeProviders.length : ''
                            }
                        />
                    )}

                    {/*<OutlinedSelector*/}
                    {/*    label={'caracteristici'}*/}
                    {/*    options={[{key: '', label: areFeatures? '' : 'toate' }]}*/}
                    {/*    features={features}*/}
                    {/*    selectedCount={*/}
                    {/*        //@ts-ignore*/}
                    {/*        activeFeatures? activeFeatures.length : ''*/}
                    {/*    }*/}
                    {/*/>*/}

                    {/*<OutlinedSelector*/}
                    {/*    label={'teme'}*/}
                    {/*    options={[{key: '', label: areThemes? '' : 'toate' }]}*/}
                    {/*    themes={allThemes}*/}
                    {/*    selectedCount={*/}
                    {/*        //@ts-ignore*/}
                    {/*        activeThemes? activeThemes.length : ''*/}
                    {/*    }*/}
                    {/*/>*/}

                    {/*<OutlinedSelector*/}
                    {/*    label={'volatilitate'}*/}
                    {/*    options={[{key: '', label: areVolatilities? '' : 'toate' }]}*/}
                    {/*    volatility={volatility}*/}
                    {/*    selectedCount={*/}
                    {/*        //@ts-ignore*/}
                    {/*        activeVolatility? activeVolatility.length : ''*/}
                    {/*    }*/}
                    {/*/>*/}


                    <OutlinedSelector
                        label={'Sortează'}
                        defaultValue={searchSort}
                        onChange={(value) => {
                            setSearchSort(value)
                            // console.log(value)
                        }}
                        options={[
                            {key: 'a-z', label: 'Alfabetic A-Z'},
                            {key: 'z-a', label: 'Alfabetic Z-A'},
                        ]}
                    />

                    {(activeProviders.length > 0 || activeThemes.length > 0 || activeFeatures.length > 0 || activeVolatility.length > 0 || searchSort.length > 0) ? (
                        <Button onClick={() => {
                            setSearchSort('');
                            emptyStates()
                        }} className={styles.resetButton} text={'resetează'} />
                    ) : null}

                </div>

                <div  onClick={() => {setOverlayActive(false)}} className={clsx(styles.overlayBlock, overlayActive && styles.overlayBlockActive)}>

                </div>

                {/*OVERLAY FILTERS START OVERLAY FILTERS START OVERLAY FILTERS START OVERLAY FILTERS START */}

                {!elementRemoved && (
                    <div className={clsx(styles.overlayFilters, overlayActive && styles.overlayFiltersActive)}>

                        <div id={'mobFilterHeader'} className={styles.overlayFilters__header}>
                            <h4 className={styles.overlayHeading}>
                                Filtrează
                            </h4>

                            <button onClick={() => {setOverlayActive(false)}} className={styles.closeButton} >
                                <Close/>
                            </button>
                        </div>

                        <Button onClick={() => {
                            setSearchSort('');
                            emptyStates()
                        }} className={styles.resetButton} text={'resetează'} />

                        <div className={styles.scrollFilters} >

                            {/*PROVIDERS MOBILE FILTER START PROVIDERS MOBILE FILTER START PROVIDERS MOBILE FILTER START */}
                            {!categIsProvider && (
                                <>
                                    <OutlinedSelector
                                        label={'furnizori'}
                                        options={[{key: '', label: areProviders? '' : 'toți' }]}
                                        disableClickAwayListener
                                        style
                                        className={styles.outlinedFilter}
                                        onOpen={() => {
                                            setOpenProviderFilter(true);
                                        }}
                                        onClose={() => {
                                            setOpenProviderFilter(false);
                                        }}
                                        selectedCount={areProviders? activeProviders.length : ''}
                                    />

                                    <GamesFilterGrid
                                        activeItems={activeProviders}
                                        open={openProviderFilter}
                                        filterItems={providers}
                                        onClickItem={(e: React.MouseEvent<HTMLDivElement>, item:any) => {
                                            selectFilters(item['category_id'], providerFilterSlugPage)
                                        }}
                                    />

                                    <div className={clsx(styles.divider)}></div> {/* DIVIDER */}
                                </>
                            )}

                            {/*PROVIDERS MOBILE FILTER END PROVIDERS MOBILE FILTER END PROVIDERS MOBILE FILTER END */}



                            {/*FEATURES MOBILE FILTER START FEATURES MOBILE FILTER START FEATURES MOBILE FILTER START */}
                            {/*<>*/}
                            {/*    <OutlinedSelector*/}
                            {/*        // key={'search-filter-toggle-'+active.toString()}*/}
                            {/*        label={'caracteristici'}*/}
                            {/*        options={[{key: '', label: areFeatures? '' : 'toate' }]}*/}
                            {/*        disableClickAwayListener*/}
                            {/*        style*/}
                            {/*        className={styles.outlinedFilter}*/}
                            {/*        onOpen={() => {*/}
                            {/*            setOpenFeatureFilter(true);*/}
                            {/*        }}*/}
                            {/*        onClose={() => {*/}
                            {/*            setOpenFeatureFilter(false);*/}
                            {/*        }}*/}
                            {/*        selectedCount={areFeatures? activeFeatures.length : ''}*/}
                            {/*    />*/}

                            {/*    <GamesFilterGrid*/}
                            {/*        checkboxFilter={true}*/}
                            {/*        activeItems={activeFeatures}*/}
                            {/*        open={openFeatureFilter}*/}
                            {/*        filterItems={features}*/}
                            {/*        onClickItem={(e: React.MouseEvent<HTMLDivElement>, item:any) => {*/}
                            {/*            dispatch(featuresFilterSlugPage(selectMultipleFilters(item, activeFeatures)));*/}
                            {/*        }}/>*/}

                            {/*</>*/}
                            {/*FEATURES MOBILE FILTER END FEATURES MOBILE FILTER END FEATURES MOBILE FILTER END */}

                            {/*<div className={clsx(styles.divider)}></div> /!* DIVIDER *!/*/}

                            {/*THEMES MOBILE FILTER START THEMES MOBILE FILTER START THEMES MOBILE FILTER START */}
                            {/*<>*/}
                            {/*    <OutlinedSelector*/}
                            {/*        label={'teme'}*/}
                            {/*        options={[{key: '', label: areThemes? '' : 'toate' }]}*/}
                            {/*        disableClickAwayListener*/}
                            {/*        style*/}
                            {/*        className={styles.outlinedFilter}*/}
                            {/*        onOpen={() => {*/}
                            {/*            setOpenThemeFilter(true);*/}
                            {/*        }}*/}
                            {/*        onClose={() => {*/}
                            {/*            setOpenThemeFilter(false);*/}
                            {/*        }}*/}
                            {/*        selectedCount={areThemes? activeThemes.length : ''}*/}
                            {/*    />*/}

                            {/*    <GamesFilterGrid*/}
                            {/*        checkboxFilter={true}*/}
                            {/*        activeItems={activeThemes}*/}
                            {/*        open={openThemeFilter}*/}
                            {/*        filterItems={allThemes}*/}
                            {/*        onClickItem={(e: React.MouseEvent<HTMLDivElement>, item:any) => {*/}
                            {/*            dispatch(themeFilterSlugPage(selectMultipleFilters(item, activeThemes)));*/}
                            {/*        }}*/}
                            {/*    />*/}
                            {/*</>*/}
                            {/*THEMES MOBILE FILTER END THEMES MOBILE FILTER END THEMES MOBILE FILTER END */}

                            {/*<div className={clsx(styles.divider)}></div> /!* DIVIDER *!/*/}

                            {/*THEMES MOBILE FILTER START THEMES MOBILE FILTER START THEMES MOBILE FILTER START */}
                            {/*<>*/}
                            {/*    <OutlinedSelector*/}
                            {/*        label={'volatilitate'}*/}
                            {/*        options={[{key: '', label: areVolatilities? '' : 'toate' }]}*/}
                            {/*        disableClickAwayListener*/}
                            {/*        style*/}
                            {/*        className={styles.outlinedFilter}*/}
                            {/*        onOpen={() => {*/}
                            {/*            setOpenVolFilter(true);*/}
                            {/*        }}*/}
                            {/*        onClose={() => {*/}
                            {/*            setOpenVolFilter(false);*/}
                            {/*        }}*/}
                            {/*        selectedCount={*/}
                            {/*            activeVolatility? activeVolatility.length : ''*/}
                            {/*        }*/}
                            {/*    />*/}

                            {/*    <GamesFilterGrid*/}
                            {/*        checkboxFilter={true}*/}
                            {/*        activeItems={activeVolatility}*/}
                            {/*        open={openVolFilter}*/}
                            {/*        filterItems={volatility}*/}
                            {/*        onClickItem={(e: React.MouseEvent<HTMLDivElement>, item:any) => {*/}
                            {/*            dispatch(volatilityFilterSlugPage(selectMultipleFilters(item.value, activeVolatility)));*/}
                            {/*        }}*/}
                            {/*    />*/}
                            {/*</>*/}
                            {/*THEMES MOBILE FILTER END THEMES MOBILE FILTER END THEMES MOBILE FILTER END */}

                            {/*<div className={clsx(styles.divider)}></div>*/}

                            <OutlinedSelector
                                label={'Sortează'}
                                defaultValue={searchSort}
                                style
                                className={styles.outlinedFilter}
                                onChange={(value) => {
                                    setSearchSort(value)
                                }}
                                options={[
                                    {key: 'a-z', label: 'Alfabetic A-Z'},
                                    {key: 'z-a', label: 'Alfabetic Z-A'},
                                ]}
                            />

                            <div className={clsx(styles.divider)}></div>

                        </div>

                    </div>
                )}


            </div>

            {/*OVERLAY FILTERS END OVERLAY FILTERS END OVERLAY FILTERS END OVERLAY FILTERS END */}


            {loading && <div style={{textAlign: 'center', padding: 30}}><CircularProgress/></div>}


            {availableGames.length === 0 &&
                <div className={'games-not-found'}>
                    NU S-A GĂSIT NIMIC
                </div>
            }

            <GameGrid games={availableGames} page={'category'} key={`grid-${api_id}`}/>

        </div>
    )
}

export default PageTypeCategoryGames