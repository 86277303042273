export default class Statics {
    static paymentIcons:any = {
        'credit_card': <div className={'payment_method_icon'}><img src="/default/icons/credit_card.svg" alt="credit_card"/></div>,
        'pay_safe_card': <div className={'payment_method_icon'}><img src="/default/icons/pay_safe.svg" alt="credit_card"/></div>,
        'cnp': <div className={'payment_method_icon'}><img src="/default/icons/top_pay.svg" alt="credit_card"/></div>,
        'debit_card': <div className={'payment_method_icon'}><img src="/default/icons/credit_card.svg" alt="credit_card"/></div>,
        "bank_transfer":<div className={'payment_method_icon'}><img width={"20px"} height={"20px"} src="/default/icons/aircash.png" alt="aircash"/></div>,
        "voucher":<div className={'payment_method_icon'}><img width={"20px"} height={"20px"} src="/default/icons/abon.png" alt="aircash"/></div>,
    };
    
    static paymentCardTypes =  {
        'credit_card':
            <div>
                <img src="/spins/images/viza1.svg" alt="type-card"/>
                <img src="/spins/images/viza2.svg" alt="type-card"/>
                <img src="/spins/images/viza3.svg" alt="type-card"/>
            </div>,
        
        "debit_card":
            <div>
                <img src="/spins/images/viza1.svg" alt="type-card"/>
                <img src="/spins/images/viza2.svg" alt="type-card"/>
                <img src="/spins/images/viza3.svg" alt="type-card"/>
                <img src="/spins/images/viza4.svg" alt="type-card"/>
                <img src="/spins/images/viza5.svg" alt="type-card"/>
                <img src="/spins/images/viza6.svg" alt="type-card"/>
            </div>,

    }
}