import {FC} from 'react';
import CMS from "utils/cms";
import styles from 'themes/default/styles/bannerGoToAction.module.scss';
import strings from "utils/strings";
import useAuth from "hooks/auth";
import clsx from 'clsx';
import Game from "utils/game";
import GameInterface from "interfaces/game";
import {useState, useEffect} from 'react';
import Skeleton from '@mui/material/Skeleton';
import { useMediaQuery } from 'react-responsive';

interface Props {
    json: any,
    api_id: string;
}

const GameBanner:FC<Props> = ({json, api_id}) => {
    const auth = useAuth();
    const data = CMS.jsonGameBanner(json);
    const {title, game_id, thumbnail_src, mobile_random_mode_image, run_random_game, provider_src, game_name, provider_name, desktop_image} = data;
    const games:Array<GameInterface> = Game.getAll();
    const [game, setGame] = useState<GameInterface | undefined>(undefined);
    const [load, setLoad] = useState<boolean>(false)
    const [mobileLoad, setMobileLoad] = useState<boolean>(false)
    const [skeletonHeight, setSkeletonHeight] = useState<number>(0)
    const [mobSkeletonHeight, setMobSkeletonHeight] = useState<number>(0)

    const isMedia1 = useMediaQuery({
        query: '(max-width: 575px)'
    });

    const initGame = () => {
        let game:GameInterface | undefined = games.find(g => g.gameId === game_id);

        if (run_random_game) {
            const randomIndex:number = Math.floor(Math.random() * (games.length - 1));

            if (games[randomIndex]) game = games[randomIndex];
        }

        if (game) setGame(game);
    };


    const imageLoaded = (src:any) => {
        return new Promise((resolve, reject) => {
            const img = document.createElement('img');
            img.src = src

            img.onload = () => {
                resolve(true);
            }

            img.onerror = () => {
                resolve(false);
            }
        })
    }

    const finalTitle:string = data.run_random_game ? 'Încearcă-ți norocul!' : title;
    const finalButtonTitle:string = data.run_random_game ? 'Alege jocul' : strings['play'];
    let mobileThumbnail:string = thumbnail_src;

    if (run_random_game && mobile_random_mode_image.length > 0) {
        mobileThumbnail = CMS.getPublicFilePath(mobile_random_mode_image[0]['name'], api_id, mobile_random_mode_image[0]['path']);
    }


    useEffect(() => {
        const sectionWidth = document.querySelector('[data-section="game-banner"]');
        const mobBanner = document.getElementById('mobImgBannerContainer');

        if(sectionWidth) {
            const height = sectionWidth.clientWidth * 0.3019
            setSkeletonHeight(height)
        }

        if(mobBanner) {
            const height = mobBanner.clientWidth * 0.7505
            setMobSkeletonHeight(height)
        }

        const loadImages = async () => {
            if(isMedia1) {
                await imageLoaded(mobileThumbnail).then((res) => {
                    setMobileLoad(true)
                })
            } else {
                await imageLoaded(CMS.getPublicFilePath(desktop_image[0]['name'], api_id, desktop_image[0]['path'])).then((res) => {
                    setLoad(true)
                })
            }
        }

        loadImages();

        initGame();

    }, []);


    if (desktop_image.length > 0) {
        return (
            <div  data-section-title='game-banner' data-section="game-banner" className={clsx(styles.root, data.run_random_game && styles.run_random_game)}>
                <div  className={styles.root__bgImage}>
                    {load && (
                        <img  loading={'lazy'} src={CMS.getPublicFilePath(desktop_image[0]['name'], api_id, desktop_image[0]['path'])} alt={finalTitle}/>
                    )}

                    {!load && (
                        <Skeleton variant="rectangular" style={{width: '100%', minHeight: `${skeletonHeight}px`, background: '#404040', display: 'block', borderRadius: '5px'}}   />
                    )}
                </div>
                <div  className={styles.root__info}>
                    <h5 className={styles.root__info__title}>{finalTitle}</h5>
                    {(provider_src.length > 0 && !data.run_random_game) &&
                        <div className={styles.root__info__game}>
                            <img loading={'lazy'} className={styles.root__info__game__provider_thumb} src={provider_src} alt={provider_name}/>
                            <div>
                                <span className={styles.root__info__game__name}>{game_name}</span>
                                <span className={styles.root__info__game__provider_name}>{provider_name}</span>
                            </div>
                        </div>
                    }
                    {((provider_name.length > 0 && game_name.length > 0 && game && !data.run_random_game) || (data.run_random_game && game)) &&
                        <button type={'button'} onClick={(e:any) => auth.runGame(game, false, () => {}, e)} className="btn__primary">{finalButtonTitle}</button>
                    }
                </div>

                <div id={'mobImgBannerContainer'} className={styles.root__block}>
                    {!run_random_game &&
                        <div className={styles.root__wrapper}>
                            <div className={styles.root__scaleBlock} style={{backgroundImage: `url(${mobileThumbnail}`}}></div>
                            <div className={styles.root__imageWrapper}>
                                <img loading={'lazy'} src={mobileThumbnail} alt={finalTitle}/>
                            </div>
                        </div>
                    }

                    {(run_random_game && mobile_random_mode_image.length > 0) &&

                    mobileLoad ? (
                        <img style={{minWidth: '100%', height: 'auto'}} width={524} height={393} loading={'lazy'}  src={mobileThumbnail} alt={finalTitle}/>
                    ) : (
                        <Skeleton variant="rectangular" style={{width: '100%', minHeight: `${mobSkeletonHeight}px`, background: '#404040', display: 'block'}}/>
                    )

                    }

                    <div className={styles.root__image}>
                        <div className={styles.root__infoMob}>
                            {!data.run_random_game &&
                                <div className={styles.root__wrapImage}>
                                    <img loading={'lazy'} className={styles.root__info__game__provider_thumb} src={provider_src} alt={provider_name}/>
                                </div>
                            }

                            {data.run_random_game && <h5 className={styles.root__info__title}>{finalTitle}</h5>}

                            <div className={styles.root__secondBlock}>
                                {(provider_src.length > 0 && !data.run_random_game) &&
                                    <div className={styles.root__info__game}>
                                        {/*<img className={styles.root__info__game__provider_thumb} src={provider_src} alt={provider_name}/>*/}
                                        <div>
                                            <span className={styles.root__info__game__name}>{game_name}</span>
                                            <span className={styles.root__info__game__provider_name}>{provider_name}</span>
                                        </div>
                                    </div>
                                }

                                {((provider_name.length > 0 && game_name.length > 0 && game && !data.run_random_game) || (data.run_random_game && game)) &&
                                    <button type={'button'} onClick={(e:any) => auth.runGame(game, false, () => {}, e)} className={clsx("btn__primary", styles.root__btn)}>
                                        {finalButtonTitle}
                                    </button>
                                }
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return null;
}

export default GameBanner;