import QuestLeaderboardInterface from "@/AbsoluteComponents/interfaces/quest_leaderboard_interface";

export {};

export const cms_project_id = '52253f55-1528-4c5f-ad90-b227512fa3fa'
export const origin = 'https://cashpot.ro'
export const referer = 'cashpot.ro'
export const hardcoded_quest= false
export const show_vip_quest = true
export const show_lucky_ticket = true
export const smartico_visible = true
export const hardcoded_quest_json:QuestLeaderboardInterface = {
    "leader": {
        "nickname": "Ru*************an",
        "points": 315809,
        "level": 24,
        "levelName": "24"
    },
    "leaderBoard": {
        "0": {
            "pointsNeeded": 0,
            "restPlayers": "1000+"
        },
        "1": {
            "pointsNeeded": 100,
            "restPlayers": "100+"
        },
        "2": {
            "pointsNeeded": 500,
            "restPlayers": "100+"
        },
        "3": {
            "pointsNeeded": 1000,
            "restPlayers": "100+"
        },
        "4": {
            "pointsNeeded": 2000,
            "restPlayers": "100+"
        },
        "5": {
            "pointsNeeded": 5000,
            "restPlayers": "10+"
        },
        "6": {
            "pointsNeeded": 7500,
            "restPlayers": "10+"
        },
        "7": {
            "pointsNeeded": 10000,
            "restPlayers": "10+"
        },
        "8": {
            "pointsNeeded": 13500,
            "restPlayers": "10+"
        },
        "9": {
            "pointsNeeded": 17250,
            "restPlayers": "10+"
        },
        "10": {
            "pointsNeeded": 21750,
            "restPlayers": "10+"
        },
        "11": {
            "pointsNeeded": 27000,
            "restPlayers": "10+"
        },
        "12": {
            "pointsNeeded": 33000,
            "restPlayers": "10+"
        },
        "13": {
            "pointsNeeded": 40500,
            "restPlayers": "7"
        },
        "14": {
            "pointsNeeded": 49500,
            "restPlayers": "4"
        },
        "15": {
            "pointsNeeded": 60000,
            "restPlayers": "4"
        },
        "16": {
            "pointsNeeded": 72000,
            "restPlayers": "3"
        },
        "17": {
            "pointsNeeded": 87000,
            "restPlayers": "3"
        },
        "18": {
            "pointsNeeded": 105000,
            "restPlayers": "0"
        },
        "19": {
            "pointsNeeded": 126000,
            "restPlayers": "1"
        },
        "20": {
            "pointsNeeded": 150000,
            "restPlayers": "1"
        },
        "21": {
            "pointsNeeded": 180000,
            "restPlayers": "2"
        },
        "22": {
            "pointsNeeded": 212500,
            "restPlayers": "1"
        },
        "23": {
            "pointsNeeded": 250000,
            "restPlayers": "0"
        },
        "24": {
            "pointsNeeded": 300000,
            "restPlayers": "1"
        },
        "25": {
            "pointsNeeded": 360000,
            "restPlayers": "0"
        },
        "26": {
            "pointsNeeded": 450000,
            "restPlayers": "0"
        },
        "27": {
            "pointsNeeded": 570000,
            "restPlayers": "0"
        },
        "28": {
            "pointsNeeded": 720000,
            "restPlayers": "0"
        },
        "29": {
            "pointsNeeded": 900000,
            "restPlayers": "0"
        },
        "30": {
            "pointsNeeded": 1200000,
            "restPlayers": "0"
        }
    },
    "maxLevelTop": {
        "first": {
            "nickname": "Ru*************an",
            "level": 24,
            "points": 315809,
            "reachedAt": "2024-06-21T22:24:57.496Z"
        },
        "second": {
            "nickname": "Ga**io",
            "level": 22,
            "points": 212890,
            "reachedAt": "2024-06-28T09:11:56.594Z"
        },
        "third": {
            "nickname": "ba**li",
            "level": 21,
            "points": 194749,
            "reachedAt": "2024-06-28T18:43:51.291Z"
        }
    },
    "state": {
        "endTime": "2024-07-01T07:00:00.000Z",
        "status": "Ended"
    },
    "apiFail": false
}