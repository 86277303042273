import { FC } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import styles from 'themes/default/styles/categoriesButtonsSlider.module.scss';
import "swiper/css";
import "swiper/css/pagination";
import Link from 'next/link';
import CMS from "utils/cms";
import clsx from 'clsx';

interface Props {
    json: object;
    api_id: string;
    second: boolean;
}

const CategoriesButtonsSlider: FC<Props> = ({ json, api_id, second }) => {
    const data:any = CMS.jsonCategoriesButtonsSlider(json);

    if (Array.isArray(data.slider) && data.slider.length > 0) {
        return (
            <div className={clsx(styles.slider, !data.move_higher && styles.slider__sliderRelative , data.slider.length >= 10 && styles.slider__alignLeft)}>
                <div className={clsx(styles.slider__inner, !data.move_higher && styles.slider__innerRelative)}>
                    <Swiper
                        spaceBetween={0}
                        slidesPerView={'auto'}
                        centerInsufficientSlides={false}
                        className={styles.swiper}
                    >
                        {data.slider.map((item:any, i:number) => {
                            return (
                                <SwiperSlide className={styles.slider__swiper_item} key={'categories_buttons_slider-'+i}>

                                    <Link style={{ backgroundImage: `url('/default/images/backgrounds/bgDark3.webp')` }} href={'/'+item['api_id']} title={item['title']} className={styles.slider__item}>
                                        {item.icon.length > 0 && <img src={CMS.getPublicFilePath(item.icon[0]['name'], api_id, item.icon[0]['path'])} width={20} height={20} alt={item['title']}/>}

                                        <div className={styles.slider__item__title}>
                                            {item['title']}
                                        </div>

                                    </Link>

                                </SwiperSlide>
                            )
                        })}
                    </Swiper>
                </div>
            </div>
        );
    }

    return null;
};

export default CategoriesButtonsSlider;