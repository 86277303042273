import {FC, useEffect, useState, useRef} from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import withGame from 'hooks/game';
import GameInterface from 'interfaces/game';
import styles from 'themes/default/styles/categoryGamesSlider.module.scss';
import clsx from 'clsx';
import Card from 'themes/default/ui/game/card';
import strings from "utils/strings";
import Link from 'next/link';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from "swiper";
import { Pagination } from "swiper";
import PaginationArrow from "ui/paginationArrow";
import CMS from "utils/cms";
import NewArrow from "themes/default/svg/newArrow";
import SwiperGamesPreloader from "ui/SwiperGamesPreloader";
import { useMediaQuery } from 'react-responsive';
import Game from "utils/game";
import Skeleton from '@mui/material/Skeleton';
import {useRouter} from 'next/router';
import {useDispatch} from 'react-redux';
import useCommon from "@/AbsoluteComponents/hooks/common";
import {lazy_loading_offset} from "@/AbsoluteComponents/utils/constants";
import LazyComponentWrap from "components/lazy_component_wrap";

interface Props {
    json: Array<any>;
    externalGames?: Array<GameInterface>;
    title?: string;
    count?: number;
    index: number;
    api_id?: string;
    disableLazyImage?: boolean;
    disableDemo?: boolean;
    onSliderInit?: () => void;

}

const CategoryGamesSlider:FC<Props> = (props) => {

    const {index} = props

    if (index >= lazy_loading_offset) {
        return (
            <LazyComponentWrap>
                <Core { ... props}/>
            </LazyComponentWrap>
        )
    } else {
        return <Core { ... props}/>
    }
}





const Core: FC<Props> = ({
    json,
    externalGames,
    title,
    count,
    index,
    api_id,
    disableLazyImage,
    disableDemo,
    onSliderInit
}) => {
    const id = `game_card_234234`
    const [swiper, setSwiper] = useState<any>();
    const [loading, setLoading] = useState(false);
    const [cardSkeleton, setCardSkeleton] = useState<Array<any>>([])
    const [show, setShow] = useState(false);
    const allGames:Array<GameInterface> = Game.getAll();
    const router = useRouter()
    const dispatch = useDispatch();
    const game = withGame();
    const data = CMS.jsonCategoryGamesSlider(json);
    const isPromotionPage:boolean = router.route.slice(1, 9) === 'promotii'
    const common_hook = useCommon()
    const elem = useRef<any>(null)

    const rootRef = useRef<any>(null);

    const [games, setGames] = useState<GameInterface[]>([]);
    
    const isMedia1 = useMediaQuery({
        query: '(max-width: 1199px)'
    });

    const getGamesByCategory = ():Array<GameInterface> => {
        const fullList = allGames.filter((g:any) => (g && g.categories?.includes(data.category_id)));

        if (data.category_id) {
            fullList.sort((a:any, b:any) => {
                if (a.categoryOrder[data.category_id] > b.categoryOrder[data.category_id]) {
                    return 1;
                }

                if (a.categoryOrder[data.category_id] < b.categoryOrder[data.category_id]) {
                    return -1;
                }

                return 0;
            })
        }

        return fullList.filter((g:any) => g.categories?.includes(data.category_id)).slice(0, ( /*data.count*/ 12 ));
    };

    useEffect(() => {
        common_hook.observe(elem.current)
        
        if (!externalGames) {

        } else {
            setGames(externalGames);
        }
        
        const gameCard = document.getElementById(id)

        if(gameCard) {
            let skeletCards: Array<any> = []
            let cardWidth = gameCard.clientWidth
            let cardHeight = gameCard.clientHeight

            for(let i = 0; i < 10; i++) {
                skeletCards.push({
                    cardId: i,
                    width: cardWidth,
                    height: cardHeight
                })
            }

            setCardSkeleton(skeletCards)
        }

    }, []);


    const categoryGames:Array<GameInterface> = getGamesByCategory();
    let mainGames = !games.length ? /*storedGames*/ [] : games;

    if (!externalGames) {
        mainGames = categoryGames;
    }

    if (!mainGames.length) return null;

    
    return (

        <div
            ref={elem} data-section={CMS.BLOCK_CATEGORY_GAMES_SLIDER}
            data-section-title={title || data.category_title}
            className={clsx(styles.categoryGames, 'slideClass', 'theme-slider', 'swiper-init-padding', isPromotionPage && 'noPadding')}
        >
            
            <div className={'gamesSlider-box'}>
                
                <div className={'gamesSlider'}>
                    <span style={{padding: `${isPromotionPage && 0}`}}
                          className={'gamesSlider__title'}>{title || data.category_title}</span>
                </div>
                
                {!externalGames && <Link href={`/${data.api_id}`}>
                    <button className={clsx('btn-responsive')}>{strings['vezi_mai_multe']}<NewArrow/></button>
                </Link>}
                
                {data.api_id.length > 0 &&
                    <Link href={`/${data.api_id}`}>
                        <button className={'linkMore-btn'}>{strings['vezi_toate']}<NewArrow/></button>
                    </Link>
                }
            
            </div>
            
            {(loading /*&& storedGames.length === 0*/) &&
                <div style={{paddingTop: 42, paddingLeft: 50}}><SwiperGamesPreloader margin={data.minimize ? 6 : 14}
                                                                                     large={!data.minimize}/></div>}
            
            {isMedia1 && loading ? (
                <div className={styles.sliderContainer}>
                    <div className={styles.slider}>
                        {mainGames.length > 0 && (
                            mainGames.map((game: GameInterface, i: number) => {
                                return (
                                    <Card id={i===0? id : undefined} key={`category_games-${index}-${game.gameId}`} disableDemo={disableDemo}
                                          disableLazyLoading={disableLazyImage} game={game} homeSliderItem
                                          minimize={data.minimize}
                                    />
                                );
                            }))
                        }
                    </div>
                </div>
            ) : (
                
                (mainGames.length > 0) && (
                    <Swiper
                        pagination={{
                            clickable: true,
                        }}
                        modules={[Pagination, Navigation]}
                        className={clsx(styles.themes__mySwiper, 'swiper-wrapper')}
                        slidesPerView='auto'
                        onSwiper={(sw) => {
                            setSwiper(sw);
                            if (typeof onSliderInit === "function") onSliderInit();
                        }}
                    
                    >
                        <PaginationArrow onClick={() => swiper.slideNext()}/>
                        <PaginationArrow onClick={() => swiper.slidePrev()} rightArrow/>
                        
                        {mainGames.length > 0 ? (
                            mainGames.map((game: GameInterface, i: number) => {
                                return (
                                    <SwiperSlide key={`category_games-${index}-${game.gameId}`}>
                                        <Card id={i===0? id : undefined} disableDemo={disableDemo} disableLazyLoading={disableLazyImage}
                                              game={game} homeSliderItem minimize={data.minimize}/>
                                    </SwiperSlide>
                                )
                            })
                        
                        ) : (
                            
                            cardSkeleton?.map((sk, i) => {
                                return (
                                    <SwiperSlide key={'skeleton' + sk.cardId}>
                                        <Skeleton
                                            variant="rectangular"
                                            className={'skeletonGameCard'}
                                            animation="wave"
                                        />
                                    </SwiperSlide>
                                )
                            })
                        )}
                    </Swiper>
                )
            )}
        </div>
        
    )
}
export default CategoryGamesSlider;