import {DeviceType} from "@/AbsoluteComponents/interfaces/types";

export default class CommonClass {
    static custom_events = {
        "listen_pin_timeout":"listen_pin_timeout"
    }
    
    static local_storage_items = {
        "send_pin_info":"send_pin_info",
        "change_pwd_token": "change_pwd_token"
    }

    static device_type(navigator:any, userAgent:any):DeviceType | "" {
        if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
            return "App Store"
        } else if (/Huawei/i.test(navigator.userAgent) && (userAgent.includes('huawei') || userAgent.includes('honor'))) {
            return "AppGallery"
        } else if (userAgent.includes('android') && !userAgent.includes('huawei')) {
            return "Google Play"
        } else {
            return ""
        }
    }
}