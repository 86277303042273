import styles from "themes/default/styles/VIPQuest.module.scss"
import CMS from "@/AbsoluteComponents/utils/cms";
import {useSelector} from "react-redux";
import clsx from "clsx";


interface Props {
    data: any
    api_id: any
}

const QuestPrizes:React.FC<Props> = ({data, api_id}) => {
    const getState = (st:string) => {
        return useSelector((state:any) => state.account[st])
    }
    const status = getState("questStatus")
    const leaderboard = getState("questLeaderboard")
    // const winners = {
    //     first: {
    //         nickname: "Nica",
    //         level: 23
    //     },
    //
    //     second: {
    //         nickname: "Valea",
    //         level: 22
    //     },
    //
    //     third: {
    //         nickname: "Vova",
    //         level: 21
    //     },
    // }
    const winners  = leaderboard.maxLevelTop
    const {prizes} = data
    
    const generateGrades = ():Array<any> => {
        let res:any = [];
        
        if(Array.isArray(prizes) && prizes.length === 3 && Object.keys(winners).length) {
            let first_place = prizes.find((pr) => pr.grade === "1")
            let second_place = prizes.find((pr) => pr.grade === "2")
            let third_place = prizes.find((pr) => pr.grade === "3")
            
            if(first_place) res.first_place = first_place
            if(second_place) res.second_place = second_place
            if(third_place) res.third_place = third_place
            
            first_place.user = winners.first
            first_place.color = "#EEB665"
            
            second_place.user = winners.second
            second_place.color = "#DADADA"
            
            third_place.user = winners.third
            third_place.color = "#DF8E4C"
            
            res = [second_place, first_place,  third_place]
        }
        
        
        return res
    }
    const grades:Array<any> = generateGrades();
    
    return (Array.isArray(prizes) && prizes.length === 3) ? (
        <div className={styles.quest_prizes}>
            
            <div className={styles.container}>
                {(Array.isArray(grades) && grades.length)? grades.map((obj) => {
                    
                    let prize_texts:Array<string> = []
                    
                    return obj.user && (
                        <div key={"Prize "+obj.grade} className={clsx(styles.card)}>
                            
                            <span
                                style={{
                                    color: obj.color
                                }}
                                className={clsx(styles.nickname, styles["nickname_place_"+obj.grade])}
                            >
                                {obj.grade}. {obj.user.nickname}
                            </span>
                            
                            <div className={clsx(styles.prizes_card, styles["place_"+obj.grade])}>
                                <div className={styles.card_content}>
                                    
                                    {obj.money_prize.length ? (
                                        <>
                                            <span className={styles.main_text}>
                                                {obj.money_prize}
                                            </span>
                                            
                                            <span className={styles.secondary}>
                                                RON CASH
                                            </span>
                                        </>
                                    ) : null}
                                    
                                    {obj.specific_prize.length ? (
                                        <span className={styles.main_text}>
                                            {obj.specific_prize}
                                        </span>
                                    ) : null}
                                    
                                    <div
                                        style={{
                                            background: obj.color
                                        }}
                                        className={styles.user_level}
                                    >
                                        nivel {obj.user.level}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }) : null}
            </div>
           
        
        </div>
    
    ) : null
}

export default QuestPrizes