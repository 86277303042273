

const NameIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M4.51217 6.56732C3.67968 6.56732 3.00482 7.24219 3.00482 8.07468V18.309C3.00482 19.1415 3.67969 19.8164 4.51218 19.8164H19.4913C20.3238 19.8164 20.9987 19.1415 20.9987 18.309V8.07467C20.9987 7.24218 20.3238 6.56732 19.4913 6.56732H14.3987V5.58203H19.4913C20.868 5.58203 21.984 6.69803 21.984 8.07467V18.309C21.984 19.6857 20.868 20.8017 19.4913 20.8017H4.51218C3.13553 20.8017 2.01953 19.6857 2.01953 18.309V8.07468C2.01953 6.69803 3.13553 5.58203 4.51217 5.58203H9.58756V6.56732H4.51217Z"
                  fill="#EEB665"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M14.002 4.67669H10.002C9.99964 4.67669 9.99841 4.67691 9.99794 4.67701C9.99742 4.67711 9.99699 4.67725 9.99653 4.67744C9.99548 4.67789 9.9936 4.67895 9.99155 4.681C9.9895 4.68305 9.98843 4.68493 9.98799 4.68598C9.9878 4.68644 9.98766 4.68687 9.98755 4.6874C9.98746 4.68787 9.98724 4.6891 9.98724 4.69141V6.02016H14.0167V4.69141C14.0167 4.6891 14.0164 4.68787 14.0164 4.6874C14.0163 4.68717 14.0163 4.68696 14.0162 4.68676C14.0161 4.68649 14.016 4.68624 14.0159 4.68598C14.0155 4.68493 14.0144 4.68305 14.0124 4.681C14.0103 4.67895 14.0084 4.67789 14.0074 4.67744C14.0069 4.67725 14.0065 4.67711 14.006 4.67701C14.0055 4.67691 14.0043 4.67669 14.002 4.67669ZM10.002 3.69141C9.44967 3.69141 9.00195 4.13912 9.00195 4.69141V7.00544H15.002V4.69141C15.002 4.13912 14.5542 3.69141 14.002 3.69141H10.002Z"
                  fill="#EEB665"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M8.37354 12.128C9.01512 12.128 9.53522 11.6079 9.53522 10.9663C9.53522 10.3247 9.01512 9.80462 8.37354 9.80462C7.73195 9.80462 7.21185 10.3247 7.21185 10.9663C7.21185 11.6079 7.73195 12.128 8.37354 12.128ZM8.37354 13.1133C9.55928 13.1133 10.5205 12.152 10.5205 10.9663C10.5205 9.78057 9.55928 8.81934 8.37354 8.81934C7.18779 8.81934 6.22656 9.78057 6.22656 10.9663C6.22656 12.152 7.18779 13.1133 8.37354 13.1133Z"
                  fill="white"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M4.89648 15.2905C4.89648 14.4661 5.56476 13.7979 6.38913 13.7979H10.3459C11.1703 13.7979 11.8386 14.4661 11.8386 15.2905V16.8001C11.8386 17.0722 11.618 17.2928 11.3459 17.2928C11.0738 17.2928 10.8533 17.0722 10.8533 16.8001V15.2905C10.8533 15.0103 10.6261 14.7831 10.3459 14.7831H6.38913C6.10892 14.7831 5.88177 15.0103 5.88177 15.2905V16.8001C5.88177 17.0722 5.66121 17.2928 5.38913 17.2928C5.11705 17.2928 4.89648 17.0722 4.89648 16.8001V15.2905Z"
                  fill="white"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M13.5371 14.809C13.5371 14.537 13.7577 14.3164 14.0298 14.3164H18.9639C19.236 14.3164 19.4565 14.537 19.4565 14.809C19.4565 15.0811 19.236 15.3017 18.9639 15.3017H14.0298C13.7577 15.3017 13.5371 15.0811 13.5371 14.809Z"
                  fill="#EEB665"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M13.5371 11.3374C13.5371 11.0653 13.7577 10.8447 14.0298 10.8447H18.9639C19.236 10.8447 19.4565 11.0653 19.4565 11.3374C19.4565 11.6094 19.236 11.83 18.9639 11.83H14.0298C13.7577 11.83 13.5371 11.6094 13.5371 11.3374Z"
                  fill="#EEB665"/>
        </svg>
    )
}

export default NameIcon