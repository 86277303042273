import * as actionTypes from './actions';
import GameInterface from "../../interfaces/game";
import {
    OPEN_FORGOT_PASSWORD_MODAL,
    OPEN_MINI_GAME_MODAL,
    OPEN_QUEST_ENDED,
    OPEN_RESET_PWD_MODAL, OPEN_SET_NICKNAME,
    OPEN_SHARE_TICKET,
    OPEN_SPORT_TICKET_MODAL,
    OPEN_UPLOAD_MAIN_DOCUMENT_MODAL,
    OPEN_WHEEL_MODAL,
    openFullGameModal,
    openMiniGameModal
} from "./actions";
import WinnerInterface from "../../interfaces/winner";


interface initialValues {
    openLogin: boolean,
    openUploadDocument: boolean,
    openUploadMainDocument: boolean,
    openDeposit: boolean,
    openWithdraw: boolean,
    openSupport: boolean,
    openSignup: boolean,
    campaignId: string,
    openForgotPassword: boolean,
    openMiniGame: GameInterface | undefined,
    openFullGame: GameInterface | undefined,
    openParticipation: boolean,
    openVerification: boolean,
    openWheel: boolean
    depositAfterLogin: boolean
    openHowTopPlay: boolean,
    openTheQuest: boolean,
    openQuestEnded: boolean,
    openShareTicket: boolean
    openSportTicketModal: boolean
    miniGameModalId: string
    openResetPwdModal: string
    openSetNickName: boolean
}

const initialState:initialValues = {
    openLogin: false,
    openUploadDocument: false,
    openUploadMainDocument: false,
    openDeposit: false,
    openWithdraw: false,
    openSupport: false,
    openSignup: false,
    campaignId: '',
    openForgotPassword: false,
    openMiniGame: undefined,
    openFullGame: undefined,
    openParticipation: false,
    openVerification: false,
    depositAfterLogin: false,
    openWheel: false,
    openHowTopPlay: false,
    openTheQuest: false,
    openQuestEnded: false,
    openSportTicketModal: false,
    openShareTicket: false,
    miniGameModalId: "",
    openResetPwdModal: "",
    openSetNickName: false
};

const reducer = (state = initialState, action:any) => {
    switch (action.type) {
        case actionTypes.OPEN_LOGIN_MODAL:
            return {...state, openLogin: action.payload };

        case actionTypes.OPEN_SPORT_TICKET_MODAL:
            return {...state, openSportTicketModal: action.payload };

        case actionTypes.OPEN_SHARE_TICKET:
            return {...state, openShareTicket: action.payload };

        case actionTypes.OPEN_QUEST_ENDED:
            return {...state, openQuestEnded: action.payload };

        case actionTypes.OPEN_UPLOAD_DOCUMENT_MODAL:
            return {...state, openUploadDocument: action.payload};

        case actionTypes.OPEN_UPLOAD_MAIN_DOCUMENT_MODAL:
            return {...state, openUploadMainDocument: action.payload};

        case actionTypes.OPEN_DEPOSIT_MODAL:
            return {...state, openDeposit: action.payload};

        case actionTypes.OPEN_WITHDRAW_MODAL:
            return {...state, openWithdraw: action.payload};

        case actionTypes.OPEN_SUPPORT_MODAL:
            return {...state, openSupport: action.payload};

        case actionTypes.UPDATE_CAMPAIGN_ID:
            return {...state, campaignId: action.payload};

        case actionTypes.OPEN_FORGOT_PASSWORD_MODAL:
            return { ...state, openForgotPassword: action.payload};

        case actionTypes.OPEN_MINI_GAME_MODAL:
            return { ...state, openMiniGame: action.payload};

        case actionTypes.OPEN_FULL_GAME_MODAL:
            return { ...state, openFullGame: action.payload};

        case actionTypes.OPEN_PARTICIPATION_MODAL:
            return {...state, openParticipation: action.payload};

        case actionTypes.OPEN_SIGNUP_MODAL:
            return { ...state, openSignup: action.payload}

        case actionTypes.OPEN_VERIFICATION_MODAL:
            return { ...state, openVerification: action.payload};

        case actionTypes.OPEN_WHEEL_MODAL:
            return {...state, openWheel: action.payload};

        case actionTypes.OPEN_DEPOSIT_AFTER_LOGIN:
            return {...state, depositAfterLogin: action.payload};

        case actionTypes.OPEN_HOW_TO_PLAY_QUETS:
            return {...state, openHowTopPlay: action.payload};

        case actionTypes.OPEN_THE_QUETS:
            return {...state, openTheQuest: action.payload};
            
        case actionTypes.SET_MODAL_MINI_GAME_ID:
            return {...state, miniGameModalId: action.payload};
            
        case actionTypes.OPEN_RESET_PWD_MODAL:
            return {...state, openResetPwdModal: action.payload};
            
        case actionTypes.OPEN_SET_NICKNAME:
            return {...state, openSetNickName: action.payload};

        default: return state;
    }
}

export default reducer;