import React, {useEffect, useRef} from "react";
import styles from "themes/default/styles/LuckyTicket.module.scss"
import {raffleService} from "@/AbsoluteComponents/services/game";
import {useSelector} from "react-redux";
import useGame from "@/AbsoluteComponents/hooks/game";
import {getAuthToken, getPlayerId} from "@/AbsoluteComponents/utils/cookies";
import useAuth from "@/AbsoluteComponents/hooks/auth";
import clsx from "clsx";

interface WinnersTableInterface {
    selectedDate:any
    data:any
}

const WinnersTable:React.FC<WinnersTableInterface> = ({selectedDate, data}) => {
    
    // VARIABLES
    const {instances} = data
    const game_hook = useGame()
    const auth = useAuth()
    const interval = useRef<any>(null)
    
    // STATES
    const getState = (st:string) => {
        
        return useSelector((state:any) => state.common[st])
    }
    
    const winners = getState("raffle_winners")
    
    
    const handlerWinners = async (playerId?:string) => {
        const current_instance = instances.find((inst:any) => inst["moday_date"] === selectedDate ||inst["monday_date"] === selectedDate).instanceid
        await game_hook.updateRaffleWinners("FixedIntervals", current_instance, playerId && playerId)
    }
    
    useEffect(() => {

        if(selectedDate.length > 0) {
            if(!getAuthToken().length) {
                handlerWinners()
            }
            
            if(getAuthToken().length && auth.isLoggedIn()) {
                interval.current = setInterval(() => {
                    let playerId = getPlayerId()
                    if(playerId.length > 0) {
                        handlerWinners(playerId)
                        clearInterval(interval.current)
                    }
                }, 50)
            }
        }

    }, [selectedDate, auth.isLoggedIn()]);
    
    
    return (
        <div className={styles.winners_table}>
            
            <div className={styles.table_header}>
                <div className={clsx(styles.tb_col_nickname, styles.tb_col)}>
                    Nickname
                </div>
                
                <div className={clsx(styles.tb_col_prize, styles.tb_col)}>
                    Premiu
                </div>
            </div>
            
            {(Array.isArray(winners) && winners.length) ? winners.map((wr, i) => {
                return <WinnerRow key={"raffle_winner_row"+i} data={wr}/>
            }) : null}
        </div>
    )
}

interface WinnerRowProps {
    data:any
}

const WinnerRow:React.FC<WinnerRowProps> = ({data}) => {
    
    const {username, prize} = data
    
    return (
        <div className={styles.table_item}>
            <div className={styles.nickname}>
                {username}
            </div>
            
            <div className={styles.prize}>
                {prize}
            </div>
        </div>
    )
}

export default WinnersTable