import * as actionTypes from './actions';
import '../../interfaces/user';
import UserInterface from "../../interfaces/user";
import BalanceInterface from "../../interfaces/balance";
import Offer from "../../interfaces/offer";
import GameInterface from "../../interfaces/game";
import {getAuthToken, getVerificationStatus} from "../../utils/cookies";
import VerificationStatus from "../../interfaces/VerificationStatus";
import {
    UPDATE_LEADERBOARD,
    UPDATE_QUEST_STATUS,
    UPDATE_DEPOSIT_NUM,
    UPDATE_PLAYER_QUEST_STATUS, UPDATE_TIMED_FREE_SPINS, RESET_PWD_PHONE_ENDING, SMARTICO_USER_PROPS
} from "./actions";
import QuestLeaderboardInterface from "@/AbsoluteComponents/interfaces/quest_leaderboard_interface";
import SmarticoMiniGame from "@/AbsoluteComponents/interfaces/smartico_minigames";

interface initialValues {
    user?: UserInterface,
    balance?: BalanceInterface,
    betBalance: number,
    updating: boolean,
    offers: Array<Offer>,
    offersCondition: string,
    favoriteGames: Array<GameInterface>,
    favoriteGamesLoading:boolean,
    freeSpinsGames:Array<string>,
    freeBonusGames:Array<string>,
    documentsListToggle:boolean,
    verificationStatus:Array<VerificationStatus>;
    questStatus:any,
    spinStatus: Array<any>,
    depositNum: number,
    questLeaderboard: QuestLeaderboardInterface
    validation_opened: boolean
    user_docs:Array<any>,
    timedFreeSpins: Array<string>
    smarticoMiniGames: Array<SmarticoMiniGame>
    bonusCenterIsOpen: boolean
    resetPwdPhoneEnding: string
    smarticoProps: any
}

const initialState:initialValues = {
    user: undefined,
    balance: undefined,
    betBalance: 0,
    updating: false,
    offers: [],
    favoriteGames: [],
    favoriteGamesLoading: false,
    documentsListToggle: false,
    offersCondition: '',
    freeSpinsGames: [],
    freeBonusGames: [],
    verificationStatus: [],
    questStatus: {},
    questLeaderboard: {
        leader: {},
        state: {},
        leaderBoard: {},
        maxLevelTop: {},
        apiFail: false
    },
    spinStatus: [],
    depositNum: 0,
    validation_opened: false,
    user_docs:[],
    timedFreeSpins: [],
    smarticoMiniGames: [],
    bonusCenterIsOpen: false,
    resetPwdPhoneEnding: "",
    smarticoProps: undefined
};

const reducer = (state = initialState, action:any) => {
    switch (action.type) {

        case actionTypes.UPDATE_DEPOSIT_NUM:
            return {...state, depositNum: action.payload};
            
        case actionTypes.UPDATE_USER_DOCS:
            return {...state, user_docs: action.payload};
            
        case actionTypes.UPDATE_VALIDATIONS:
            return {...state, validation_opened: action.payload};

        case actionTypes.UPDATE_BET_BALANCE:
            return {...state, betBalance: action.payload};

        case actionTypes.UPDATE_SPIN_STATUS:
            return {...state, spinStatus: action.payload};

        case actionTypes.UPDATE_USER_DATA:
            return {...state, user: action.payload};

        case actionTypes.USER_IS_UPDATING:
            return {...state, updating: action.payload};

        case actionTypes.UPDATE_USER_BALANCE:
            return {...state, balance: action.payload};

        case actionTypes.UPDATE_OFFER:
            return {...state, offers: action.payload}

        case actionTypes.UPDATE_OFFER_CONDITION:
            return {...state, offersCondition: action.payload}

        case actionTypes.UPDATE_FAVORITES_GAMES:
            return {...state, favoriteGames: action.payload}

        case actionTypes.UPDATE_FREE_SPINS_GAMES:
            return {...state, freeSpinsGames: action.payload}

        case actionTypes.UPDATE_FREE_BONUS_GAMES:
            return {...state, freeBonusGames: action.payload}

        case actionTypes.UPDATE_TOGGLE_DOCUMENTS_LIST:
            return {...state, documentsListToggle: !state.documentsListToggle}

        case actionTypes.UPDATE_VERIFICATION_STATUS:
            return {...state, verificationStatus: action.payload}

        case actionTypes.UPDATE_PLAYER_QUEST_STATUS:
            return {...state, questStatus: action.payload}

        case actionTypes.UPDATE_QUEST_STATUS:
            return {...state, questStatus: action.payload}

        case actionTypes.UPDATE_LEADERBOARD:
            return {...state, questLeaderboard: action.payload}
        
        case actionTypes.UPDATE_TIMED_FREE_SPINS:
            return {...state, timedFreeSpins: action.payload}
        
        case actionTypes.UPDATE_SMARTICO_MINI_GAMES:
            return {...state, smarticoMiniGames: action.payload}
        
        case actionTypes.OPEN_BONUS_CENTER:
            return {...state, bonusCenterIsOpen: action.payload}
        
        case actionTypes.RESET_PWD_PHONE_ENDING:
            return {...state, resetPwdPhoneEnding: action.payload}
        
        case actionTypes.SMARTICO_USER_PROPS:
            return {...state, smarticoProps: action.payload}
        
        
        default: return state;
    }
}

export default reducer