import {
    signInService,
    getProfile,
    getBalance,
    getFavoritesGames,
    getOffers,
    getPaymentMethods,
    getBetBalance,
    candyQuestServices,
    getDocumentValidation,
    refreshToken,
    getDocumentHistory,
    failedLogin, phoneVerify
} from 'services/account';
import {
    setAuthToken,
    deleteAuthToken,
    deletePlayerId,
    getAuthToken,
    deleteRealityCheckEndTime,
    setRealityCheckEndTime,
    saveRedirectTarget,
    deleteVerificationStatus,
    setProfileLoaded,
    deleteProfileLoaded,
    setRefreshToken,
    getRefeshToken,
    deleteRefteshToken,
    deleteExpiresIn,
    setExpiresIn,
    setLoggedInTime,
    deleteLoggedInTime, deleteRedirectTarget, setPlayerId
} from 'utils/cookies';
import {
    updateUserData,
    userIsUpdating,
    updateUserBalance,
    updateFavoritesGames,
    updateFreeSpinsGames,
    updateOffer,
    updateFreeBonusGames,
    updateVerificationStatus,
    updateSpinStatus,
    updateDepositNum,
    updateBetBalance,
    updateQuestStatus as questUpdate,
    updateValidations,
    updateUserDocs,
    updatePlayerQuestStatus, updateTimedFreeSpins, updateSmarticoMiniGames, setResetPwdPhoneEnding
} from 'store/account/actions';
import {updateSnackBar} from 'store/common/actions';
import {
    openForgotPasswordModal,
    openLoginModal,
    openResetPwdModal,
    openSetNickName,
    openWithdraw
} from 'store/modals/actions';
import {useDispatch, useSelector} from 'react-redux';
import UserInterface from 'interfaces/user';
import { useRouter } from 'next/router';
import Router from 'next/router';
import BalanceInterface from "interfaces/balance";
import {getSlug} from "utils/constants";
import strings from "utils/strings";
import Game from "utils/game";
import GameInterface from "interfaces/game";
import Offer from "interfaces/offer";
import CMS from "utils/cms";
import Payment from "utils/payment";
import VerificationStatus from "interfaces/VerificationStatus";
import games from 'public/json/games.json'
import ServiceResponse from "interfaces/service_response";
import {playRealGame} from "@/AbsoluteComponents/services/game";
import DocumentHistory from "@/AbsoluteComponents/interfaces/documetHistory";
import useCommon from "@/AbsoluteComponents/hooks/common";
import {hardcoded_quest} from "@/template/config/constants";
import QuestLeaderboardInterface from "@/AbsoluteComponents/interfaces/quest_leaderboard_interface";
import SmarticoClass from "@/AbsoluteComponents/utils/smartico_class";
import SmarticoMiniGame from "@/AbsoluteComponents/interfaces/smartico_minigames";
import CommonClass from "@/AbsoluteComponents/utils/common_class";


function useAuth() {
    const router = useRouter();
    const currentRoute = router.pathname;

    const dispatch = useDispatch();
    const providersNames:Array<any> = useSelector((state:any) => state.common.providersNames);
    const questStatus:any = useSelector((state: any) => state.account.questStatus);
    const candyQuestStatus:any = useSelector((state:any) => state.account.questStatus)
    let user:UserInterface = useSelector((state:any) => state.account.user);
    const balance:BalanceInterface = useSelector((state:any) => state.account.balance);
    let updating:boolean = useSelector((state:any) => state.account.updating);
    const depositNum: number = useSelector((state:any) => state.account.depositNum);
    const status = useSelector((state:any) => state.account.questStatus)
    const leaderboard = useSelector((state:any) => state.account.questLeaderboard)
    const smarticoMiniGames = useSelector((state:any) => state.account.smarticoMiniGames)
    const common_hook = useCommon()
    
    const zendeskIdentify = (name:string, email:String):void => {
        // @ts-ignore
        if (typeof window.zE === "function") {

        }
    }

    const signIn = async (email: string, password: string): Promise<boolean> => {
        const data:any = await signInService(email, password);
        
        if (data.error && typeof data?.response === "undefined") {
            dispatch(updateSnackBar(strings['autentificare_esuata'] || data.message.toString(), 'error', false))
            
        } else if (typeof data.response === "object" && typeof data.response['accessToken'] === 'string') {
            
            setAuthToken(data.response['accessToken']);
            setRealityCheckEndTime(Number(data.response['realityCheck']['endTime']));
            setRefreshToken(data.response["refreshToken"])
            setExpiresIn(data.response["expiresIn"])
            setLoggedInTime(Date.now())
            
            const tokenUpdateEvent = new CustomEvent("token_update", {
                detail: {
                    expiresIn: data.response.expiresIn
                }
            })
            
            window.dispatchEvent(tokenUpdateEvent)
            
            
            if ( Array.isArray(data.response['verificationStatus']) ) {
                dispatch(updateVerificationStatus(verificationStatusFactory(data.response['verificationStatus'])));
            }

            dispatch(updateUserData(profileFactory({firstName: data.response['firstName'], lastName: data.response['lastName'], email: email})));

            updateProfile();
            
            const logged = new CustomEvent("logged")
            
            window.dispatchEvent(logged)
            
            return true;
        } else if(
            data.error &&
            data?.response.hasOwnProperty("extraData") &&
            data?.response.extraData?.hasOwnProperty("leftFailedLoginCount") &&
            data?.response.extraData.leftFailedLoginCount === 1 &&
            data?.response.extraData?.hasOwnProperty("phoneNumberLastDigits")
        ) {
            dispatch(updateSnackBar(strings['autentificare_esuata'] || data.message.toString(), 'error', false))
            dispatch(setResetPwdPhoneEnding(data?.response.extraData.phoneNumberLastDigits as string))
            
        } else if(data.error && data.message.length && data.response.hasOwnProperty("code") && data.response.code === "403") {
            dispatch(updateSnackBar(
                data.message.toString(),
                'error',
                false,
                "Recuperați contul",
                () => {
                    dispatch(openForgotPasswordModal(true))
                    dispatch(openLoginModal(false))
                    dispatch(openResetPwdModal(""))
                    
                }))
        }

        return false;
    };
    
    const frozenLoginPhonePost = async ( number:string) => {
        const req  = await failedLogin(number)
        
        if(req.error && req.message) {
            dispatch(updateSnackBar(req.message, 'error', false))
        }
        
        if(typeof req.response === "object" && req.response.hasOwnProperty("success") && req.response.success && req.message && req.response?.resendSeconds) {
            dispatch(updateSnackBar(req.message+ " " + `Au mai rămas ${req.response.leftAttempts} încercări`, 'success', false))
            let currentDate = new Date();
            currentDate.setSeconds(currentDate.getSeconds() + req.response?.resendSeconds / 10000);
            
            localStorage.setItem(
                CommonClass.local_storage_items.send_pin_info,
                JSON.stringify({
                    expires: currentDate,
                    attempts: req.response.leftAttempts,
                    phone: number
                })
            )
            const listenSendPinTimeout = new CustomEvent(CommonClass.custom_events.listen_pin_timeout)
            window.dispatchEvent(listenSendPinTimeout)
        } else {
            dispatch(updateSnackBar(strings["unknown_error"], 'error', false))
        }
        
    }
    
    const frozenLoginPhoneVerify = async (code:string, number:string) => {
        const req = await phoneVerify(code, number)
        const data = req?.response
        
        if(!req.error && typeof data === "object" && req?.response.hasOwnProperty("accessToken")) {
            
            localStorage.removeItem(CommonClass.local_storage_items.send_pin_info)
            dispatch(openResetPwdModal(req?.response.accessToken))
            dispatch(setResetPwdPhoneEnding(""))
            dispatch(openLoginModal(false))
            
        } else {
            dispatch(updateSnackBar(req?.message || strings["unknown_error"], "error", true))
        }
        
    }
    
    
    const signOut = async (redirectTo?:string): Promise<void> => {
        const needReload = ( currentRoute.search( '/account' ) > -1 || (router.asPath.search( '/joc') > -1 && router.asPath.search( 'real') > -1) || currentRoute.search( '/favorites' ) > -1 || currentRoute.search( '/free-spin' ) > -1 || currentRoute.search( '/free-bonus' ) > -1|| currentRoute.search( '/last-played' ) > -1 );
        deleteAuthToken();
        deletePlayerId();
        deleteRefteshToken();
        deleteProfileLoaded();
        deleteRealityCheckEndTime();
        deleteLoggedInTime();
        localStorage.removeItem('bonus')
        localStorage.removeItem('spins')
        deleteVerificationStatus();
        // deleteRedirectTarget()
        dispatch(userIsUpdating(true));
        dispatch(updateUserData());
        // await signOutService();
        dispatch(userIsUpdating(false));
        dispatch(updateUserBalance(undefined));
        dispatch(updateFreeBonusGames([]));
        dispatch(updateFreeSpinsGames([]));
        dispatch(questUpdate({}))
        zendeskIdentify('', '');
        
        if (redirectTo && redirectTo.length > 0) {
            window.location.href = redirectTo;
            return;
        } else {
            window.location.href = '/';
        }
        
        // if( needReload ) {
        //     window.location.href = '/';
        // }
    };

    const fetchOffers = async () => {
        const result: Offer[] = [];

        const offers:any = await getOffers();
        
        if(Array.isArray(offers?.response)) {
            
            offers?.response?.map((item:any) => {
                let minDepositAmount = typeof item['minDepositAmount'] === 'number' ? item['minDepositAmount'] : 0;
                
                if (minDepositAmount === 0) return;
                // console.log(item)
                result.push({
                    campaignId: typeof item['campaignId'] === 'string' ? item['campaignId'] : '',
                    name: typeof item['name'] === 'string' ? item['name'] : '',
                    title: typeof item['title'] === 'string' ? item['title'] : '',
                    minDepositAmount: minDepositAmount,
                    freeSpin:  Array.isArray(item['freeSpin']) ? item['freeSpin'] : [],
                    freeBonus:  Array.isArray(item['freeBonus']) ? item['freeBonus'] : [],
                    promo: Array.isArray(item['promo']) ? item['promo'] : [],
                    cash: Array.isArray(item['cash'])? item['cash'] : [],
                    timedFreeSpin: Array.isArray(item['timedFreeSpin']) ? item['timedFreeSpin'] : [],
                    methods: Array.isArray(item['methods']) ? item['methods'] : [],
                });
            });
        }
        
        
        dispatch(updateOffer(result));
    }

    const profileFactory = (json:any):UserInterface => ({
        email: typeof json['email'] === "string" ? json['email'] : '',
        firstName: typeof json['firstName'] === "string" ? json['firstName'] : '',
        lastName: typeof json['lastName'] === "string" ? json['lastName'] : '',
        phoneNumber: typeof json['phoneNumber'] === "string" ? json['phoneNumber'] : '',
        userName: typeof json['userName'] === "string" ? json['userName'] : '',
        nickName: typeof json['nickName'] === "string" ? json['nickName'] : '',
        city: typeof json['city'] === "string" ? json['city'] : '',
        address: typeof json['address'] === "string" ? json['address'] : '',
        zip_code: typeof json['zip_code'] === "string" ? json['zip_code'] : '',
        receiveEmail: typeof json['receiveEmail'] === "boolean" ? json['receiveEmail'] : false,
        receiveSms: typeof json['receiveSms'] === "boolean" ? json['receiveSms'] : false,
        birthDate: typeof json['birthDate'] === "string" ? json['birthDate'] : '',
    });

    const updateDeposits = () => {
        getPaymentMethods()
            .then((res) => {
                if(Number(res?.response?.totalDepositCount) > 0) {
                    dispatch(updateDepositNum(Number(res?.response?.totalDepositCount)));
                } else {
                    dispatch(updateDepositNum(0))
                };
            })
    }

    const updateProfile = async () => {
        
        let token = getAuthToken()
        if (token.length === 0) return;

        dispatch(userIsUpdating(true));
        
        const data = await getProfile(token);
        const betData: ServiceResponse = await getBetBalance()
        const validations = await getDocumentValidation()

        fetchFavoritesGames();
        fetchOffers();
        updateBalance();

        dispatch(userIsUpdating(false));

        if( betData.response && typeof betData.response.amount === 'number') {
            dispatch(updateBetBalance(betData.response.amount))
        }

        if(data.response && data.response['email']) {
            setProfileLoaded();
            dispatch(updateUserData(data.response));
            dispatch(updateSpinStatus(data.response.attributes));
            updateDeposits()
            zendeskIdentify(`${data.response['firstName']} ${data.response['lastName']}`, data.response['email'])
            
        } else {
            deleteProfileLoaded();
            deleteAuthToken();
            deletePlayerId();
            deleteRefteshToken();
            deleteExpiresIn();
        }
        
        if(Array.isArray(validations.response) && validations.response.length) {
            let opened = validations.response.find((item:any) => {
                return item.status !== 'approved'
            });
            dispatch(updateValidations(Boolean(opened)))
        }

    }

    const updateBalance = async () => {
        const token:string = getAuthToken();

        if (!token.length) return;

        const data = await getBalance();
        const freeSpinsGames:Array<string> = [];
        const freeBonusesGames:Array<string> = [];
        const timedFreeBonusesGames:Array<string> = [];

        if (data.response && typeof data.response['balance'] !== undefined) {
            if (typeof data.response.wallet === "object") {
                const {accounts, key} = data.response.wallet;

                if (typeof accounts === "object") {
                    Object.keys(accounts).map((key:any) => {
                        if (accounts[key] && accounts[key].type === 'freeSpins' && accounts[key].amount > 0) {
                            if (Array.isArray(accounts[key].games)) {
                                accounts[key].games.map((g:any) => {
                                    if (g['gameId']) {
                                        freeSpinsGames.push(g['gameId']);
                                    }
                                })
                            }
                        }

                        if (accounts[key] && accounts[key].type === 'freeBonus' && accounts[key].amount > 0) {
                            if (typeof accounts[key].game?.gameId === "string") {
                                freeBonusesGames.push(accounts[key].game.gameId);
                            }
                        }
                        
                        if (accounts[key] && accounts[key].type === 'timedFreeSpins' && accounts[key].interval > 0) {
                            
                            if (Array.isArray(accounts[key].games)) {
                                accounts[key].games.map((g:any) => {
                                    if (g['gameId']) {
                                        timedFreeBonusesGames.push(g['gameId']);
                                    }
                                })
                            }
                        }
                        
                    });
                }
                
                if(typeof key === "object" && Object.keys(key).length && key.hasOwnProperty("playerId")) {
                    setPlayerId(key["playerId"])
                }
            }
            
            dispatch(updateTimedFreeSpins(timedFreeBonusesGames))
            dispatch(updateFreeSpinsGames(freeSpinsGames));
            dispatch(updateFreeBonusGames(freeBonusesGames));
            dispatch(updateUserBalance(Payment.balanceFactory(data.response)));
        }
    }

    const isLoggedIn = (): boolean => {
        return user !== undefined && !updating;
    };

    const getQuestStatus = ():any => {
        return questStatus
    }

    const getCandyQuestStatus = () => {
        return candyQuestStatus
    }
    
    
    const updateDocs = async () => {
        const result: DocumentHistory[] = [];
        const documents = await getDocumentHistory();
        
        if (!documents.error) {
            
            documents.response.map((document:any) => {
                result.push({
                    documentName: typeof document['documentName'] === 'string' ? document['documentName'] : '',
                    status: typeof document['status'] === 'string' ? document['status'] : '',
                    type: typeof document['type'] === 'string' ? document['type'] : '',
                    updatedTime: typeof document['updatedTime'] === 'number' ? document['updatedTime'] : 0,
                    uploadedTime: typeof  document['uploadedTime'] === 'number' ? document['uploadedTime'] : 0,
                })
            })
            
            dispatch(updateUserDocs(result))
        }
    }
    
    const currentUserQuestPercentage = () => {
        if(!getAuthToken().length && !Object.keys(leaderboard.leaderBoard).length && Object.keys(status).length) return 0
        
        let level = status?.level || 0
        let next_level = level + 1
        let user_points = status?.points || 0
        let from = leaderboard.leaderBoard[level]?.pointsNeeded || 0
        let to = leaderboard.leaderBoard[next_level]?.pointsNeeded || 0

        return common_hook.getPercentage(from, to, user_points)
    }
    
    const updateQuestStatus = async (method: "GET" | "POST") => {
        
        const {hardcoded} = CMS.getCurrentQuest()
        
        if(!getAuthToken()?.length) return
        
        if(hardcoded) {
            let h_status = {
                "optIn": false,
                "level": 1,
                "levelName": "1",
                "points": 1
            }
            dispatch(updatePlayerQuestStatus(h_status))
            return
        }

        const status = await candyQuestServices().getOrPostStatus(getAuthToken(), method)
        
        if(method === "POST") {
            if(status.response.hasOwnProperty("status") && status.response.status === "OK") {
                updateQuestStatus("GET")
            }
        }
        
        if(method === "GET") {
            if(!status.error && status.response.hasOwnProperty("optIn")) {
                dispatch(updatePlayerQuestStatus(status.response))
            }
        }
    }

    const isUpdating = (): boolean => {
        return updating;
    };

    const getUser = (): UserInterface => {
        return user;
    };


    const getUserBalance = (): number => {
        const cash:number = balance?.cash || 0;
        const promo:number = balance?.promo || 0;

        return Number((cash + promo).toFixed(2))
    }

    const getCurrency = (): string => {
      return balance?.currency
    }

    const getFreeSpins = ():number => {
        return balance?.freeSpins || 0
        // return balance?.freeSpins || 999
    }
    
    const getTimedFreeSpins = ():number => {
        return balance?.timedFreeSpins || 0
    }
    
    const getFreeBonuses = ():number => {
        return balance?.grantedFreeBonuses || 0
        // return balance?.grantedFreeBonuses || 15
    }

    const getFreeBets = ():number => {
        return balance?.freeBets || 0
    }

    const getCash = ():number => {
        return balance?.cash || 0
    }

    const getBonus = (): number => {
        return balance?.promo || 0
    }
    const getWithdrawProgress = (): number => {
        return balance?.withdrawProgress
    }


    const openRealGame = async (friendlyName:string) => {
        if ( !isLoggedIn() ) {
            dispatch(openLoginModal(true));
            return;
        }

        router.push('/joc/'+getSlug(friendlyName)+'/real');
    };
    
   

    const runGame = (game:GameInterface, demo:boolean, callback?:(url:string) => void, event?:any) => {
        let gamePath:string = '';
        let vendorSlug:string = '';
        let gameUrl:string = '';
        
        Game.getVendorsList().map(v => {
            if (v.id === game.vendor) {
                vendorSlug = getSlug(v.name);
            }
        });

        if (vendorSlug.length) {
            gamePath = '/joc/'+vendorSlug+'/'+getSlug(game.friendlyName);
        }

        if (!getAuthToken() && !demo) {
            dispatch(openLoginModal(true));
            saveRedirectTarget(`${gamePath}${(!demo ? '/real' : '')}`);
            return;
        }
        
        
        if(isLoggedIn() && !getUser().nickName && game.nicknameRequired && !demo) {
            dispatch(openSetNickName(true))
            saveRedirectTarget(`${gamePath}${(!demo ? '/real' : '')}`);
            return;
        }

        if (gamePath.length) {

            gameUrl = `${gamePath}${(!demo ? '/real' : '')}`;

            router.push(gameUrl);

            if (typeof callback === "function") callback(gameUrl);

            if (event) {
                let gameById = event.target;
                const section = gameById?.closest("[data-section]")?.getAttribute('data-section') || '-';
                const title = gameById?.closest("[data-section-title]")?.getAttribute('data-section-title') || '-';

                let info = {
                    url: window.location.href,
                    section: section,
                    sectionTitle: title
                }

                CMS.fbTrackGames({info})
                CMS.gtagTrackGames({info})
            }
        }
    };

    const openWithdrawPopup = ():void => {
        
        if (getCash() === 0) {
            dispatch(updateSnackBar(`${strings['nu_aveti_sold_cash']}`, 'error', false));
            return;
        }

        if(depositNum === 0) {
            dispatch(updateSnackBar(`${strings['nu_aveti_depuneri']}`, 'error', false));
            return;
        }

        dispatch(openWithdraw(true));
    };

    const closeWithdrawPopup = ():void => {
        dispatch(openWithdraw(false));
    };

    const fetchFavoritesGames = async ():Promise<void> => {
        const token:string = getAuthToken();

        if (!token.length) return;

        const data = await getFavoritesGames();

        if (!data.error && Array.isArray(data.response.games)) {

            let favGamesIds:Array<string> = [];

            data.response.games.map((g: any) => {
                favGamesIds.push(g.gameId)
            })

            // @ts-ignore
            let matchGames = games.filter(g => favGamesIds.includes(g.gameId))
            
            dispatch(updateFavoritesGames(matchGames.map((json:any) => Game.gameInterfaceFactory(json))));
        }
    };

    const getProviderSlug = (game:GameInterface):string => {
        let slug:string = '';

        const provider:any = providersNames.find((p:any) => p.id === game.provider);

        if (provider && provider.name) {
            return getSlug(provider.name);
        }

        return slug;
    };
    
    const updSmarticoMiniGames = async () => {
        SmarticoClass.getMiniGames().then((res:any) => {
            if(Array.isArray(res) && res.length) {
                let result:Array<SmarticoMiniGame> = res.map((x) => {
                    return SmarticoClass.miniGamesFactory(x)
                })
                dispatch(updateSmarticoMiniGames(result))
            }
        })
    }
    
    const getSmarticoMiniGames = ():Array<SmarticoMiniGame> => {
        return smarticoMiniGames
    }

    const verificationStatusFactory = (list:Array<any>):Array<VerificationStatus> => {
        let verifications:Array<VerificationStatus> = [];

        list.map((json:any) => {
            if (typeof json === "object") {
                verifications.push({
                    leftCountAttempt: typeof json['leftCountAttempt'] === "number" ? json['leftCountAttempt'] : 0,
                    method: typeof json['method'] === "string" ? json['method'] : '',
                    requiredBySite: typeof json['requiredBySite'] === "boolean" ? json['requiredBySite'] : false,
                    value: typeof json['value'] === "string" ? json['value'] : '',
                    verified: typeof json['verified'] === "boolean" ? json['verified'] : false,
                });
            }
        })
        
        return verifications;
    };

    return {
        signIn,
        signOut,
        updateProfile,
        updateBalance,
        isLoggedIn,
        isUpdating,
        getUser,
        getUserBalance,
        getCurrency,
        getFreeSpins,
        getFreeBonuses,
        getCash,
        getBonus,
        getWithdrawProgress,
        openRealGame,
        openWithdrawPopup,
        closeWithdrawPopup,
        fetchFavoritesGames,
        getProviderSlug,
        runGame,
        verificationStatusFactory,
        getQuestStatus,
        getCandyQuestStatus,
        getFreeBets,
        updateQuestStatus,
        updateDeposits,
        updateDocs,
        currentUserQuestPercentage,
        getTimedFreeSpins,
        updSmarticoMiniGames,
        getSmarticoMiniGames,
        frozenLoginPhonePost,
        frozenLoginPhoneVerify
    }
}

export default useAuth;