import strings from "utils/strings";
import TextField from "ui/textfield";
import Modal from 'react-modal';
import {useDispatch, useSelector} from 'react-redux';
import {openForgotPasswordModal} from 'store/modals/actions';
import Image from 'next/image';
import useAuth from 'hooks/auth';
import Button from "@/template/small_ui/button";
import {useState} from 'react';
import clsx from 'clsx';
import UnstyledButtonsSimple from "ui/datePicker";
import dayjs, { Dayjs } from 'dayjs';
import {forgotPassword} from "services/account";
import {updateSnackBar} from "store/common/actions";
import {useForm} from 'react-hook-form';
import ModalLayout from "@/template/ui/modal_layout";


function ForgotPasswordModal() {

    const [loading, setLoading] = useState(false);

    const [birthDate, setDate] = useState<any>(dayjs(new Date(), 'YYYY-MM-DD'));

    const dispatch = useDispatch();
    const auth = useAuth();
    const open = useSelector((state:any) => state.modals.openForgotPassword);

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();


    const onSubmit = async (formFields:any, e:any) => {
        e.preventDefault();

        const form = new FormData(e.target);

        // const birthDate:string = form.get('birthDate') !== null ? form.get('date')!.toString() : '';

        const email:string = form.get('email') !== null ? form.get('email')!.toString() : '';

        setLoading(true);
        const password:any = await forgotPassword(birthDate, email);
        setLoading(false);

        if(!password.error) {
            dispatch(updateSnackBar('Ţi-am trimis un e-mail cu un link de resetare a parolei. Accesează acel link pentru a seta o nouă parolă.', 'success'));

            dispatch(openForgotPasswordModal(false));
            const root =  document.getElementsByTagName('html')[0];
            root.removeAttribute('style');
        } else {
            // dispatch(updateSnackBar( password.message || '', 'error'));
            dispatch(updateSnackBar( 'Verifică datele și încearcă din nou sau contactează departamentul de asistență.' , 'error'));
        }

    }

        const emailProps = {
            name: 'email'
        };


    return (
        <Modal
            ariaHideApp={false}
            isOpen={open}
            className={'modal'}
            onRequestClose={() => {
                dispatch(openForgotPasswordModal(false))
                const root =  document.getElementsByTagName('html')[0];
                root.removeAttribute('style');
            }}
            contentLabel="Example Modal"
        >

           <ModalLayout onClose={() => {
               dispatch(openForgotPasswordModal(false))
               const root =  document.getElementsByTagName('html')[0];
               root.removeAttribute('style');
           }}>
               <>
                   <div className={clsx('ReactModal__Inner')}>

                       <div className="ReactModal__Inner__Header">
                           <h2 style={{color: '#EEB665'}} className="ReactModal__Title">{strings['resetare_parola']}</h2>
                       </div>

                       <div className="ReactModal__Inner__Content">
                           <p style={{maxWidth: 390}}>{strings['pentru_resetare']}</p>

                           <form onSubmit={handleSubmit(onSubmit)}>
                               <div className={clsx("mb-20", 'datepickinput', 'depositFilter-formInput')}>
                                   <UnstyledButtonsSimple
                                       label={'Data nașterii'}
                                       onChange={birthDate => setDate(dayjs(birthDate).format('YYYY-MM-DD'))}
                                   />
                               </div>
                               <div className={clsx("mb-20")}>
                                   <TextField
                                       label={'Email'}
                                       type='text'
                                       placeholder={'Email'}
                                       {...emailProps}

                                       {...register("email", {
                                           required: true,
                                           pattern: /^\S+@\S+\.\S+$/i
                                       })}
                                   />
                                   {errors.email?.type === "pattern" && (
                                       // <p className="errorMsg">{'Adresa email invalida'}</p>
                                       <p className="errorMsgLogin" style={{marginTop: -12}}>{'Adresă email invalidă'}</p>
                                   )}
                               </div>

                               <Button type={'submit'} loading={loading} text={strings['trimite_email']} />
                           </form>
                       </div>

                   </div>
               </>
           </ModalLayout>

        </Modal>
    )


}
export default ForgotPasswordModal