import React, {FC, useRef, useState} from 'react'
import CategoryGamesSlider from "../categoryGamesSlider/categoryGamesSlider";
import GameInterface from "interfaces/game";
import {useSelector} from 'react-redux';
import CMS from "utils/cms";
import Game from "utils/game";
import CategoryGamesPreloader from "../categoryGamesSlider/preloader";
import {lazy_loading_offset} from "@/AbsoluteComponents/utils/constants";
import LazyComponentWrap from "components/lazy_component_wrap";

interface Props {
    json: any
    index?:any
}

const RtpGamesCarousels:FC<Props> = (props) => {
    
    const {index} = props
    
    if (index >= lazy_loading_offset) {
        return (
            <LazyComponentWrap>
                <Core { ... props}/>
            </LazyComponentWrap>
        )
    } else {
        return <Core { ... props}/>
    }
}

export default RtpGamesCarousels

const Core:React.FC<Props> =({json})=> {

    //ADDED START
    const [hourlyHotLoading, setHourlyHotLoading] = useState<boolean>(true)
    const [hourlyColdLoading, setHourlyColdLoading] = useState<boolean>(true)
    const [dailyHotLoading, setDailyHotLoading] = useState<boolean>(true)
    const [dailyColdLoading, setDailyColdLoading] = useState<boolean>(true)
    //ADDED END

    const rtp:any = useSelector((state:any) => state.common.liveRTP);
    const games:Array<GameInterface> = Game.getAll();
    const {hourlyHot, hourlyCold, dailyHot, dailyCold} = rtp;

    const [show, setShow] = useState(false);
    const rootRef = useRef<any>(null);

    const data = CMS.liveRtpGamesCarousel(json);

    const schema = (list:Array<any>, hot:boolean):Array<GameInterface> => {
        const result:Array<GameInterface> = [];

        if (Array.isArray(games) && games.length) {
            list.map((item:any) => {
                const game:any = games.find((g:GameInterface) => g.gameId === item.gameId);

                if (game?.gameId) {
                    result.push({...game, ...item, hotRTP: hot, coldRTP: !hot});
                }
            });
        }

        return result;
    };

    // console.log(json)

    return (
        <div >

            {rtp.hasOwnProperty('hourlyHot') && (
                <>
                    {data.hourlyHot && schema(hourlyHot, true).length > 0 &&
                        <CategoryGamesSlider
                            index={0}
                            disableDemo
                            title={'Hourly Hot'}
                            count={schema(hourlyHot, true).length}
                            json={[]}
                            externalGames={schema(hourlyHot, true)}
                            onSliderInit={() => {
                                setHourlyHotLoading(false);
                            }}
                        />
                    }

                    {(hourlyHotLoading && data.hourlyHot) && <CategoryGamesPreloader />}
                </>
            )}


            {rtp.hasOwnProperty('hourlyCold') && (
                <>
                    {data.hourlyCold && schema(hourlyCold, true).length > 0 &&
                        <CategoryGamesSlider
                            index={0}
                            disableDemo
                            title={'Hourly Cold'}
                            count={schema(hourlyCold, false).length}
                            json={[]}
                            externalGames={schema(hourlyCold, false)}
                            onSliderInit={() => {
                                setHourlyColdLoading(false);
                            }}
                        />
                    }
                    {(hourlyColdLoading && data.hourlyCold) && <CategoryGamesPreloader />}
                </>
            )}

            {rtp.hasOwnProperty('dailyHot') && (
                <>
                    {data.dailyHot && schema(dailyHot, true).length > 0 &&
                        <CategoryGamesSlider
                            index={0}
                            disableDemo
                            title={'Daily Hot'}
                            count={schema(dailyHot, true).length}
                            json={[]}
                            externalGames={schema(dailyHot, true)}
                            onSliderInit={() => {
                                setDailyHotLoading(false);
                            }}
                        />
                    }

                    {(dailyHotLoading && data.dailyHot) && <CategoryGamesPreloader />}
                </>
            )}


            {rtp.hasOwnProperty('dailyCold') && (
                <>
                    {data.dailyCold && schema(dailyCold, true).length > 0 &&
                        <CategoryGamesSlider
                            index={0}
                            disableDemo
                            title={'Daily Cold'}
                            count={schema(dailyCold, false).length}
                            json={[]}
                            externalGames={schema(dailyCold, false)}
                            onSliderInit={() => {
                                setDailyColdLoading(false);
                            }}
                        />
                    }

                    {(dailyColdLoading && data.dailyCold) && <CategoryGamesPreloader />}
                </>
            )}

            {/*ADDED END*/}

        </div>
    )
}

