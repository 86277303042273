import strings from "utils/strings";
import Modal from 'react-modal';
import {useEffect} from 'react';
import {openSupport} from 'store/modals/actions';
import {useDispatch, useSelector} from 'react-redux';
import ContactForm from "@/template/ui/contactForm";
import ModalLayout from "@/template/ui/modal_layout";

const SupportForm = ({}) => {
    const dispatch = useDispatch();
    const open = useSelector((state:any) => state.modals.openSupport);

    useEffect(() => {
        if(open) {
            const root =  document.getElementsByTagName('html')[0];
            root.setAttribute('style', 'overflow-y: hidden');
        } else {
            const root =  document.getElementsByTagName('html')[0];
            root.removeAttribute('style');
        }
    })


    return (
        <Modal
            ariaHideApp={false}
            className={'ReactModal__SM'}
            isOpen={open}
            onRequestClose={() => dispatch(openSupport(false))}
            contentLabel="Example Modal"
        >
            <ModalLayout onClose={() => dispatch(openSupport(false))}>
                <>
                    <div className="ReactModal__Inner__Header">
                        <h2 className="ReactModal__Title">{strings['assistance']}</h2>
                    </div>

                    <div className="ReactModal__Inner__Content">
                        <ContactForm publicForm={false} modal/>
                    </div>
                </>
            </ModalLayout>
        </Modal>
    )
}

export default SupportForm