import React, {useEffect, useState} from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from 'themes/default/styles/categoryGamesSlider.module.scss';
import clsx from 'clsx';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from "swiper";
import { Pagination } from "swiper";
import PaginationArrow from "ui/paginationArrow";
import Skeleton from '@mui/material/Skeleton';


const CategoryGamesPreloader:React.FC = () => {
    const [swiper, setSwiper] = useState<any>();
    const [isMobile, setIsMobile] = useState<boolean | undefined>(undefined);
    let cardSkeletons:Array<number> = [];
    for(let i = 0; i < 20; i++) {
        cardSkeletons.push(i)
    }

    useEffect(() => {
        const width = window.innerWidth;
        const mobile: boolean = width < 991;
        setIsMobile(mobile)
    }, [])

    return (
        <div style={{maxHeight: '399.88px', transform: 'translateY(5px)'}} className={clsx(styles.categoryGames, 'slideClass', 'theme-slider', 'swiper-init-padding')}>
            <Swiper
                pagination={{
                    clickable: true,
                }}
                modules={[Pagination, Navigation]}
                className={clsx(styles.themes__mySwiper, 'swiper-wrapper')}
                slidesPerView='auto'
                onSwiper={(sw) => setSwiper(sw)}

            >
                <PaginationArrow onClick={() => swiper.slideNext()}/>
                <PaginationArrow onClick={() => swiper.slidePrev()} rightArrow />

                {cardSkeletons.length > 0 && (
                    cardSkeletons?.map((sk, i) => {
                        return (
                            <SwiperSlide style={{marginLeft: '-1px'}} key={'skeleton' + i}>
                                <Skeleton
                                    className={'skeletonGameCard'}
                                    variant="rectangular"
                                    animation="wave"
                                />
                            </SwiperSlide>
                        )
                    })
                )}

            </Swiper>

        </div>
    )
}

export default CategoryGamesPreloader