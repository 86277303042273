import Footer from 'themes/default/ui/footer';
import Header from 'themes/default/ui/header';
import CMS from "@/AbsoluteComponents/utils/cms";
import GetAppBlock from "@/template/cms-views/getAppBlock";



function MyAppView(props:any) {

    const {children, cls} = props;

    return (
        <>
            <div className={cls} id={'main_container'} >
                <Header/>

                {children}
            </div>
            
             <GetAppBlock/>
            <Footer/>
        </>
    );
}

export default MyAppView;