import Image from 'next/image';
import styles from 'themes/default/styles/Header.module.scss';
import Link from 'next/link';
import {useRouter} from 'next/router';
import strings from "utils/strings";
import clsx from 'clsx';
import Search from "@/template/ui/search";
import FacebookIcon from 'themes/default/svg/facebookicon';
import InstagramIcon from 'themes/default/svg/instagram';
import CloseIcon from "themes/default/svg/closeicon";
import {openDeposit, openSignupModal, openLoginModal, openWheelModal} from 'store/modals/actions';
import UserDropDown from "themes/default/ui/header/userDropDown";
import Balance from 'themes/default/ui/balance/balance-view';
import {showAccountSideBar, showGuestSideBar} from 'store/common/actions';
import SearchBar from "themes/default/ui/search/bar";
import {showSearchBar} from "store/common/actions";
import CircularProgress from '@mui/material/CircularProgress';
import AccountSideBar from "./accountSideBarMobile";
import a from "themes/default/styles/Header.module.scss";
import VerificationIndicator from "components/VerificationIndicator";
import ArrowRight from "themes/default/svg/arrow-right";
import React, { FC, useRef, useState, useEffect} from 'react';
import useAuth from "hooks/auth";
import CMS from "utils/cms";
import Offer from "interfaces/offer";
import {getAuthToken} from "utils/cookies";
import {useDispatch, useSelector} from 'react-redux';
import SmarticoClass from "@/AbsoluteComponents/utils/smartico_class";
import BonusCenter from "themes/default/ui/bonusCenter";
import GetAppPopup from "@/template/small_ui/get_app_popoup";


const  DefaultHeaderView:FC = () => {

    const router = useRouter();
    const auth = useAuth()
    const generalSettings = CMS.getDocumentGeneralSettings();
    const user = auth.getUser();
    const dispatch = useDispatch();

    const timer = useRef<any>(null);
    const sidebar = useRef<any>(null);

    let isLotoPage = router.pathname.search('loto') > -1;
    let isSportPage: boolean = router.pathname.slice(1, 6) === 'sport';
    const isMobile:boolean = JSON.parse(JSON.stringify(useSelector((state:any) => state.common.isMobile)));

    const [isGuest, setIsGuest] = useState(false);
    const [isTransition, setIsTransition] = useState<boolean>(false)
    const [scrolledDown, setScrolledDown] = useState(false);
    const smartico_props = useSelector((state:any) => state.account.smarticoProps);

    const searchActive:boolean = useSelector((state:any) => state.common.showSearchBar);
    const showSidebar:boolean = useSelector((state:any) => state.common.guestSidebar);
    const enableSpin: string = useSelector((state: any) => state.common.enableSpinWheel);
    const offer:Array<Offer> = useSelector((state:any) =>  state.account.offers);
    const showAccount:boolean = useSelector((state:any) => state.common.showAccountSideBar);


    useEffect(() => {
        setTimeout(() => {
            setIsTransition(true)
        }, 700)
    }, [])

    const hide:any = () => {
        dispatch(showAccountSideBar(false))
    }

    const hideMenu:any = () => {
        dispatch(showGuestSideBar(false));
    }

    const showMenu:any = () => {
        dispatch(showGuestSideBar(true));
    }

    const keepOverlay:any = (e:any) => {
        e.stopPropagation();
    }

    const closeSearchToggle = () => {
        dispatch(showSearchBar(false));
    };

    const updateBalance = async () => {
        await auth.updateBalance();

        timer.current = setTimeout(updateBalance, 2000);
    };
    
    useEffect(() => {
        window.addEventListener("scroll", (e) => {
            if(window.scrollY > 0) {
                setScrolledDown(true);
            } else {
                setScrolledDown(false);
            }
        });
        
        window.addEventListener("resize", (e) => {
            if(window.scrollY > 0) {
                setScrolledDown(false);
            } else {
                setScrolledDown(false);
            }
        });


    }, []);

    useEffect(() => {
        hideMenu();
        dispatch(showAccountSideBar(false));

        closeSearchToggle();

    }, [router.pathname, router.query.game_slug]);

    useEffect(() => {
        if (sidebar.current) sidebar.current.scrollTop = 0;
    }, [showSidebar]);


    useEffect(() => {
        setIsGuest(getAuthToken().length === 0);

        // auth.updateProfile();

        return () => {
            clearTimeout(timer.current);
        }
    }, [])

    useEffect(() => {
        if (!auth.isLoggedIn()) setIsGuest(true);
    }, [auth.isUpdating()]);

    return (
        <>
            <div className={clsx(styles.headerContainerWrapper, (isSportPage && isMobile) && styles.inSports , 'header-scroll', scrolledDown && 'fixed-scroll', searchActive && 'active-search')}>
                <GetAppPopup />
                <div id="navbar" className={clsx(styles.headerContainer, )}>
                    <div className={clsx(styles.headerContainer__inner, (isSportPage && isMobile) && styles.inSportsInner)}>
                        <div className={styles.headerContainer__nav}>
                            <button onClick={showMenu} className={'btn__menu'}><Image src={"/default/icons/menu_btn.svg"} alt={strings['menu']} width={32} height={32}/></button>

                            <ul className={styles.headerContainer__navList}>
                                <li className={styles.headerContainer__list}>
                                    <Link   className={clsx('btn__outline', router.pathname === '/slots' && 'active')} href={'/slots'}>
                                        {strings['sots']}
                                    </Link>
                                </li>

                                <li className={styles.headerContainer__list}>
                                    <Link   className={clsx('btn__outline', router.pathname === '/casino-live' && 'active')} href={'/casino-live'}>
                                        {strings['cazino_live']}
                                    </Link>
                                </li>

                                <li className={clsx(styles.headerContainer__list, isSportPage && styles.sportLink)}>
                                    <Link   className={'btn__outline'} href={'/sport'}>
                                        {strings['sports']}
                                    </Link>
                                </li>

                                <li className={clsx(styles.headerContainer__list, isLotoPage && styles.sportLink)}>
                                    <Link   className={'btn__outline'} href={'/loto'}>
                                        {strings['loto']}
                                    </Link>
                                </li>

                            </ul>
                        </div>

                        <Link    aria-label={"home"} className={clsx(styles.headerContainer__logo, (isMobile && isSportPage) && styles.logoSport)} href={'/'} onClick={closeSearchToggle}>
                            <img  className={'first-logo'} src={"/default/icons/gif-logo.gif"} alt={'logo'} width={200} height={100}/>
                        </Link>

                        {!auth.isLoggedIn() ? <div className={'logIn-Logo'}>
                                <Link   aria-label={"offers"} className={styles.headerContainer__logo} href={'/'} onClick={closeSearchToggle}>
                                    <img  className={'first-logo'} src={"/default/icons/gif-logo.gif"} alt={'logo'} width={200} height={100}/>
                                </Link>
                            </div> :
                            <Link   aria-label={"home"} className={styles.headerContainer__mobileLogo} href={'/'} onClick={closeSearchToggle}>
                                <img  className={'first-logo'} src={"/default/icons/logoMobile.webp"} alt={'logo'} width={45} height={45}/>
                            </Link>
                        }

                        {!auth.isLoggedIn()  && <Link   aria-label={"home"} className={styles.headerContainer__logoOut} href={'/'} onClick={closeSearchToggle}>
                            <img  className={'first-logo'} src={"/default/icons/gif-logo.gif"} alt={'logo'} width={200} height={100}/>
                        </Link>}

                        <div  className={clsx('rightSideBtn', searchActive && 'search-active', styles.headerContainer__buttons)}>

                            {router.pathname.slice(1, 6) !== 'sport' && (
                                <Search clickHandle={() => {
                                    dispatch(showSearchBar(true));
                                    hideMenu();
                                }}/>
                            )}

                            {auth.isUpdating() && <div style={{padding: '0 8px'}}><CircularProgress size={18}/></div>}

                            {/*{(auth.isLoggedIn()) && (*/}
                            <div className={'sideBar__block'}>
                                {/*{auth.isLoggedIn() && (*/}
                                {/*    <button type={'button'} className={'btn__menu'} onClick={() => router.push('/account/available-offers')}>*/}
                                {/*        <img src={"/default/icons/icon_gift.svg"} alt={strings['promotions']} width={32} height={32} style={{display: 'inline-block'}}/>*/}
                                {/*        <span className={clsx('headerOffer', 'bellPromotion')}>{offer.length}</span>*/}
                                {/*    </button>*/}
                                {/*)}*/}
                                
                                {auth.isLoggedIn() &&  <BonusCenter />}
                                
                                
                                {/*{router.pathname.slice(1, 6) !== 'sport' && (*/}
                                {/*    <button onClick={() => {dispatch(openWheelModal(true))}} className={clsx('wheelButton', !auth.isLoggedIn() && 'notLogged')}>*/}
                                {/*        {(enableSpin === 'enable' && auth.isLoggedIn()) && (*/}
                                {/*            <img width={33} height={33} src={`/default/images/Wheel/wheelIcon2.svg`}  alt={'wheel'}/>*/}
                                {/*        )}*/}
                                
                                {/*        {(enableSpin === 'disable' && auth.isLoggedIn()) && (*/}
                                {/*            <img width={33} height={33} src={`/default/images/Wheel/wheelIcon1.svg`}  alt={'wheel'}/>*/}
                                {/*        )}*/}
                                
                                {/*        {(!auth.isLoggedIn() && !auth.isUpdating()) && (*/}
                                {/*            <img width={33} height={33} src={`/default/images/Wheel/wheelIcon1.svg`}  alt={'wheel'}/>*/}
                                {/*        )}*/}
                                {/*    </button>*/}
                                {/*)}*/}


                                {auth.isLoggedIn() && (
                                    <Balance />
                                )}
                            </div>
                            {/*)}*/}

                            {/*ADDED START*/}

                            {(!isGuest && !auth.isLoggedIn()) && (
                                <div className={'authPlaceholder'} style={{width: '315.19px'}}></div>
                            )}

                            {/*ADDED END*/}

                            {(!auth.isLoggedIn() && !auth.isUpdating() && isGuest) &&
                                <div className={'notLogIn-btns'}>
                                    <button type={'button'} className={'btn__connection'} onClick={() => {
                                        dispatch(openLoginModal(true))

                                        const root =  document.getElementsByTagName('html')[0];
                                        root.setAttribute('style', 'overflow-y: hidden');
                                    }}>
                                        {strings['conectare']}
                                    </button>

                                    <button className={'btn__primary'} onClick={() => {
                                        dispatch(openSignupModal(true))

                                        const root =  document.getElementsByTagName('html')[0];
                                        root.setAttribute('style', 'overflow-y: hidden');
                                    }
                                    }>{strings['cont_nou']}</button>
                                </div>
                            }

                            <div className={'userDropDown'}>
                                <UserDropDown />
                            </div>

                            <VerificationIndicator mobile={false}/>

                        </div>

                    </div>

                    {!isSportPage ? <SearchBar/> : null}

                </div>
            </div>

            {/*THIS IS SIDEBAR START  THIS IS SIDEBAR START  THIS IS SIDEBAR START  THIS IS SIDEBAR START  THIS IS SIDEBAR START  */}

            <div style={{zIndex: showSidebar ?  1000 : 0}} className={clsx(styles.overlay, showSidebar && styles.active)}  onClick={hideMenu}>

                <div style={{transition: `${isTransition && 'all 0.3s ease'}`}} className={clsx(styles.sidebar, showSidebar && styles.active)} onClick={keepOverlay} ref={sidebar}>

                    <div className={clsx(styles.sideBar_wrapper)} >
                        <div className={'btnSidebar'}>
                            <span  className={'btnSidebar__title'}>Menu</span>
                            <button onClick={hideMenu} type={'button'}  className={clsx(styles.sidebar__close)}><CloseIcon /></button>
                        </div>

                        <Link   aria-label={"home"} className={styles.sidebar__logo} href={'/'}>
                            <Image  className={'first-logo'} src={"/default/icons/gif-logo.gif"} alt={'logo'} width={200} height={100}/>
                        </Link>

                        {(!auth.isLoggedIn() && !auth.isUpdating()) &&
                            <div className={clsx(styles.sidebar__btn, styles.sidebar__btnLog)}>
                                <button onClick={() => {
                                    dispatch(openLoginModal(true))
                                    const root =  document.getElementsByTagName('html')[0];
                                    root.setAttribute('style', 'overflow-y: hidden');
                                }
                                } className={clsx('btn__connection', 'link-connection')}>{strings['conectare']}</button>
                                <button type={'button'} className={'btn__primary'}
                                        onClick={() => {
                                            dispatch(openSignupModal(true))
                                            const root =  document.getElementsByTagName('html')[0];
                                            root.setAttribute('style', 'overflow-y: hidden');
                                        }
                                        }>{strings['cont_nou']}</button>
                            </div>
                        }

                        {auth.isLoggedIn() &&
                            <div className={styles.sidebar__btn}>
                                <div className={styles.sidebar__btnWrap}>
                                    <button className={clsx('btn__connection', 'link-connection')} onClick={() => auth.openWithdrawPopup()}>{strings['retragere']}</button>
                                    <button className={'btn__primary'} type={'button'} onClick={() => dispatch(openDeposit(true))}>{strings['depunere']}</button>
                                </div>

                                <div className={styles.sidebar__userBlock}>
                                    
                                    <div
                                        onClick={() => {
                                            SmarticoClass.changeAvatar()
                                        }}
                                        style={{cursor: 'pointer'}}
                                        className={styles.sidebar__user}
                                    >
                                        
                                        {(typeof smartico_props === "object" && Object.keys(smartico_props).length) ? (
                                            <img src={smartico_props?.avatar_id} alt={""} width={100} height={100} />
                                        ) : (
                                            <div className={styles.sidebar__userName}>
                                                {user.firstName[0]}{user.lastName[0]}
                                            </div>
                                        )}
                                        
                                    </div>
                                    
                                    <div className={styles.sidebar__userData}>
                                    
                                    <div onClick={() => {
                                            dispatch(showAccountSideBar(true)),
                                                hideMenu()
                                        }} style={{cursor: 'pointer'}}
                                              className={styles.sidebar__userFullName}>{user.firstName} {user.lastName}</div>
                                        <div className={styles.sidebar__balanceBlock}>
                                            <div  className={styles.sidebar__balance}>{strings['balanta']}</div>
                                            <div className={styles.sidebar__balanceItem}>{ auth.getUserBalance()} <span className={styles.sidebar__currence}>{auth.getCurrency()}</span></div>
                                        </div>
                                    </div>
                                    
                                    <div onClick={() => {
                                        dispatch(showAccountSideBar(true)),
                                            hideMenu()
                                    }}
                                         className={styles.sidebar__seeProfile}>
                                        <div style={{lineHeight: 1, textAlign: 'left', cursor: 'pointer'}}>Vezi</div>
                                        <div style={{cursor: 'pointer'}}>Profilul</div>
                                    </div>
                                    <button  onClick={() => {
                                        dispatch(showAccountSideBar(true)),
                                        hideMenu()
                                    }}
                                             className={styles.sidebar__userBtn}>
                                        <img src="/default/icons/menuArrow.svg" alt="img"/>
                                    </button>
                                </div>

                                <div style={{padding: '0 16px'}}><VerificationIndicator mobile/></div>

                                <div className={clsx( styles.sidebar__btnAside)}>

                                    <button className={clsx('btn__connection', 'link-connection')} onClick={() => auth.openWithdrawPopup()}>{strings['retragere']}</button>
                                    <button className={'btn__primary'} type={'button'} onClick={() => {
                                        dispatch(openDeposit(true))
                                        const root =  document.getElementsByTagName('html')[0];
                                        root.setAttribute('style', 'overflow-y: hidden');
                                    }
                                    }>{strings['depunere']}</button>
                                </div>

                            </div>
                        }
                        <div style={{paddingTop: 20, paddingBottom: 5}} className={clsx(styles.sidebar__links)}>
                            
                            <li
                                style={{
                                    paddingTop: 0,
                                    paddingBottom: 0,
                                    cursor: "pointer",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                    paddingRight: "10px"
                                }}
                                className={clsx(styles.headerContainer__list, styles.quest_item)}
                                onClick={() => {
                                    if (auth.isLoggedIn()) {
                                        SmarticoClass.openDashboard()
                                    } else {
                                        dispatch(openLoginModal(true))
                                    }
                                }}
                            >
                                <div
                                    style={{paddingLeft: 0, width: "100%"}}
                                    className={'btn__outline'}
                                >
                                    <Image
                                        src={'/default/icons/playArena.svg'}
                                        alt={''}
                                        width={24}
                                        height={24}
                                    />
                                    
                                    {strings['play_hub']}
                                </div>
                                
                                <div className={clsx(styles.sides, styles.right)}>
                                    <ArrowRight/>
                                </div>
                            </li>
                            
                            {!CMS.getCurrentQuest().hardcoded && (
                                <li
                                    style={{paddingTop: 0, paddingBottom: 0}}
                                    className={clsx(styles.headerContainer__list, styles.quest_item)}
                                >
                                    <Link   className={'btn__outline'} href={'/' + CMS.getCurrentQuest().url_id}>
                                        <div className={clsx(styles.sides, styles.left)}>
                                            <Image
                                                src={'/default/icons/questIcon.svg'}
                                                alt={''}
                                                width={24}
                                                height={24}
                                            />
                                            {CMS.getCurrentQuest().name.toUpperCase()}
                                        </div>
                                        
                                        <div className={clsx(styles.sides, styles.right)}>
                                            <ArrowRight/>
                                        </div>
                                    
                                    </Link>
                                
                                </li>
                            )}
                            
                            
                            <li
                                style={{paddingTop: 0, paddingBottom: 0}}
                                className={clsx(styles.headerContainer__list)}
                            >
                                <Link
                                    className={'btn__outline'}
                                    href={'/slots'}
                                >
                                    <Image
                                        src={'/default/icons/slotsIcon.svg'}
                                        alt={''}
                                        width={24}
                                        height={24}
                                    />
                                    
                                    {strings['sots']}
                                </Link>
                            </li>
                            
                            <li style={{paddingTop: 0, paddingBottom: 0}} className={styles.headerContainer__list}><Link
                                className={clsx('btn__outline', router.pathname === '/casino-live' && 'active')}
                                href={'/casino-live'}><Image src={'/default/icons/casinoIcon.svg'} alt={''} width={24}
                                                             height={24}/>{strings['cazino_live']}</Link></li>
                            <li style={{paddingTop: 0, paddingBottom: 0}} className={styles.headerContainer__list}><Link
                                className={'btn__outline'} href={'/sport'}><Image src={'/default/icons/icon_sport.svg'}
                                                                                  alt={''} width={24}
                                                                                  height={24}/>{strings['sports']}
                            </Link></li>
                            <li style={{paddingTop: 0, paddingBottom: 0}} className={styles.headerContainer__list}><Link
                                className={'btn__outline'} href={'/loto'}><Image src={'/default/icons/icon_loto.svg'}
                                                                                 alt={''} width={24}
                                                                                 height={24}/>{strings['loto']}</Link>
                            </li>
                            
                            {/*{smartico_visible && (*/}
                            {/*    <Button*/}
                            {/*        text={"SMARTICO"}*/}
                            {/*        onClick={() => {*/}
                            {/*            if(auth.isLoggedIn()) {*/}
                            {/*                SmarticoClass.openDashboard()*/}
                            {/*            } else {*/}
                            {/*                dispatch(openLoginModal(true))*/}
                            {/*            }*/}
                            {/*        }}*/}
                            {/*    />*/}
                            {/*)}*/}
                        </div>
                        
                        <ul className={styles.sidebar__links}>
                            
                            <li style={{marginTop: '5px'}}>
                                <button
                                    className={styles.sidebar__linkSearch}
                                    onClick={() => {
                                        dispatch(showSearchBar(true));
                                        hideMenu();
                                        // setShowSidebar(false);
                                    }}>
                                    <Image alt={'img'} src={'/default/icons/icon_search.svg'} width={24}
                                           height={24}/>{strings['search']}
                                </button>
                            
                            </li>
                            
                            {auth.isLoggedIn() &&
                                <li><Link href={'/favorites'}><Image alt={'img'} src={"/default/icons/Heart.svg"} width={20} height={20}/>{strings['favorite']}</Link></li>}
                            {auth.isLoggedIn() && <li><Link href={'/last-played'}><Image alt={'img'} src={"/default/icons/last_played.svg"} width={20} height={20}/>{strings['last-played']}</Link></li>}

                            {auth.isLoggedIn() && <li><Link href={'/account/available-offers'}><Image alt={'img'} src={"/default/icons/icon_offers.svg"} width={20} height={20}/>{strings['oferte_active']}</Link></li>}

                            <li><Link href={'/promotii'}><Image alt={'img'} src={"/default/icons/promoIcon.svg"} width={20} height={20}/>{strings['promotions']}</Link></li>
                            <li><Link href={'/live-rtp'}><Image alt={'img'} src={"/default/icons/liveRTP.svg"} width={20} height={20}/>{strings['life_rtp']}</Link></li>
                            <li><Link href={'/contact'}><Image alt={'img'} src={"/default/icons/asistenta.svg"} width={20} height={20}/>{strings['asistenta']}</Link></li>
                            <li style={{marginBottom: '5px'}} ><Link href={'/privacy-policy'}><Image alt={'img'} src={"/default/icons/information.svg"} width={20} height={20}/>{strings['informatii']}</Link></li>

                        </ul>
                        {auth.isLoggedIn() &&
                            <div className={styles.sidebar__links}>
                                <button onClick={() => {
                                    auth.signOut();
                                    hideMenu()
                                }}
                                        className={styles.dropDown__logOut}
                                        style={{paddingLeft: 10}}
                                >
                                    <Image style={{marginRight: 12}} alt={'img'} src={"/default/icons/logout.svg"} width={20} height={20}/>
                                    <span className={styles.userLogOut}>{strings['iesire_din_cont']}</span>
                                </button>
                            </div>
                        }



                        <div className={styles.sidebar__socialBlock} style={{marginTop: 50}}>
                            {generalSettings.instagram.length > 0 &&
                                <Link className={clsx(styles.sidebar__socialLink, styles.sidebar__facebook)}  target="_blank" href={generalSettings.instagram}>
                                    <InstagramIcon/>
                                </Link>
                            }

                            {generalSettings.facebook.length > 0 &&
                                <Link className={clsx(styles.sidebar__socialLink, styles.sidebar__facebook)}  target="_blank" href={generalSettings.facebook}>
                                    <FacebookIcon/>
                                </Link>
                            }
                        </div>
                    </div>
                </div>
            </div>

            {/*THIS IS SIDEBAR END  THIS IS SIDEBAR END  THIS IS SIDEBAR END  THIS IS SIDEBAR END  THIS IS SIDEBAR END  */}

            <div style={{zIndex: showAccount ?  1000 : 0}} className={clsx(styles.overlayAccount, showAccount && styles.active)} onClick={hide} >
                <div className={clsx(styles.accountSideBar, showAccount && styles.active)} onClick={keepOverlay}>
                    <button className={clsx(a.accountSideBar__close)} onClick={() => dispatch(showAccountSideBar(false))} type={'button'}>
                        <img className={a.accountSideBar__icon} src={'/default/icons/backicon.svg'} alt=""/> <span>{strings['contul_meu']}</span>
                    </button>
                    <AccountSideBar />
                </div>
            </div>

        </>
    );
}

export default DefaultHeaderView;