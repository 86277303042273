import styles from "themes/spins/styles/accountMenu.module.scss";
import {FC} from 'react';
import clsx from 'clsx';
import {showAccountSideBar, showGuestSideBar} from "store/common/actions";
import {useDispatch} from 'react-redux';
import {useRouter} from 'next/router';

interface Props {
    title: string,
    forGuest?: boolean,
    marginTitle?: boolean,
    homePage?:boolean;
    count?:number;
    h1?:boolean;
}

export const Title: FC<Props>  = ({title, forGuest, marginTitle, homePage, count, h1}) => {
    const dispatch = useDispatch();
    const router = useRouter();

    const clickHandle = () => {
        if(homePage) {
            router.push('/');
        } else if (forGuest) {
            dispatch(showGuestSideBar(true));
        } else {
            dispatch(showAccountSideBar(true));
        }
    }

    return (
        <div className={clsx(styles.profile, 'pointerEvent', forGuest && styles.profile__forGuest)} >
            <span style={{display:'flex', alignItems: 'center'}} onClick={clickHandle}>

                <button
                    type={'button'}
                    className={'btn-account'}>
                    <img src={'/default/icons/backicon.svg'} alt="img"/>
                </button>

                {h1 ? (
                    <h1 className={clsx(styles.profile__title, marginTitle && 'marginTitle')}>
                        <span>{title}</span>
                        {count !== undefined && <span className={styles.profile__titleCount}>{count}</span>}
                    </h1>
                ) : (
                    <h2 className={clsx(styles.profile__title, marginTitle && 'marginTitle')}>
                        <span>{title}</span>
                        {count !== undefined && <span className={styles.profile__titleCount}>{count}</span>}
                    </h2>
                )}

            </span>
        </div>
    )
}

export default Title























