import type { AppProps } from 'next/app';
import ZendeskControl from "components/ZendeskControl";
import Smartico from "components/smartico_init";
import LoginModal from "components/modals/login";
import DepositModal from "@/template/ui/modals/deposit";
import ForgotPasswordModal from '@/template/ui/modals/forgotPassword';
import WithdrawModal from "@/template/ui/modals/withdraw";
import RealityCheckModal from "@/template/ui/modals/realityCheck";
import SupportModal from "@/template/ui/modals/Support";
import SignUpModal from "components/modals/signUp";
import VerificationModal from "@/template/ui/modals/verification";
import SearchOverlay from "@/template/ui/search/overlay";
import UploadDocumentModal from "@/template/ui/modals/uploadDocument";
import Lists from "@/template/ui/notifications/lists";
import PopUpMini from "@/template/ui/game/popUpMini";
import PopUpFull from "@/template/ui/game/popUpFull";
import MobileBottomBar from "@/template/ui/MobileBottomBar";
import WheelModal from "@/template/ui/modals/wheel";
import ShareTicketModal from "@/template/ui/modals/shareTicket";
import SportTicketModal from "@/template/ui/modals/sportTicket";
import GeneralComponent from "@/template/ui/GeneralComponent";
import MyAppView from "@/template/pages/app";
import SmarticoMiniGameModal from "@/AbsoluteComponents/themes/spins/ui/modals/smartico_mini_game_modal";
import ResetPwdModal from "@/template/ui/modals/reset_pwd_modal";
import { useRouter } from 'next/router';
import RTPWSS from 'components/rtp/wss';
import CashPotWSS from 'components/CashPotWSS';
import store from "../store";
import {Provider} from "react-redux";
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { ThemeProvider as NextThemeProvider } from 'next-themes';
import 'swiper/css';
import "swiper/css/pagination";
import QueryModalsWatcher from "components/queryModalsWatcher";
import {useEffect, useRef, useState} from 'react';
import {isMobilePlatform, setIsSamsungBrowser} from "store/common/actions";
import RoutesGuard from "components/RoutesGuard";
import {COOKIE_AUTH_TOKEN} from "utils/cookies";
import Head from 'next/head';
import isMobile from 'is-mobile';
import React from 'react';
import "@/template/styles/globals.scss";
import MuiThemeConfig from '@/template/config/mui-theme';
import type { NextApiRequest, NextApiResponse } from 'next';
import {smartico_visible} from "@/template/config/constants";
import SetNickNameModal from "@/template/ui/modals/set_nick_name";
import CMS from "@/AbsoluteComponents/utils/cms";
import MaintenancePage from "@/template/ui/maintenance_page";




interface Props {
    test: any,
    headers: any,
    answ:any
}


function MyApp ({ Component,  pageProps, ...rest  }: AppProps & Props){
    const router = useRouter();
    const classes = [];
    const theme = MuiThemeConfig;
    const test = useRef<any>(null)
    const raw_gen_settings = CMS.getGenSettingsJSON()
    const general_settings = CMS.getAllFields(raw_gen_settings)
    const {
        maintenance_start,
        maintenance_finish,
        maintenance,
    } = general_settings
    const [show_maintenance, set_show_maintenance] = useState<undefined | boolean>(maintenance ? undefined : false)

    if(router.pathname === '/' || router.pathname === '/casino-live' || router.pathname === '/tombola') classes.push('home-page-wrap') ;
    if(router.pathname === '/' || router.pathname === '/casino-live') classes.push("no_padding_top")
    
    if (router.pathname.search('/account') > -1) {
        classes.push('profile-page-wrap')
    }
    
    if (!classes.length) {
        classes.push('default-page-wrap');
    }
    
    if (router.pathname.search('/joc/') > -1) classes.push('game-page-wrap') ;


    
    useEffect(() => {
        const current = Date.now()
        const maintenance_start_time = new Date(maintenance_start + ' UTC+3').getTime();
        const maintenance_finish_time = new Date(maintenance_finish + ' UTC+3').getTime();
        const match_dt_time =  new Date(current) > new Date(maintenance_start_time) && new Date(current) < new Date(maintenance_finish_time)

        set_show_maintenance(match_dt_time)
        

        const mobile = isMobile({tablet: true, featureDetect: true});
        store.dispatch(isMobilePlatform(mobile));
        
        const isSamsungBrowser = navigator.userAgent.match(/SamsungBrowser/i);
        
        if(isSamsungBrowser) {
            store.dispatch(setIsSamsungBrowser(true))
        }
        
    }, []);
    
    
    if ((router.pathname.search('/webview') === 0) /* || Object.keys(rest.headers).includes('token_from_app')*/) {
        if (typeof window !== 'undefined') {
            document.body.classList.add('hideZendesk');
            document.body.classList.add('hideCookiesBlock');
            document.body.classList.add('appWebview');
        }
        
        return (
            <Provider store={store}>
                {smartico_visible && <Smartico />}
                
                <GeneralComponent>
                    <MuiThemeProvider theme={theme}>
                        <NextThemeProvider defaultTheme={"dark"} disableTransitionOnChange = {true} enableSystem  storageKey='theme' themes={['light', 'dark']}>
                            <Component {...pageProps} />
                        </NextThemeProvider>
                    </MuiThemeProvider>
                </GeneralComponent>
            </Provider>
        );
    }
   


    if(show_maintenance === false) {
        return (

            <Provider store={store}>
                <Head>
                    <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" lang={"ro"}/>
                </Head>

                {smartico_visible && <Smartico/>}

                <GeneralComponent>
                    <ZendeskControl/>
                    <RoutesGuard/>
                    <Lists/>
                    <RTPWSS/>

                    <MuiThemeProvider theme={theme}>

                        <NextThemeProvider defaultTheme={"dark"} disableTransitionOnChange={true} enableSystem
                                           storageKey='theme' themes={['light', 'dark']}>

                            <MyAppView cls={classes.join(' ')}>
                                <Component {...pageProps} />
                            </MyAppView>

                            <LoginModal/>
                            <SupportModal/>
                            <UploadDocumentModal/>
                            <SearchOverlay/>
                            <DepositModal/>
                            <ForgotPasswordModal/>
                            <WithdrawModal/>
                            <RealityCheckModal/>
                            <PopUpMini/>
                            <PopUpFull/>
                            <SignUpModal/>
                            <VerificationModal/>
                            <CashPotWSS/>
                            <QueryModalsWatcher/>
                            <WheelModal/>
                            <MobileBottomBar/>
                            <ShareTicketModal/>
                            <SportTicketModal/>
                            <SmarticoMiniGameModal/>
                            <ResetPwdModal/>
                            <SetNickNameModal/>


                        </NextThemeProvider>
                    </MuiThemeProvider>

                </GeneralComponent>

            </Provider>
        )
    } else if(show_maintenance) {
        return <MaintenancePage/>
    } else if(show_maintenance === undefined) return <></>
}

MyApp.getInitialProps = async (context:any, req?:NextApiRequest, res?:NextApiResponse) => {
    let req_cookies:any = 'unknown';
    let tExist:any = 'unknown';
    let answ:any = 0
    
    if (context.ctx.req && context.ctx.req.cookies) {
        // if (context.ctx.req && context.ctx.res.writeHead) {
        req_cookies = context.ctx.req.cookies;
        tExist = context.ctx.req.cookies && Boolean(context.ctx.req.cookies[COOKIE_AUTH_TOKEN]);
        
        const tokenExist:boolean = context.ctx.req.cookies && Boolean(context.ctx.req.cookies[COOKIE_AUTH_TOKEN]);
        // const isRealGame:boolean = context.ctx.asPath.search('/joc') > -1 && context.ctx.asPath.search('real') > -1;
        // const isRealGame:boolean = context.router.asPath.search('/joc') > -1 && context.router.asPath.search('/real') > -1;
        const isRealGame:boolean = '/joc/[provider_slug]/[game_slug]/real' === context.ctx.pathname;
        
        if (tokenExist && (context.ctx.asPath === '/account' || context.ctx.asPath === '/account/') ) {
            context.ctx.res.writeHead(301, { Location: '/account/profile' });
            context.ctx.res.end();
        }
        
        if (!tokenExist && (isRealGame || context.ctx.asPath.search('account') > -1 || context.ctx.asPath === '/free-spins' || context.ctx.asPath === '/free-bonus' || context.ctx.asPath === '/favorites' || context.ctx.asPath === '/last-played')) {
            context.ctx.res.writeHead(301, { Location: '/?redirect='+context.ctx.asPath });
            context.ctx.res.end();
        }
        
        if (tokenExist && context.ctx.asPath.search('/forgot-password') > -1) {
            context.ctx.res.writeHead(301, { Location: '/?redirect='+context.ctx.asPath });
            context.ctx.res.end();
        }
        
        if(context.ctx.req.headers.hasOwnProperty("token_from_app")) {
            // answ = context.ctx.req.headers["token_from_app"]
            // context.ctx.res.setHeader('Set-Cookie', `user_temp_token=${context.ctx.req.headers["token_from_app"]}; Path=/;`);
        }
    }
    
    
    return {
        headers: context.ctx.req ? context.ctx.req.headers : {unknown: '-'},
        answ:answ,
        test: {
            // pathname: context.ctx.pathname,
            // cookies: req_cookies,
            // tokenExist: tExist,
            // keys: context?.ctx?.req ? Object.keys(context.ctx.req) : 'unknown',
            // themeCookie: context.ctx.req?.cookies['theme'] || 'light'
        }
    }
};


export default MyApp;
