import React, {FC, useState} from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import styles from 'themes/default/styles/themesSlider.module.scss';
import clsx from 'clsx';
import "swiper/css";
import "swiper/css/pagination";
import { Navigation } from "swiper";
import { Pagination } from "swiper";
import PaginationArrow from "ui/paginationArrow";
import Link from 'next/link';
import CMS from "utils/cms";
import strings from "utils/strings";
import NewArrow from "themes/default/svg/newArrow";
import Slide from "./Slide";
import {lazy_loading_offset} from "@/AbsoluteComponents/utils/constants";
import LazyComponentWrap from "components/lazy_component_wrap";


interface Props {
    json: object;
    api_id: string;
    index?:any
}

const ThemesSlider:FC<Props> = (props) => {
    
    const {index} = props
    
    if (index >= lazy_loading_offset) {
        return (
            <LazyComponentWrap>
                <Core { ... props}/>
            </LazyComponentWrap>
        )
    } else {
        return <Core { ... props}/>
    }
}

export default ThemesSlider;

const Core: FC<Props> = ({json, api_id}) => {
    const [swiper, setSwiper] = useState<any>();

    const data = CMS.jsonThemesSlider(json);


    return (
        <div className={clsx(styles.themes, 'theme-slider', 'slideClass')}>
            <div className={styles.themes__box}>
                <div className={styles.themes__wrapper}>
                    <span className={'gamesSlider__title'}>{data.title}</span>
                </div>
                {data.api_id.length > 0 && <Link href={'/'+data.api_id}><button className={'linkMore-btn'}>{strings['vezi_toate']}<NewArrow /></button></Link>}

            </div>

            <Link className={clsx( 'btn-responsive', 'btnResponsive-themesGame')} style={{top: 0}} href={`/${data.api_id}`}>{strings['vezi_mai_multe']}<NewArrow /></Link>

            <Swiper
                pagination={{
                    clickable: true,
                }}
                modules={[Pagination, Navigation]}
                className={clsx(styles.themes__mySwiper, 'swiper-wrapper', 'swiper-init-padding')}
                slidesPerView='auto'
                onSwiper={(sw:any) => {
                    setSwiper(sw)
                }}

            >
                <PaginationArrow onClick={() => swiper.slideNext()}/>
                <PaginationArrow onClick={() => swiper.slidePrev()} rightArrow />

                {data.themes.map((item:any, i:number) => {
                    if(item.image.length > 0) {
                        return (
                            <SwiperSlide className={clsx(styles.theme__Slide, 'slide-themesSlider')} key={`theme-${i}`+ 1}>

                                <Slide item={item} apiId={api_id} />

                            </SwiperSlide>
                        )
                    }
                })}
            </Swiper>
        </div>
    )
}

