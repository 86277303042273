import styles from "@/template/styles/OldPromotions.module.scss";
import CMS from "utils/cms";
import Button from "@/template/small_ui/button";
import clsx from 'clsx';
import React from 'react'
import { useRouter } from 'next/router';

interface Props {
    prom: any
}


const DefaultPromoCardView:React.FC<Props> = ({prom}) => {

    const router = useRouter();

    return (
        <div className={clsx(styles.oldPromotion, styles.promotionItem)}>

            <div className={styles.imageContainer}>
                <img src={CMS.getPublicFilePath(prom.mobile_image[0].name, 'promotions', prom.mobile_image[0].path)}/>
            </div>

            <div className={styles.bottomContainer}>

                <h3>
                    {prom.title}
                </h3>

                <p>
                    {prom.excerpt}
                </p>

                <Button onClick={() => {
                        router.push(prom.url_extern.length ? prom.url_extern : '/promotii/'+prom.api_id)
                    }} className={styles.button} text={'Toate detaliile'}
                />

            </div>
        </div>
    )
}

export default DefaultPromoCardView