import strings from "./strings";

export const profileSubmenu = [
    { route: '/account/account-validation', icon: '/default/icons/myProfile.svg', text: strings['verificarea_contului']},
    { route: '/account/my-documents', icon: '/default/icons/myDocuments.svg', text: strings['documentele_mele'] },
    { route: '/account/profile', icon: '/default/icons/personalinfo.svg', text: strings['informatii_personale'], },
];

export const limitsSubMenu = [
    { route: '/account/responsable-game', icon: '/default/icons/responsableGame.svg', text: strings['joc_responsabil']},
    { route: '/account/password-change',  icon: '/default/icons/passwordChange.svg', text: strings['schimbare_parola'] },
    { route: '/account/profile-close',  icon: '/default/icons/user.svg', text: strings['cont']},
];

export const transactionSubmenu = [
    {route: '/account/activity', icon: '/default/icons/activity.svg', text: strings['activitate'] },
    {route: '/account/transactions/game', icon: '/default/icons/transactGame.svg', text: strings['tranzactii_joc']},
    {route: '/account/transactions/betting', icon: '/default/icons/betting.svg', text: strings['tranzactii_pariuri']},
    {route: '/account/transactions/deposit', icon: '/default/icons/deposit.svg', text: strings['depuneri']},
    {route: '/account/transactions/withdrawal', icon: '/default/icons/retragere.svg', text: strings['retragere']},
    {route: '/account/transactions/promotions', icon: '/default/icons/promotion.svg', text: strings['promotions']},
]

export const supportSubmenu = [
    { route: '/terms-and-conditions', icon: '/default/icons/conditions.svg', text: strings['termeni_și_condiții']},
    { route: '/privacy-policy', icon: '/default/icons/privacy.svg', text: strings['politica_de_confidențialitate']},
    { route: '/account/support', icon: '/default/icons/support.svg', text: strings['asistenta']},
]

export const passwordRules = [
    {
        'match': function (text:string):boolean {
            return text.match(/^.{6,}$/) !== null;
        },
        'description': strings['cel_putin_6_caractere']

    },

    {
        'match': function(text:string):boolean {
            return text.match(/[a-z]/) !== null;
        },

        'description': strings['cel_putin_o_litera_mica']
    },

    {
        'match': function(text:string):boolean {
            return text.match(/[A-Z]/) !== null;
        },

        'description': strings['cel_putin_o_litera_mare']
    },

    {
        'match': function(text:string):boolean {
            return text.match(/[?=.*\d]/) !== null;
        },

        'description': strings['un_caracter_sau_un_numar']
    },
];



export const paginationLimit = 10;

export const getSlug = (text:string):string => {
    return text.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '');
}

export const validationTypes:Array<any> = [
    {key: 'identification_card', label: 'Card de identificare'},
    {key: 'passport', label: 'Pașaport'},
    {key: 'driving_license', label: 'Permis de conducere'},
];

export const validateEmail = (email:string):boolean => {
    return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email);
}

export const theme:string = process.env.NEXT_PUBLIC_THEME || '';

export const lazy_loading_offset = 8