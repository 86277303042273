import Image from 'next/image';
import clsx from 'clsx';
import styles from '../../../styles/VIPQuest.module.scss';
import Modal from 'react-modal';

interface Props {
    how_to?: string
    open: boolean
    close():void
    
}

const HowToPlay:React.FC<Props> = ({how_to,open, close}) => {

    
    return (
        <Modal
            onAfterOpen={() => {
            }}
            onAfterClose={() => {
            }}
            ariaHideApp={false}
            className={clsx(styles.modalQuest)}
            isOpen={open}
            contentLabel="Example Modal"
        >
            <div className={styles.modalQuest__header}>
                {/*<div className={styles.mobileDisapear} style={{width: '40px'}}></div>*/}
                <h4 className={clsx(styles.heading, "ReactModal__Title")}>Cum particip?</h4>
                <button onClick={close} className={styles.modalQuest__closeBtn} >
                    <Image src={'/default/icons/close.svg'} width={15} height={15} alt={'close'}/>
                </button>
            </div>
            
            {how_to?.length ? (
                <div className={styles.modalQuest__content} dangerouslySetInnerHTML={{__html:how_to}} />
                
            ) : null}
        
        </Modal>
    )
}

export default HowToPlay