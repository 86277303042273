import React  from 'react'
import {WheelComponent, CenteredImages} from "./StyledWheel";
import clsx from 'clsx';


interface Props {
    startSpinDeg: number,
    rotateTo: number,
    isSpinning: boolean,
    isStartSpinning: boolean,
    isEndSpinning: boolean
    mainImage: any
    lineImage: any
    initialPosition: number | null
    rotateBefore: number | null
    imagesLoaded: boolean
    audioLoaded: boolean
    dataLoaded: boolean
}

const WheelSpinningElements:React.FC<Props> = ({
   startSpinDeg,
   rotateTo,
   isSpinning,
   isEndSpinning,
   isStartSpinning,
   mainImage,
   lineImage,
   initialPosition,
   rotateBefore,

}) => {

    return (
        <WheelComponent
            id='wheel'
            startSpinDeg={startSpinDeg}
            initialPosition={ typeof initialPosition === 'number' ? initialPosition : 0}
            rotateBefore={typeof rotateBefore === 'number' ? rotateBefore : 0}
            rotateTo={rotateTo}
            className={clsx(
                isSpinning && "spinning",
                isStartSpinning && "startSpinning",
                isEndSpinning && "endSpinning"
            )}
        >
            <CenteredImages
                width={'390px'}
                height={'390px'}
                src={mainImage.url}
                alt="wheel"
            />


            <CenteredImages
                src={lineImage.url}
                alt="wheel"
            />

        </WheelComponent>
    )
}

export default WheelSpinningElements