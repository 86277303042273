import React, {useState, useEffect, useRef} from 'react'
import styles from '@/template/styles/JackpotLive.module.scss';
import clsx from 'clsx'
// @ts-ignore
import numberFormatter from 'number-formatter';
import JackpotCounterDunamic from "./jackpot_counter_dynamic";
import {useRouter} from 'next/router';
import Image from "next/image";
import {useSelector} from "react-redux";
import PlayLight from 'themes/default/svg/playLight';



interface Props {
    jackpot: any
    api_id: string
}

const JackpotCard: React.FC<Props> = ({jackpot, api_id}) => {
    
    const dynamicClassName = styles[jackpot.bgImage];
    const contentClassName = styles['content_'+jackpot.bgImage];
    const router = useRouter()
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [jpIterations, setJPIterations] = useState<number>(0)
    const isMobile:boolean = useSelector((state:any) => state.common.isMobile);
    
    const navigateToJPPage = () => {router.push('/'+api_id)}
    const topValsStyle = {maxHeight: "26px", width: `fit-content`}
    const midValsStyle = {maxHeight: "24px", width: `fit-content`}
    
    
    return (
        <div onClick={isMobile ? navigateToJPPage : () => {}}  className={clsx(styles.JackpotCard, dynamicClassName, 'jackpot-card', styles.needFix, jackpot.bgImage)}>
            
            <Image
                src={`/default/images/JackpotsLive/${jackpot.bgImage}.webp`}
                alt={jackpot.jackpot_name}
                width={590}
                height={710}
                className={styles.JackpotCard__bg_img}
            />
            
            <div className={clsx(styles.JackpotCard__content, contentClassName)}>
                
                <ul style={{position: 'absolute', left: -30, listStyle: 'none'}}>
                    
                    {/*{jackpot.levels.map((x:any, i:number) => {*/}
                    {/*    return (*/}
                    {/*        <li key={'item'+i}>*/}
                    {/*            {x.value.toFixed(2)}*/}
                    {/*        </li>*/}
                    {/*    )*/}
                    {/*})}*/}
                
                </ul>
                
                {jackpot.jackpot_name === 'Sky Jackpot' && (
                    <div className={styles.skyValue}>
                        
                        <JackpotCounterDunamic
                            id={"counter_top"+jackpot.bgImage}
                            textWidth={17}
                            textHeight={26}
                            style={topValsStyle}
                            className={styles.valueContainer}
                            jackpot={jackpot}
                            value={jackpot.levels[0].value && jackpot.levels[0].value}
                            level={jackpot.levels[0]}
                        />
                        
                        <span className={styles.ron}>
                            ron
                        </span>
                    </div>
                )}
                
                {/*TOP START TOP START TOP START TOP START TOP START TOP START TOP START TOP START */}
                
                <div
                    className={
                        clsx(
                            styles.topValues,
                            styles.values,
                            styles.columnFlex
                        )
                    }
                >
                    
                    <JackpotCounterDunamic
                        id={"counter_top"+jackpot.bgImage}
                        textWidth={17}
                        textHeight={26}
                        style={topValsStyle}
                        className={styles.valueContainer}
                        jackpot={jackpot}
                        level={jackpot.levels[0]}
                        value={jackpot.levels[0].value && jackpot.levels[0].value}
                    />
                    
                    <span className={clsx(styles.ron, styles.rightSide)}>
                        ron
                    </span>
                
                </div>
                
                {/*TOP END TOP END TOP END TOP END TOP END TOP END TOP END TOP END */}
                
                {/*MID START MID START MID START MID START MID START MID START MID START MID START */}
                <div className={
                    clsx(
                        styles.midValues,
                        styles.values,
                        styles.columnFlex
                    )}
                >
                    
                    <JackpotCounterDunamic
                        id={"counter_top"+jackpot.bgImage}
                        textWidth={16}
                        textHeight={26}
                        className={styles.midValue}
                        jackpot={jackpot}
                        style={midValsStyle}
                        level={jackpot.levels[1]}
                        value={jackpot.levels[1]?.value && jackpot.levels[1].value}
                    />
                    
                    {/*<div style={{width: `${maxWidth + 5}px`}} className={styles.midValue}>*/}
                    
                    {/*    {numberFormatter("# ##0.00", (changinMidValue / 100).toFixed(2))}*/}
                    {/*</div>*/}
                    
                    <span className={styles.ron}>
                        ron
                    </span>
                
                </div>
                
                {/*MID END MID END MID END MID END MID END MID END MID END MID END */}
                
                {/*BOT START BOT START BOT START BOT START BOT START BOT START */}
                <div
                    className={
                        clsx(
                            styles.botValues,
                            styles.values,
                            styles.botColumnFlex
                        )
                    }
                >
                    
                    <div className={clsx(styles.leftSide, styles.sides)}>
            
                        <span className={clsx(styles.value)}>
                            {/*{ minorValue && Number(minorValue).toFixed(2)}*/}
                            
                            <JackpotCounterDunamic
                                id={"counter_top"+jackpot.bgImage}
                                textWidth={8}
                                textHeight={10}
                                className={styles.botValue}
                                jackpot={jackpot}
                                level={jackpot.levels[2]}
                                value={jackpot.levels[2].value && jackpot.levels[2].value}
                            />
            
                        </span>
                        
                        <span className={styles.ron}>
                            ron
                        </span>
                    
                    </div>
                    
                    <div className={clsx(styles.rightSide, styles.sides)}>
            
                        <span className={clsx(styles.value)}>
                            
                            <JackpotCounterDunamic
                                id={"counter_top"+jackpot.bgImage}
                                textWidth={8}
                                textHeight={10}
                                className={styles.botValue}
                                jackpot={jackpot}
                                level={jackpot.levels[3]}
                                value={jackpot.levels[3]?.value && jackpot.levels[3].value}
                            />
            
                        </span>
                        
                        <span className={styles.ron}>
                            ron
                        </span>
                
                    </div>
                
                </div>
                
                {/*BOT END BOT END BOT END BOT END BOT END BOT END */}
                
                <button onClick={navigateToJPPage} className={styles.JackpotCard__playBtn}>
                    <div className={styles.button_inner}>
                        <PlayLight />
                    </div>
                </button>
            
            </div>
        </div>
    )
}

export default JackpotCard