import styles from 'themes/default/styles/userDropDown.module.scss';
import strings from "utils/strings";
import Link from 'next/link';
import clsx from 'clsx';
import {ClickAwayListener} from '@mui/base';
import Vector from 'themes/default/svg/vector';
import ArrowRight from "@/template/svg/arrow-right";
import VerificationIndicator from "components/VerificationIndicator";
import React from "react";
import VerificationStatus from "interfaces/VerificationStatus";
import {useSelector} from "react-redux";
import useAuth from "hooks/auth";
import {useState, useEffect} from 'react'
import {useRouter} from 'next/router';
import LogoutIcon from "themes/spins/svg/logouticon";

const UserDropDown = () => {

    const auth = useAuth();
    const user = auth.getUser();
    const smartico_props = useSelector((state:any) => state.account.smarticoProps);
    const router = useRouter();
    const currentRoute = router.pathname;
    const [open, setOpen] = useState(false);

    const toOpen = () => {
        setOpen(!open)
    }

    useEffect(() => {
        setOpen(false);
    }, [currentRoute]);

    return (
        <ClickAwayListener onClickAway={() => setOpen(false)}>
            <div>
                {user !== undefined &&
                    <div  className={clsx(styles.wrapper, open && styles.active)}>
                        <button onClick={toOpen} className={clsx(styles.auth)}>
                            
                            {(typeof smartico_props === "object" && Object.keys(smartico_props).length) ? (
                                <img src={smartico_props?.avatar_id} alt={""} width={100} height={100}/>
                            ) : (
                                <div className={clsx(styles.auth__name)}>
                                    {user.firstName[0]}{user.lastName[0]}
                                </div>
                            )}
                            
                            {/*<div className={clsx(styles.auth__name)}>*/}
                            {/*    {user.firstName[0]}{user.lastName[0]}*/}
                            {/*</div>*/}
                        
                        
                        </button>
                        <span className={styles.vector}>
                       <Vector/>
                       </span>
                        <div className={clsx(styles.dropDown)}>

                            <ul className={clsx(styles.dropDown__links, )} >
                                <li><Link   href={'/account/profile'}>{strings['personal_details']}</Link></li>
                                <li><Link   href={'/account/account-validation'}>{strings['account_verification']}</Link></li>
                                <li><Link   href={'/account/my-documents'}>{strings['documentele_mele'] }</Link></li>
                                <li><Link   href={'/account/password-change'}>{strings['schimbare_parola']}</Link></li>
                                <li><Link   href={'/account/activity'}>Istoric activitate</Link></li>
                                <li><Link   href={'/account/responsable-game'}>Setări limite</Link></li>

                            </ul>
                            <button onClick={() => auth.signOut()} className={styles.dropDown__logOut}>
                                {/*<OffIcon />*/}
                                <span className={styles.userLogOut}>{strings['iesire_din_cont']}</span>
                            </button>
                        </div>

                    </div>
                }
            </div>
        </ClickAwayListener>
    )
}

export default UserDropDown;
