import style from 'themes/default/styles/wheel.module.scss';
import React, { useEffect, useState} from 'react';
import Fireworks from "./fireworks";
import Button from "themes/default/small_ui/button";

interface Props {
    prise: any
    closePopup():void
    setPlay(type: string):void
    clickEnd(type: boolean): void
}

const PriseContainer: React.FC<Props> = ({prise, closePopup, setPlay, clickEnd}) => {

    const [toggleAnim, setToggleAnim] = useState<boolean>(false)

    useEffect(() => {
        setPlay('tada')

        setTimeout(() => {
            setPlay('')
        }, 5000)
    }, [])

    useEffect(() => {
        setTimeout(() => {
            setToggleAnim(!toggleAnim)
        }, 1000)
    }, [toggleAnim])

    return (
        <div className={style.overlayContainer}>
            <Fireworks/>

            <div className={style.main}>

                <img hidden={toggleAnim} className={style.mainImg} src={'/default/images/Wheel/prizeAnimBg2.jpg'}  alt={'bg'}/>

                <img hidden={!toggleAnim} className={style.mainImg} src={'/default/images/Wheel/prizeAnimBg1.jpg'}  alt={'bg'}/>

                <img className={style.gift} src={'/default/images/Wheel/prizeBox.webp'}  alt={'gift'}/>

                <img className={style.platform} src={'/default/images/Wheel/prizePlatform.webp'}  alt={'platform'}/>

                <div className={style.prize} >
                    {prise.name === 'Default' ? (
                        <h3>default</h3>
                    ) : (
                        <img src={prise.image} alt={prise.name}/>

                    )}
                    <Button onClick={() => {
                        closePopup();
                        clickEnd(true);
                    }} text={'închide'}/>
                </div>
            </div>

        </div>
    )
}

export default PriseContainer