import React, {useEffect, useState} from "react";
import styles from "themes/default/styles/VIPQuest.module.scss"
import {useDispatch, useSelector} from "react-redux";
import CMS from "@/AbsoluteComponents/utils/cms";
import Image from 'next/image';
import useAuth from "@/AbsoluteComponents/hooks/auth";
import Button from "@/template/small_ui/button";
import {openDeposit, openLoginModal} from "@/AbsoluteComponents/store/modals/actions";
import strings from "utils/strings";
import StandartCountdown from "themes/default/small_ui/standart_countdown";
import useGame from "@/AbsoluteComponents/hooks/game";
import useCommon from "@/AbsoluteComponents/hooks/common";
import HowToPlay from "themes/default/cms-views/vipQuest/elems/how_to_play_modal";
import QuestIsEndedModal from "themes/default/cms-views/vipQuest/elems/quest_ended_modal";
import Tooltip from '@mui/material/Tooltip';
import QuestLeaderboardInterface from "@/AbsoluteComponents/interfaces/quest_leaderboard_interface";


interface Props {
    data:any
    api_id:string
}

const Hero:React.FC<Props> = ({data, api_id}) => {
    
    const {
        end_time_stamp,
        main_banner,
        mobile_main_banner,
        title,
        time_limit_text,
        lvs,
        user_pin,
        leader_pin,
        how_to_participate,
        quest_logo,
        prizes
    } = data
    
    const {name, quest_api_id, url_id, hardcoded} = CMS.getCurrentQuest()
    
    // STATES
    const getState = (st:string) => {
        return useSelector((state:any) => state.account[st])
    }
    const auth = useAuth()
    const game = useGame()
    
    const common_hook = useCommon()
    const leaderboard = getState("questLeaderboard")
    const status = auth.getQuestStatus()

    const [loaded, setLoaded] = useState<boolean>(false)
    const [openModal, setOpenModal] = useState<boolean>(false)
    const [openEndedModal, setOpenEndedModal] = useState<boolean>(false)
    const gameHook = useGame()
    
    const dispatch = useDispatch()
    const getLvPercentage = (val:number) => {
        return common_hook.getPercentage(0, 30, val)
    }
    const userToNextLvData = ():any => {
        
        let points = 0
        let qt_points = 0
        
        if(!Object.keys(status).length || !Object.keys(leaderboard.leaderBoard).length) {
            return {
                points: points,
                qt_points: qt_points
            }
        }
        
        let current_level = leaderboard.leaderBoard[Number(status.level)]
        let next_level = leaderboard.leaderBoard[Number(status.level) + 1]
        let points_to = next_level.pointsNeeded
        let points_from = current_level.pointsNeeded
        points = status.points - points_from
        qt_points = points_to - points_from
        
        return {
            points: points,
            qt_points: qt_points
        }
    }
    
    
    //REQUESTS
    const optIn = async () => {
        auth.updateQuestStatus("POST")
    }
    
    useEffect(() => {
        setLoaded(true)
        auth.updateQuestStatus("GET")
        gameHook.updateQuestLaderboard()
    }, [auth.isLoggedIn()]);
    
    useEffect(() => {
        if(leaderboard.state.hasOwnProperty("status") && leaderboard.state.status === "Ended") {
            setOpenEndedModal(true)
        }
    }, [leaderboard]);
    

    return (
        <div className={styles.hero}>
            <Image
                src={CMS.getPublicFilePath(main_banner[0].name, api_id, main_banner[0].path)}
                width={1500}
                height={600}
                alt={main_banner[0].name}
                className={styles.bg_image}
            />
            
            <Image
                src={CMS.getPublicFilePath(mobile_main_banner[0].name, api_id, mobile_main_banner[0].path)}
                width={800}
                height={600}
                alt={mobile_main_banner[0].name}
                className={styles.bg_image_mobile}
            />
            
            <div className={styles.surface}>
                
                <div className={styles.content}>
                    
                    {title ? (
                        <h2>
                            {title}
                        </h2>
                    ) : null}
                    
                    <div className={styles.same_width_blocks}>
                        {loaded && (
                            <div className={styles.time_and_stats}>
                                
                                <Image
                                    src={CMS.getPublicFilePath(quest_logo[0].name, api_id, quest_logo[0].path)}
                                    width={400}
                                    height={134}
                                    // layout={"responsive"}
                                    alt={quest_logo[0].name}
                                    className={styles.quest_logo}
                                />
                                
                                <span className={styles.time_limit_text}>
                                    {time_limit_text}
                                </span>
                                
                                <StandartCountdown className={styles.countdown} endTime={common_hook.endTimeFactory(end_time_stamp)}/>
                                
                                {(Object.keys(status).length && status.optIn) ? (
                                    <div className={styles.lv_stats}>
                                        
                                        <div className={styles.info}>
                                            
                                            
                                            <div className={styles.left}>
                                                
                                                <div>
                                                    CP
                                                    <span className={styles.yellow}> {Number(status.points)}</span>
                                                    {status?.level !== lvs.length ? (
                                                        <span className={styles.gray}>
                                                            /{leaderboard?.leaderBoard[status?.level + 1]?.pointsNeeded} până la Nivelul {status.level + 1}
                                                        </span>
                                                    ) : null}
                                                
                                                </div>
                                                
                                                <div>
                                                    <span className={styles.yellow}>
                                                        {status.level}
                                                    </span>
                                                    <span >
                                                        /{lvs.length}
                                                    </span>
                                                </div>
                                            
                                            </div>
                                        
                                        
                                        </div>
                                        
                                        <div className={styles.lv_bar}>
                                            <div
                                                style={{width: `${getLvPercentage(status.level)}%`}}
                                                className={styles.points_amn}
                                            >
                                                <Tooltip
                                                    title={"Nivelul tău"}
                                                    placement={"bottom"}
                                                >
                                                    <Image
                                                        src={CMS.getPublicFilePath(user_pin[0].name, api_id, user_pin[0].path)}
                                                        width={100}
                                                        height={100}
                                                        alt={user_pin[0].name}
                                                        className={styles.pin_image}
                                                    />
                                                </Tooltip>
                                            </div>
                                            
                                            {/*{(Number(status.level) === Number(leaderboard.leader.level) && Number(status.points) < Number(leaderboard.leader.points)) && (*/}
                                                <Tooltip
                                                    title={"Nivelul Leader-ului"}
                                                    placement={'bottom'}
                                                >
                                                    <Image
                                                        src={CMS.getPublicFilePath(leader_pin[0].name, api_id, leader_pin[0].path)}
                                                        width={100}
                                                        height={100}
                                                        alt={leader_pin[0].name}
                                                        className={styles.leader_pin_image}
                                                        style={{left: `${getLvPercentage(leaderboard.leader.level)}%`}}
                                                    />
                                                </Tooltip>
                                            {/*)}*/}
                                        
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                        )}
                        
                        <div className={styles.controllers}>
                            <Button
                                disabled={hardcoded}
                                className={styles.opt_in_btn}
                                text={(auth.isLoggedIn() && Object.keys(status)) ? (status?.optIn ? strings["deposit"] : strings["want_to_play"]) : strings["conectare"]}
                                onClick={() => {
                                    if (!auth.isLoggedIn()) {
                                        dispatch(openLoginModal(true))
                                    } else if (auth.isLoggedIn() && !status?.optIn) {
                                        optIn()
                                    } else if (auth.isLoggedIn() && status?.optIn) {
                                        dispatch(openDeposit(true))
                                    }
                                }}
                            />
                            
                            <Button
                                className={styles.opt_in_btn}
                                text={strings["particip"]}
                                onClick={() => {
                                    setOpenModal(true)
                                }}
                                outlined={true}
                            />
                        
                        </div>
                    </div>
                </div>
            </div>
            
            <HowToPlay
                open={openModal}
                close={() => {
                    setOpenModal(false)
                }}
                how_to={how_to_participate}
            />
            
            <QuestIsEndedModal
                open={openEndedModal}
                close={() => {setOpenEndedModal(false)}}
                leader={leaderboard.leader.nickname && leaderboard.leader.nickname}
                winners={(Array.isArray(prizes) && prizes.length) ? leaderboard.maxLevelTop : []}
            />
        
        </div>
    )
}


export default Hero