import React, {useState} from "react";
import styles from "themes/default/styles/VIPQuest.module.scss"
import ArrowRight from "@/template/svg/arrow-right";
import clsx from "clsx";


interface Props {
    data:any,
    api_id: string
}

const Terms:React.FC<Props> = ({data, api_id}) => {
    
    const [openTerms, setOpenTerms] = useState<boolean>(false)
    
    const {
        terms_and_condiitons_title,
        terms_list,
        terms_and_condiitons_subtitle 
    } = data
    
    const ordered_list = terms_list.sort((a:any, b:any) => Number(a.order) - Number(b.order))

    return (
        <div className={styles.terms}>
            <div
                onClick={() => {setOpenTerms((x:boolean) => !x)}}
                className={clsx(
                    styles.open_terms,
                    openTerms && styles.open
                )}
            >
                <span>
                    {terms_and_condiitons_title}
                </span>
                
                <ArrowRight />
            </div>
            
            {openTerms && (
                <div className={clsx(styles.terms_list)}>
                    {ordered_list?.length && terms_list.map((term: any, i: number) => {
                        return (
                            <div key={"terms_list item: " + i} className={styles.term_item}>
                                
                                <div className={styles.order}>
                                    {term.order}
                                </div>
                                
                                <div className={styles.content} dangerouslySetInnerHTML={{__html: term.content}}/>
                            
                            </div>
                        )
                    })}
                </div>
            )}
            
        </div>
    
    )
}

export default Terms