import {FC, useState} from 'react';
import FavoriteFalseIcon from 'themes/default/svg/favoriteFalse';
import FavoriteTrueIcon from 'themes/default/svg/favoriteTrue';
import styles from 'themes/default/styles/card.module.scss';
import GameInterface from "interfaces/game";
import {useSelector, useDispatch} from 'react-redux';
import {updateFavoritesGames} from "store/account/actions";
import {updateSnackBar} from "store/common/actions";
import {updateFavoriteGame} from "services/account";
import strings from "utils/strings";
import clsx from 'clsx';

interface Props {
    game:GameInterface,
    styledIcon?: boolean,
    overwriteClass?: string,
}

const FavoriteButton:FC<Props> = ({game, styledIcon, overwriteClass }) => {
    const favoritesGames:Array<GameInterface> = JSON.parse(JSON.stringify(useSelector((state:any) => state.account.favoriteGames)));
    const isFavorite:boolean = favoritesGames.findIndex((g) => g.gameId === game.gameId) > -1;

    const dispatch = useDispatch();

    const [loading, isLoading] = useState(false);

    const handleClick = async () => {
        isLoading(true);
        const data:any = await updateFavoriteGame(game.gameId, !isFavorite);

        if (data.error) {
            dispatch(updateSnackBar(data.message, 'error', false));
        } else if (data.response?.success) {
            if (isFavorite) {
                dispatch(updateFavoritesGames(favoritesGames.filter((g) => g.gameId !== game.gameId)));
                dispatch(updateSnackBar(strings['favorite_game_removed'], 'error', false));
            } else {
                favoritesGames.push(game);
                dispatch(updateFavoritesGames(favoritesGames));
                dispatch(updateSnackBar(strings['favorite_game_added'], 'success', false));
            }
        } else {
            dispatch(updateSnackBar(strings['unknown_error'], 'error', false));
        }

        isLoading(false);
    };

    let classes = clsx(styles.card__favorite, styledIcon && 'popupIcon' ,  loading && styles.card__favorite__loading);

    if (overwriteClass) classes = overwriteClass;

    return <button disabled={loading} className={classes} onClick={handleClick} type={'button'}>{isFavorite ? <FavoriteTrueIcon/> : <FavoriteFalseIcon/>}</button>
}

export default FavoriteButton