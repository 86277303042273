import strings from "utils/strings";
import TextField from "ui/textfield";
import Modal from 'react-modal';
import {useDispatch, useSelector} from 'react-redux';
import {openDeposit, openDepositAfterLogin, updateCampaignId} from 'store/modals/actions';
import {updateSnackBar} from 'store/common/actions';
import Image from 'next/image';
import Link from 'next/link';
import {useRouter} from 'next/router';
import useAuth from 'hooks/auth';
import {useEffect, useState, useRef} from 'react';
import clsx from 'clsx';
import {getDocumentValidation, getPaymentInfo, getPaymentMethods, getProfile} from "services/account";
import Selector from 'ui/selectDropDown';
import PaymentMethod from "interfaces/paymentMethod";
import Offer from "interfaces/offer";
import Payment from "utils/payment";
import ArrowRight from "themes/default/svg/arrow-right";
import CircularProgress from '@mui/material/CircularProgress';
import DepositIframe from "./iframe";
import InfoIcon from 'themes/default/svg/info'
import Button from "@/template/small_ui/button";
import CMS from "utils/cms";
import {getAuthToken} from "utils/cookies";
import {updateSpinStatus} from "store/account/actions";


function DepositModal() {
    const dispatch = useDispatch();
    const auth = useAuth();
    const router = useRouter();
    const open = useSelector((state:any) => state.modals.openDeposit);
    const campaignId = useSelector((state:any) => state.modals.campaignId);
    const isMobile:boolean = useSelector((state:any) => state.common.isMobile);
    const [cash, setCash] = useState(0);

    const [applying, setApplying] = useState<boolean>(false);
    const [loading, setLoading] = useState<Boolean>(false);
    const [methods, setMethods] = useState<Array<PaymentMethod>>([]);
    const [offers, setOffers] = useState<Array<Offer>>([]);
    const [amount, setAmount] = useState(0);
    const [redirectUrl, setRedirectUrl] = useState('');
    const [qrCode, setQrCode] = useState('');
    const [depositCount, setDepositCount] = useState<number>(0)
    const [selectedMethod, setSelectedMethod] = useState<PaymentMethod | null>(null);
    const [selectedOffer, setSelectedOffer] = useState<Offer | null>(null);
    const [payComUrl, setPayComUrl] = useState<string>('')
    const [isCardIframe, setIsCardIframe] = useState<boolean>(false)
    //added
    const [clientSecret, setClientSecret] = useState<string>('');
    
    const _checkProfile = async () => {
        const data  = await getProfile(getAuthToken());

        // @ts-ignore
        if (typeof window!.profileIntervalCount === "undefined") window!.profileIntervalCount = 0;

        if (data.response && data.response['email']) {
            dispatch(updateSpinStatus(data.response.attributes));

            // @ts-ignore
            if (window!.profileIntervalCount < 1) {
                // @ts-ignore
                window!.profileIntervalCount += 1;
                setTimeout(_checkProfile, 2000);
            } else {
                // @ts-ignore
                window!.profileIntervalCount = undefined;
            }
        }
    }

    const onSubmit = async (e:any) => {
        e.preventDefault();

        const isCnp:boolean = selectedMethod?.methodType === 'cnp';
        
        if (!isCnp && amount < getMinAmount()) {
            dispatch(updateSnackBar(`Depunere minimă ${getMinAmount()} RON.`, 'error'));
            return;
        }

        if (!isCnp && amount > getMaxAmount()) {
            dispatch(updateSnackBar(`Limita de depunere ${getMaxAmount()} RON.`, 'error'));
            return;
        }

        const form = new FormData(e.target);
        
        setApplying(true);
        const data = await getPaymentInfo(
            selectedMethod?.methodType || '',
            selectedMethod?.method || '',
            amount,
            form.get('bonus')?.toString() || ''
        )
        
        setApplying(false);

        if (data.error) {
            dispatch(updateSnackBar(data.message || 'Eroare necunoscută', 'error'));
        } else if (data.response?.success) {
            if (data.response.redirectUrl && data.response.redirectUrl.length) setRedirectUrl(data.response.redirectUrl);
            if (data.response.qrCode && data.response.qrCode.length) setQrCode(data.response.qrCode);

            //added

            if(selectedMethod?.methodType === "credit_card") {
                setIsCardIframe(true)
            } else {
                setIsCardIframe(false)
            }

            if (data.response.clientSecret && data.response.clientSecret.length) {

                //@ts-ignore
                const pay = window.Pay.com({identifier: "{merchant_identifer}"});

                const checkout = await pay.checkout({token: data.response.clientSecret,});
                
                if(selectedMethod?.methodType === "debit_card") {
                    const styles = {
                        background: '#fff',
                        padding: '10px',
                        borderRadius: '10px'
                    }
                    
                    await checkout.universal({
                        container: "#card_frame",
                        style: {
                            base: styles
                        }
                    });
                    setRedirectUrl('return null')
                } else {
                    const baseStyles = {
                        background: 'transparent',
                        padding: '10px',
                        borderRadius: '10px',
                        marginBottom: "2px",
                        fontSize: "20px",
                        color: "#fff",
                        fontWeight: "700 !important"
                    }
                    
                    await checkout.universal({
                        container: "#card_frame",
                        style: {
                            base: baseStyles,
                            modalForm: {
                                base:baseStyles
                            }
                            
                        }
                    });
                }
                
                // @ts-ignore
                checkout.on(checkout.EVENT_TYPES.PAYMENT_SUCCESS, data => {
                    successHandle()
                })
                
                
            }
            //added

            //Send info to google and FB

            let type

            if(depositCount>0){
                type = 'first deposit'
            } else {
                type = 'deposit'
            }

            const info = [
                {user: auth.getUser()},
                {method: selectedMethod?.method},
                {methodName: selectedMethod?.methodName},
                {amount: amount},
                {offerName: selectedOffer?.title}
            ]

            CMS.gtagTrackDeposit(type,{info})
            CMS.fbTrackDeposit(type,{info})

            //Send info to google and FB end
        } else {
            dispatch(updateSnackBar('Eroare necunoscută', 'error'));
        }
    };
    const fetchDepositInformation = async () => {
        setLoading(true);

        const data = await getPaymentMethods();
        let depositLimitAmount:number | undefined;

        if (Array.isArray(data.response?.methods)) {
            setDepositCount(Number(data.response.totalDepositCount) || 0)

            const methods:Array<PaymentMethod> = [];

            const card = data.response.methods.find((json:any) => json['methodType'] === 'credit_card');
            
            let pay_safe = data.response.methods.find((json:any) => json['methodType'] === 'pay_safe_card' && json["method"] === "e00a8a7b-bc66-49d9-a630-249028da0952");
            
            if(!pay_safe) {
                pay_safe = data.response.methods.find((json:any) => json['methodType'] === 'pay_safe_card')
            }
            
            const cnp = data.response.methods.find((json:any) => json['methodType'] === 'cnp');
            //ADDED
            const debitCard = data.response.methods.find((json:any) => json['methodType'] === 'debit_card');
            const bank_transfer = data.response.methods.find((json:any) => json['methodType'] === 'bank_transfer');
            const voucher = data.response.methods.find((json:any) => json['methodType'] === 'voucher');

            if (debitCard) methods.push(Payment.paymentMethodFactory(debitCard));
            if (card) methods.push(Payment.paymentMethodFactory(card));
            if (pay_safe) methods.push(Payment.paymentMethodFactory(pay_safe));
            if (cnp) methods.push(Payment.paymentMethodFactory(cnp));
            if (bank_transfer) methods.push(Payment.paymentMethodFactory(bank_transfer));
            if (voucher) methods.push(Payment.paymentMethodFactory(voucher));

            setMethods(methods);
            setSelectedMethod(methods[0] || null);

            if (data.response && Object.keys(data.response).includes('depositLimitAmount')) {
                depositLimitAmount = Number(data.response.depositLimitAmount);
            }
        }

        if (Array.isArray(data.response?.campaigns)) {
            const campaigns:Array<Offer> = data.response.campaigns.map((json:any) => Payment.offerFactory(json));
            setOffers(campaigns);
        }

        const documentValidation = await getDocumentValidation();
        let approved:boolean = false;

        if (Array.isArray(documentValidation.response)) {
            approved = documentValidation.response.find((item:any) => (item.type === 'identification_card' && item.status === 'approved')) !== undefined;
        }

        if (!approved) {
            if (depositLimitAmount === 0) {
                dispatch(updateSnackBar('Ai atins limita de depuneri de <b>900 RON</b>. Trebuie să <a href="/account/account-validation" title="Verificarea contului">validezi contul</a> pentru a putea depune în continuare.', 'error'));
                dispatch(openDeposit(false));
            } else if (depositLimitAmount && depositLimitAmount > 0) {
                dispatch(updateSnackBar(`Mai poți depune <b>${depositLimitAmount} RON</b> fără a fi necesară validarea contului. Te rugăm să încarci dovada identității <a href="/account/account-validation" title="Verificarea contului">aici</a> pentru a elimina limita de depunere.`, 'error'));
            }
        }

        setLoading(false);
    }
    
    const getFilteredOffers = ():Array<Offer> => {
        
        let filtered = offers.filter((off:Offer) => off.methods.includes(selectedMethod?.method)).sort((a:any, b:any) => a.minDepositAmount - b.minDepositAmount)
        
        return filtered
    }
    
    const getMinAmount = ():number  => {
        const minAmount:number = selectedMethod?.minAmount || 0;

        if ( selectedOffer && selectedOffer.minDepositAmount > 0) {
            return selectedOffer.minDepositAmount;
        }

        return minAmount;
    }

    const getMaxAmount = ():number => {
        return selectedMethod?.maxAmount || 0;
    }

    const getAmountWithTax = ():number => {
        if (amount > 0 && selectedMethod/*&& selectedMethod?.feeInclude*/) {
            if (selectedMethod.feeType === 'percentage') {
                const percentage = selectedMethod?.feeValue / 100;

                if (percentage > 0) {
                    if (selectedMethod.methodType !== 'credit_card' && selectedMethod.methodType !== 'debit_card') {
                        return Number((amount - (amount * percentage)).toFixed(2));
                    } else {
                        return Number((amount + (amount * percentage)).toFixed(2));
                    }
                }
            } else {
                return amount + selectedMethod?.feeValue
            }
        }

        return amount;
    };

    const getTaxAmount = ():number => {
        if (amount > 0 && selectedMethod /*&& selectedMethod?.feeInclude*/) {
            if (selectedMethod.feeType === 'percentage') {
                const percentage = selectedMethod?.feeValue / 100;

                if (percentage > 0) return (amount * percentage);
            } else {
                return selectedMethod?.feeValue
            }
        }

        return 0;
    };

    const emailProps = {
        name: 'amount',
        value: amount || '',
        onChange: (e:any) => {
            setAmount(parseInt(e.target.value));
        }
    };

    const successHandle = () => {
        dispatch(updateSnackBar('Depunerea a fost completată cu succes', 'success'));
        dispatch(openDeposit(false));
    }

    useEffect(() => {
        dispatch(openDeposit(false));
    }, [router.pathname]);

    useEffect(() => {
        setCash(auth.getCash() + amount);
        return () =>{
            setIsCardIframe(false)
        }
    }, [])
    
    useEffect(() => {
        if (cash > 0 && auth.getCash() >= cash && !redirectUrl) {
            setTimeout(() => {
                successHandle()
            }, 1000);
        }
    }, [auth.getCash()]);
    
    useEffect(() => {
        if(!getFilteredOffers().length) {
            setSelectedOffer(null)
        }
    }, [getFilteredOffers()]);
    
    // useEffect(() => {
    //     if(Array.isArray(methods) && methods.length && campaignId.length && Array.isArray(offers) && offers.length) {
    //
    //         let offer = offers.find((off) => off.campaignId === campaignId)
    //
    //         let method = methods.find((m) => offer?.methods.includes(m.method))
    //
    //         if(method) {
    //             setSelectedMethod(method)
    //         }
    //
    //     }
    // }, [campaignId, methods, offers]);
    //

    
    const isCard:boolean = selectedMethod?.methodType === 'credit_card' || selectedMethod?.methodType === 'debit_card';

    const fromAmount:number = !isCard ? Number(amount.toFixed(2)) : Number(getAmountWithTax().toFixed(2));
    const toAmount:number = !isCard ? Number(getAmountWithTax().toFixed(2)) : Number(amount.toFixed(2));
    
    return (
        <Modal
            onAfterOpen={() => {
                fetchDepositInformation();
                document.body.classList.add('hideZendesk')
            }}
            onAfterClose={() => {
                setAmount(0);
                setRedirectUrl('');
                setQrCode('');
                setSelectedMethod(null);
                // if(triedToOpenDeposit) {
                dispatch(openDepositAfterLogin(false));
                dispatch(updateCampaignId(''))
                document.body.classList.remove('hideZendesk')
                // }
            }}
            ariaHideApp={false}
            className={clsx(redirectUrl.length ? 'ReactModal__Deposit' : 'ReactModal__SM', 'depositModal', isCardIframe && 'adaptive_iframe', isMobile && 'isMobile')}
            isOpen={open}
            onRequestClose={() => {
                dispatch(openDeposit(false));
                const root =  document.getElementsByTagName('html')[0];
                root.removeAttribute('style');
            }}
            contentLabel="Example Modal"
        >

            <button
                onClick={() => {
                    dispatch(openDeposit(false))
                    const root =  document.getElementsByTagName('html')[0];
                    root.removeAttribute('style');
                }}

                className={'ReactModal__Close'}
            >
                <Image  src={'/default/icons/close.svg'} width={15} height={15} alt={'close'}/>
            </button>

            <div style={{height: isCardIframe? "calc(100% - 24px)" : "auto"}}  className={clsx('ReactModal__Inner')}>

                <div className="ReactModal__Inner__Header">
                    <h2 className="ReactModal__Title">{strings['deposition']}</h2>

                    <div className={clsx("deposit_warning", (isCardIframe && isMobile) && "mobile")}>

                        {isCardIframe && (
                            <div className={clsx("deposit_warning", isMobile && "mobile")}>
                                <span>
                                    Suma depusă disponibilă este {getMaxAmount()} RON datorită limitei cumulate. Accesează
                                    <Link   href={'/account/responsable-game'}>
                                        {" "}
                                        acest link
                                        {" "}
                                    </Link>
                                    pentru mai multe informații despre limitele tale.
                                </span>

                            </div>
                        )}
                    </div>
                </div>

                <div className="ReactModal__Inner__Content">
                    {(selectedMethod && selectedMethod?.methodType !== 'cnp' && (getMaxAmount() > 0 && getMaxAmount() < 50000) && !loading) &&
                        <div className="deposit_warning">
                            <InfoIcon/>

                            <span>
                                Suma depusă disponibilă este {getMaxAmount()} RON datorită limitei cumulate. Accesează
                                <Link   href={'/account/responsable-game'}>
                                    {" "}
                                    acest link
                                    {" "}
                                </Link>
                                pentru mai multe informații despre limitele tale.
                            </span>

                        </div>
                    }

                    <div id={'card_frame'} />

                    {redirectUrl.length > 0 && (
                        <DepositIframe
                            amount={toAmount}
                            url={redirectUrl}
                            onSuccess={() => {
    
                                if(amount >= 100) {
                                    setTimeout( _checkProfile, 2000 )
                                }
    
                                successHandle();
                            }}
                        />)
                    }

                    {qrCode.length > 0 && <div className={'mb-30'}><img src={qrCode} alt="QR CODE"/></div>}

                    {qrCode.length > 0 && (
                        <button onClick={() => {
                            dispatch(openDeposit(false))
                            const root =  document.getElementsByTagName('html')[0];
                            root.removeAttribute('style');
                        }
                        } type={'button'} className={'logInModal__btn'}>
                            {strings['close']}
                        </button>
                    )}

                    {(redirectUrl.length === 0 && qrCode.length === 0) && (
                        <>
                            {loading ? (
                                <div style={{padding: 50, textAlign: 'center'}}>
                                    <CircularProgress/>
                                </div>
                            ) : (
                                <>
                                    <form onSubmit={onSubmit}>
                                        <div className="mb-20">
                                            <Selector
                                                name={'method'}
                                                defaultValue={selectedMethod?.method}
                                                label={strings['deposit_method']}
                                                options={methods.map((method:PaymentMethod) => ({
                                                    key: method.method,
                                                    label: method.methodName,
                                                    icon: method.methodIcon,
                                                    methodLogo: method.methodLogo,
                                                    card_types: method.methodIconsPaymentTheme
                                                }))}
                                                onChange={(key) => {
                                                    setSelectedMethod(methods?.find((m) => m.method === key) || null);
                                                    setAmount(0);
                                                }}
                                            />
                                        </div>

                                        {selectedMethod?.methodType !== 'cnp' &&
                                            <div className="mb-30">
                                                <TextField label={'Adaugă fonduri'} type='text' placeholder={'Adaugă fonduri'} {...emailProps}/>
                                            </div>
                                        }

                                        {(selectedMethod && selectedMethod.amountList.length > 0 && selectedMethod?.methodType !== 'cnp') &&
                                            <div className="mb-30">
                                                <div className="deposit-amount-list">
                                                    {selectedMethod.amountList.map(a => {
                                                        return (
                                                            <div className="deposit-amount-list__col" key={`${selectedMethod.methodType}-${a}`}>
                                                                <button
                                                                    className={clsx('deposit-amount-list__btn', amount === a && 'deposit-amount-list__btn--active')}
                                                                    type={'button'}
                                                                    onClick={() => setAmount(a)}
                                                                >
                                                                    RON {a}
                                                                </button>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        }

                                        {getFilteredOffers().length ? (
                                            <div  className="mb-20">
                                                <Selector
                                                    defaultValue={campaignId}
                                                    name={'bonus'}
                                                    label={strings['choose_bonus']}
                                                    options={[
                                                        {key: '', label: strings['withoutBonus']},
                                                        ...getFilteredOffers().map((offer:Offer) => ({key: offer.campaignId, label: offer.title}))
                                                    ]}
                                                    onChange={(key) => setSelectedOffer(getFilteredOffers()?.find((m) => m.campaignId === key) || null)}
                                                />
                                            </div>) : null
                                        }

                                        <Button loading={applying} type={'submit'} text={strings['depune']}/>
                                    </form>

                                    {(amount > 0 && selectedMethod?.methodType !== 'cnp') &&
                                        <div className="deposit_overview">
                                            <div className="deposit_overview__amount">
                                                <div className="deposit_overview__amount__type">{strings['haveToPay']}</div>
                                                {fromAmount} RON
                                            </div>

                                            <div className="deposit_overview__arrow"><ArrowRight/></div>

                                            <div className="deposit_overview__amount">
                                                <div className="deposit_overview__amount__type">{strings['willGet']}</div>
                                                {toAmount} RON
                                            </div>
                                        </div>
                                    }

                                    {getTaxAmount() > 0 &&
                                        <div className={'tax-notice'}>
                                            <InfoIcon/>
                                            {/*<span>Taxa de {getTaxAmount()} RON este adăugat la depunerea ta.</span>*/}
                                            Taxa de {getTaxAmount()} RON este inclusă în depunerea ta.
                                        </div>
                                    }
                                </>
                            )}
                        </>
                    )}
                </div>

            </div>
        </Modal>
    )
}


export default DepositModal