function ManageAccounts() {
    return (
        <svg className={'svg-icon'} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="mask0_1834_34823" style={{maskType: 'alpha'}} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
                <rect width="24" height="24"/>
            </mask>
            <g mask="url(#mask0_1834_34823)">
                <path d="M10 12C8.9 12 7.95833 11.6083 7.175 10.825C6.39167 10.0417 6 9.1 6 8C6 6.9 6.39167 5.95833 7.175 5.175C7.95833 4.39167 8.9 4 10 4C11.1 4 12.0417 4.39167 12.825 5.175C13.6083 5.95833 14 6.9 14 8C14 9.1 13.6083 10.0417 12.825 10.825C12.0417 11.6083 11.1 12 10 12ZM2 20V17.2C2 16.65 2.14167 16.1333 2.425 15.65C2.70833 15.1667 3.1 14.8 3.6 14.55C4.45 14.1167 5.40833 13.75 6.475 13.45C7.54167 13.15 8.71667 13 10 13H10.35C10.45 13 10.55 13.0167 10.65 13.05C10.5167 13.35 10.404 13.6627 10.312 13.988C10.2207 14.3127 10.15 14.65 10.1 15H10C8.81667 15 7.754 15.15 6.812 15.45C5.87067 15.75 5.1 16.05 4.5 16.35C4.35 16.4333 4.22933 16.55 4.138 16.7C4.046 16.85 4 17.0167 4 17.2V18H10.3C10.4 18.35 10.5333 18.696 10.7 19.038C10.8667 19.3793 11.05 19.7 11.25 20H2ZM10 10C10.55 10 11.021 9.804 11.413 9.412C11.8043 9.02067 12 8.55 12 8C12 7.45 11.8043 6.97933 11.413 6.588C11.021 6.196 10.55 6 10 6C9.45 6 8.97933 6.196 8.588 6.588C8.196 6.97933 8 7.45 8 8C8 8.55 8.196 9.02067 8.588 9.412C8.97933 9.804 9.45 10 10 10Z"/>
                <path d="M21 14L16.2606 19L13 16.3027" stroke="#9D0000" strokeWidth="1.5"/>
            </g>
        </svg>
    )
}
export default ManageAccounts