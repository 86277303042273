import {FC} from 'react';
import styles from 'themes/default/styles/signup.module.scss';
import {useDispatch} from 'react-redux';
import clsx from 'clsx';
import {openVerificationModal} from 'store/modals/actions';
import useAuth from "hooks/auth";
import ManageAccounts from 'themes/default/svg/manageAccounts';
import VerificationIndicatorViewInterface
    from "interfaces/ui-view-interfaces/verification_indicator_view_interface";

const VerificationIndicatorView:FC<VerificationIndicatorViewInterface> = ({ ... props}) => {

    const {
        mobile,
        allVerified,
    } = props

    const dispatch = useDispatch();
    const auth = useAuth();


    if (auth.isLoggedIn() && !allVerified) {
        return (
            <div
                className={clsx(styles.indicator, mobile && styles.indicator__mobile)}
                onClick={() => {
                    
                    dispatch(openVerificationModal(true))
                }}
            >
                <ManageAccounts/>
                <span>Verificare</span>
            </div>
        )
    }

    return null;
}

export default VerificationIndicatorView