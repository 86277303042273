import clsx from 'clsx';


function NewArrow() {
    return (
        <svg className={clsx('svg-icon', 'newArrow')} width="15" height="15" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.6667 25.3333L11.3334 16L20.6667 6.66663" stroke="white" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

    )
}
export default NewArrow