import styles from "@/template/styles/OldPromotions.module.scss";
import React, {useState, useEffect} from 'react';
import PromoCard from "./promotionCard";
import strings from "utils/strings";
import {useRouter} from 'next/router';

interface Props {
    isMedia1: boolean
    isImageLoaded: boolean
    oldPromotions: Array<any>
}

const DefaultOldPromSidebarView:React.FC<Props> = ({isMedia1, isImageLoaded, oldPromotions}) => {

    const [asideH, setAsideH] = useState<number | string>('unset')

    const router = useRouter();

    useEffect(() => {
        let aside = document.getElementById('pagePromId')

        //@ts-ignore
        if(isImageLoaded) {
            if(isMedia1) {
                setAsideH('unset')
            } else {
                //@ts-ignore
                setAsideH(aside.clientHeight )
            }
        }

    }, [isMedia1, router, isImageLoaded])

    return (
        <div style={{height: asideH}} id={'toSetHeight'} className={styles.promotionsAside}>

            <h3 >
                {strings['old_promotions']}
            </h3>

            <div style={{height: asideH+'px'}} className={styles.mobileSlider}>
                <div className={styles.oldPromotionContainer}>
                    {oldPromotions?.map((x, i) => {
                        return (
                            <PromoCard key={'promo side card ' + i} prom={x} />
                        )}
                    )}
                </div>
            </div>
        </div>
    )
}

export default DefaultOldPromSidebarView