import { FC, useState } from 'react';
import styles from 'themes/default/styles/FullWidthCarousel.module.scss';
import strings from 'utils/strings';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CarouselArrow from 'ui/CarouselArrow';
import CMS from "utils/cms";
import Link from 'next/link';
import clsx from 'clsx';
import PageProgress from "@/template/small_ui/PageProgress";
import {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {fullWidthSliderLoaded} from 'store/cms/actions';

interface Props {
    json: object;
    api_id: string;
    first?:boolean;
    index?:any
}

const FullWidthCarousel: FC<Props> = ({ json, api_id, first }) => {
    const data = CMS.jsonFullWidthCarousel(json);
    const dispatch = useDispatch();
    const desktopImageLoaded = useSelector((state:any) => Boolean(state.cms.uploadedFullWidthSlider[`desktop_${api_id}`]));
    const mobileImageLoaded = useSelector((state:any) => Boolean(state.cms.uploadedFullWidthSlider[`mobile_${api_id}`]));
    const isMobile = useSelector((state:any) => Boolean(state.common.isMobile));
    
    const [init, setInit] = useState<boolean>(false);
    
    const settings = {
        // dots: false,
        dots: true,
        infinite: true,
        heightAuto: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <CarouselArrow />,
        prevArrow: <CarouselArrow leftArrow/>,
    };
    
    
    
    useEffect(() => {
        if (data.sliders[0].image.length) {
            const desktopImage = document.createElement('img');
            desktopImage.src = CMS.getPublicFilePath(data.sliders[0].image[0].name, api_id, data.sliders[0].image[0].path);
            
            desktopImage.onload = () => {
                // setDesktopImageLoaded(true);
                dispatch(fullWidthSliderLoaded(`desktop_${api_id}`));
            }
        } else {
            dispatch(fullWidthSliderLoaded(`desktop_${api_id}`));
        }
        
        if (data.sliders[0].mobile_image.length) {
            const mobileImage = document.createElement('img');
            mobileImage.src = CMS.getPublicFilePath(data.sliders[0].mobile_image[0].name, api_id, data.sliders[0].mobile_image[0].path);
            
            mobileImage.onload = () => {
                // setMobileImageLoaded(true);
                dispatch(fullWidthSliderLoaded(`mobile_${api_id}`));
            }
        } else {
            dispatch(fullWidthSliderLoaded(`mobile_${api_id}`));
        }
        
    }, []);
    
    useEffect(() => {
        // const element = document.getElementById('main_container');
        // if (first && element) {
        //     element.style.paddingTop = "0";
        // }
        // return () => {
        //     element && (element.style.paddingTop = "");
        // };
    }, [first]);
    
    // console.log(json)
    
    return (
        <div  className={clsx(styles.slider, styles.sliderWrapper, 'full-width-carousel-root')}>
            <Slider
                {...settings}
                autoplay={true}
                autoplaySpeed={10000}
                className={styles.slider__child}
                onInit={() => {
                    setInit(true);
                }}
            >
                {data.sliders.map((slide:any, i:number) => {
                    const hideBtn = slide.promotion_api_id === '';
                    
                    return (
                        <div key={`slide-${i}`}>
                            <div className={clsx(styles.sliderBlock, styles.imagesSlider)}>
                                {slide.image.length > 0 && <img style={{maxWidth: '100%', height: 'auto'}} width={1910} height={576} src={CMS.getPublicFilePath(slide.image[0].name, api_id, slide.image[0].path)} alt={slide.title}/>}
                                
                                <div  className={styles.slider__moreInfo}>
                                    <h3 className={styles.slider__title}>{slide.title}</h3>
                                    { hideBtn ?  null : <Link  href={slide.url_extern.length ? slide.url_extern : '/promotii/'+slide.api_id} className={'btn__primary'} >{strings['afla_mai_multe']}</Link> }
                                </div>
                            </div>
                            
                            <Link href={slide.url_extern.length ? slide.url_extern : '/promotii/'+slide.api_id} title={slide.title} className={clsx(styles.sliderBlock, styles.imagesSlider, styles.imagesSliderMobile)}>
                                {slide.mobile_image.length > 0 && <img style={{minWidth: '100vw', height: 'auto'}} width={408} height={306} src={CMS.getPublicFilePath(slide.mobile_image[0].name, api_id, slide.mobile_image[0].path)} alt={slide.title}/>}
                                
                                {/*<div  className={styles.slider__moreInfo}>*/}
                                {/*    { hideBtn ?  null : <Link href={'/promotii/'+slide.api_id} className={'btn__primary'} >{strings['afla_mai_multe']}</Link> }*/}
                                {/*</div>*/}
                            </Link>
                        </div>
                    )
                })}
            </Slider>
            
            {(first && !desktopImageLoaded && !mobileImageLoaded) &&
                <PageProgress force types={[]} api_id={api_id}/>
            }
        </div>
    );
};

export default FullWidthCarousel;