import styles from "themes/default/styles/accountMenu.module.scss";
import clsx from 'clsx';
import {FC, useState} from 'react';

interface Props {
    title: string,
    children?: any
    active:boolean,
}

const DefaultAccountSubmenuView:FC<Props> = ({ title, children, active}) => {

    const [show, setShow] = useState<boolean>(active);

    const setShowHandle = () => {
        setShow(!show)
    }

    return (
        <div className={styles.accountMenu__block}>

            <div className={clsx(styles.accountMenu__myProfile, show && styles.activeTitle, styles.arrow)}>
                <div
                     className={clsx(styles.accountMenu__title)}
                     onClick={() => {
                        setShowHandle()
                     }}
                >
                    {title}
                </div>
            </div>

            {show && (
                <div>{children}</div>
            )}

        </div>
    )
}

export default DefaultAccountSubmenuView