import styles from 'themes/default/styles/search.module.scss';
import clsx from 'clsx';
import React, {FC, useEffect, useRef, useState} from 'react';
import GameGrid from "@/template/ui/game/grid";
import {useDispatch, useSelector} from "react-redux";
import GameInterface from "@/AbsoluteComponents/interfaces/game";
import Game from "@/AbsoluteComponents/utils/game";
import {useRouter} from "next/router";
import {showSearchBar as showSearchBarFunc, updateSearchValue} from "@/AbsoluteComponents/store/common/actions";

const DefaultSearchOverlayView = () => {

    const value:string = useSelector((state:any) => state.common.searchValue);
    const showSearchBar:boolean = useSelector((state:any) => state.common.showSearchBar);
    const activeProviders:Array<any> = useSelector((state:any) => state.common.activeSearchProviders);
    const searchSort:string = useSelector((state:any) => state.common.searchSort);
    const games:Array<GameInterface> = Game.getAll();
    const rootOverlay:any = useRef();
    const router = useRouter();
    const dispatch = useDispatch();

    const [isTransition, setIsTransition] = useState<boolean>(false)

    useEffect(() => {
        setTimeout(() => {
            setIsTransition(true)
        }, 700)
    }, [])

    const matchGames = (): Array<GameInterface> => {
        let result:Array<GameInterface> = [];

        games?.map((game:GameInterface) => {
            const nameExist:boolean = value.length ? game.friendlyName.toLowerCase().includes(value.toLowerCase()) : false;
            const hasProvider = activeProviders.length === 0 || game.categories.some(category => activeProviders.includes(category));

            if (game.hideFromSearch) return;

            if (
                (value.length && activeProviders.length && hasProvider && nameExist) ||
                (!value.length && activeProviders.length && hasProvider) ||
                (value.length && !activeProviders.length && nameExist)
            ) {
                result.push(game);
            }
        });


        if (searchSort === 'a-z' || searchSort === 'z-a') {
            result.sort((a:any, b:any):number => {
                const prevName = a.friendlyName;
                const nextName = b.friendlyName;

                if (prevName > nextName) {
                    return 1;
                }

                if (prevName < nextName) {
                    return -1;
                }

                return 0;
            });

            if (searchSort === 'z-a') result.reverse();
        }

        return result;
    };

    const showSearch:boolean = value.length > 0 || activeProviders.length > 0;
    const availableGames = matchGames();

    useEffect(() => {


        if (value.length || activeProviders.length) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = '';

            if (window.scrollY > 0) {
                document.querySelector('.header-scroll')?.classList.add('fixed-scroll');
            }
        }
    }, [value]);

    //CMS REQUESTS END

    useEffect(() => {
        dispatch(showSearchBarFunc(false));
        dispatch(updateSearchValue(''));

        const field:any = document.getElementById('nav-search-input');
        if (field) field.value = '';
    }, [router.pathname, router.query]);


    return(
        <div
            style={{transition: `${isTransition && 'opacity .2s linear, transform .2s linear'}`}}
            data-section={'search'}
            id={'search-overlay'}
            className={clsx(styles.overlay, (showSearch && showSearchBar) && styles.active)}
            ref={rootOverlay}
        >
            <div className={styles.overlay__results}>
                <div className="page-container">
                    <GameGrid
                        onCardClick={/*sendGameInfo*/ () => {}}
                        games={availableGames}
                        page={'search'}
                        innerScroll
                    />
                </div>
            </div>

            {availableGames.length === 0 && (
                <div className={styles.overlay__empty}>NU S-A GĂSIT NIMIC</div>
                )
            }

        </div>
    )
}
export default DefaultSearchOverlayView;
