import React, {useEffect} from "react";
import CMS from "utils/cms";
import styles from "themes/default/styles/LuckyTicket.module.scss"
import LuckyTicketHero from "themes/default/cms-views/luckyTicket/elems/hero";
import LuckyTicketPrizes from "themes/default/cms-views/luckyTicket/elems/prizes";
import LuckyTicketResults from "themes/default/cms-views/luckyTicket/elems/results";
import LuckyTicketRules from "themes/default/cms-views/luckyTicket/elems/rules";
import LuckyTicketTerms from "themes/default/cms-views/luckyTicket/elems/terms";

interface Props {
    json:any
    api_id:string
}

const LuckyTicket:React.FC<Props> = ({json, api_id}) => {
    
    const data = CMS.getAllFields(json)
    
    
    return(
        <div className={styles.main}>
            
            <LuckyTicketHero data={data} api_id={api_id}/>
            
            <LuckyTicketPrizes data={data} api_id={api_id}/>
            
            <LuckyTicketRules data={data} api_id={api_id}/>
            
            <LuckyTicketResults data={data} api_id={api_id}/>
            
            <LuckyTicketTerms data={data} api_id={api_id}/>
            
        </div>
    )
}

export default LuckyTicket