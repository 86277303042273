import styled from "styled-components";


interface WheelComponentProps {
    startSpinDeg: number;
    rotateTo: number;
    initialPosition: number
    rotateBefore:number
}

export const WheelContainer = styled.div `
      margin: 0;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: start;
      position: relative;
      padding: 0!important;
    
`;

export const Header = styled.div`
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
    gap: 10px;
    margin-top: 20px;
    padding: 0 10px;
`

export const CloseBtn = styled.button`
    width: 48px;
    height: 48px;
    font-size: 14px;
    border-radius: 40px;
    border: none;
    background: #1B1025;
`

export const TabsContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    gap: 10px;
    z-index: 1000000000;
`

export const Tabs = styled.div`
    font-size: 20px;
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    } 
    
    &.selectedTab {
        text-decoration: underline;
    }
`

export const Absolute = styled.div`
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: fit-content;
`

export const Relative = styled.div`
    position: relative;
    width: 500px;
    
    @media (max-height: 920px) {
        width: 400px;
    }
    
    @media (max-width: 500px) {
        width: 100%;
        
    }
   
`

export const WheelConstruction = styled.div`
    position: relative;
   
    @media (max-height: 920px) {
        transform: scale(0.8) translateY(-70px);
        
    }
    
    @media (max-height: 730px) {
        transform: scale(0.63) translateY(-130px);
        
    }
    
    @media (max-height: 670px) {
        transform: scale(0.6) translateY(-170px);
        
    }
    
    @media (max-width: 500px) {
        transform: scale(0.63) translateY(-170px);
    }
    
    @media (max-width: 360px) {
        transform: scale(0.6) translateY(-170px);
    }
    
    @media (max-width: 330px) {
        transform: scale(0.56) translateY(-180px);
    }
    
    &.hidden {
        opacity: 0;
    }

`

export const LoadingContainer = styled.div`
    position: absolute;
    bottom: 0px;
    left: 0;
    height: 85%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: inherit;
    
`

export const Overlayed = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const WheelComponent = styled.div<WheelComponentProps>`
  width: 500px;
  height: 500px;
  transition: 0.5s all;
  -webkit-transition: 0.5s all;
  transform: rotate(${(props) => props.initialPosition}deg);
  
  &.appear {
  -webkit-animation: appear ;
      -webkit-animation-duration: 1s;
      animation: appear ;
      animation-duration: 1s;
      transform: scale(0.7)  rotate(${(props) => props.initialPosition - 50}deg);;
        opacity: 0.6;
      
    @keyframes appear {
      0% {
        transform: scale(0.7)  rotate(${(props) => props.initialPosition - 50}deg);;
        opacity: 0.6;
      }
      100% {
        transform:  scale(1.0) rotate(${(props) => props.initialPosition}deg);
        opacity: 1;
      }
  }
  }
 
  &.startSpinning {
  -webkit-animation: startspin;
    -webkit-animation-duration: 2s;
    animation: startspin;
    animation-duration: 2s;
    @keyframes startspin {
      0% {
        transform: rotate(${(props) => props.initialPosition}deg);
      }
      30% {
        transform: rotate(${(props) => props.initialPosition - props.startSpinDeg}deg);
      }
      100% {
        transform: rotate(720deg);
      }
    }
  }

  &.endSpinning {
    transition: 0.5s all;
    -webkit-transition: 0.5s all;
    transform: rotate(${(props) => props.rotateTo }deg);
    -webkit-animation: endspinn;
    -webkit-animation-duration: 3.5s;
    -webkit-animation-timing-function: ease-out;
    animation: endspinn;
    animation-duration: 3.5s;
    animation-timing-function: ease-out;
    
    @keyframes endspinn {
      0% {
        transform: rotate(0deg);
      }
     
      100% {
        transform: rotate(${(props) => props.rotateTo + 1080}deg);
      }
    }
  }

  &.spinning {
  -webkit-animation: spinn linear infinite;
    -webkit-animation-duration: 1.5s;
    animation: spinn linear infinite;
    animation-duration: 1.5s;

    @keyframes spinn {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(720deg);
        //transform: rotate(360deg);
      }
    }
  }
`;

export const CenteredImages = styled.img`
    position: absolute;
    top: 0;
    left: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;
    
     &.enabledSpin {
     -webkit-animation: scale linear infinite;   
        -webkit-animation-duration: 2.5s;   
        animation: scale linear infinite;   
        animation-duration: 2.5s;   
       
    }
    
    &.active {
    -webkit-animation: scale infinite;
        -webkit-animation-duration: 1.5s; 
        animation: scale infinite;
        animation-duration: 1.5s; 
    }
   
    
   &.spinnedToday {
        animation: none;
        
        &:hover {
            animation: none;
        }
   }
    
    @keyframes scale {
      0% {
        
        transform: translate(-50%, -50%) scale(1) rotate(-2deg);
      }
      
      50% {
      
        transform:translate(-50%, -50%) scale(1.1) rotate(7deg);
      }
      
      100% {
      
        transform:translate(-50%, -50%) scale(1) rotate(-2deg);
      }
    }
    
    &.appear {
    -webkit-animation: appearborder ;
          animation: appearborder ;
          -webkit-animation-duration: 1s;
          animation-duration: 1s;
          border: none;
          transform: translate(-50%, -50%) scale(0.7);
          opacity: 0.6;
          @keyframes appearborder {
              0% {
                transform: translate(-50%, -50%) scale(0.7);
                opacity: 0.6;
              }
              100% {
                transform: translate(-50%, -50%) scale(1.0);
                opacity: 1;
              }
          }

    }
`

export const Chevrone = styled.div `
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -30px;
  z-index: 2;
  pointer-events: none;
  
  
  &.startTick {
  -webkit-animation: start-tick;
    animation: start-tick;
  animation-duration: 1s;
  -webkit-animation-duration: 1s;
  }
  
   @keyframes start-tick {
      0% {
     
        transform: rotate(0deg) translateX(-50%);
      }
      50% {
      
        transform: rotate(50deg) translateX(-50%);
      }
      100% {
      
        transform: rotate(0deg) translateX(-50%);
      }
    }
`;

export  const ArrowImage = styled.img`

 &.startTick {
    animation: start-tick;
    animation-duration: 1s;
    
    -webkit-animation: start-tick;
    -webkit-animation-duration: 1s;
  }
  
  &.spinTick{
  -webkit-animation: spin-tick linear infinite;
    animation: spin-tick linear infinite;
    -webkit-animation-duration: 0.1s ;
    animation-duration: 0.1s ;
  }
  
  &.endTick{
  -webkit-animation:end-tick;
    animation: end-tick;
    animation-duration: 3.5s;
    animation-timing-function: ease-out;
    -webkit-animation-duration: 3.5s;
    -webkit-animation-timing-function: ease-out;
  }
  
  @keyframes start-tick {
      0% {
     
        transform: rotate(0deg);
      }
      50% {
      
        transform: rotate(50deg);
      }
      
      85% {
     
        transform: rotate(-50deg);
      }
      
      90% {
     
        transform: rotate(0deg);
      }
      
      95% {
     
        transform: rotate(-50deg);
      }
      
      100% {
     
        transform: rotate(0deg);
      }
    }
    
  @keyframes spin-tick {
      0% {
     
        transform: rotate(0deg);
      }
      50% {
      
        transform: rotate(-50deg);
      }
      100% {
      
        transform: rotate(0deg);
      }
    }
  
  @keyframes end-tick {
      0% {
     
        transform: rotate(0deg);
      }
      2% {
      
        transform: rotate(-50deg);
      }
      
      4% {
     
        transform: rotate(0deg);
      }
      6% {
      
        transform: rotate(-50deg);
      }
      
      8% {
     
        transform: rotate(0deg);
      }
      10% {
      
        transform: rotate(-50deg);
      }
      
      12% {
     
        transform: rotate(0deg);
      }
      14% {
      
        transform: rotate(-50deg);
      }
      
      16% {
     
        transform: rotate(0deg);
      }
      18% {
      
        transform: rotate(-50deg);
      }
      
      20% {
     
        transform: rotate(0deg);
      }
      22% {
      
        transform: rotate(-50deg);
      }
      
      24% {
     
        transform: rotate(0deg);
      }
      26% {
      
        transform: rotate(-50deg);
      }
      
      28% {
     
        transform: rotate(0deg);
      }
      30% {
      
        transform: rotate(-50deg);
      }
      
      32% {
     
        transform: rotate(0deg);
      }
      34% {
      
        transform: rotate(-50deg);
      }
      
      34% {
     
        transform: rotate(0deg);
      }
      36% {
      
        transform: rotate(-50deg);
      }
      
      38% {
     
        transform: rotate(0deg);
      }
      42% {
      
        transform: rotate(-50deg);
      }
      
      46% {
     
        transform: rotate(0deg);
      }
      50% {
      
        transform: rotate(-50deg);
      }
      
      54% {
     
        transform: rotate(0deg);
      }
      58% {
      
        transform: rotate(-50deg);
      }
      
      62% {
     
        transform: rotate(0deg);
      }
      66% {
      
        transform: rotate(-50deg);
      }
      
      
     70% {
     
        transform: rotate(0deg);
      }
     74% {
      
        transform: rotate(-50deg);
      }
      
      79% {
      
        transform: rotate(0deg);
      }
      84% {
      
        transform: rotate(-50deg);
      }
      89% {
      
        transform: rotate(0deg);
      }
    
       94% {
      
        transform: rotate(-50deg);
      }      
      
      100% {
     
        transform: rotate(0deg);
      }
      
      
    }

`;

export const GlowImageUpper = styled.img`
    position: absolute;
    top: 5px;
    left: 65px;
`;

export const GlowImageBottom = styled.img`
    position: absolute;
    bottom: 10px;
    left: 250px;
`;

export const GlowsContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    
    &.appear {
    -webkit-animation: appearglow;
        animation: appearglow;
        animation-duration: 1s;
        -webkit-animation-duration: 1s;
        transform:  scale(0.7);
        opacity: 0.6;
        
          @keyframes appearglow {
          
              0% {
                transform:  scale(0.7);
                opacity: 0.6;
              }
              
              100% {
                transform:  scale(1.0);
                opacity: 1;
              }
          }
    
    }
    
    
`

export const BgImage = styled.img`
    position: absolute;
    bottom: -130px;
    left: 0;
  
    &.appear {  
    -webkit-animation: appearbg;
        animation: appearbg ;
          animation-duration: 1s;
          -webkit-animation-duration: 1s;
          border: none;
          transform:  scale(0.5);

          @keyframes appearbg {
              0% {
                transform:  scale(0.5);
                transform-origin: bottom;
                
              }
 
              100% {
                transform:  scale(1.0);
                transform-origin: bottom;
              }
        }
    }
    
`;

export const Front = styled.img`
    position: absolute;
    bottom: -270px;
    left: 50%;
    transform: translateX(-50%);
    
    
    &.appear {
        animation: appearfront;
        animation-duration: 1s;
        -webkit-animation: appearfront;
        -webkit-animation-duration: 1s;
        border: none;
        transform: translateX(-50%) scale(0.9);
        transform-origin: top;
        @keyframes appearfront {
          0% {
            transform: translateX(-50%) scale(0.9);
            transform-origin: top;
          }
          100% {
            transform: translateX(-50%) scale(1.0);
            transform-origin: top;
           
          }
        }
    }
  
`;


export const ToggleSound = styled.button`
    width: 40px;
    height: 40px;
    color: #fff;
    border-radius: 100px;
    border: none;
    background: rgba(255, 255, 255, 0.1);
    padding: 0;
`;

export const SpinButton = styled.div`
    width: 232px;
    height: 232px;
    background: none;
    border: none;
    position: relative;
    transform: scale(1);
    transition: 0.2s all;
   
`

export const SpinBtnCenter = styled.button`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: none;
    border: none;
    width: 100px;
    height: 100px;
`



