import styles from 'themes/default/styles/search.module.scss';
import Image from 'next/image';
import { FC } from 'react'
import SearchViewInterface from "interfaces/ui-view-interfaces/search_view_interface";

const DefaultSearchView:FC<SearchViewInterface> = ({ ... searchProps }) => {

    const {
        clickHandle
    } = searchProps

    return (
        <button type={'button'} onClick={clickHandle} className={styles.search_icon}>
            <Image alt={'img'}  src={'/default/icons/icon_search.svg'} width={24} height={24}/>
        </button>
    );
}
export default DefaultSearchView;
