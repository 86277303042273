import Link from 'next/link';
import strings from "@/AbsoluteComponents/utils/strings";
import clsx from 'clsx';
import styles from '../../styles/footer.module.scss';
import CMS from "@/AbsoluteComponents/utils/cms";

const DefaultMenufooterView = () => {
    
    const footer_menu_raw: Array<any> = CMS.getFooterMenu()
    const data = CMS.getAllFields(footer_menu_raw[0])

    return (
        <div>

            <h5 className={styles.menu_heading}>
                {strings['menu']}
            </h5>

            <div className={clsx(styles.menuContainer)}>
                <ul className={clsx(styles.menu, styles.firstColumn)}>
                    
                    {(Array.isArray(data?.menu) && data?.menu.length) ? data?.menu.map((menu_item:any, i:number) => {
                        
                        const {link, name} = menu_item
                        
                        return link.includes("http") ? (
                            <li key={`footer_menu_item_${i}`}>
                                <a className={styles.menu__item} href={link} target={"_blank"}>{name}</a>
                            </li>
                            ) : (
                            <li key={`footer_menu_item_${i}`}>
                                <Link className={styles.menu__item} href={link}>{name}</Link>
                            </li>
                        )
                    }) : null}

                </ul>
                
            </div>
        </div>
    )
}
export default DefaultMenufooterView;