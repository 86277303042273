import { FC } from 'react';
import styles from 'themes/default/styles/PageTypeInformation.module.scss';
import clsx from 'clsx';
import CMS from "utils/cms";
import SideBarMenu from "components/sidebarMenu";

interface Props {
    json: object;
}


const PageTypeInformation: FC<Props> = ({ json }) =>  {

    const data = CMS.jsonBlockPages(json);

    return (
        <div className={'container'}>
            <div className="row">
                <div className="col-md-4 col-lg-4 col-xl-3"><SideBarMenu /></div>
                <div className="col-1"></div>

                <div className={clsx(styles.info, 'info', "col-md-7 col-lg-7 col-xl-8")}>
                    <h1 className={clsx('title-block', 'title')}>{data.title}</h1>

                    {data.sections.map((item:any, i:number) => {

                        return (
                            <div  key={`info-${i}`} style={{marginTop: (item.title.length === 0 && !item.with_border) ? 22 : 0}}>

                                {item.title.length > 0 && <h3 className={styles.infoTitle}>{item.title}</h3>}
                                <div className={clsx(styles.infoBlock, item.with_border === true && styles.border_block)} dangerouslySetInnerHTML={{__html: item.content}}/>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default PageTypeInformation;