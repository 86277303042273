import CMS from "@/AbsoluteComponents/utils/cms";
import styles from "@/template/styles/Ui.module.scss";
import {useRouter} from "next/router";

interface Props {

}

const GetAppBlock:React.FC<Props> = () => {
    const generalSettings = CMS.getGenSettingsJSON();
    const router = useRouter()
    const data = generalSettings ? CMS.getAllFields(generalSettings) : {}
    const currentPage = router.pathname;
    const {app_block_visible, app_links, app_links_hide_on} = data
    const page_not_allowed = (Array.isArray(app_links_hide_on) && app_links_hide_on.length) ? app_links_hide_on?.some((item:any) => currentPage.includes(item.page_url)) : false
    
    return (app_block_visible && !page_not_allowed) && (
        <div className={styles.app_links_container}>
            {(Array.isArray(app_links) && app_links.length) ? (
                <div className={styles.app_links}>
                    {app_links.map((l:any, i:number) => {

                        const {dwn_from_text, dwn_text, logo, store_url} = l
                        return (
                            <button
                                onClick={() => {
                                    if(typeof store_url === "string" && store_url.length) {
                                        window.open(store_url, "_blank")
                                    }
                                }}
                                key={`app_links_nr${i}`}
                            >
                                {(Array.isArray(logo) && logo.length) ? (
                                    <img src={CMS.getPublicFilePath(logo[0].name, "general_settings", logo[0].path)} width={46} height={46} alt={dwn_from_text} />
                                ) : null}

                                <div className={styles.inf}>
                                    <span>{dwn_text}</span>
                                    <strong>{dwn_from_text}</strong>
                                </div>
                            </button>
                        )
                    })}
                </div>
            ) : null}
        </div>
    )
}

export default GetAppBlock