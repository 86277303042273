import React, {FC} from 'react';
import CMS from "utils/cms";
import styles from 'themes/default/styles/themes.module.scss';
import gameStyles from 'themes/default/styles/card.module.scss';
import ThemeItem from './ThemeItem';

interface Props {
    json:any;
    api_id:string;
}

const ThemesPage:FC<Props> = ({json, api_id}) => {
    const data = CMS.jsonThemesPage(json);

    //ADDED END
    return (
        <div className={'page-container'}>
            <h1 className={gameStyles.page_title}> {data.title}<span className={gameStyles.page_title__count}>{data.themes.length}</span></h1>
            <div className={styles.grid}>
                {data.themes.map((item:any, i:number) => {
                    return (
                        <ThemeItem key={'theme' + i} apiId={api_id} item={item}/>
                    )
                })}
            </div>
        </div>
    )
};

export default ThemesPage