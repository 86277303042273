import React, {useState} from "react";
import styles from "themes/default/styles/LuckyTicket.module.scss"
import strings from "utils/strings";
import Chevrone from "themes/default/svg/Chevrone";
import clsx from "clsx";

interface Props {
    data:any
    api_id:string
}

const LuckyTicketTerms:React.FC<Props> = ({data}) => {
    
    const [show, setShow] = useState<boolean>(false)
    const {terms_and_conditions} = data
    
    
    return (
        <div className={styles.terms}>
            <div className={styles.divider}>
            
            </div>
            
            <button
                onClick={() => {
                    setTimeout(() => {
                        setShow((x:boolean) => !x)
                    }, 50)
                }}
                className={clsx(styles.button_terms, show && styles.showed)}
            >
                {strings["termeni_și_condiții"]} <Chevrone />
            </button>
            
             <div className={clsx(styles.terms_content, show && styles.showed)}  dangerouslySetInnerHTML={{__html:terms_and_conditions}}/>
            
        </div>
    )
}

export default LuckyTicketTerms