import styles from '../../styles/search.module.scss';
import {useDispatch, useSelector} from 'react-redux';
import {showSearchBar, updateSearchValue, applySearchProvidersFilter, applySearchSort} from "store/common/actions";
import clsx from 'clsx';
import ArrowLeft from "themes/default/svg/arrow-left";
import OutlinedSelector from "@/template/small_ui/OutlinedSelector";
import Filter from 'themes/default/svg/filter';
import {useState, useEffect} from 'react';
import strings from 'utils/strings';
import Game from  'utils/game'
import {useRouter} from 'next/router';
import GameInterface from "interfaces/game";
import useAuth from "hooks/auth";
import CMS from "utils/cms"


let prevActive:boolean = false;

function SearchBar() {
    const dispatch = useDispatch();
    const auth = useAuth()
    const router = useRouter()
    const isSportPage = router.pathname.search('sport') > -1;

    const [asideFilter, setAsideFilter] = useState(false);
    const [game, setGame] = useState<GameInterface | undefined>(undefined);
    const [openFilters, setOpenFilters] = useState(false);

    const active:boolean = useSelector((state:any) => state.common.showSearchBar);
    const providers:Array<any> = CMS.jsonAllProvidersPages().providers;
    const activeProviders:Array<any> = useSelector((state:any) => state.common.activeSearchProviders);
    const value:string = useSelector((state:any) => state.common.searchValue);
    const searchSort:string = useSelector((state:any) => state.common.searchSort);

    const gamesList:Array<GameInterface> = Game.getAll();

    const [isTransition, setIsTransition] = useState<boolean>(false)


    useEffect(() => {
        setTimeout(() => {
            setIsTransition(true)
        }, 700)
    }, [])


    const selectProviders = (provider_id:string) => {
        let list:Array<any> = JSON.parse(JSON.stringify(activeProviders));

        if (list.filter((id:any) => id === provider_id).length) {
            list = list.filter((id:any) => id !== provider_id)
        } else {
            list.push(provider_id);
        }

        dispatch(applySearchProvidersFilter(list));
    };


    const reset = () => {
        dispatch(applySearchProvidersFilter([]));
    };

    const showFilter = () => {
        setAsideFilter(true);
    }
    const hideFilter = () => {
        setAsideFilter(false)
    }

    const hide = () => {
        setAsideFilter(false)
    }

    const keepOverlay:any = (e:any) => {
        e.stopPropagation();
    }


    useEffect(() => {

        const initGame = () => {
            let game:GameInterface | undefined

            const randomIndex:number = Math.floor(Math.random() * (gamesList.length - 1));

            if (gamesList[randomIndex]) game = gamesList[randomIndex];

            if (game) setGame(game);
        };

        initGame()


    }, [active])


    useEffect(() => {
        setTimeout(() => {
            const overlay:any = document.getElementById('search-overlay');
            const navbar = document.getElementById('navbar');
            const navHeight = navbar?.offsetHeight || 0;

            if (navHeight > 0 && overlay !== null) {
                overlay.style.top = navHeight+'px';
            }

            prevActive = active;
        }, (active && !prevActive) ? 400 : 0);

        if (!active) document.body.style.overflow = '';

    }, [active, openFilters, value, activeProviders]);



    return  (
        <>
            <div style={{transition: `${isTransition && 'all 0.3s ease'}`, transitionDelay: `${isTransition && '0.4s'}`, opacity: `${!isTransition ? '0' : '1'}`}} className={clsx(styles.search_bar, active && styles.active)}>
                <div className={clsx(styles.search_bar__inner)}>
                    
                    <div className={clsx(styles.sides, styles.left)}>
                        <button type={'button'} className={styles.search_bar__back}
                                onClick={() => dispatch(showSearchBar(false))}>
                            <ArrowLeft/>
                        </button>
                        
                        <input
                            autoComplete={'off'}
                            type="text"
                            name={'search'}
                            id={'nav-search-input'}
                            className={styles.search_bar__input}
                            placeholder={`Caută în ${gamesList.length} de jocuri`}
                            onChange={(e: any) => dispatch(updateSearchValue(e.target.value))}
                        />
                    </div>
                    
                    
                    <div className={clsx(styles.sides, styles.right)}>
                        {game &&
                            <button className={styles.outlinedButton} onClick={(e: any) => auth.runGame(game, false, () => {
                            }, e)}> {'i\'m feeling lucky'}</button>
                        }
                        
                        <div className={styles.search_bar__filterIcon} onClick={showFilter}>
                            <Filter/>
                            {/*{(openFilters && active && activeProviders.length > 0) && <span className={styles.search_bar__selectedProvider}>{activeProviders.length}</span>}*/}
                            {(activeProviders.length > 0) && <span className={styles.search_bar__selectedProvider}>{activeProviders.length}</span>}
                        </div>
                    </div>
                    
                    <OutlinedSelector
                        key={'search-filter-toggle-'+active.toString()}
                        labelIcon={<Filter/>}
                        options={[{key: '', label: 'Filtre'}]}
                        className={styles.search_filter_desktop}
                        disableClickAwayListener
                        onOpen={() => {
                            setOpenFilters(true);
                        }}
                        onClose={() => {
                            setOpenFilters(false);
                        }}
                    />

                    <OutlinedSelector
                        label={'Sortează'}
                        defaultValue={searchSort}
                        className={styles.search_filter_desktop_az}
                        onChange={(value) => {
                            dispatch(applySearchSort(value));
                        }}
                        options={[
                            {key: 'a-z', label: 'Alfabetic A-Z'},
                            {key: 'z-a', label: 'Alfabetic Z-A'},
                        ]}
                    />
                </div>
            </div>

            {(openFilters && active) &&

                <div className={styles.search_bar__furnizorBlock}>
                    {providers.length > 0 &&
                        <>
                            <h6 className={styles.search_bar__title}>Furnizori</h6>
                            <div className={styles.search_bar__providers}>
                                {providers.map((provider, i:number) => {
                                    return (
                                        <div
                                            key={`provider-${i}`}
                                            className={clsx(styles.search_bar__providers__item, activeProviders.includes(provider['category_id']) && styles.active)}
                                            onClick={() => selectProviders(provider['category_id'])}
                                        >
                                            <img loading="lazy" src={provider['thumbnail_src']} alt={provider['provider_name']}/>
                                        </div>
                                    )
                                })}
                            </div>
                        </>
                    }
                </div>
            }

            {(openFilters && active && activeProviders.length > 0) &&
                <div className={clsx(styles.search_bar__selectedBlock, )}>
                    <span className={styles.search_bar__selectedGames}>{activeProviders.length} selectate</span>

                    <button onClick={reset} className={clsx('btn__primary', styles.search_bar__reset)}>{strings['reseteaza']}</button>
                </div>
            }

            <div className={clsx(styles.searchOverlay, asideFilter && styles.active)} onClick={hide}>
                <div className={clsx(styles.search__sideBar, asideFilter && styles.active)} onClick={keepOverlay}>

                    <div  className={styles.search__sideBar__filterText}>
                        <p className={styles.search__sideBar__text}>Filtrează</p>
                        <span className={styles.search__sideBar__textIcon} style={{cursor: 'pointer'}} onClick={hideFilter}>
                       <img style={{maxWidth: '100%', height: 'auto'}} width={16} height={16} src={'/default/icons/closeIcon.svg'} alt="icon"/>
                     </span>
                    </div>

                    <OutlinedSelector
                        label={'Sortează'}
                        style
                        defaultValue={searchSort}
                        onChange={(value) => {
                            dispatch(applySearchSort(value));
                        }}
                        options={[
                            {key: 'a-z', label: 'Alfabetic A-Z'},
                            {key: 'z-a', label: 'Alfabetic Z-A'},
                        ]}
                    />

                    <div className={styles.search_bar__filterWrapper}>
                        <div className={styles.search_bar__filterWrapper__btn}>
                            <div><span style={{color: '#EEB665'}}><Filter/></span> <span>Filtre</span></div>
                            {activeProviders.length > 0 && <button onClick={reset}
                                                                   className={clsx('btn__primary', styles.search_bar__reset)}>{strings['reseteaza']}</button>}
                        </div>
                    </div>

                    {providers.length > 0 &&
                        <>
                            <div className={clsx(styles.search_bar__providers, 'row')}>
                                {providers.map((provider, i:number) => {
                                    return (
                                        <div
                                            key={`provider-${i}`}
                                            className={clsx(styles.search_bar__providers__item, 'col-4',  activeProviders.includes(provider['category_id']) && styles.active)}
                                            onClick={() => selectProviders(provider['category_id'])}
                                        >
                                            <img loading="lazy" src={provider['thumbnail_src']} alt={provider['provider_name']}/>
                                        </div>
                                    )
                                })}
                            </div>
                        </>
                    }
                </div>
            </div>
        </>
    )
}

export default SearchBar