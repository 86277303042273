import clsx from 'clsx';

function InstagramIcon() {
    return (
//       <svg className={clsx('svg-icon', 'svg-iconfacebook' )} width={25} height={19} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
//     viewBox="0 0 490 490">
//
//         <path d="M245,135c-60.654,0-110,49.346-110,110s49.346,110,110,110s110-49.346,110-110S305.654,135,245,135z M245,335
//     c-49.626,0-90-40.374-90-90c0-49.626,40.374-90,90-90c49.626,0,90,40.374,90,90C335,294.626,294.626,335,245,335z"/>
//     <path d="M355,90c-19.299,0-35,15.701-35,35s15.701,35,35,35s35-15.701,35-35S374.299,90,355,90z M355,140
//     c-8.272,0-15-6.729-15-15s6.728-15,15-15c8.272,0,15,6.729,15,15S363.272,140,355,140z"/>
//     <path d="M245,175c-38.598,0-70,31.402-70,70c0,38.598,31.402,70,70,70c38.598,0,70-31.402,70-70C315,206.402,283.598,175,245,175
//     z M245,295c-27.57,0-50-22.43-50-50s22.43-50,50-50s50,22.43,50,50S272.57,295,245,295z"/>
//     <path d="M340,45H150C94.86,45,50,89.86,50,145v195c0,55.14,44.86,100,100,100h190c55.141,0,100-44.86,100-100V145
//     C440,89.86,395.141,45,340,45z M420,340c0,44.112-35.888,80-80,80H150c-44.112,0-80-35.888-80-80V145c0-44.112,35.888-80,80-80
//     h190c44.112,0,80,35.888,80,80V340z"/>
//     <path d="M390,0H100C44.86,0,0,44.86,0,100v290c0,55.14,44.86,100,100,100h290c55.141,0,100-44.86,100-100V100
//     C490,44.86,445.141,0,390,0z M470,390c0,44.112-35.888,80-80,80H100c-44.112,0-80-35.888-80-80V100c0-44.112,35.888-80,80-80h290
//     c44.112,0,80,35.888,80,80V390z"/>
//
// </svg>

        <svg className={clsx('svg-icon', 'svg-iconfacebook')} width={24} height={24} version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <path d="M12.0007 8.75005C10.2057 8.75005 8.75067 10.2051 8.75067 12C8.75067 13.795 10.2057 15.25 12.0007 15.25C13.7956 15.25 15.2507 13.795 15.2507 12C15.2507 10.2051 13.7956 8.75005 12.0007 8.75005Z" fill="#8F84A4"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M6.77021 3.08151C10.2183 2.69614 13.783 2.69614 17.2312 3.08151C19.1296 3.29369 20.6607 4.78947 20.8836 6.69452C21.2958 10.2195 21.2958 13.7806 20.8836 17.3056C20.6607 19.2106 19.1296 20.7064 17.2312 20.9186C13.783 21.304 10.2183 21.304 6.77021 20.9186C4.87181 20.7064 3.34062 19.2106 3.1178 17.3056C2.70552 13.7806 2.70552 10.2195 3.1178 6.69452C3.34062 4.78947 4.87181 3.29369 6.77021 3.08151ZM17.0007 6.00005C16.4484 6.00005 16.0007 6.44776 16.0007 7.00005C16.0007 7.55233 16.4484 8.00005 17.0007 8.00005C17.553 8.00005 18.0007 7.55233 18.0007 7.00005C18.0007 6.44776 17.553 6.00005 17.0007 6.00005ZM7.25067 12C7.25067 9.37669 9.37732 7.25005 12.0007 7.25005C14.624 7.25005 16.7507 9.37669 16.7507 12C16.7507 14.6234 14.624 16.75 12.0007 16.75C9.37732 16.75 7.25067 14.6234 7.25067 12Z" fill="#8F84A4"/>
    </svg>

)
}
export default InstagramIcon;