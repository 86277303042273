import styles from 'themes/default/styles/card.module.scss';
import clsx from 'clsx';
import CloseIcon from "themes/default/svg/closeicon";
import React, {useState, useEffect} from 'react';
import GameInterface from "interfaces/game";
import strings from "utils/strings";
import useAuth from "hooks/auth";
import {openMiniGameModal, openFullGameModal} from "store/modals/actions";
import { useRouter } from 'next/router';
import {useDispatch, useSelector} from 'react-redux';
import { Swiper, SwiperSlide } from 'swiper/react';
import FavoriteButton from "./favoriteButton";
import Game from  'utils/game';


const PopUpFull= () => {
    const [jackpot, setJackpot] = useState<Array<any>>([]);

    const auth = useAuth();
    const dispatch = useDispatch();
    const fullList:Array<GameInterface> =  Game.getAll();

    const game:GameInterface | undefined = useSelector((state:any) => state.modals.openFullGame);

    const hide:any = () => {
        dispatch(openFullGameModal(undefined))
    }

    const keepOverlay:any = (e:any) => {
        e.stopPropagation();
    }

    const jackpotEgt = async() => {
        if (game !== undefined) {
            const data:any = await Game.getJackpot(game)

            setJackpot(data)
        }
    }

    const router = useRouter();

    const [isTransition, setIsTransition] = useState<boolean>(false)

    useEffect(() => {
        setTimeout(() => {
            setIsTransition(true)
        }, 700)
    }, [])

    useEffect(() => {
        dispatch(openFullGameModal(undefined))
    }, [router.pathname])


    useEffect(() => {
        if(game){
            const root =  document.getElementsByTagName('html')[0];

            root.classList.add('bodyScrollHide');

        } else if(!game) {
            const root =  document.getElementsByTagName('html')[0];
            root.classList.remove('bodyScrollHide')
        }

    }, [game]);

    const recommendedGame = ():Array<GameInterface> => {
        const games:Array<GameInterface> = [];

        const idCategorie:string = '9d1c8035-4da3-4cce-991b-e0c643b3ea6e';

        fullList.map((item) => {
           const id = item.categories.find(i => i === idCategorie)

            if (id) {
                games.push(item)
            }
        })

        return games.length > 10 ? games.slice(0, 10) : games;

    }

    useEffect(() => {
        // jackpotEgt()
    }, [game]);

    return(
        <div style={{transition: `${isTransition && 'all 0.3s ease'}`}} className={clsx(styles.overlay,  game && styles.active)} onClick={hide}>
            <div style={{transition: `${isTransition && 'all 0.3s ease'}`}} className={clsx(styles.popup, styles.popup__fullWrapper,  Boolean(game) && styles.active)} onClick={keepOverlay}>
                <div className={styles.popup__closeicon} onClick={() => dispatch(openFullGameModal(undefined))}>
                    <div className={styles.popup__closeLine}></div>
                    <CloseIcon />
                </div>
                <div>
                    <div style={{maxWidth: 112, margin:'auto'}}><img src={game?.thumbnail} alt={game?.friendlyName}/></div>
                    <div className={styles.popup__providerImage}><img src={game?.providerImage} alt=""/></div>
                </div>
                <div className={styles.popup__btnFavorite}>
                    <div className={clsx(styles.popup__fullBlock, styles.popup__fullBlockWrapper)}>
                        { auth.isLoggedIn()  && (game !== undefined &&  <FavoriteButton game={game} styledIcon />)}
                        <div className={styles.popup__fullGameName}>{game?.friendlyName}</div>
                        {/*{(typeof game?.rtp === "string" && Number(game.rtp) > 0 && !game?.hotRTP && !game?.coldRTP) &&*/}
                        {/*    <div className={styles.card__subtitle}>RTP {(Number(game?.rtp) * 100).toFixed(2)}%</div>*/}
                        {/*}*/}
                    </div>
                    <button
                        onClick={game ? () => auth.runGame(game, false) : () => {}}
                        className={clsx("btn__primary", styles.popup__btnSeeMore)}
                    >
                            {strings['joaca_acum']}
                    </button>
                    <div style={{display:'flex', justifyContent: 'center'}}  onClick={game ? () => auth.runGame(game, true) : () => {}}>
                        <button
                                className={styles.popup__demoBtn}
                                type={'button'}>Demo
                                <img className={styles.popup__demoBtnArrow}
                                     src="/default/icons/menuArrow.svg" alt="img"/>
                        </button>
                    </div>
                </div>
                {jackpot?.length > 0 && <div className={styles.popup__fullCategories}>
                    {jackpot?.map((item: any, i: number) => {
                        return (
                            <div className={styles.popup__wrapperPlatinum} key={'jackpot ' + i}>
                                <div>
                                    <img className={styles.popup__iconFullBlock} src={item.image} alt="img"/>
                                    <span>{item.label}</span>
                                </div>
                                <div className={styles.popup__currency}>
                                    <span className={styles.popup__prize}>{item.prize}</span>
                                    <span>{item.currency}</span>
                                </div>
                            </div>
                        )
                    })}
                </div>}

                {recommendedGame().length > 0 &&
                    <>
                        <h5 className={styles.popup__recommendedGame__BlockTitle}>{strings['jocuri_recomandate']}</h5>
                        <div className={styles.popup__recommendedGame__block}>
                            <Swiper
                                pagination={{
                                    clickable: true,
                                }}
                                slidesPerView='auto'
                            >
                                <div style={{display:'flex'}}>
                                    {recommendedGame().map((item) => {
                                        return (

                                            <SwiperSlide  key={'recomendedgameID-'+item.gameId} className={styles.popup__recommendedGame}>
                                                <img onClick={() =>{
                                                    dispatch(openMiniGameModal(item));
                                                    dispatch(openFullGameModal(undefined))}
                                                } src={item.thumbnail} alt={item.friendlyName}/>
                                            </SwiperSlide>
                                        )
                                    })}
                                </div>
                            </Swiper>
                        </div>
                    </>
                }
            </div>
        </div>
    )
}
export default PopUpFull;