import styles from 'themes/default/styles/accountMenu.module.scss'
import strings from "utils/strings";
import Submenu from "themes/default/ui/sideBarAccountDesktop/submenu";
import Link from 'next/link';
import clsx from 'clsx'
import useAuth from "hooks/auth";
import {useDispatch, useSelector} from 'react-redux';
import {openDeposit} from 'store/modals/actions';
import a from 'themes/default/styles/Header.module.scss';
import  { useRouter } from 'next/router'
import {profileSubmenu, limitsSubMenu, transactionSubmenu, supportSubmenu} from 'utils/constants'
import React from "react";
import SmarticoClass from "@/AbsoluteComponents/utils/smartico_class";


const DefaultAccountSideBarVIew = () => {

    const auth = useAuth();
    const user = auth.getUser();
    const router = useRouter();
    const currentRoute = router.pathname;
    const dispatch = useDispatch();
    const smartico_props = useSelector((state:any) => state.account.smarticoProps);

    return (
        <div >
            {user &&
                <div className={a.sidebar__btn}>

                    <div className={a.sidebar__btnWrap}>
                        <button className={clsx('btn__connection', 'link-connection')} onClick={() => auth.openWithdrawPopup()}>{strings['retragere']}</button>
                        <button className={'btn__primary'} type={'button'} onClick={() => dispatch(openDeposit(true))}>{strings['depunere']}</button>
                    </div>

                    <div className={a.sidebar__userBlock} style={{paddingTop: 16}}>
                        <div  className={a.sidebar__user}>
                            
                            {(typeof smartico_props === "object" && Object.keys(smartico_props).length) ? (
                                <img onClick={()=>{SmarticoClass.changeAvatar()}} src={smartico_props?.avatar_id} alt={""} width={100} height={100} />
                            ) : (
                                <div className={a.sidebar__userName}>
                                    {user?.firstName[0]} {user?.lastName[0]}
                                </div>
                            )}
                            
                        </div>
                        
                        <div className={a.sidebar__userData}>
                            <div className={a.sidebar__userFullName}>{user?.firstName} {user?.lastName}</div>
                            <div className={a.sidebar__balanceBlock}>
                                <div  className={a.sidebar__balance}>{strings['balanta']}</div>
                                <div className={a.sidebar__balanceItem}>{ auth.getUserBalance()} <span className={styles.sidebar__currence}>{auth.getCurrency()}</span></div>
                            </div>
                        </div>
                    </div>

                    <div  className={clsx( styles.sidebar__btnAside, styles.btnBlock)}>
                        <button className={clsx('btn__connection', 'link-connection')} onClick={() => auth.openWithdrawPopup()}>{strings['retragere']}</button>
                        <button className={'btn__primary'} type={'button'} onClick={() => dispatch(openDeposit(true))}>{strings['depunere']}</button>
                    </div>

                </div>
            }

            <Submenu
                active={profileSubmenu.findIndex(item => item.route === currentRoute) > -1}
                title={strings['cont']}
            >

                <ul className={styles.accountMenu__info }>
                    {profileSubmenu.map((item:any, i:number) => {

                        return (
                            <li  key={`profileSubmenu-${i}`}>
                                <Link   className={clsx(currentRoute === item.route && styles.active)} href={item.route}>

                                    <img src={item.icon} alt={item.text}/>

                                    <span className={styles.accountMenu__text}>
                                        {item.text}
                                    </span>
                                </Link>
                            </li>
                        )
                    })}
                </ul>

            </Submenu>


            <Submenu
                active={transactionSubmenu.findIndex(item => item.route === currentRoute) > -1}
                title = {strings['istoric']}
            >

                <ul  className={styles.accountMenu__info}>
                    {transactionSubmenu.map((item:any, i:number) => {
                        return <li key={`transactionSubmenu-${i}`}><Link   className={clsx(currentRoute === item.route && styles.active)}
                            href={item.route}><img src={item.icon} alt="img"/> <span className={styles.accountMenu__text}>{item.text}</span></Link></li>
                    })}
                </ul>

            </Submenu>


            <Submenu
                active={limitsSubMenu.findIndex(item => item.route === currentRoute) > -1}
                title={strings['setari']}
            >

                <ul  className={styles.accountMenu__info }>
                    {limitsSubMenu.map((item:any, i:number) => {
                        return <li key={`limitsSubMenu-${i}`}><Link   className={clsx(currentRoute === item.route && styles.active)}
                             href={item.route}><img src={item.icon} alt="img"/><span  className={styles.accountMenu__text}>{item.text}</span></Link></li>
                    })}
                </ul>

            </Submenu>

            <Submenu
                active={supportSubmenu.findIndex(item => item.route === currentRoute) > -1}
                title={strings['support']}
            >

                <ul className={styles.accountMenu__info }>
                    {supportSubmenu.map((item:any, i:number) => {
                        return <li key={`supportSubMenu-${i}`}>
                            <Link    className={clsx(currentRoute === item.route && styles.active)}  href={item.route}>
                                <img src={item.icon} alt=""/>
                                <span  className={styles.accountMenu__text}>{item.text}</span>
                            </Link>
                        </li>
                    })}
                </ul>

            </Submenu>

            <div className={a.sidebar__socialBlock} style={{paddingLeft: 40, marginTop: 50}}>

            </div>

        </div>
    )
}

export default DefaultAccountSideBarVIew;