const TicketIcon = () => {
    return (
        <svg width="32" className={"svg-icon"} height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M15.017 3.14783L14.0814 2.60767C13.6031 2.33153 12.9915 2.4954 12.7154 2.97369L4.7154 16.8301L6.61864 17.9289L6.12462 18.7984L4.2154 17.6961L3.34937 17.1961L3.84937 16.3301L11.8494 2.47369C12.4017 1.51711 13.6248 1.18936 14.5814 1.74164L15.511 2.27835L15.017 3.14783ZM5.13658 20.5374L3.2154 19.4282L2.34937 18.9282L1.84937 19.7942L0.849369 21.5263C0.297085 22.4828 0.624834 23.706 1.58142 24.2583L3.31347 25.2583L4.1795 25.7583C4.44498 25.2985 4.81382 24.9355 5.23972 24.6818C5.05761 24.3973 4.91351 24.0925 4.80922 23.7754C4.48712 23.957 4.18796 24.1846 3.9226 24.4553L2.08142 23.3923C1.60313 23.1161 1.43925 22.5045 1.71539 22.0263L2.7154 20.2942L4.74949 21.4686C4.83587 21.1532 4.9624 20.8439 5.13065 20.5478L5.13658 20.5374Z"
                  fill="#EEB665"/>
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M30.2679 7.7417C31.2245 8.29398 31.5523 9.51716 31 10.4738L22.5 25.1962L8.64359 17.1962L17.1436 2.47375C17.6959 1.51716 18.9191 1.18941 19.8756 1.7417L22.4737 3.2417C21.6453 4.67658 22.1369 6.51135 23.5718 7.33978C25.0067 8.1682 26.8414 7.67658 27.6699 6.2417L30.2679 7.7417ZM6.87564 24.2584C5.91906 23.7061 5.59131 22.4829 6.14359 21.5263L7.64359 18.9282L21.5 26.9282L20 29.5263C19.4477 30.4829 18.2245 30.8106 17.2679 30.2584L14.6699 28.7584C15.4983 27.3235 15.0067 25.4887 13.5718 24.6603C12.1369 23.8319 10.3021 24.3235 9.47372 25.7584L6.87564 24.2584Z"
                  fill="#EEB665"/>
        </svg>
    )
}

export default TicketIcon