import styles from '../../styles/footer.module.scss';
import Builder from "../../cms-views/builder";
import {useState, FC, useEffect} from 'react';
import CMS from "utils/cms";
import clsx from 'clsx';
import strings from "utils/strings";
import { useRouter } from 'next/router';
import { useSelector} from 'react-redux';


const Footer:FC = ({ ... footerProps}) => {

    const router = useRouter();
    const [openFooter, setOpenFooter] = useState<boolean>(false)
    const [openBtnVisible, setOpenBtnVisible] = useState<boolean>(false)
    const isMobile:boolean = JSON.parse(JSON.stringify(useSelector((state:any) => state.common.isMobile)));

    let isSportPage:boolean = useSelector((state:any) => state.common.isSportPage)

    useEffect(() => {
        setTimeout(() => {
            setOpenBtnVisible(true)
        }, 1000)
    }, [])


    return (

        <div id={'footer'}
             className={clsx(
                styles.footer,
                (isSportPage && !isMobile) && styles.sportFooter,
                openFooter && styles.open,
                (isSportPage && isMobile) && styles.mobileSportFooter,
            )
        }>

            {(isSportPage && !isMobile && openBtnVisible) && (
                <button
                    onClick={() => {
                        setOpenFooter(!openFooter)
                    }}
                    className={styles.toogleFooter}
                >
                    {openFooter ? (
                        <>
                            {strings['close_footer']}
                        </>
                    ) : (
                        <>
                            {strings['open_footer']}
                        </>
                    )}

                </button>
            )}

            <Builder blocks={CMS.getCopyrightBlocks()} api_id={CMS.API_ID_COPYRIGHT}/>

        </div>

    )
}
export default Footer;