import {FC} from "react";
import useAuth from 'hooks/auth';
import styles from 'themes/default/styles/card.module.scss';
import GameInterface from "interfaces/game";
import LazyImage from "ui/LazyImage";
import clsx from 'clsx';
import PlayIcon from 'themes/default/svg/play';
import {useDispatch, useSelector} from 'react-redux';
import FavoriteButton from "./favoriteButton";
import {useState, useEffect} from 'react';
import {openMiniGameModal} from "store/modals/actions";

interface Props {
    game_thumbnail?: string;
    width?: number;
    game?: GameInterface;
    homeSliderItem?: boolean,
    minimize?: boolean,
    hideDetails?: boolean,
    disableLazyLoading?: boolean,
    disableDemo?: boolean,
    json?:any,
    onCardClick?(game: any, gameUrl: string):void,
    page?: string
    id?:string
}

const Card: FC<Props> = ({game_thumbnail, width, game, json, homeSliderItem, minimize, hideDetails, disableLazyLoading, disableDemo, onCardClick, page, id}) => {
    const dispatch = useDispatch();
    const auth = useAuth();
    const showSearchBar:boolean = useSelector((state:any) => state.common.showSearchBar);
    const [imageLoaded, setImageLoaded] = useState<boolean>(false);

    let imageGame:string = game_thumbnail || '';

    if (game !== undefined) {
        imageGame = game.thumbnail;
    }

    const loadImage = (src:string) => {
        return new Promise((resolve, reject) => {
            const img = document.createElement('img');
            img.src = src;

            img.onload = () => {
                resolve(true);
            }

            img.onerror = () => {
                resolve(false);
            }
        })
    }

    useEffect(() => {
        const loadImages = async () => {

            let imageArr:Array<string> = [imageGame]
            let arr = []

            await loadImage(imageArr[0])
            arr.push(imageArr[0])
            // console.log(imageArr[0])
            if(arr.length === 1) {
                setImageLoaded(true)
            }
        }

        loadImages()

    }, [])


    const openGamePopup = (e:any) => {
        e.stopPropagation();
        if (game) dispatch(openMiniGameModal(game));
    }

    const clickHandle = (url:string) => {
        if (showSearchBar && typeof onCardClick === "function") onCardClick(game, url);
    }
    return (
        <div
            id={id}
            style={{maxWidth: `${ page === 'live-casino'? 'unset' : width}` || undefined}}
            className={
                clsx(
                    styles.card,
                    homeSliderItem && styles.card__home_slider_item,
                    minimize && styles.card__minimize,
                    (homeSliderItem && minimize) && styles.card__min_right,
                    (homeSliderItem && !minimize) && styles.card__regular_right,
                    page === 'live-casino' && styles.casinoCard
                    )
                }

        >

            {!disableLazyLoading ? (
                <LazyImage src={imageGame} alt={game?.friendlyName || ''} />
            ) : (
                <img loading="lazy" src={imageGame} alt={game?.friendlyName || ''} />
            )}


            <div className={'cardBlock'} onClick={openGamePopup} />

            <div className={clsx(styles.card__inner, hideDetails && styles.hideDetailsInner)}>
                {game &&
                    <button
                        type={'button'}
                        className={styles.card__play}
                        onClick={(e:any) => auth.runGame(game, false, clickHandle, e)}
                    >
                        <PlayIcon/>
                    </button>
                }

                <h5 className={styles.card__title}>{game?.friendlyName}</h5>

                <div className={clsx(hideDetails && styles.hideDetails)}>

                    {/*{(typeof game?.rtp === "string" && Number(game.rtp) > 0 && !game?.hotRTP && !game?.coldRTP) && <div className={styles.card__subtitle}>RTP {(Number(game?.rtp) * 100).toFixed(2)}%</div>}*/}

                    {game?.providerImage && <img loading="lazy" className={styles.card__providerImage} src={game.providerImage} alt={game?.friendlyName}/>}

                </div>

                {(!disableDemo && game?.demoAvailable) && <button onClick={(e:any) => auth.runGame(game, true, clickHandle, e)} className={styles.card__play_demo} type={'button'}>Demo</button>}

            </div>

            {game?.hotRTP && <div className={clsx(styles.card__rtp, styles.card__rtp__hot)}><img width="12" src="/default/icons/fire_icon.svg" alt="Hot"/> {game.rtp}%</div>}
            {game?.coldRTP && <div className={clsx(styles.card__rtp, styles.card__rtp__cold)}><img width="12" src="/default/icons/snowflake_icon.svg" alt="Cold"/> {game.rtp}%</div>}

            {auth.isLoggedIn() && (game?.gameId && <FavoriteButton game={game} />)}


        </div>
    )
}
export default Card;