import React, { useState } from 'react';
import clsx from 'clsx';
import Link from 'next/link';
import { FC } from 'react';
import CMS from 'utils/cms';
import strings from 'utils/strings';
import { Title } from 'components/title';
import Image from './Image';
import CasinoSportsToggler from "@/template/small_ui/casino_sports_toggler";
import styles from 'themes/default/styles/cmsPromotii.module.scss';

interface Props {
    json: any;
    api_id: string;
}

const PromotionItem: FC<{ item: any; api_id: string }> = ({ item, api_id }) => (
    <div key={`image-${item.api_id}`} className={clsx('col-xl-3', 'col-lg-4', 'col-md-6', 'promotionsBlock__col')}>
        <div
            style={{ backgroundImage: `url('/default/images/backgrounds/bgDark3.webp')` }}
            className={clsx('promotionsBlock__imageBlock')}
        >
            {item.mobile_image.length > 0 && <Image src={CMS.getPublicFilePath(item.mobile_image[0].name, api_id, item.mobile_image[0].path)} />}

            <div className={'promotionsBlock__details'}>
                <p className={'promotionsBlock__text'}>{item.title}</p>
                <p className={'promotionsBlock__description'}>{item.excerpt}</p>
            </div>

            <div className={'promotionsBlock__container'}>
                <Link
                    className={clsx('promotionsBlock__link', 'btn__primary', 'promotionsBtn')}
                    href={item.url_extern.length ? item.url_extern : '/promotii/' + item.api_id}
                >
                    {strings['toate_detaliile']}
                </Link>
            </div>
        </div>
    </div>
);

const PromotionsBlock: FC<Props> = ({ json, api_id }) => {
    const data = CMS.getPromotions(json);
    const [offerType, setOfferType] = useState<'casino' | 'sport'>('casino');

    const filteredPromotions = data.all_promotions.filter((prom: any) =>
        offerType === 'sport' ? prom.is_sport : !prom.is_sport
    );

    return (
        <div>
            <div className={clsx('mb-20', styles.header)}>
                <Title homePage forGuest title={strings['promotions']} />

                <div className={styles.switch} >
                    <CasinoSportsToggler
                        setType={(type: 'casino' | 'sport') => {
                            setOfferType(type);
                        }}
                        offerType={offerType}
                    />
                </div>

            </div>

            <div className={clsx('promotionsBlock__items', 'row')}>
                {filteredPromotions.length > 0 &&
                    filteredPromotions.map((item: any) => (
                        <PromotionItem key={item.api_id} item={item} api_id={api_id} />
                    ))}
            </div>
        </div>
    );
};

export default PromotionsBlock;