import Modal from 'react-modal';
import React, {FC, useState} from 'react';
import clsx from 'clsx';
import {useDispatch, useSelector} from 'react-redux';
import {openSetNickName, openShareTIcket} from "store/modals/actions";
import styles from "@/template/styles/SetNickNameModal.module.scss";
import ModalLayout from "@/template/ui/modal_layout";
import TextField from "@/AbsoluteComponents/ui/textfield";
import {editProfile} from "@/AbsoluteComponents/services/account";
import {updateSnackBar} from "@/AbsoluteComponents/store/common/actions";
import {updateUserData} from "@/AbsoluteComponents/store/account/actions";
import useAuth from "@/AbsoluteComponents/hooks/auth";
import UserInterface from "@/AbsoluteComponents/interfaces/user";
import Button from "@/template/small_ui/button";
import NameIcon from "@/template/svg/NameIcon";
import {deleteRedirectTarget, getRedirectTarget} from "@/AbsoluteComponents/utils/cookies";
import {useRouter} from "next/router";
import Game from "@/AbsoluteComponents/utils/game";

interface  Props {

}

const SetNickNameModal: FC<Props> = () => {
    const open = useSelector((state:any) => state.modals.openSetNickName)
    const dispatch = useDispatch()
    const auth = useAuth();
    const user = auth.getUser();
    const logged:boolean = auth.isLoggedIn();
    const data:UserInterface = user || {};
    const [sending, isSending] = useState<boolean>(false);
    const router = useRouter();
    
    const onSubmit = async (e:any) => {
        e.preventDefault();
        
        if (data.nickName && data.nickName.length > 0) return;
        
        const redirectTarget:string = getRedirectTarget();
        
        const form = new FormData(e.target);
        data.nickName = form.get('nickName')?.toString() || '';
        
        if(!data.nickName.length) {
            dispatch(updateSnackBar( 'Vă rugăm să completați câmpul!.' , 'error'));
            return
        }
        
        isSending(true);
        const edit = await editProfile(data);
        isSending(false);


        if(edit.error) {
            dispatch(updateSnackBar( 'Această poreclă există deja.' , 'error'));
        } else {
            dispatch(updateSnackBar( 'Datele au fost salvate.' , 'success'));
            dispatch(updateUserData(data));
            dispatch(openSetNickName(false))
            
            if (redirectTarget.length) {
                setTimeout(() => {
                    router.push(redirectTarget);
                    deleteRedirectTarget();
                }, 300)
            }
        }
        
    }
    
    
    return (
        <Modal
            ariaHideApp={false}
            isOpen={open}
            // isOpen={true}
            className={clsx(styles.modal, 'ReactModal__Deposit')}
            onRequestClose={() => {
                dispatch(openSetNickName(false))
            }}
        >
            
            <ModalLayout title={'Setează porecla'} onClose={() => {dispatch(openSetNickName(false))}} >
                <>
                    <div className="ReactModal__Inner__Header">
                        <h2 className={clsx('ReactModal__Title', styles.title)}>Setează poreclă</h2>
                    </div>
                    
                    <div className={styles.modalContent}>
                        
                        <div className={styles.top}>
                            <div className={styles.img_container}>
                                {/*<img src={"/default/icons/user.svg"} alt={'user'}/>*/}
                                <NameIcon />
                            </div>
                            
                            <strong>Hai să jucăm!</strong>
                            
                            <span>Pentru a continua aveți nevoie de o poreclă</span>
                            
                        </div>
                        
                        <form onSubmit={onSubmit}>
                            <TextField
                                type={'text'}
                                label={'Poreclă'}
                                placeholder={'Poreclă'}
                                className={styles.input}
                                {...{
                                    name: 'nickName',
                                    autoComplete: 'off'
                                }}
                            />
                            
                            <Button
                                loading={sending}
                                text={"setează"}
                                type={"submit"}
                            />
                        </form>
                    </div>
                </>
            </ModalLayout>
        
        </Modal>
    )
}

export default SetNickNameModal