import React, {useState, useEffect} from 'react'
import Skeleton from '@mui/material/Skeleton'

interface Props {
    src: any
}

const Image: React.FC<Props> = ({src}) => {

    const [imageLoaded, setImageLoaded] = useState<boolean>(false)

    const loadImage = (src:string) => {
        return new Promise((resolve, reject) => {
            const img = document.createElement('img');
            img.src = src;

            img.onload = () => {
                resolve(true);
            }

            img.onerror = () => {
                resolve(false);
            }
        })
    }

    useEffect(() => {

        const loadImages = async () => {
            let imageArr:Array<string> = [src]
            let arr = []

            await loadImage(imageArr[0])

            arr.push(imageArr[0])

            if(arr.length === 1) {
                setImageLoaded(true)
            }
        }

        loadImages()

    }, [])

    return (
        <div className={'promotionsBlock__imageWrapper'}>
            {imageLoaded ?
                <img src={src} alt="img"/>
                :
                <Skeleton
                    style={{width: '100%', display: 'block', borderRadius: '0', background: '#322d3d'}}
                    animation="wave"
                    variant="rectangular"
                    className={'themeCategorySkeleton'}
                />
            }
        </div>
    )
}

export default Image