import {FC} from 'react';
import CMS from "utils/cms";
import styles from 'themes/default/styles/bannerGoToAction.module.scss';
import strings from "utils/strings";
import NavLink from 'next/link';
import clsx from 'clsx';

interface Props {
    json:any;
    api_id:string
}

const BannerGoToAction:FC<Props> = ({json,api_id}) => {

    const data = CMS.jsonBannerGoToAction(json);
    const {desktop_image, image,  title, live_rtp} = data;


    if (desktop_image.length > 0) {
        return (
            <div className={styles.root}>
                <div className={styles.root__blockImage}>
                    <h5 className={clsx(styles.root__info__title, styles.mobileTitle)}>{title}</h5>
                    <div className={styles.root__liveImageBlock}>
                        <img className={styles.desctopImg} src={CMS.getPublicFilePath(desktop_image[0]['name'], api_id, desktop_image[0]['path'])} alt={title}/>
                        {/*<img  className={styles.mobileImg} src={process.env.NEXT_PUBLIC_CMS_API +image[0].path} alt={title}/>*/}
                        <img  className={styles.mobileImg} src={CMS.getPublicFilePath(image[0]['name'], api_id, image[0]['path'])} alt={title}/>
                    </div>
                    <div className={clsx(styles.root__infoMob,  styles.root__backColor)}>
                        {live_rtp && <NavLink href="/live-rtp" title={title} className={clsx("btn__primary", styles.root__gameLive,)}>{strings['veiw_more']}</NavLink>}
                    </div>
                </div>




                <div className={styles.root__info} style={{width: 'auto'}}>
                    <h5 className={styles.root__info__title}>{title}</h5>
                    {live_rtp && <NavLink href="/live-rtp" title={title} className="btn__primary">{strings['veiw_more']}</NavLink>}
                </div>

            </div>
        );
    }

    return null;
}

export default BannerGoToAction