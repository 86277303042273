 import React, {useLayoutEffect, useEffect} from "react";
import CMS from "@/AbsoluteComponents/utils/cms";
import styles from "themes/default/styles/VIPQuest.module.scss"
import Hero from "themes/default/cms-views/vipQuest/elems/hero";
import Slider from "themes/default/cms-views/vipQuest/elems/slider";
import Terms from "themes/default/cms-views/vipQuest/elems/terms";
 import QuestPrizes from "themes/default/cms-views/vipQuest/elems/prizes";


interface Props {
    json: any
    api_id: any
}

const VipQuest:React.FC<Props> = ({json, api_id}) => {
    
    const data = CMS.getAllFields(json)
    
    return (
        <div className={styles.main}>
            
            <Hero data={data} api_id={api_id}/>
            
            <QuestPrizes data={data} api_id={api_id} />
            
            <Slider data={data} api_id={api_id}/>
            
            <Terms data={data} api_id={api_id}/>
            
        </div>
        
    )
}

export default VipQuest