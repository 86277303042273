import {useRouter} from 'next/router';
import SidebarMenuViewInterface from "interfaces/ui-view-interfaces/sidebar_menu_view_interface";
import SideBarMenuView from "@/template/ui/sidebarMenu";

const SideBarMenu = ( ) =>  {

    const router = useRouter();
    const currentRoute = router.asPath;

    const sidebarProps:SidebarMenuViewInterface = {
        currentRoute
    }

    return <SideBarMenuView { ... sidebarProps } />
}

export default SideBarMenu;