import React from "react";
import styles from "themes/default/styles/LuckyTicket.module.scss"

interface Props {
    data:any
    api_id:string
}

const LuckyTicketPrizes:React.FC<Props> = ({data}) => {
    
    const {prizes_section_title, repeater} = data
    
    const ordered_prizes = repeater?.sort((a:any, b:any) => Number(a.order) - Number(b.order))
    
    return (
        <div id={"lucky_ticket_prizes"} className={styles.prizes}>
            <div className={styles.container}>
                
                <h4>
                    {prizes_section_title}
                </h4>
                
                <div className={styles.grid_container}>
                    <div className={styles.grid}>
                        {ordered_prizes.length && ordered_prizes.map((pr:any, i:number) => {
                            return (
                                <div
                                    className={styles.grid_item}
                                    key={"prize"+i}
                                >
                                    {pr.value}
                                </div>
                            )
                        })}
                    </div>
                </div>
                
            </div>
        </div>
    )
}

export default LuckyTicketPrizes