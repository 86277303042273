import React, {FC, useState} from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from '../../styles/TopGamesSlider.module.scss';
import Card from 'themes/default/ui/game/card';
import clsx from 'clsx';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import "swiper/css";
import "swiper/css/pagination";
import { Navigation } from "swiper";
import { Pagination } from "swiper";
import PaginationArrow from "ui/paginationArrow";
import CMS from "utils/cms";
import GameInterface from "interfaces/game";
import Game from "utils/game";
import TopGamesNumber from "themes/default/svg/TopGamesNumber";
import {lazy_loading_offset} from "@/AbsoluteComponents/utils/constants";
import LazyComponentWrap from "components/lazy_component_wrap";

interface Props {
    json: Array<any>;
    index?:any
}

const TopGamesSlider:FC<Props> = (props) => {
    
    const {index} = props
    
    if (index >= lazy_loading_offset) {
        return (
            <LazyComponentWrap>
                <Core { ... props}/>
            </LazyComponentWrap>
        )
    } else {
        return <Core { ... props}/>
    }
}

export default TopGamesSlider

const Core: FC<Props> = ({ json }) => {
    const [swiper, setSwiper] = useState<any>();
    const [hover, setHover] = useState<any>(null);
    const games = Game.getAll();
    const data = CMS.jsonTopGameSlider(json);

    const getGameInfo = (gameId:string):GameInterface | undefined => {
        return games?.find((item:any) => item.gameId === gameId);
    };

    return (
        <div  data-section="top-games-slider" data-section-title='Top 10 Păcănele' className={clsx(styles.topGamesSlider, 'slideClass', 'theme-slider')}>
            <div className={styles.topGamesSlider__box}>
                <div className={'gamesSlider__title'}>
                    <div className={styles.topGamesSlider__title}>{data.title}</div>
                </div>
            </div>

            <Swiper pagination={{
                clickable: true,
            }}
                    modules={[Pagination, Navigation]}
                    className={clsx(styles.themes__mySwiper, 'swiper-wrapper','swiper-style')}
                    spaceBetween={0}
                    slidesPerView='auto'
                    onSwiper={(sw) => setSwiper(sw)}
            >
                <PaginationArrow onClick={() => swiper.slideNext()}/>
                <PaginationArrow onClick={() => swiper.slidePrev()} rightArrow />

                {data.games.map((item:any, i:number) => {
                    const game = getGameInfo(item.game_id);

                    if (game) {
                        return (
                            <SwiperSlide key={`top_games-${i}`} className={styles.topGamesSlider__swiper_item} style={{width: 400, zIndex: data.games.length - i}}>
                                <div className={clsx(styles.topGamesSlider__block, hover === i && styles.hover)} >
                                    <TopGamesNumber className={clsx(styles.topGamesSlider__index, i + 1 === 10 && styles.last)} number={i + 1} />

                                    <div className={styles.topGamesSlider__card} onMouseEnter={() => setHover(i)} onMouseLeave={() => setHover(null)}>
                                        <Card page={'live-casino'} width={228} game={game}/>
                                    </div>
                                </div>

                            </SwiperSlide>
                        )
                    }

                })}
            </Swiper>

        </div>
    )
}

