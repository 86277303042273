import Modal from 'react-modal';
import clsx from 'clsx';
import {useState, useEffect, useRef} from 'react';
import Link from 'next/link';
import useAuth from "@/AbsoluteComponents/hooks/auth";
import {getAuthToken, getRealityCheckEndTime, setRealityCheckEndTime} from "@/AbsoluteComponents/utils/cookies";
import {getRealityCheck, resetRealityCheck} from "@/AbsoluteComponents/services/account";
import Button from "@/template/small_ui/button";
import styles from '@/template/styles/modals.module.scss';


const RealityCheck = () => {
    const [loading, setLoading] = useState(false);
    const [loadingOff, setLoadingOff] = useState(false);
    
    const [open, setOpen] = useState(false);
    const [info, setInfo] = useState({
        bets: 0,
        wins: 0,
        lost: 0,
        interval: 0,
        spendTime: 0,
        currency: 'RON',
    });

    const auth = useAuth();
    const timer = useRef<any>(null);

    const resetHandle = async () => {
        setLoading(true);
        const data = await resetRealityCheck();

        if (!data.error && Number(data.response['endTime'])) {
            setRealityCheckEndTime(Number(data.response['endTime']));
            timer.current = setTimeout(runChecker, 30000);
        }

        setLoading(false);
        setOpen(false);
    };

    const runChecker = async ():Promise<void> => {
        const endTime:number = getRealityCheckEndTime();
        const token:string = getAuthToken();
        const date = new Date();

        if (endTime > 0 && !open && date.getTime() >= endTime && token.length) {
            const data = await getRealityCheck();

            if (data.response?.interval) {
                setInfo((prevInfo:any) => ({
                    ...prevInfo,
                    bets: data.response.bets,
                    wins: data.response.wins,
                    lost: data.response.lost,
                    spendTime: data.response.spendTime,
                    interval: data.response.interval,
                }));

                setOpen(true);
            }
        } else {
            timer.current = setTimeout(runChecker, 30000);
        }
    };

    useEffect(() => {
        runChecker();
    }, []);

    return (

        <Modal
            onAfterOpen={() => {

            }}
            onAfterClose={() => {

            }}
            ariaHideApp={false}
            className={clsx('ReactModal__SM', styles.realityCheck)}
            isOpen={open}
            onRequestClose={() => {}}
            contentLabel="Example Modal"
        >
            <div className={clsx('ReactModal__Inner')}>

                <div className="ReactModal__Inner__Header">
                    <h2 className="ReactModal__Title">
                        Informare
                    </h2>
                </div>

                <div className="ReactModal__Inner__Content">
                    <div>
                        <p>Ești conectat de {info.spendTime} minut(e) pe site.</p>

                        <p>
                            Pentru detalii suplimentare ale tranzacțiilor apasă
                            <Link   href={'/account/transactions/game'}>
                                aici
                            </Link>
                        </p>

                        <table>
                            <tbody>

                                <tr className="table-content">
                                    <td style={{textAlign: 'left', textTransform: 'none'}}>Ai pariat</td>
                                    <td>{info.bets.toFixed(2)} {info.currency}</td>
                                </tr>

                                <tr className="table-content">
                                    <td style={{textAlign: 'left', textTransform: 'none'}}>Ai câștigat</td>
                                    <td>{info.wins.toFixed(2)} {info.currency}</td>
                                </tr>

                                <tr className="table-content">
                                    <td style={{textAlign: 'left', textTransform: 'none'}}>Ai pierdut</td>
                                    <td>{info.lost.toFixed(2)} {info.currency}</td>
                                </tr>

                            </tbody>
                        </table>

                        <div className={styles.reality_check_control}>

                            <Button
                                text={'Ieșire din cont'}
                                outlined
                                loading={loadingOff}
                                disabled={loading}
                                type={'button'}
                                onClick={async () => {
                                    setLoadingOff(true);
                                    await auth.signOut();
                                    setOpen(false);
                                    setLoadingOff(false);
                                }}
                            />

                            <Button
                                text={'Continuare'}
                                disabled={loadingOff}
                                loading={loading}
                                type={'button'}
                                onClick={() => resetHandle()}
                            />

                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default RealityCheck;