import {categoryGamesService} from "services/game";
import GameInterface from "interfaces/game";
import {getFavoritesGames} from 'services/account';
import games from 'public/json/games.json';
import vendors from 'public/json/vendors.json';
import CMS from "@/AbsoluteComponents/utils/cms";
import QuestLeaderboardInterface from "@/AbsoluteComponents/interfaces/quest_leaderboard_interface";


interface CategoryGamesInterface {
    total: number;
    games: Array<GameInterface>,
}

class Game {
    static favoritesGames:Array<GameInterface> = [];
    static vendors:Array<any> = [];
    
    static quetsLeaderboardFactory = (json:any):QuestLeaderboardInterface => {
        return {
            leader: (json["leader"] &&  Object.keys(json["leader"]).length) ? json["leader"] : {},
            leaderBoard: (json["leaderboard"] &&  Object.keys(json["leaderboard"]).length) ? json["leaderboard"] : {},
            maxLevelTop: (json["maxLevelTop"] &&  Object.keys(json["maxLevelTop"]).length) ? json["maxLevelTop"] : {},
            state: (json["state"] &&  Object.keys(json["state"]).length) ? json["state"] : {},
            apiFail: json["apiFail"] ? json["apiFail"] : false,
        }
    }

    static gameInterfaceFactory = (json:any) => {
        const stringToJson = (text:string) => {
            try {
                return JSON.parse(text);
            } catch (e) {
                return []
            }
        }

        return {
            provider: typeof json['provider'] === "string" ? json['provider'] : '',
            vendor: typeof json['vendor'] === "string" ? json['vendor'] : '',
            gameId: typeof json['gameId'] === "string" ? json['gameId'] : '',
            volatility: typeof json['volatility'] === "string" ? json['volatility'] : '',
            name: typeof json['name'] === "string" ? json['name'] : '',
            friendlyName: typeof json['friendlyName'] === "string" ? json['friendlyName'] : '',
            rtp: typeof json['rtp'] === "string" ? json['rtp'] : "0",
            demoAvailable: typeof json['demoAvailable'] === "boolean" ? json['demoAvailable'] : false,
            favorite: typeof json['favorite'] === "boolean" ? json['favorite'] : false,
            gameAssets: typeof json['gameAssets'] === "object" ? json['gameAssets'] : {},
            thumbnail: typeof json['gameAssets'] === "object" ? json['gameAssets']['thumbnail'] : '',
            providerImage: typeof json['gameAssets'] === "object" ? json['gameAssets']['provider'] : '',
            categories: Array.isArray(json['categories']) ? json['categories'] : [],
            order: typeof json['order'] === "number" ? json['order'] : 0,
            categoryOrder: typeof json['categoryOrder'] === "object" ? json['categoryOrder'] : {},
            hideFromSearch: typeof json['hideFromSearch'] === "boolean" ? json['hideFromSearch'] : false,
            useBonus: typeof json['useBonus'] === "boolean" ? json['useBonus'] : false,
            deleted: typeof json['deleted'] === "number" ? json['deleted'] : 0,
            features: Array.isArray(json['features'])? json['features'] : [],
            themes: Array.isArray(json['themes']) ? (json['themes']) : [],
            tags: Array.isArray(json['tags']) ? json['tags'] : [],
            vendorName: typeof json['vendorName'] === "string" ? json['vendorName'] : "",
            nicknameRequired: json.hasOwnProperty("nicknameRequired") ? json['nicknameRequired'] : null
            // nicknameRequired: true
        }
    };

    static getCategoryGames = async (category_id: string, count?: number): Promise<CategoryGamesInterface> => {
        const response:CategoryGamesInterface = {
            total: 0,
            games: []
        };

        const data = await categoryGamesService(category_id, count);

        if (!data.error) {
            response.total = data.response?.total || 0;
            data.response?.games?.map((game:any) => {
                response.games.push(this.gameInterfaceFactory(game));
            })
        }

        return response;
    };

    static updateFavoritesGames = async ():Promise<void> => {
        const data = await getFavoritesGames();

        if (!data.error && Array.isArray(data.response)) {
            this.favoritesGames = data.response.map((json:any) => this.gameInterfaceFactory(json));
        }
    };
    
    static vendorInterfaceFactory = (json:any) => ({
        id: typeof json['id'] === "string" ? json['id'] : '',
        name: typeof json['name'] === "string" ? json['name'] : '',
        providerId: typeof json['providerId'] === "string" ? json['providerId'] : '',
    });

    static getEgtJackpots = async () => {
        const request = await fetch(process.env.NEXT_PUBLIC_LOCAL_API + '/api/jackpot/egt');
        const response = await request.json();

        return [
            {
                image: '/default/icons/platinumIcon.svg',
                label: 'Spades',
                prize: typeof response['currentLevelIV'] === "number" ? (response['currentLevelIV'] / 100).toFixed(2) : 0,
                currency: typeof  response['currency'] === 'string' ?  response['currency'] : '',
            },
            {
                image: '/default/icons/goldIcon.svg',
                label: 'Hearts',
                prize: typeof response['currentLevelIII'] === 'number' ? (response['currentLevelIII'] / 100).toFixed(2) : 0,
                currency: typeof  response['currency'] === 'string' ?  response['currency'] : '',
            },
            {
                image: '/default/icons/silverIcon.svg',
                label: 'Diamonds',
                prize: typeof response['currentLevelII'] === 'number' ? (response['currentLevelII'] / 100).toFixed(2) : 0,
                currency: typeof  response['currency'] === 'string' ?  response['currency'] : '',
            },
            {
                image: '/default/icons/bronzeIcon.svg',
                label: 'Clubs',
                prize: typeof response['currentLevelI'] === 'number' ? (response['currentLevelI'] / 100).toFixed(2) : 0,
                currency: typeof  response['currency'] === 'string' ?  response['currency'] : '',
            }
        ];
    }

    static getJackpotegtEq = async () => {
        const request =  await  fetch(process.env.NEXT_PUBLIC_LOCAL_API + '/api/jackpot/egtEq');
        const response =  await request.json();

        return [
            {
                image: '/default/icons/platinumIcon.svg',
                label: 'Platinum',
                prize: typeof response['currentLevelIV'] === "number" ? (response['currentLevelIV'] / 100).toFixed(2) : 0,
                currency: typeof  response['currency'] === 'string' ?  response['currency'] : '',
            },
            {
                image: '/default/icons/goldIcon.svg',
                label: 'Gold',
                prize: typeof response['currentLevelIII'] === 'number' ? (response['currentLevelIII'] / 100).toFixed(2) : 0,
                currency: typeof  response['currency'] === 'string' ?  response['currency'] : '',
            },
            {
                image: '/default/icons/silverIcon.svg',
                label: 'Silver',
                prize: typeof response['currentLevelII'] === 'number' ? (response['currentLevelII'] / 100).toFixed(2) : 0,
                currency: typeof  response['currency'] === 'string' ?  response['currency'] : '',
            },
            {
                image: '/default/icons/bronzeIcon.svg',
                label: 'Bronze',
                prize: typeof response['currentLevelI'] === 'number' ? (response['currentLevelI'] / 100).toFixed(2) : 0,
                currency: typeof  response['currency'] === 'string' ?  response['currency'] : '',
            }
        ];

    }
    
    static getctJackpotegt = async () => {
        const request =  await  fetch(process.env.NEXT_PUBLIC_LOCAL_API + '/api/jackpot/ct');
        const response =  await request.json();
        let result:Array<any> = [];

        if (typeof response.jpsystems === "object") {
            Object.keys(response.jpsystems).map((key:any) => {
                const info = response.jpsystems[key];

                result = [
                    {
                        image: '/default/icons/platinumIcon.svg',
                        label: 'Platinum',
                        prize: Number(info.jpsystem_current_levels[3]['value_vis']) ? Number(info.jpsystem_current_levels[3]['value_vis']) : 0,
                        currency: typeof  info['currency'] === 'string' ?  info['currency'] : '',
                    },
                    {
                        image: '/default/icons/goldIcon.svg',
                        label: 'Gold',
                        prize: Number(info.jpsystem_current_levels[2]['value_vis']) ? Number(info.jpsystem_current_levels[2]['value_vis']) : 0,
                        currency: typeof  info['currency'] === 'string' ?  info['currency'] : '',
                    },
                    {
                        image: '/default/icons/silverIcon.svg',
                        label: 'Silver',
                        prize: Number(info.jpsystem_current_levels[1]['value_vis']) ? Number(info.jpsystem_current_levels[1]['value_vis']) : 0,
                        currency: typeof  info['currency'] === 'string' ?  info['currency'] : '',
                    },

                ]
            });
        }


        return result;
    }

    static getskyJackpot = async () => {
        const request =  await  fetch(process.env.NEXT_PUBLIC_LOCAL_API + '/api/jackpot/sky');
        const response =  await request.json();

        return [
            {
                image: '/default/icons/platinumIcon.svg',
                label: 'SkyJackpot',
                // prize: typeof response['currentLevelI'] === 'number' ? (response['currentLevelI'] / 100).toFixed(2) : 0,
                prize: typeof response['features']![0]!['featureData']!['ticker'] === 'number' ? (response['features']![0]!['featureData']!['ticker']).toFixed(2) : 0,
                currency: typeof  response['features']![0]['currency'] === 'string' ? response['features']![0]['currency']  : '',
            }
        ];
    }
    
    static getJackpot = async (game:GameInterface) => {
        const egtPROVIDER = '00000005-dc86-47af-8013-c46b4c5105af';
        const egtEqPROVIDER = '00000055-dc86-47af-8013-c46b4c5105af';
        const ctPROVIDER = '00000017-dc86-47af-8013-c46b4c5105af';
        const skyJackpot = '00000001-dc86-47af-8013-c46b4c5105af';

        if(game.provider == egtPROVIDER) {

            return await Game.getEgtJackpots();

        }

        if(game.provider == ctPROVIDER) {

            return await Game.getctJackpotegt();

        }

        if(game.provider == skyJackpot) {

            return await Game.getskyJackpot();

        }
        if(game.provider == egtEqPROVIDER) {

            return await Game.getJackpotegtEq();
        }
    }

    static getAll():Array<GameInterface> {
        const list:Array<GameInterface> = [];

        if(Array.isArray(games)) {
            games?.map((json:any) => {
                if (!json.deleted) {
                    list.push(this.gameInterfaceFactory(json));
                }
            });
        }

        return list;
    }

    static getVendorsList():Array<any> {
        return Array.isArray(vendors) ? vendors : [];
    }

    static getFilters():any{

        let all_games = this.getAll()

        let featuresFilters: Array<any> = []

        let themesFilters: Array<any> = []

        let tagsFilters: Array<any> = []

        let volatilityFilters: Array<any> = [];

        let volatilityVariants: Array<any> = []

        all_games.map((item:GameInterface) => {

            const features = item.features;

            features?.map((feature) => {
                if (!featuresFilters.includes(feature)) featuresFilters.push(feature);
            });


            const themes = item.themes;

            themes?.map((th) => {
                if (!themesFilters.includes(th)) themesFilters.push(th);
            });


            const tags = item.tags;

            tags?.map((t) => {
                if (!tagsFilters.includes(t)) tagsFilters.push(t);
            });



            if (item.hasOwnProperty('volatility')) {
                let isVolatility = volatilityVariants.includes(item.volatility)

                if (!isVolatility && item?.volatility?.length) {
                    volatilityVariants.push(item.volatility)
                }
            }

        });

        if (volatilityVariants.length) {
            volatilityVariants.map((v) => {
                if (v === 'low') volatilityFilters.push({text: "Low", value: "low"})
                if (v === 'low_med') volatilityFilters.push({text: "Low-Med", value: "low_med"})
                if (v === 'average') volatilityFilters.push({text: "Average", value: "average"})
                if (v === 'med_high') volatilityFilters.push({text: "Med-High", value: "med_high"})
                if (v === 'high') volatilityFilters.push({text: "High", value: "high"})
                if (v === 'adjusted') volatilityFilters.push({text: "Adjusted", value: "adjusted"})
            });
        }

        // const filters = [
        //     {filters: featuresFilters, fileName: 'featuresFilter'},
        //     {filters: themesFilters, fileName: 'themesFilter'},
        //     {filters: volatilityFilters, fileName: 'volatilityFilter'},
        //     {filters: tagsFilters, fileName: 'tagsFilter'}
        // ];

        return {
            featuresFilters,
            themesFilters,
            volatilityFilters,
            tagsFilters
        }
    }

}

export default Game