import {useEffect, useRef} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {getAuthToken} from "../utils/cookies";
import UserInterface from "../interfaces/user";
import {updateFreeSpinsGames, updateFreeBonusGames, updateUserBalance} from "../store/account/actions";
import ReconnectingWebSocket from 'reconnecting-websocket';
import Balance from "../interfaces/balance";
import Payment from "../utils/payment";
import { updatePlayJackpot } from "store/common/actions";

function CashPotWSS() {
    const dispatch = useDispatch();
    const user:UserInterface | undefined = useSelector((state:any) => state.account.user);
    const ws = useRef<ReconnectingWebSocket | null>(null);
    const wsSender = useRef<any>(null);
    const playJackpot = useSelector((state: any) => state.common.playJackpot)

    const runWebSocket = () => {
        const token:string = getAuthToken();

        let api_route =  process.env.NEXT_PUBLIC_SITE_API?.replace('https://', '')
        let ref =  process.env.NEXT_PUBLIC_WS_REFERRER
        // const url:string = `wss://${api_route}/socket.io/?referer=https%3A%2F%2F${ref}&socket_id=435b4356c538_8&EIO=3&transport=websocket`;
        const url:string = `wss://${api_route}/socket.io/?referer=${ref}&socket_id=435b4356c538_8&EIO=3&transport=websocket`;

        ws.current = new ReconnectingWebSocket(url);

        ws.current.onopen = (event) => {

            const autoSend = () => {
                ws.current?.send('2');
                wsSender.current = setTimeout(autoSend, 25000);
            };

            wsSender.current = setTimeout(autoSend, 25000);

        };

        ws.current.onclose = function(event) {

            clearTimeout(wsSender.current);
        };

        ws.current.onerror = function(event) {

        };

        ws.current.addEventListener('error', (event) => {

        });

        ws.current.onmessage = function (event) {

            if (event.data.search('42') === 0) {
                try {
                    const json = JSON.parse(event.data.replace('42', ''));
                    let balance:Balance | null = null;

                    if(json[0] === "server" && json[1].status === "Connected") {
                        ws.current?.send(`42${JSON.stringify(['startJackpotTicker',{ "name": "pragmatic", "currency": "RON" }])}`);
                    }

                    if (json[0] === 'balance' && typeof json[1].payload === "object") balance = Payment.balanceFactory(json[1].payload);
                    if (json[0] === 'balance-change' && typeof json[1] === "object") balance = Payment.balanceFactory(json[1]);

                    if (balance) {
                        dispatch(updateUserBalance(balance));

                        if (typeof balance.wallet === "object") {
                            const freeSpinsGames:Array<string> = [];
                            const freeBonusesGames:Array<string> = [];
                            const {accounts,freeSpins, freeBonuses, freeBets} = balance.wallet;

                            if (typeof accounts === "object") {
                                Object.keys(accounts).map((key:any) => {
                                    if (accounts[key] && accounts[key].type === 'freeSpins' && accounts[key].amount > 0) {
                                        const isFreeBet:boolean = accounts[key]?.accountingExtraData?.isFreeBet;

                                        if (Array.isArray(accounts[key].games) && !isFreeBet) {
                                            accounts[key].games.map((g:any) => {
                                                if (g['gameId']) {
                                                    freeSpinsGames.push(g['gameId']);
                                                }
                                            })
                                        }
                                    }

                                    if (accounts[key] && accounts[key].type === 'freeBonus' && accounts[key].amount > 0) {
                                        if (typeof accounts[key].game?.gameId === "string") {
                                            freeBonusesGames.push(accounts[key].game.gameId);
                                        }
                                    }
                                });
                            }

                            dispatch(updateFreeBonusGames(freeBonusesGames));
                            dispatch(updateFreeSpinsGames(freeSpinsGames));
                        }

                    }
                    
                    if (json[0] === "jackpot" && json[1].jackpots?.length) {
                        let lvs:any = []
                        
                        let sortedJackpots = json[1].jackpots?.sort((a:any, b:any) => b.jackpotID - a.jackpotID);
                        
                        let keys = ['a', 'k', 'q', 'j' ]

                        sortedJackpots?.map((x:any, i: number) => {
                            lvs.push({
                                id: x.jackpotID,
                                status: x.status,
                                value: Number(x.amount.toFixed(2)),
                                level: x.level,
                                key: keys[i]
                            })
                        });

                        let jackpot:any = {
                            bgImage: "playJackpotBg",
                            jackpot_name: 'Play Jackpot',
                            levels: lvs
                        }
                        
                        dispatch(updatePlayJackpot(jackpot));
                    }

                } catch (e) {}
            }
        };
    };

    useEffect(() => {
        runWebSocket();

        return () => {
            clearTimeout(wsSender.current);
            ws.current?.close();
            ws.current = null;
        }
    }, []);

    useEffect(() => {
        const token:string = getAuthToken();

        if (user) {
            ws.current?.send(`42${JSON.stringify(['player-login', {
                'token': getAuthToken(),
                'type': 'token'
            }])}`);
        }
    }, [user]);


    return null;
}

export default CashPotWSS