import React, {useState, useEffect} from 'react'
import Skeleton from '@mui/material/Skeleton'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import styles from '../../styles/winner.module.scss';
import clsx from 'clsx';
import "swiper/css";
import "swiper/css/pagination";
import { Navigation, Pagination } from "swiper";
import PaginationArrow from "ui/paginationArrow";
import CMS from "utils/cms";


interface Props {
    json?: object;
}


const WinnersProloader:React.FC<Props> = ({json}) => {
    const [swiper, setSwiper] = useState<any>();
    const [screen, setScreen] = useState<number | undefined>(undefined)
    const data = CMS.jsonWinnersSlider(json);

    let winnersTemplates: Array<number> = []
    let quantity = 20;

    for(let i = 0; i < quantity; i++) {
        winnersTemplates.push(i)
    }

    useEffect(() => {
        let width = window.innerWidth;
        setScreen(width)
    }, [])


    return (
        <div data-section="winners-slider-preloader" className={'winnerBlock'}>
            <h4 className={'gamesSlider__title'}>Câștiguri recente</h4>

            <Swiper
                pagination={{
                    clickable: true,
                }}
                modules={[Pagination, Navigation]}
                className={clsx('slideClass', 'swiper-init-padding')}
                spaceBetween={16}
                slidesPerView='auto'
                onSwiper={(sw:any) => setSwiper(sw)}
            >
                <PaginationArrow className={'winnerBlockArrow'} onClick={() => swiper.slideNext()}/>
                <PaginationArrow className={'winnerBlockArrow'} onClick={() => swiper.slidePrev()} rightArrow />


                {winnersTemplates.map((x, i) => {
                    return (
                        <SwiperSlide key={`winners-${i}`}>
                            <div className={clsx(styles.item, styles.skeletonItem)}>
                                <Skeleton variant="rectangular" style={{
                                    display: 'block',
                                    width: '100%',
                                    height: '100%',
                                    background: '#404040',
                                    borderRadius: '10px'
                                }}>

                                </Skeleton>
                            </div>
                        </SwiperSlide>
                    )
                })}
            </Swiper>

        </div>
    )
}

export default  WinnersProloader