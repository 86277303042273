import {FC} from 'react';
import CMS from "utils/cms";
import Link from 'next/link';
import gameStyles from "themes/default/styles/card.module.scss";
import clsx from 'clsx';

interface Props {
    json:any;
    api_id:string;
}

const AllProviders:FC<Props>  = ({json, api_id}) => {
    const data = CMS.jsonAllProvidersPages();

    return(
        <div className={'page-container'}>
            <h1 className={gameStyles.page_title}>{data.title}</h1>
            <div  className={clsx("row", 'provider-block')}>
                {data.providers.map((item:any, i:number) => {
                    return (
                        <div  key={'providers'+ i} className={'provider-wrapper'}>
                            <Link className={clsx('')} style={{display:'inline-block'}} href={'/'+ item.api_id}>
                                <img src={item.thumbnail_src} alt={item.name}/>
                            </Link>
                        </div>
                    )
                })}
            </div>
        </div>
    )
};

export default AllProviders;