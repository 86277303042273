import {FC, createRef, useEffect, useState} from 'react';
import static_values from "utils/static_values";
import clsx from 'clsx';

interface Props {
    src: string,
    alt: string,
}


const LazyImage: FC<Props>  = ({src, alt}) => {
    const img:any = createRef();
    const [defaultSrc, setDefault] = useState('');

    const imgLoad = (img:any) => {
        const src = img.getAttribute('data-lazy');
        const alt = img.getAttribute('data-alt');

        img.setAttribute('src', src);
        img.setAttribute('alt', alt);

        if (img.complete) {
            img.parentNode.classList.add('loaded');
            static_values.images.push(src);
        } else {
            img.onload = function() {
                img.parentNode.classList.add('loaded');
                static_values.images.push(src);
            };
        }
    };

    const imgObserver = () => {
        if (!img.current) return;

        if (typeof window !== "undefined") {
            const isSamsungBrowser = navigator.userAgent.match(/SamsungBrowser/i);

            if ('IntersectionObserver' in window && !isSamsungBrowser) {
                const io = new IntersectionObserver((entries, observer) => {
                    entries.forEach(entry => {
                        if (entry.isIntersecting) {
                            imgLoad(entry.target);
                            observer.disconnect();
                        }
                    });
                });

                io.observe(img.current)
            } else {
                if (img.current) {
                    imgLoad(img.current);
                }
            }
        }
    };


    useEffect(() => {
        imgObserver();

    }, []);

    return (
        <div className={'lazy-game-thumb'}>
            <div className={clsx("lazy-game-thumb__preview", 'themes_lazy_game_thumb__preview')}/>
            <img ref={img} className={'lazy'} data-alt={alt} alt={"lazy_image"} data-lazy={src}/>
        </div>
    );
}

export default LazyImage