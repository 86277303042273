import React, {useState} from "react";
import styles from "themes/default/styles/LuckyTicket.module.scss"
import {Swiper, SwiperSlide} from "swiper/react";
import clsx from "clsx";
import useAuth from "@/AbsoluteComponents/hooks/auth";
import strings from "@/AbsoluteComponents/utils/strings";
import Button from "@/template/small_ui/button";
import {openDeposit, openLoginModal} from "@/AbsoluteComponents/store/modals/actions";
import {useDispatch} from "react-redux";


interface Props {
    data:any
    api_id:string
}

const LuckyTicketRules:React.FC<Props> = ({data}) => {
    const {
        rules_section_title,
        rules_section_info,
        rules_section_rules_list,
        rules_section_info_content
    } = data
    
    const [swiper, setSwiper] = useState<any>();
    const auth = useAuth()
    const dispatch = useDispatch()
    
    return (
        <div id={"lucky_ticket_rules"} className={styles.rules}>
            <div className={styles.container}>
                
                <div className={styles.headings}>
                    
                    <h4>
                        {rules_section_title}
                    </h4>
                    
                    <p>
                        {rules_section_info}
                    </p>
                    
                </div>
                
                <Swiper
                    slidesPerView='auto'
                    onSwiper={(sw) => {
                        setSwiper(sw);
                    }}
                    spaceBetween={8}
                    className={styles.swiper}
                >
                    
                    {rules_section_rules_list?.length && rules_section_rules_list.map((tk:any, i:number) => {
                        return (
                            <SwiperSlide
                                className={styles.sw_item}
                                key={"ticket_item: "+i}
                            >
                                
                                <TicketSlideItem info={tk} />
                                
                            </SwiperSlide>
                        )
                    })}
                
                </Swiper>
                
                <div className={clsx(styles.content_list)} dangerouslySetInnerHTML={{__html: rules_section_info_content}}/>
                
                
                <Button
                    className={styles.get_tickets_btn}
                    text={strings["get_tickets"]}
                    onClick={() => {
                        if(auth.isLoggedIn()) {
                            dispatch(openDeposit(true))
                        } else {
                            dispatch(openLoginModal(true))
                        }
                    }}
                />
            </div>
        </div>
    )
}

interface TicketSlideitemProps {
    info: any
}

const TicketSlideItem:React.FC<TicketSlideitemProps> = ({info}) => {
    
    const auth = useAuth()
    
    const divElements = Array.from({ length: 11 }, (_, index) => (
        <div className={styles.divider_item} key={"divider_item"+index} />
    ));
    
    const top_values = [
        {
            label: strings["depunere_minima"],
            value: info.min_deposit
        },
        {
            label: strings["max_dep"],
            value: info.max_deposit
        }
    ]
    
    
    return (
        <div className={styles.ticket_item}>
            <TicketHeaderBottom />
            <div className={styles.content}>
                
                <div className={clsx(styles.top)}>
                    {top_values.map((inf, i) => {
                        return (
                            <div className={styles.part} key={"min deposit ticket" + info.min_deposit + i}>
                                
                                <span className={styles.label}>
                                    {inf.label}
                                </span>
                                
                                <span className={styles.value}>
                                    {inf.value} RON
                                </span>
                            
                            </div>
                        )
                    })}
                </div>
                
                <div className={styles.divider}>
                    <div className={clsx(styles.divider_ends, styles.start)}>
                    
                    </div>
                    
                    {divElements}
                    
                    <div className={clsx(styles.divider_ends, styles.end)}>
                    
                    </div>
                </div>
                
                <div className={clsx(styles.bot, styles.sides)}>
                    <span className={styles.value}>
                        {info.ticket_amn}
                    </span>
                    
                    <span className={styles.label}>
                        {strings["tickets"]}
                    </span>
                </div>
            
            </div>
            <TicketHeaderBottom reverse/>
        </div>
    )
}

interface TicketHeaderBottomProps {
    reverse?: boolean
}

const TicketHeaderBottom: React.FC<TicketHeaderBottomProps> = ({reverse}) => {
    return (
        <svg
            style={{
                transform: `
                    rotate(${reverse ? 180 : 0}deg)
                    translateY(${reverse ? 5 : 2}px)
                `
            }}
            width="180"
            height="21"
            viewBox="0 0 180 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M180 21L180 10C180 4.47715 175.523 7.46948e-07 170 7.8575e-07L114.804 1.17354e-06C110.454 1.2041e-06 107.063 3.63329 105.197 7.56217C102.36 13.5329 96.4394 17.64 89.5957 17.64C82.7519 17.64 76.8317 13.5329 73.9948 7.56217C72.1281 3.63329 68.7369 1.66732e-06 64.3871 1.69764e-06L10 2.07677e-06C4.47716 2.11527e-06 5.33896e-08 4.47716 1.19249e-07 10L2.50423e-07 21L180 21Z"
                fill="#1F1B24"/>
        </svg>
    )
}


export default LuckyTicketRules