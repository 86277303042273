import { FC } from 'react';
import CMS from "utils/cms";
import clsx from 'clsx';
import strings from "utils/strings";
import {useState} from 'react';
import {
    openDeposit,
    openDepositAfterLogin,
    openLoginModal,
    openSignupModal,
    updateCampaignId
} from "store/modals/actions";
import {useDispatch, useSelector} from 'react-redux';
import { useRouter } from 'next/router';
import {useEffect} from 'react';
import useAuth from "hooks/auth";
import styles from '../../styles/OldPromotions.module.scss'
import { useMediaQuery } from 'react-responsive';
import OldPromSidebar from '../../ui/promotions/oldPromSidebar'
import {smartico_visible} from "@/template/config/constants";
import SmarticoWidget from "./smartico_elems/smartico_widgets";
import SmarticoClass from "@/AbsoluteComponents/utils/smartico_class";
import Button from "@/template/small_ui/button";
import SmarticoMiniGame from "@/AbsoluteComponents/interfaces/smartico_minigames";
import {updateSnackBar} from "@/AbsoluteComponents/store/common/actions";
import Page404 from "@/template/ui/page404";



interface Props {
    json: object;
    api_id: string;
}

const IdPromotions:FC<Props> = ({json,api_id}) => {
    const auth = useAuth();
    const triedToOpenDeposit:boolean = useSelector((state: any) => state.modals.depositAfterLogin);
    const router = useRouter();

    const dispatch = useDispatch();
    const data = CMS.getIdPromotion(json);
    const giftbox = auth.getSmarticoMiniGames().find((x:SmarticoMiniGame) =>
        x.saw_game_type === SmarticoClass.saw_game_types.giftbox
    )
    const giftbox_visible = typeof giftbox !== "undefined" && Boolean(Object.keys(giftbox).length)
    const [isShown, setIsShown] = useState(false);
    const [asideH, setAsideH] = useState<number | string>('unset')
    const [update, setUpdate] = useState(0);
    const [isImageLoaded, setIsImageLoaded] = useState<boolean>(false)

    const isMedia1 = useMediaQuery({
        query: '(max-width: 1024px)'
    });

    const oldPromotions: Array<any> = CMS.getOldPromotions();

    const expiredPromotions:Array<any> = CMS.getExpiredPromotions()

    const registerToContinue = () => {
        if (!auth.isLoggedIn()  && !auth.isUpdating()) {
            dispatch(openLoginModal(true));
        }else {
            router.push('/account/account-validation');

        }
    };

    const registerToPlay = () => {
        if (!auth.isLoggedIn()  && !auth.isUpdating()) {
            dispatch(openLoginModal(true));
        }else {
            router.push('/');

        }
    };
    const registerToDeposit = () => {

        if(data.bonus_id.length > 0) {
            dispatch(updateCampaignId(data.bonus_id))
        }

        if (!auth.isLoggedIn()  && !auth.isUpdating()) {
            dispatch(openLoginModal(true));
            dispatch(openDepositAfterLogin(true));
        }else {
            dispatch(openDeposit(true))
        }
    };

    const registerForGame = (path:string) => {
        if (!auth.isLoggedIn()  && !auth.isUpdating()) {
            dispatch(openLoginModal(true));
        }else {
            router.push('/'+path)

        }
    };

    const handleClick = () => {
        setIsShown(isShown => !isShown);
    };

    const imageLoaded = (src: any) => {
        return new Promise((resolve, reject) => {
            const img = document.createElement('img');
            img.src = src;

            img.onload = () => {
                resolve(true);
            }

            img.onerror = () => {
                resolve(false);
            }
        })
    }

    const loadImage = async () => {
        let arr = []

        await imageLoaded(CMS.getPublicFilePath(data.image[0].name, api_id, data.image[0].path)).then(() => {
            setIsImageLoaded(true)
        })

    }

    useEffect(() => {
        setUpdate(update + 1)
        loadImage()
    }, []);

    useEffect(() => {
        let aside = document.getElementById('pagePromId')
        //@ts-ignore

        if(isImageLoaded) {
            if(isMedia1) {
                setAsideH('unset')
            } else {
                //@ts-ignore
                setAsideH(aside.clientHeight)
            }
        }

    }, [isMedia1, router, isImageLoaded])
    
    useEffect(() => {
        if(auth.isLoggedIn() && triedToOpenDeposit) {
            dispatch(openDeposit(true));
        }
    }, [auth.isLoggedIn()])
    
    if(!data.web) {
        return <Page404 />
    }
    
    return (
        <div className={styles.main}>
            
            <div id={'pagePromId'} className={'idPromotions-Block'}>
                {/*<h1 className={'idPromotions-title'}>*/}
                {/*    {data?.title}*/}
                {/*</h1>*/}
                
                {data.image.length > 0 && (
                    
                    <div className={styles.hero}>
                        <h1
                            className={clsx(styles.title)}>{data?.title}
                        </h1>
                        
                        <img
                            width={846}
                            height={256}
                            style={{width: '100%', height: 'auto'}}
                            className={clsx('idPromotions-image', 'promo__desktopImage')}
                            src={CMS.getPublicFilePath(data.image[0].name, api_id, data.image[0].path)}
                            alt="img"
                        />
                        
                    </div>
                    
                )}
                
                {data.mobile_image.length > 0 && (
                    
                    <div className={styles.mobile_hero}>
                        
                        <h1
                            className={clsx(styles.title)}>{data?.title}
                        </h1>
                        
                        <img
                            width={680}
                            height={513}
                            style={{width: '100%', height: 'auto'}}
                            className={clsx('idPromotions-image', 'promo__mobileImage')}
                            src={CMS.getPublicFilePath(data.mobile_image[0].name, api_id, data.mobile_image[0].path)}
                            alt="img"
                        />
                    
                    
                    </div>
                
                )}
                
                <p>{data.excerpt}</p>
                
                <div className={'idPromotions-content'} dangerouslySetInnerHTML={{__html: data.content}}/>
                
                {data.show_validation_button && <div className={'wrappButton'} style={{textAlign: 'center'}}>
                    <button onClick={registerToContinue} style={{display: 'inline-block'}}
                            className={clsx('btn__primary', 'idPromotions-btn')}>{strings['valideaza_contul']}</button>
                </div>}
                
                {data.show_play_button && <div className={'wrappButton'} style={{textAlign: 'center'}}>
                    <button onClick={registerToPlay} style={{display: 'inline-block'}}
                            className={clsx('btn__primary', 'idPromotions-btn')}>play
                    </button>
                </div>}
                
                {data.deposit_button && <button className={clsx('btn__primary', 'idPromotions-btn')}
                                                onClick={registerToDeposit}>{strings['depune_acum']}</button>}
                
                {(data.category_games_button && data.category_api_id.length > 0) &&
                    <div className={'wrappButton'} style={{textAlign: 'center'}}>
                        <button onClick={() => registerForGame(data.category_api_id)} style={{display: 'inline-block'}}
                                className={clsx('btn__primary', 'idPromotions-btn')}>DESCOPERĂ JOCURILE
                        </button>
                    </div>
                }
                
                {(data.play_game_button) &&
                    <div className={'wrappButton'} style={{textAlign: 'center'}}>
                        <button onClick={() => router.push('/live-rtp')}
                                className={clsx('btn__primary', 'idPromotions-btn')}>DESCOPERĂ LIVE RTP
                        </button>
                    </div>
                }
                
                {(data.create_account && !auth.isLoggedIn()) &&
                    <div className={'wrappButton'} style={{textAlign: 'center'}}>
                        <button
                            onClick={() => {
                                dispatch(openSignupModal(true))
                            }}
                            className={clsx('btn__primary', 'idPromotions-btn')}
                        >
                            DESCHIDE CONT
                        </button>
                    </div>
                }

                
                {(data.smartico_tournament_id && smartico_visible) && (
                    <div style={{display: "flex", justifyContent: "center"}}>
                        <SmarticoWidget
                            type={SmarticoClass.WidgetTypes.Tournaments}
                            tournament_id={data.smartico_tournament_id}
                            iframe_id={`smartico-${SmarticoClass.WidgetTypes.Tournaments}-${data.smartico_tournament_id}`}
                            openWidget={() => {
                                SmarticoClass.showWidget(
                                    SmarticoClass.WidgetTypes.Tournaments,
                                    `smartico-${SmarticoClass.WidgetTypes.Tournaments}-${data.smartico_tournament_id}`,
                                    data.smartico_tournament_id
                                )
                            }}
                        />
                    </div>
                )}
                
                {/*{(data.smartico_mission_id && smartico_visible) && (*/}
                {/*    <div style={{display: "flex", justifyContent: "center"}}>*/}
                {/*        <SmarticoWidget*/}
                {/*            type={SmarticoClass.WidgetTypes.Missions}*/}
                {/*            tournament_id={data.smartico_tournament_id}*/}
                {/*            iframe_id={`smartico-${SmarticoClass.WidgetTypes.Missions}-${data.smartico_mission_id}`}*/}
                {/*            openWidget={() => {*/}
                {/*                SmarticoClass.showWidget(*/}
                {/*                    SmarticoClass.WidgetTypes.Missions,*/}
                {/*                    `smartico-${SmarticoClass.WidgetTypes.Missions}-${data.smartico_mission_id}`,*/}
                {/*                    data.smartico_tournament_id*/}
                {/*                )*/}
                {/*            }}*/}
                {/*        />*/}
                {/*    </div>*/}
                {/*)}*/}
                
                {(data.smartico_custom_section_id && smartico_visible) && (
                    <div style={{display: "flex", justifyContent: "center", padding: "8px"}}>
                        
                        <SmarticoWidget
                            type={SmarticoClass.WidgetTypes.Missions}
                            tournament_id={data.smartico_tournament_id}
                            iframe_id={`smartico-${SmarticoClass.WidgetTypes.Missions}-${data.smartico_mission_id}`}
                            openWidget={() => {
                                SmarticoClass.openCustomInlineSection(
                                    SmarticoClass.WidgetTypes.Missions,
                                    `smartico-${SmarticoClass.WidgetTypes.Missions}-${data.smartico_mission_id}`,
                                    600,
                                    data.smartico_custom_section_id
                                )
                            }}
                        />
                    </div>
                )}
                
                {(data.open_smartico_dashboard && smartico_visible) && (
                    <div style={{display: "flex", justifyContent: "center", padding: "8px"}}>
                        
                        <button
                            onClick={() => {
                                if(auth.isLoggedIn()) {
                                    SmarticoClass.openDashboard()
                                } else {
                                    dispatch(openLoginModal(true))
                                }
                            }}
                            className={clsx('btn__primary', 'idPromotions-btn')}
                        >
                            DESCHIDE {strings["play_hub"].toUpperCase()}
                        </button>
                    </div>
                )}
                
                {/*<div style={{ justifyContent: "center", padding: "8px"}}>*/}
                {/*    */}
                {/*    <Button*/}
                {/*        text={"Test"}*/}
                {/*        onClick={() => {*/}
                {/*            SmarticoClass.openInlineMiniGame("694", "test_iframe")*/}
                {/*        }}*/}
                {/*    />*/}
                {/*    */}
                {/*    <iframe id="test_iframe" style={{width: "100%", height: "500px"}}  />*/}
                {/*    */}
                {/*</div>*/}
                
                
                {data.show_giftbox_btn  &&
                    
                    <div className={'wrappButton'} style={{textAlign: 'center'}}>

                        <Button
                            disabled={auth.isLoggedIn() && !giftbox_visible}
                            text={"DESCHIDE ACUM"}
                            onClick={() => {
                                if(auth.isLoggedIn()) {
                                    if(typeof giftbox !== "undefined" && Boolean(Object.keys(giftbox).length)) {
                                        SmarticoClass.fwPopupMiniGame(String(giftbox.id))
                                    } else {
                                        dispatch(updateSnackBar("La moment nu puteți accesa oferta", "error", false))
                                    }
                                } else {
                                    dispatch(openLoginModal(true))
                                }
                            }}
                            style={{display: 'inline-block'}}
                        />
                    </div>
                }
                
                
                {data.terms_and_conditions &&
                    <div>
                        <div className={'idPromotions-wrapper'}>
                            
                            <button className={clsx('idPromotions-wrapperBtn', isShown && 'activeArrow')}
                                    onClick={handleClick}>
                            <span className={'idPromotions-termsConditions'}>{strings['termeni_și_condiții']}</span>
                                <img width={15} height={15} style={{width: 15, height: 15}}
                                     src={'/default/icons/arrow.png'} alt="img"/>
                            </button>
                        </div>
                        {isShown && <p className={'idPromotions-termsConditions'}
                                       dangerouslySetInnerHTML={{__html: data.terms_and_conditions}}/>}
                    </div>
                }
            </div>
            
            {/*SIDEBAR*/}
            <OldPromSidebar isMedia1={isMedia1} oldPromotions={oldPromotions} isImageLoaded={isImageLoaded}/>
            {/*SIDEBAR END*/}
            
            {/*DIVIDER START*/}
            {expiredPromotions.length > 0 && <div className={styles.divider}/>}
            {/*DIVIDER END*/}
        
        </div>
    )
}

export default IdPromotions