import React, {useState} from "react";
import styles from "themes/default/styles/VIPQuest.module.scss"
import {Swiper, SwiperSlide} from "swiper/react";
import clsx from "clsx";
import PaginationArrow from "@/AbsoluteComponents/ui/paginationArrow";
import Image from "next/image";
import CMS from "@/AbsoluteComponents/utils/cms";
import {useDispatch, useSelector} from "react-redux";
import Check from "themes/default/svg/check";
import Button from "@/template/small_ui/button";
import useAuth from "@/AbsoluteComponents/hooks/auth";
import {openDeposit, openLoginModal} from "@/AbsoluteComponents/store/modals/actions";
import JavaScriptChannels from "@/AbsoluteComponents/utils/js_channels";


interface Props {
    data:any
    api_id:string
}

const Slider:React.FC<Props> = ({data, api_id}) => {
    
    const auth = useAuth()
    const dispatch = useDispatch()
    const [swiper, setSwiper] = useState<any>();
    const {lvs, image_types, terms_and_condiitons_subtitle } = data
    
    const getState = (st:string) => {
        return useSelector((state:any) => state.account[st])
    }
    
    const status = getState("questStatus")
    
    return (
        <div className={styles.swiper_container}>
            <div className={styles.subtitle_box}>
                <span className={styles.subtitle}>
                    {terms_and_condiitons_subtitle}
                </span>
            </div>
            
            
            <Swiper
                slidesPerView='auto'
                onSwiper={(sw) => {
                    setSwiper(sw);
                }}
                spaceBetween={15}
                className={styles.swiper}
            >
                <PaginationArrow
                    onClick={() => swiper.slideNext()}
                    className={clsx(styles.dir_button, styles.dir_button_left)}
                />
                
                <PaginationArrow
                    onClick={() => swiper.slidePrev()}
                    rightArrow
                    className={clsx(styles.dir_button, styles.dir_button_right)}
                />
                
                {lvs?.length && lvs.map((lv: any, i: number) => {
                    
                    let img_type: string = ""
                    
                    let passed: boolean = false
                    
                    if (Object.keys(status).length && Number(status.level) > Number(lv.lv_nr)) {
                        passed = true
                    }
                    
                    Object.keys(lv).map((k) => {
                        if (typeof lv[k] === "boolean" && k.includes("image_type") && lv[k]) {
                            img_type = k
                        }
                    })
                    
                    let image = image_types.find((t: any) => t.type_name === img_type) || {}
                    
                    return (
                        <SwiperSlide
                            className={styles.sw_item}
                            key={"quest_level: " + i}
                        >
                            <LvItem
                                image={image}
                                lv={lv}
                                api_id={api_id}
                                passed={passed}
                            />
                        </SwiperSlide>
                    )
                })}
            
            </Swiper>
            
            <Button
                className={styles.deb_btn}
                text={"Depune acum"}
                onClick={() => {
                    JavaScriptChannels.appOpenDepositScreen();
                    if (auth.isLoggedIn()) {
                        dispatch(openDeposit(true))
                    } else {
                        dispatch(openLoginModal(true))
                    }
                }}
            />
        </div>
    
    )
}

export default Slider

interface LvProps {
    lv: any
    image: any
    api_id: string
    passed: boolean
}

const LvItem: React.FC<LvProps> = ({lv, image, api_id, passed}) => {
    
    const {
        points,
        prize_text_primary,
        lv_nr,
        prize_text_secondary
    } = lv

    return (
        <div className={clsx(styles.lv_item, passed && styles.passed)}>
            
            <div className={styles.top}>
                <div className={styles.header}>
                    <span>
                        NIVEL {lv_nr}
                    </span>
                </div>
                
                <div className={styles.content}>
                    <div className={styles.prize_text}>
                        
                        <span className={styles.main_val}>
                            {prize_text_primary}
                        </span>
                        
                        {prize_text_secondary.length ? (
                            <span className={styles.secondary}>
                                {prize_text_secondary}
                            </span>
                        ) : null}
                        
                    </div>
                    
                    <div className={styles.image}>
                        {Object.keys(image).length ? (
                            <Image
                                src={CMS.getPublicFilePath(image.image[0]['name'], api_id, image.image[0]['path'])}
                                alt={image.image[0]['name']}
                                width={200}
                                height={200}
                            />
                        ) : null}
                    </div>
                    
                </div>
            </div>
            
            <div className={styles.bot}>
                {passed ? (
                    <div className={styles.tick}>
                        <Check />
                    </div>
                ) : null}
                
                <span>
                    {points} CP
                </span>
            </div>
        </div>
    )
}