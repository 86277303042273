

function Chevrone() {
    return (
        <svg color={'inherit'} width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.9125 19.1125C2.42235 19.6027 1.62765 19.6027 1.1375 19.1125C0.647347 18.6223 0.647347 17.8277 1.1375 17.3375L8.475 10L1.1375 2.6625C0.647348 2.17235 0.647347 1.37765 1.1375 0.8875C1.62765 0.397347 2.42235 0.397347 2.9125 0.8875L11.3179 9.29289C11.7084 9.68342 11.7084 10.3166 11.3179 10.7071L2.9125 19.1125Z" fill="white"/>
        </svg>

    )
}
export default Chevrone;