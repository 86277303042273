import Image from 'next/image';
import Link from 'next/link';
import {useRouter} from 'next/router';
import useAuth from "hooks/auth";
import strings from 'utils/strings';
import clsx from 'clsx';
import {Tooltip} from '@mui/material';
import {ClickAwayListener} from '@mui/base';
import React, {useState, useEffect} from 'react';
import {openDeposit} from "store/modals/actions";
import {useDispatch, useSelector} from 'react-redux';
import styles from '@/template/styles/balance.module.scss'
import candyQuestLvs from 'public/json/candy_quest.json';
import {navigateToPendingTickets} from "store/common/actions";
import {getDocumentValidation, getWithdrawal} from "services/account";
import moment from 'moment';
import {validationTypes} from "utils/constants";
import CMS from "@/AbsoluteComponents/utils/cms";


const DefaultBalanceView:React.FC= () => {
    const data = useAuth();
    const router = useRouter();
    const progress = data.getWithdrawProgress();
    const dispatch = useDispatch();

    const [open, setOpen] = useState(false);
    const [transactionsCount, setTransactionsCount] = useState(0);
    const betBalance = useSelector((state:any) => state.account.betBalance)
    const leaderboard: any = useSelector((state:any) => state.account.questLeaderboard)


    const getPendingWithdraw = async () => {
        const data = await getWithdrawal(0, 10, 'pending', moment().subtract(2, 'days').format('YYYY-MM-DD HH:mm:ss'),  moment().format('YYYY-MM-DD HH:mm:ss'));

        if (!data.error) {
            setTransactionsCount(data.response?.transactions?.length || 0)
        }
    };

    const openPendingWithdraw = () => {
        router.push('/account/transactions/withdrawal?status=pending');
    };

    useEffect(() => {
        setOpen(false);
    }, [router.pathname]);

    useEffect(() => {
        if (open) {
            getPendingWithdraw();
        } else {
            setTransactionsCount(0);
        }
    }, [open]);


    const freeBonus = data.getFreeBonuses();
    const freeSpins =  data.getFreeSpins();
    
    let bonus = Number(localStorage.getItem('bonus') || 0);
    let spins = Number(localStorage.getItem('spins') || 0);

    let specialOffer =  ((freeBonus > bonus) || (freeSpins > spins));

    const changeClass = () => {
        localStorage.setItem('bonus', freeBonus.toString());
        localStorage.setItem('spins', freeSpins.toString());
    }


    const [documents, setDocument] = useState([]);

    const fetchValidation = async () => {

        const result:any = [];

        const documents = await getDocumentValidation();

        if(!documents.error) {
            documents.response.map((item:any) => {
                result.push({
                    documentName: typeof item['documentName'] === 'string' ? item['documentName'] : '',
                    status: typeof item['status'] === 'string' ? item['status'] : '',
                    type: typeof item['type'] === 'string' ? item['type'] : '',
                    updatedTime: typeof item['updatedTime'] === 'number' ? item['updatedTime'] : 0,
                    uploadedTime: typeof  item['uploadedTime'] === 'number' ? item['uploadedTime'] : 0,
                    verificationType:typeof  item['verificationType'] === 'string' ? item['verificationType'] : 0,
                })
            })

            setDocument(result);
        }
    }


    useEffect(() => {
        fetchValidation()
    }, []);

    useEffect(() => {
        // console.log('bet Balance',betBalance)
    }, [betBalance])

    const types:Array<string> = validationTypes.map(item => item.key);

    let approved = documents.find((item:any) => {
        return item.status === 'approved' && types.includes(item.type) ;
    });

    const status:boolean = documents.length === 0 ? true : approved !== undefined;

    //ADDED START  ADDED START  ADDED START  ADDED START  ADDED START  ADDED START

    let userCandyStatus = data.getCandyQuestStatus()


    let currentLevel = candyQuestLvs.find((lv:any) => {
        return lv.Nivel === userCandyStatus.level
    })

    const getPercentage = (): number => {
        let questPercentage:number = 0;
        if(currentLevel && currentLevel.Nivel !== 40 ) {
            let minPoints:number = currentLevel.Puncte;

            let maxPoints:any = candyQuestLvs[userCandyStatus.level].Puncte

            let currentLvTotalPoints:number = maxPoints - minPoints;

            let currentLvUserPoints:number = userCandyStatus.points - minPoints;
            let percent:number = currentLvTotalPoints / 100;
            questPercentage = parseFloat((currentLvUserPoints/percent).toFixed(2))
        } else if (currentLevel && currentLevel.Nivel === 40) {
            questPercentage = 100
        }

        return questPercentage
    }
   
    return (
        <ClickAwayListener onClickAway={() => setOpen(false)}>
            <div className={clsx(styles.dropDownBalanceWrapper, open && styles.dropDownBalance_active)}>

                <button
                    id="myDIV"
                    className={clsx(styles.btn_balance, !status && styles.btnBalance_NotValidation, (status  && specialOffer) && styles.btnBalance_activeSpins)}
                    onClick={() => {setOpen(!open), changeClass()}}
                >
                    <span className={clsx(styles.useBalanceWrapper)}>

                        <span className={styles.useBalance}>
                            {data.getUserBalance()}
                        </span>

                        <span className={styles.balanceCurrency} >
                            {data.getCurrency()}
                        </span>

                    </span>

                    <Image onClick={(e) => {
                        e.stopPropagation();
                        dispatch(openDeposit(true));
                    }} style={{border: '1px solid transparent'}} className={clsx(styles.btn_balance_add, 'btn-balance__add')} src={'/default/icons/AddNew.svg'} alt={'img'} width={48} height={48}/>
                </button>

                <div className={clsx(styles.dropDownBalance)}>
                    <div className={styles.dropDownBalance__items}>
                        <p className={styles.dropDownBalance__title}>{strings['balanta']}</p>
                        <p className={styles.dropDownBalance__data}>{ data.getUserBalance()}
                            <span className={styles.dropDownBalance__currency}>
                                {data.getCurrency()}
                            </span>
                        </p>
                    </div>

                    <div className={styles.dropDownBalance__cash}>
                        <div className={styles.dropDownBalance__cashAm} style={{display:'flex',  justifyContent: 'space-between', alignItems: 'center', WebkitAlignItems: 'center'}}>
                            <p className={styles.dropDownBalance__title}>{strings['bani_cash']}</p>
                            <p className={styles.dropDownBalance__data}>{data.getCash()}
                                <span className={styles.dropDownBalance__currency}>
                                    {data.getCurrency()}
                                </span>
                            </p>
                        </div>

                        <div className={styles.dropDownBalance__items}>
                            <p className={styles.dropDownBalance__title}>{strings['bonus']} </p>
                            <p className={styles.dropDownBalance__data}>
                                {data.getBonus()}
                                <span className={styles.dropDownBalance__currency}>
                                    {data.getCurrency()}
                                </span>
                            </p>
                        </div>

                        {/*ADDED START ADDED START ADDED START ADDED START ADDED START */}

                        <div  className={styles.dropDownBalance__progressBlock}>
                            <div className={styles.dropDownBalance__wrappers}>
                                <p className={clsx(styles.dropDownBalance__title)}> {strings['rulaj_necesar']}</p>
                                <Tooltip
                                    placement={'left'}
                                    enterTouchDelay={0}
                                    title={'Poate fi retras la 100%'}
                                >

                                    <span>
                                        <img src={'/default/icons/help.svg'}  width={20} height={20} alt={'img'}/>
                                    </span>

                                </Tooltip>
                            </div>

                            <div className={styles.dropDownBalance__progress}>
                                <p className={styles.progressData}>{ progress?.toFixed(2) || 0}%</p>
                                <div style={{height: 24, background: '#EEB665', width: `${progress}%`}}/>
                            </div>
                        </div>

                        {/*ADDED END ADDED END ADDED END ADDED END ADDED END */}

                    </div>

                    {/*ADDED START  ADDED START  ADDED START  ADDED START  ADDED START  */}

               
                    {(data.getQuestStatus().hasOwnProperty("optIn") && data.getQuestStatus().optIn ) && (
                        <div className={clsx(styles.questBalance, styles.clickable)}>
                            
                            <div onClick={() => {
                                router.push('/'+CMS.getCurrentQuest().url_id)
                            }} className={clsx(styles.questBalance__section, styles.section1)}>
                                <div className={styles.questBalance__headings}>
                                    <h4 style={{textTransform: "capitalize"}}>
                                        {CMS.getCurrentQuest().name}
                                    </h4>
                                    <span className={styles.score}>
                                        <span className={styles.yellow}>
                                            {userCandyStatus?.level || 0}
                                        </span>
                                        /{Object.keys(leaderboard.leaderBoard) && Object.keys(leaderboard.leaderBoard)[Object.keys(leaderboard.leaderBoard).length - 1] }
                                    </span>
                                
                                </div>
                                
                                <div style={{margin: 0}} className={'dropDownBalance__progress'}>
                                    
                                    <p style={{margin: 0}} className={'progressData'}>
                                        Nivelul {data.getQuestStatus().level}
                                        
                                        <span style={{marginLeft: '10px'}}>
                                            {data.currentUserQuestPercentage()}%
                                        </span>
                                    </p>
                                    
                                    <div style={{height: 24, background: '#EEB665', width: `${data.currentUserQuestPercentage()}%`}}/>
                                </div>
                            </div>
                        
                        </div>
                    )}
                    
                    {/*ADDED END  ADDED END  ADDED END  ADDED END  ADDED END  ADDED END  */}
                    
                    
                    {data.getFreeSpins() > 0 &&
                        <Link href={'/free-spins'} className={clsx(styles.dropDownBalance__items, styles.spinsStyle)}
                              title={strings['rotiri_gratuite']}>
                            
                            <p style={{color: '#EEB665'}} className={styles.dropDownBalance__title}>
                                {strings['rotiri_gratuite']}
                            </p>
                            
                            {data.getFreeSpins() === 0 ? (
                                <p className={clsx(styles.dropDownBalance__data, styles.dropDownBalance__dataStyle)}>
                                    {data.getFreeSpins()}
                                </p>
                            ) : (
                                <span className={clsx(styles.dropDownBalance__data, styles.dropDownBalance__dataStyle)}>
                                    {data.getFreeSpins()}
                                </span>
                            )}
                        </Link>
                    }
                    
                    
                    {data.getFreeBonuses() > 0 &&
                        <Link href={'/free-bonus'} title={'Speciale Gratuite'}
                              className={clsx(styles.dropDownBalance__items, styles.bonusStyle)}>
                            
                            <p style={{color: '#EEB665'}} className={styles.dropDownBalance__title}>
                                Speciale Gratuite
                            </p>
                            
                            {data.getFreeSpins() === 0 ? (
                                <p className={clsx(styles.dropDownBalance__data, styles.dropDownBalance__dataStyle)}>
                                    {data.getFreeBonuses()}
                                </p>
                            ) : (
                                <span  className={clsx(styles.dropDownBalance__data, styles.dropDownBalance__dataStyle)}>
                                    {data.getFreeBonuses()}
                                </span>
                            )}

                        </Link>
                    }

                    {data.getFreeBets() > 0 &&
                        <Link href={'/freebet'} className={clsx(styles.dropDownBalance__items, styles.betStyle )} title={strings['rotiri_gratuite']} >

                            <p style={{color: '#EEB665'}} className={styles.dropDownBalance__title}>
                                {strings['pariuri_gratuite']}
                            </p>

                            {data.getFreeBets() === 0 ? (
                                <p className={clsx(styles.dropDownBalance__data, styles.dropDownBalance__dataStyle)}>
                                    {data.getFreeBets()}
                                </p>
                            ) : (
                                <span className={clsx(styles.dropDownBalance__data, styles.dropDownBalance__dataStyle)}>
                                    {data.getFreeBets()}
                                </span>
                            )}

                        </Link>
                    }
                    
                    {data.getTimedFreeSpins() > 0 &&
                        <Link
                            href={'/free-timed-spins'}
                            className={clsx(styles.dropDownBalance__items,  styles.spinsStyle)}
                            title={strings['rotiri_gratuite']}
                        >
                            
                            <p style={{color: '#EEB665'}} className={styles.dropDownBalance__title}>
                                {strings['rotiri_gratuite']}
                            </p>
                            
                            {/*{data.getFreeSpins() === 0 ? (*/}
                            {/*    <p className={clsx(styles.dropDownBalance__data, styles.dropDownBalance__dataStyle)}>*/}
                            {/*        {data.getFreeSpins()}*/}
                            {/*    </p>*/}
                            {/*) : (*/}
                            <span
                                style={{width: "fit-content", borderRadius:"20px", padding: "0 4px"}}
                                className={clsx(styles.dropDownBalance__data, styles.dropDownBalance__dataStyle)}
                            >
                                {data.getTimedFreeSpins()} sec.
                            </span>
                            {/*)}*/}
                        
                        </Link>
                    }
                    
                    {betBalance > 0 ? (
                        <div
                            onClick={() => {
                                router.push('/account/transactions/betting?status=pending')
                                dispatch(navigateToPendingTickets(true))
                            }}
                            className={clsx(styles.dropDownBalance__items, 'clickable')}
                            style={{cursor: 'pointer'}}
                        >
                            
                            <p className={styles.dropDownBalance__title}>
                                {strings['balanta-pariuri']}
                            </p>
                            
                            <p className={styles.dropDownBalance__data}>
                                {betBalance}
                                <span className={styles.dropDownBalance__currency}>
                                    {data.getCurrency()}
                                </span>
                            </p>
                        
                        </div>
                    
                    ) : null}

                    {!status &&
                        <Link href={'/account/account-validation'} className={clsx(styles.balanceValidation, styles.dropDownBalance__items)}>

                            <p style={{color: 'red', margin: '8px 0', fontWeight: 600}} className={styles.dropDownBalance__title}>
                                {strings["ver_identity"]}
                            </p>

                        </Link>
                    }

                    {transactionsCount > 0 &&
                        <div style={{display: "flex"}} className={styles.dropDownBalance__withdraw}>
                            <div className={styles.dropDownBalance__title}>
                                Retrageri în așteptare
                            </div>
                            
                            <button
                                type={'button'}
                                className={'dropDownBalance__btn'}
                                onClick={openPendingWithdraw}
                            >
                                Detalii ({transactionsCount})
                            </button>
                        
                        </div>
                    }
                
                </div>
            </div>
        </ClickAwayListener>
    )
}
export default DefaultBalanceView;
