import strings from "utils/strings";
import TextField from "ui/textfield";
import Modal from 'react-modal';
import {useDispatch, useSelector} from 'react-redux';
import { updateSnackBar} from 'store/common/actions';
import useAuth from 'hooks/auth';
import {useEffect, useState} from 'react';
import {applyWithdraw, getWithdrawMethods} from "services/account";
import Selector from 'ui/selectDropDown';
import PaymentMethod from "interfaces/paymentMethod";
import Payment from "utils/payment";
import ArrowRight from "themes/default/svg/arrow-right";
import CircularProgress from '@mui/material/CircularProgress';
import PaymentMethodStoredData from "interfaces/paymentMethodStoredData";
import Button from "@/template/small_ui/button";
import InfoIcon from "themes/default/svg/info";
import  ModalLayout from 'themes/default/ui/modal_layout'


const WithdrawModal = () => {
    const dispatch = useDispatch();
    const auth = useAuth();
    const open = useSelector((state:any) => state.modals.openWithdraw);

    const [loading, setLoading] = useState<boolean>(false);
    const [applying, setApplying] = useState<boolean>(false);
    const [methods, setMethods] = useState<Array<PaymentMethod>>([]);
    const [amount, setAmount] = useState(0);
    const [selectedMethod, setSelectedMethod] = useState<PaymentMethod | null>(null);

    const onSubmit = async (e:any) => {
        e.preventDefault();

        const isCnp:boolean = selectedMethod?.methodType === 'cnp';

        if (!isCnp && amount < getMinAmount()) {
            dispatch(updateSnackBar(`Retragere minimă ${getMinAmount()} RON.`, 'error', false));
            return;
        }

        if (!isCnp && amount > getMaxAmount()) {
            dispatch(updateSnackBar(`Limita de retragere ${getMaxAmount()} RON.`, 'error', false));
            return;
        }

        let message = 'Exceeded the max limit';
        let replacedMsg = 'Limita maximă de retragere este';

        const form = new FormData(e.target);

        const reference = form.get('method_stored_data');

        setApplying(true);

        const data = await applyWithdraw(
            selectedMethod?.method || '',
            amount || 0,
            reference?.toString() || ''
        );

        setApplying(false);

        if (data.error && data.message) {

            if (data.message.search(message) > -1){
                message?.replace(message,replacedMsg )
            } else {
                if (data.message) {
                    dispatch(updateSnackBar(strings[data.message] || data.message, 'error', false));
                } else {
                    dispatch(updateSnackBar('Eroare necunoscută', 'error', false));
                }
            }

        } else if (data.response?.success) {

            dispatch(updateSnackBar('Retragerea a fost completată cu succes și va fi procesată câteva momente', 'success', false));
            auth.closeWithdrawPopup();

        } else {

            dispatch(updateSnackBar('Eroare necunoscută', 'error', false));

        }
    };

    const fetchInformation = async () => {
        setLoading(true);

        const data = await getWithdrawMethods();

        if (Array.isArray(data.response?.methods)) {
            const methods:Array<PaymentMethod> = [];

            const card = data.response.methods.find((json:any) => json['methodType'] === 'credit_card');
            const pay_safe = data.response.methods.find((json:any) => json['methodType'] === 'pay_safe_card');
            
            // let pay_safe = data.response.methods.find((json:any) => json['methodType'] === 'pay_safe_card' && json["method"] === "e00a8a7b-bc66-49d9-a630-249028da0952");
            //
            // if(!pay_safe) {
            //     pay_safe = data.response.methods.find((json:any) => json['methodType'] === 'pay_safe_card')
            // }
            
            const cnp = data.response.methods.find((json:any) => json['methodType'] === 'cnp');
            const bank_transfer = data.response.methods.find((json:any) => json['methodType'] === 'bank_transfer');
            const debitCard = data.response.methods.find((json:any) => json['methodType'] === 'debit_card');
            const voucher = data.response.methods.find((json:any) => json['methodType'] === 'voucher');

            if (debitCard) methods.push(Payment.paymentMethodFactory(debitCard, true));
            if (card) methods.push(Payment.paymentMethodFactory(card, true));
            if (pay_safe) methods.push(Payment.paymentMethodFactory(pay_safe, true));
            if (cnp) methods.push(Payment.paymentMethodFactory(cnp, true));
            if (bank_transfer) methods.push(Payment.paymentMethodFactory(bank_transfer, true));
            if (voucher) methods.push(Payment.paymentMethodFactory(voucher, true));
            
            setMethods(methods);
            setSelectedMethod(methods[0] || null);
        }

        setLoading(false);
    }

    const getMinAmount = ():number  => {
        return selectedMethod?.minAmount || 0;
    }

    const getMaxAmount = ():number => {
        return selectedMethod?.maxAmount || 0;
    }

    const emailProps = {
        name: 'amount',
        value: amount === 0 ? '' : amount,
        onChange: (e:any) => {
            setAmount(parseInt(e.target.value) || 0);
        }
    };

    const getAmountWithTax = ():number => {
        if (amount > 0 && selectedMethod /*&& selectedMethod?.feeInclude*/) {
            if (selectedMethod.feeType === 'percentage') {
                const percentage = selectedMethod?.feeValue / 100;

                if (percentage > 0) return amount - (amount * percentage);
            } else {
                return amount + selectedMethod?.feeValue
            }
        }

        return amount;
    };

    const getTaxAmount = ():number => {
        if (amount > 0 && selectedMethod /*&& selectedMethod?.feeInclude*/) {
            if (selectedMethod.feeType === 'percentage') {
                const percentage = selectedMethod?.feeValue / 100;

                if (percentage > 0) return Number((amount * percentage).toFixed(2));
            } else {
                return selectedMethod?.feeValue
            }
        }

        return 0;
    };

    useEffect(() => {

        if(open && (auth.getFreeBonuses() > 0 || auth.getBonus() > 0 || auth.getFreeSpins() > 0) ) {

            const bonusesStr:Array<string> = [];

            if(auth.getBonus() > 0) bonusesStr.push(`${auth.getBonus()} RON`);

            if(auth.getFreeSpins() > 0) {
                bonusesStr.push(`${auth.getFreeSpins()} runde gratuite`);
            }

            if (auth.getFreeBonuses() > 0) {
                bonusesStr.push(`${auth.getFreeBonuses()} specială gratuită`);
            }

            dispatch(updateSnackBar(`În cazul în care veți efectua o retragere, balanța dvs. bonus în valoare de ${bonusesStr.join(', ')} va fi anulată.`, 'info', false));

            dispatch(updateSnackBar('Retragerea se va face numai din balanța cash, nu și din cea bonus.', 'warning', false));

        } else {

        }

    }, [open]);

    return (
        <Modal
            onAfterOpen={() => {
                fetchInformation();
            }}
            onAfterClose={() => {
                setAmount(0);
                setSelectedMethod(null);
            }}
            ariaHideApp={false}
            className={'ReactModal__SM'}
            isOpen={open}
            onRequestClose={() => auth.closeWithdrawPopup()}
            contentLabel="Example Modal"
        >

            <ModalLayout onClose={() => auth.closeWithdrawPopup()}>

                <>
                    <div className="ReactModal__Inner__Header">
                        <h2 className="ReactModal__Title">{strings['retragere']}</h2>
                    </div>

                    <div className="ReactModal__Inner__Content">
                        {loading ? (
                            <div style={{padding: 50, textAlign: 'center'}}>
                                <CircularProgress />
                            </div>
                        ) : (
                            <>
                                <form onSubmit={onSubmit}>

                                    <div className="mb-20">
                                        <Selector
                                            name={'method'}
                                            defaultValue={selectedMethod?.method}
                                            label={strings['pick_method']}
                                            options={methods.map((method:PaymentMethod) => ({
                                                key: method.method,
                                                label: method.methodName,
                                                icon: method.methodIcon,
                                                methodLogo: method.methodLogo,
                                                card_types: method.methodIconsPaymentTheme
                                            }))}
                                            onChange={(key) => setSelectedMethod(methods?.find((m) => m.method === key) || null)}
                                        />
                                    </div>
                                    
                                    {selectedMethod &&
                                        <div className="mb-30">
                                            <Selector
                                                key={'selectedMethod-store-'+selectedMethod.methodType}
                                                name={'method_stored_data'}
                                                defaultValue={selectedMethod?.storedData[0]?.reference}
                                                label={selectedMethod.methodName}
                                                options={selectedMethod.storedData.length ?
                                                    selectedMethod.storedData.map((method:PaymentMethodStoredData) => ({
                                                        key: method.reference, label: `*${selectedMethod?.methodType === 'bank_transfer' ? method.phoneNumber : method.cardEnding}`, icon: selectedMethod.methodIcon
                                                    })) : [{key: '', label: selectedMethod.methodName, icon: selectedMethod.methodIcon}]}
                                            />
                                        </div>
                                    }

                                    <div className="mb-20">
                                        <TextField label={'Retragere bani'} type='text' placeholder={'Adaugă fonduri'} {...emailProps}/>
                                        {(amount > 0 && amount > auth.getCash()) && <p className="errorMsg">Max retragere {auth.getCash()} RON.</p>}
                                    </div>

                                    <Button loading={applying} type={'submit'} disabled={(amount > 0 && amount > auth.getCash())} text={strings['retragere']}/>


                                    {getAmountWithTax() > 0 &&
                                        <div  className="deposit_overview">
                                            <div className="deposit_overview__amount">
                                                <div className="deposit_overview__amount__type">{strings['retragere']}</div>
                                                {amount.toFixed(2)} RON
                                            </div>

                                            <div className="deposit_overview__arrow"><ArrowRight/></div>

                                            <div className="deposit_overview__amount">
                                                <div className="deposit_overview__amount__type">{strings['willGet']}</div>
                                                {getAmountWithTax().toFixed(2)} RON
                                            </div>
                                        </div>
                                    }
                                </form>
                            </>
                        )}

                        {getTaxAmount() > 0 &&
                            <div className={'tax-notice'}>
                                <InfoIcon/>
                                <span>Va fi aplicată o taxă de {getTaxAmount()} RON.</span>
                            </div>
                        }
                    </div>
                </>

            </ModalLayout>

        </Modal>

    )
}

export default WithdrawModal;