import CMS from "utils/cms";
import { FC } from 'react';
import styles from 'themes/default/styles/footer.module.scss';
import strings from "utils/strings";
import Image from 'next/image';
import MenuFooter from 'themes/default/ui/footer/menu'
import clsx from 'clsx'
import InstagramIcon from "themes/default/svg/instagram";
import FacebookIcon from "themes/default/svg/facebookicon";
import Link from 'next/link';


interface Props {
    json: object;
    api_id: any
}

const FooterInfo: FC<Props> = ({ json, api_id })  => {

    
    const data = CMS.getFooterInfo(json)

    const generalSettings = CMS.getDocumentGeneralSettings();
    

    return (
        <div className={clsx(styles.contents)}>
            <div className={styles.contents__top}>

                <div className={clsx(styles.contents__sides, styles.left)}>
                    <MenuFooter />
                </div>


                <div className={clsx(styles.contents__sides, styles.right)}>

                    <h5 className={styles.menu_heading}>
                        Info
                    </h5>

                    <div className={styles.contents__about} dangerouslySetInnerHTML={{__html: data.content}}/>

                </div>

            </div>
            

            <div  className={styles.contents__bottom}>

                <div className={styles.socialMedia}>
                    <Link className={clsx(styles.socialLink, styles.sidebar__facebook)}  target="_blank" href={generalSettings.instagram}>
                        <InstagramIcon/>
                    </Link>
                
                    <Link className={clsx(styles.socialLink, styles.sidebar__facebook)}  target="_blank" href={generalSettings.facebook}>
                        <FacebookIcon/>
                    </Link>
                </div>
                

                <div className={styles.marks}>
                    <div className={styles.contents__img}>
                        
                        <a aria-label={"onjn_url"} className={styles.onjn_url} href={data.onjn_url}>
                        </a>
                        
                        {(Array.isArray(data.web_images) && data.web_images.length) ? (
                            <div className={styles.contents__item}>
                                <Image className={styles.contents__logo} src={CMS.getPublicFilePath(data.web_images[0].name, api_id, data.web_images[0].path)} alt={strings['logo']} width={260} height={69}/>
                            </div>
                        ) : null}
                        
                    </div>
                </div>

            </div>
        </div>
    )
}

export default FooterInfo