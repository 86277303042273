
class Gradients {

    //HOT LUCK HOT LUCK HOT LUCK HOT LUCK HOT LUCK HOT LUCK
    static hotLuckGradient = () => {
        return (
            <>
                <linearGradient id="paint0_linear_3246_43411" x1="81" y1="-7" x2="81" y2="30" gradientUnits="userSpaceOnUse">
                    <stop stopColor="white"/>
                    <stop offset="1" stopColor="#758A9F"/>
                </linearGradient>
                <linearGradient id="paint1_linear_3246_43411" x1="81" y1="-7" x2="81" y2="30" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#4EBAB6"/>
                    <stop offset="0.375" stopColor="#4EBAB6" stopOpacity="0"/>
                </linearGradient>
            </>
        )
    }

    static hotLuckGradientId = 'paint0_linear_3246_43411';
    //HOT LUCK HOT LUCK HOT LUCK HOT LUCK HOT LUCK HOT LUCK


    //STANDART GRADIENT STANDART GRADIENT STANDART GRADIENT STANDART GRADIENT
    static standartGradient = () => {
        return (
            <>
                <linearGradient xmlns="http://www.w3.org/2000/svg" id="paint0_linear_3246_43334" x1="79" y1="-7" x2="79" y2="30" gradientUnits="userSpaceOnUse">
                    <stop stopColor="white"/>
                    <stop offset="1" stopColor="#BBBBBB"/>
                </linearGradient>
            </>
        )
    }

    static standartGradientId = 'paint0_linear_3246_43334';

    //STANDART GRADIENT STANDART GRADIENT STANDART GRADIENT STANDART GRADIENT


    //SKY GRADIENT SKY GRADIENT SKY GRADIENT SKY GRADIENT SKY GRADIENT SKY GRADIENT
    static skyGradient = () => {
        return (
            <>
                <linearGradient xmlns="http://www.w3.org/2000/svg" id="paint0_linear_3276_47994" x1="78.7478" y1="-7" x2="78.7478" y2="30" gradientUnits="userSpaceOnUse">
                    <stop stopColor="white"/>
                    <stop offset="1" stopColor="#41C0F5"/>
                </linearGradient>
            </>
        )
    }

    static skyGradientId = 'paint0_linear_3276_47994';

    //SKY GRADIENT SKY GRADIENT SKY GRADIENT SKY GRADIENT SKY GRADIENT SKY GRADIENT

}

export default Gradients;