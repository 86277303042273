import LazyLoad from 'react-lazy-load';
import {FC} from "react";

interface Props {
    children:JSX.Element
}

const LazyComponentWrap:FC<Props> = ({children}) => {
    return (
        <LazyLoad offset={400}>
            {children}
        </LazyLoad>
    )
}

export default LazyComponentWrap