import React, {FC, useEffect, useState} from 'react';
import 'swiper/css';
import "swiper/css";
import "swiper/css/pagination";
import styles from "themes/default/styles/providersSlider.module.scss";
import Link from 'next/link';
import CMS from "utils/cms";
import PaginationArrow from "ui/paginationArrow";
import { Navigation } from "swiper";
import { Pagination } from "swiper";
import clsx from 'clsx';
import { Swiper, SwiperSlide } from 'swiper/react';
import strings from "utils/strings";
import NewArrow from "themes/default/svg/newArrow";
import Skeleton from '@mui/material/Skeleton';
import {lazy_loading_offset} from "@/AbsoluteComponents/utils/constants";
import LazyComponentWrap from "components/lazy_component_wrap";

interface Props {
    json: any
    index?:any
}

const ProvidersSlider:FC<Props> = (props) => {
    
    const {index} = props
    
    if (index >= lazy_loading_offset) {
        return (
            <LazyComponentWrap>
                <Core { ... props}/>
            </LazyComponentWrap>
        )
    } else {
        return <Core { ... props}/>
    }
}

export default ProvidersSlider;

const Core:FC<Props> = ({json}) => {
    let data:any = CMS.jsonProvidersSlider(json);

    const [value, setValue] = useState(0);

    const [swiper, setSwiper] = useState<any>();

    useEffect(() => {
        setValue(value => value + 1);
    }, []);

    const sliders = [];
    const skeletonSlides:Array<any> = []

    for(let i = 0; i < 20; i++) {
        skeletonSlides.push({
            cardId: i,
            cards: [{cardNum: 1},{cardNum: 2}]
        })
    }

    for (let i = 0; i < data.providers.length; i+=2) {
        const provider1 = data.providers[i];
        const provider2 = data.providers[i + 1];

        sliders.push({
            provider1,
            provider2
        });
    }


    return (
        <div  className={clsx(styles.root)} style={{position: 'relative'}}>
            {/*<Suspense fallback={<div/>}>*/}
            <div className={clsx('gamesSlider mb-3', 'furnizori')}>

                <span className={'gamesSlider__title'}>{data.title}</span>

                {sliders.length > 0 && <Link className={'furnizoriHover-seeMore'} href={'/all-providers'}>{strings['vezi_toate']}<NewArrow /></Link>}
            </div>

            <Link className={'furnizori-seeMore'} href={'/all-providers'}>{strings['vezi_mai_multe']}<NewArrow /></Link>

            <Swiper
                pagination={{
                    clickable: true,
                }}
                modules={[Pagination, Navigation]}
                className={clsx(styles.themes__mySwiper, 'swiper-init-padding', 'swiper-providers')}
                slidesPerView='auto'
                onSwiper={(sw:any) => setSwiper(sw)}
            >

                <PaginationArrow onClick={() => swiper.slideNext()}/>
                <PaginationArrow onClick={() => swiper.slidePrev()} rightArrow />

                {sliders.length ? (
                    sliders.map((item:any, i) => {

                        return (
                            <SwiperSlide className={'providerSlide'} key={`providers-slider-item--${i}`} style={{width: 'auto'}}>
                                {Object.keys(item).map((key:any, i:number) => {
                                    const provider = item[key];

                                    if (!provider) return null;

                                    return (
                                        <Link style={{ backgroundImage: `url('/default/images/backgrounds/bgDark3.webp')` }} href={'/'+(provider['api_id'] || '')} key={`provider-item-${i}-${key}`} className={styles.item}>
                                            <img src={provider['thumbnail_src']} alt={provider['name']}/>
                                        </Link>

                                    )
                                })}
                            </SwiperSlide>
                        )
                    })
                ) : (
                    skeletonSlides.map((sk:any, i: number) => (
                        <SwiperSlide className={'providerSlide'} key={`skeleton-card${i}`} style={{width: 'auto'}}>

                            <div style={{padding: '0'}} className={styles.item}>
                                <Skeleton variant="rectangular" style={{width: '100%', height: '100%'}} animation="wave"/>
                            </div>

                            <div style={{padding: '0'}} className={styles.item}>
                                <Skeleton variant="rectangular" style={{width: '100%', height: '100%'}} animation="wave"/>
                            </div>

                        </SwiperSlide>
                    ))
                )}
            </Swiper>

        </div>
    )
};

