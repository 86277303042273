import strings from "utils/strings";
import TextField from "ui/textfield";
import {useDispatch, useSelector} from 'react-redux';
import {openForgotPasswordModal, openLoginModal} from 'store/modals/actions';
import Link from 'next/link';
import Button from "@/template/small_ui/button";
import {FC, useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import LoginModalViewInterface from "interfaces/modals-view-interface/login_modal_view_interface";
import useCommon from "@/AbsoluteComponents/hooks/common";
import PhoneField from "@/AbsoluteComponents/ui/PhoneField";
import st from "@/template/styles/LoginModal.module.scss"
import {setResetPwdPhoneEnding} from "@/AbsoluteComponents/store/account/actions";
import CommonClass from "@/AbsoluteComponents/utils/common_class";
import WritePinFields from "@/template/small_ui/WritePinFields";
import Countdown, {CountdownRenderProps} from "react-countdown";
import useAuth from "@/AbsoluteComponents/hooks/auth";

const DefaultLoginModalView:FC<LoginModalViewInterface> = ({ ... loginModalProps}) => {

    const {
        loading,
        onSubmit,
        emailProps,
        passwordProps,
        newAccountHandle,
        onWrongPasswordSubmit,
        verifyPhoneSubmit
    } = loginModalProps

    const dispatch = useDispatch();
    const resetPwdPhoneEnding = useSelector((state:any) => state.account.resetPwdPhoneEnding)

    
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();
    


    return (
        <>
            <div className="ReactModal__Inner__Header">
                {!resetPwdPhoneEnding.length && (
                    <h2 className="ReactModal__Title">{strings['conectare']}</h2>
                )}
            </div>

            <div className="ReactModal__Inner__Content">
                
                {resetPwdPhoneEnding.length ? (
                    <FrozenLoginActions verifyPhoneSubmit={verifyPhoneSubmit} loading={loading} submitFunc={onWrongPasswordSubmit} />
                ) : (
                    <form onSubmit={handleSubmit(onSubmit)}>
                        
                        <TextField
                            {...register("email", {
                                required: true,
                                pattern: /^\S+@\S+\.\S+$/i,
                                onChange: function (e: any) {
                                    e.currentTarget.value = e.currentTarget.value.replace(/\s+/g, "");
                                }
                            })}
                            label={'Email'}
                            type={'text'}
                            placeholder={'Email'}
                            {...emailProps}
                            inputStyle={{marginBottom: "16px"}}
                        />
                        
                        {errors.email?.type === "required" && (
                            <p className="errorMsg">{'Adresă de email este invalida'}</p>
                        )}
                        
                        {errors.email?.type === "pattern" && (
                            <p className="errorMsgLogin">{'Adresă email invalidă'}</p>
                        )}
                        
                        <TextField
                            inputStyle={{marginBottom: "16px"}}
                            type='password'
                            label={'Parola'}
                            placeholder={'Parola'}
                            {...passwordProps}
                        />
                        
                        <div style={{textAlign: 'end'}}>
                            <div onClick={() => {
                                dispatch(openLoginModal(false))
                                dispatch(openForgotPasswordModal(true))
                            }} className={'logInModal__forgerPassword'}><span>{strings['ai_uitat_parola']}?</span>
                            </div>
                        </div>
                        
                        <Button
                            type={'submit'}
                            loading={loading}
                            text={strings['conectare']} style={{marginTop: 20, marginBottom: 20}}
                        />
                        
                        <p className={'logInModal__haveAccount'}>
                            {strings['nu_ai_cont']}
                            
                            <Link   className={'logInModal__newAccount'} href={'#'} onClick={newAccountHandle}>
                                {strings['inregistreaza_te']}
                            </Link>
                        </p>
                    </form>
                )}
                
            </div>
        
        </>
    )
}

interface  FrozenLoginProps{
    submitFunc(fields:any ,e:any):void
    verifyPhoneSubmit(fields:any , e:any):void
    loading:boolean
}

const FrozenLoginActions:React.FC<FrozenLoginProps> = ({submitFunc, loading, verifyPhoneSubmit}) => {
    
    const [reset_info, set_reset_info ] = useState<string>("")
    const [completeCountdown, setCompleteCountdown] = useState<boolean>(false)
    
    const reset_info_obj:any = (reset_info.length && typeof JSON.parse(reset_info) === "object") ? JSON.parse(reset_info) : {}
    const resetPwdPhoneEnding = useSelector((state:any) => state.account.resetPwdPhoneEnding)
    const common_hook = useCommon()
    const ct_code = common_hook.getCountryCode("RO")?.dial_code || "+40"
    const dispatch = useDispatch()
    const auth = useAuth()
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();
    const listen = () => {
        
        let sotrage_item = localStorage.getItem(CommonClass.local_storage_items.send_pin_info)
        
        if(typeof sotrage_item === "string") {
            set_reset_info(sotrage_item)
            setCompleteCountdown(false)
            
        } else {
            set_reset_info("")
            setCompleteCountdown(true)
        }
    }
    
    const countDownCompleteHandler = () => {
        setCompleteCountdown(true)
    }
    
    useEffect(() => {
        listen()
        
        window.addEventListener(CommonClass.custom_events.listen_pin_timeout, listen)
        
        return () => {
            window.removeEventListener(CommonClass.custom_events.listen_pin_timeout, listen)
        }
    }, []);
    
    return !Object.keys(reset_info_obj).length ? (
        <form className={st.reset_pwd_form} onSubmit={handleSubmit(submitFunc)}>
            
            <h2>
                {strings['wrong_pwd']}
            </h2>
            
            <strong>
                {strings["one_attempt_left"]}
            </strong>
            
            <p className={st.info}>
                {strings["insert_nr"]}
                ("{ct_code} *** *** {resetPwdPhoneEnding}) {strings["insert_nr1"]}
            </p>
            
            <PhoneField/>
            
            <div className={st.controllers}>
                <Button
                    text={strings["send_via_sms"]}
                    type={"submit"}
                    loading={loading}
                />
                
                <Button
                    text={"Înapoi"}
                    type={"button"}
                    outlined
                    onClick={() => {
                        dispatch(setResetPwdPhoneEnding(""))
                    }}
                    className={st.back_btn}
                />
            
            </div>
        </form>
    ) : (
        <form onSubmit={handleSubmit(verifyPhoneSubmit)} className={st.write_pin_form}>
            <h2>
                {strings['mobile_check']}
            </h2>
            
            <p>
                Introdu codul de verificare primit prin SMS la:
            </p>
            
            <strong>
                {reset_info_obj.phone}
            </strong>
            
            <WritePinFields className={st.pin_fields} />
            
            
            <Button
                type={"submit"}
                text={"verifică"}
            />
            
            <div className={st.other_controllers}>
                
                <div className={st.resend}>
                    <button
                        type={"button"}
                        disabled={!completeCountdown}
                        onClick={() => {
                            auth.frozenLoginPhonePost(reset_info_obj?.phone)
                        }}
                    >
                        Retrimite codul
                    </button>
                    
                    {!completeCountdown && (
                        <Countdown
                            date={reset_info_obj?.expires}
                            renderer={(args) => renderer({...args})}
                            intervalDelay={1000}
                            zeroPadTime={2}
                            onComplete={countDownCompleteHandler}
                        />
                    )}
                    
                </div>
                
                <div className={st.info}>
                    <p>Au mai rămas {reset_info_obj?.attempts} încercări.</p>
                    <p>Codul poate ajunge în câteva minute</p>
                </div>
                
                <button
                    type={"button"}
                    onClick={() => {
                        dispatch(setResetPwdPhoneEnding(""))
                        localStorage.removeItem(CommonClass.local_storage_items.send_pin_info)
                        set_reset_info('')
                    }}
                >
                    Înapoi la conectare
                </button>
            
            </div>
        
        </form>
    )
}


const renderer = (args: CountdownRenderProps) => {
    const {minutes, seconds} = args;
    const padValue = (value: number) => (value < 10 ? `0${value}` : `${value}`);
    
    return (
        <div style={{color: "#fff"}}>
            (
                {padValue(minutes) || "00"} :
                {" " + padValue(seconds) || " 00"}
            )

        </div>
    );
    
};

export default DefaultLoginModalView