import Image from 'next/image';
import styles from 'themes/default/styles/pageProgress.module.scss';
import {FC} from 'react';
import CMS from "utils/cms";
import {useSelector} from 'react-redux';

interface Props {
    types:Array<any>;
    api_id:string;
    force?:boolean;
}

const PageProgress:FC<Props> = ({types,api_id, force}) => {
    const categoryGamesCarousels:any = useSelector((state:any) => state.common.categoryGamesCarousels);

    const availableCarouselsCount = () => {
        let count = 0;

        types.map((type:any) => {
            if (type['type'] === CMS.BLOCK_CATEGORY_GAMES_SLIDER) count++;
        });

        return count
    };

    const loadedCarouselsCount = () => {
        let count = 0;

        if (typeof categoryGamesCarousels[api_id] === "object") {
            count = Object.keys(categoryGamesCarousels[api_id]).length;
        }

        return count
    };

    if ((availableCarouselsCount() > loadedCarouselsCount()) || force) {
        return (
            <div className={styles.root}>
                <Image className={'first-logo'} src={"/default/icons/gif-logo.gif"} alt={'logo'} width={200} height={100}/>
            </div>
        );
    }

    return null;
}

export default PageProgress;