import React from 'react'

interface Props {
    number: number
    className: string
}

const TopGamesNumber:React.FC<Props> = ({className ,number}) => {
    return (
        <svg style={{overflow: 'visible'}} className={className} xmlns="http://www.w3.org/2000/svg" width={`${number === 10 ? '250' : '120'}`} height="310" viewBox={"0 0 120 310"} fill="none">

            <defs>
                <linearGradient id="black_gradient" x1="81" y1="-7" x2="81" y2="30" gradientUnits="userSpaceOnUse">
                    <stop stopColor="black" />
                    <stop offset="1" stopColor="black" />
                </linearGradient>

                <linearGradient id="stroke_gradient" x1="81" y1="-7" x2="81" y2="30" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#444242" />
                    <stop offset="1" stopColor="#444242" />
                </linearGradient>
            </defs>
            <g>

                <text
                    x="50%"
                    y="50%"
                    fontFamily="Roboto, sans-serif"
                    fontWeight="800"
                    fontSize="310"
                    dominantBaseline="middle"
                    textAnchor="middle"
                    fill={`url(#black_gradient)`}
                    stroke={`url(#stroke_gradient)`}
                    strokeWidth="5px"
                    letterSpacing={`${number === 10 ?  -50 :  0}px`}

                >
                    {number}
                </text>
            </g>
        </svg>
    )
}

export default TopGamesNumber

