import React, {useEffect, useState} from "react";
import styles from "themes/default/styles/LuckyTicket.module.scss"
import Image from "next/image";
import CMS from "@/AbsoluteComponents/utils/cms";
import useAuth from "@/AbsoluteComponents/hooks/auth";
import TicketIcon from "themes/default/svg/TicketIcon";
import strings from "@/AbsoluteComponents/utils/strings";
import StandartCountdown from "themes/default/small_ui/standart_countdown";
import Button from "@/template/small_ui/button";
import {useDispatch, useSelector} from "react-redux";
import {openDeposit, openLoginModal} from "@/AbsoluteComponents/store/modals/actions";
import {RaffleStatusType, RaffleUserStatusType} from "@/AbsoluteComponents/utils/small_types";
import Tooltip from "@mui/material/Tooltip";
import useGame from "@/AbsoluteComponents/hooks/game";
interface Props {
    data:any
    api_id:string
}

const LuckyTicketHero:React.FC<Props> = ({data, api_id}) => {
    //STATES
    // TODO set info with a global state when api ready
    const [userTicketInfo, setUserTicketInfo] = useState<any>({})
    const getState = (st:string) => {
        return useSelector((state:any) => state.common[st])
    }
    
    const raffle_status = getState("raffle_status")
    const user_raffle_status = getState("user_raffle_status")
    
    
    // VARIABLES
    const {page_title, bg_image, bg_image_mobile} = data
    const navs = [
        {
            label:"premii",
            target: "lucky_ticket_prizes",
            additionalClassName:""
        },
        
        {
            label:"regulament",
            target: "lucky_ticket_rules",
            additionalClassName:""
        },
        
        {
            label:"extrageri",
            target: "lucky_ticket_results",
            additionalClassName:""
        },
    ]
    const auth = useAuth()
    
    // UTILS
    const scrollToSection = (targetId:string) => {
        const targetElement = document.getElementById(targetId);
        const rect = targetElement?.getBoundingClientRect()
        if (targetElement && rect && window) {
            window.scrollTo({top: rect.top - 130, behavior: 'smooth' });
        }
    };
    
    
    
    useEffect(() => {
        if(auth.isLoggedIn()) {
            let info = {
                ticketsNr: 1
            }
            setUserTicketInfo(info)
        } else {
            setUserTicketInfo({})
        }
        
    }, [auth.isLoggedIn()]);
    
    return (
        <div className={styles.hero}>
            <Image className={styles.hero__desktop_bg} src={CMS.getPublicFilePath(bg_image[0]['name'], api_id)} alt={bg_image[0]['name']} width={1920} height={600} />
            <Image className={styles.hero__mobile_bg} src={CMS.getPublicFilePath(bg_image_mobile[0]['name'], api_id)} alt={bg_image_mobile[0]['name']} width={800} height={600} />
            
            <div className={styles.hero__inner}>
                
                <div className={styles.main_content}>
                    
                    <h3 className={styles.title}>
                        {page_title}
                    </h3>
                    
                    <UserBarComponent raffle_status={raffle_status}/>
                    
                    <div className={styles.countdown_container}>
                        <span className={styles.next_result}>
                            {strings["next_extraction"]}
                        </span>
                        
                        <StandartCountdown endTime={Date.now() + 999999999}/>
                    </div>
                    
                    <div className={styles.controllers}>
                        
                        <CTAVariants raffle_status={raffle_status} user_raffle_status={user_raffle_status}/>
                        
                    </div>
                </div>
                
                <div className={styles.page_navs}>
                    {navs.map((nav: any, i: number) => {
                        return (
                            <button
                                onClick={() => scrollToSection(nav.target)}
                                key={"lucky_ticket_page_navs" + i}
                            >
                                {nav.label}
                            </button>
                        )
                    })}
                </div>
            
            </div>
        
        </div>
    )
}

interface UserBarComponentProps {
    raffle_status:RaffleStatusType
}

const UserBarComponent:React.FC<UserBarComponentProps> = ({raffle_status}) => {
    const auth = useAuth()
    const dispatch = useDispatch()
    const {optedIn, runningInstances} = raffle_status
    const { userTickets } = runningInstances
    
    
    if(auth.isLoggedIn() && optedIn) return (
        <div className={styles.user_tickets_info}>
          
            <div className={styles.initials}>
                {auth.getUser().firstName[0]}
                {auth.getUser().lastName[0]}
            </div>
            
            <div className={styles.username}>
                {auth.getUser().nickName ? auth.getUser().nickName : auth.getUser().userName}
            </div>
            
            <span style={{color: "#fff"}}>
                ai
            </span>
            
            <span className={styles.tickets}>
                
                {Object.keys(raffle_status).length && (
                    <>
                        <TicketIcon />
                        {userTickets}
                        {userTickets === 1 ? " tichet": " tichete"}
                    </>
                )}
            
            </span>
           
        </div>
    )
    
    
    if(!auth.isLoggedIn()) return (
        <div className={styles.user_tickets_info}>

            <span className={styles.not_logged}>
                <button
                    onClick={() => dispatch(openLoginModal(true))}
                >
                    Conectează-te
                </button>
                
                pentru a afla numarul de tichete
                
            </span>
            
        </div>
    )
    
    if(auth.isLoggedIn() && !optedIn) {
        return null
    }
    
    return null
}

interface CTAVariantsProps {
    raffle_status: RaffleStatusType
    user_raffle_status: RaffleUserStatusType
}

const CTAVariants: React.FC<CTAVariantsProps> = ({raffle_status, user_raffle_status}) => {
    const auth = useAuth()
    const profile: any = auth.isLoggedIn() ? auth.getUser() : undefined
    const is_verified: boolean | undefined = typeof profile === "object" && profile.hasOwnProperty("kycVerified") ? profile.kycVerified : undefined
    const dispatch = useDispatch()
    const game_hook = useGame()
    
    const optIn = () => {
        game_hook.userRaffleStatusUpdate("POST")
    }
    
    if (auth.isLoggedIn() && user_raffle_status === "Accepted") return (
        <Button
            onClick={() => {
                dispatch(openDeposit(true))
            }}
            text={strings["get_tickets"]}
        />
    )
    
    if (auth.isLoggedIn() && user_raffle_status === "Undefined") return (
        <Button
            onClick={() => {
                optIn()
            }}
            text={strings["get_tickets"]}
        />
    )
    
    if (!auth.isLoggedIn()) return (
        <Button
            onClick={() => {
                dispatch(openLoginModal(true))
            }}
            text={strings["get_tickets"]}
        />
    )
    
    if (auth.isLoggedIn() && !is_verified && user_raffle_status === "Rejected") return (
        <Tooltip
            title={"Pentru participare profilul tău trebuie să fie validat."}
            leaveTouchDelay={1000}
            enterTouchDelay={0}
            className={"optInTooltip"}
        >
            <button disabled> {strings["get_tickets"]} </button>
        </Tooltip>
    
    )
    
    return null
}

export default LuckyTicketHero

