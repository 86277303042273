import {FC} from 'react';
import Image from 'next/image';
import clsx from 'clsx';
import styles from '../../../styles/VIPQuest.module.scss';
import Modal from 'react-modal';
import Link from "next/link";
import Button from "@/template/small_ui/button"
import CloseIcon from "@/template/svg/closeicon";
import CMS from "@/AbsoluteComponents/utils/cms";


interface Props {
    leader?: any
    congrat_text?: string
    open: boolean
    close():void
    winners:any
}

type Winner = {
    nickname: string,
    points: number,
    reachedAt: string
    
}

const QuestIsEndedModal:React.FC<Props> = (props) => {
    const {
        leader,
        congrat_text,
        open,
        close,
        winners
    } = props
    
    // console.log(leader)
    
    return (
        <Modal
            onRequestClose={close}
            ariaHideApp={false}
            className={clsx(styles.modalQuest)}
            isOpen={open}
            contentLabel="Example Modal"
        >
            <div className={styles.modalQuest__header}>
                <div className={clsx(styles.mobileDisapear, styles.hidden)} style={{width: '40px'}}></div>
                <h4 className={clsx(styles.heading, "ReactModal__Title")}>
                   Aventura {CMS.getCurrentQuest().name}
                </h4>
                <button onClick={close} className={styles.modalQuest__closeBtn}>
                    <CloseIcon />
                </button>
            </div>
            
            {/*TODO : TEST!!!!*/}
            <div className={styles.modalQuest__content}>
                {Object.keys(winners).length && winners.first.nickname.length ? (
                    <>
                        <div className={clsx(styles.textContainer, styles.congrats)}>
                            {(winners.hasOwnProperty('second') && winners.second.nickname.length) ?
                                "Felicitări câștigătorilor!" : "Felicitări câștigătorului!"
                            }
                        </div>
                        
                        {/*<div style={{marginTop: "30px"}} className={clsx(styles.textContainer, styles.nickname)}>*/}
                        {/*    {Object.entries(winners).map(([key, winner]) => {*/}
                        {/*        */}
                        {/*        let winnerItem: Winner = winner as Winner*/}
                        {/*        let chartPlace: string = ''*/}
                        {/*        */}
                        {/*        if (key === "first") {*/}
                        {/*            chartPlace = 'I'*/}
                        {/*        }*/}
                        {/*        */}
                        {/*        if (key === "second") {*/}
                        {/*            chartPlace = 'II'*/}
                        {/*        }*/}
                        {/*        */}
                        {/*        if (key === "third") {*/}
                        {/*            chartPlace = 'III'*/}
                        {/*        }*/}
                        {/*        */}
                        {/*        return winnerItem.nickname ? (*/}
                        {/*            <div className={clsx(styles.textContainer, styles.nicknameSm)} key={'winner' + key}>*/}
                        {/*                Locul {chartPlace} - {winnerItem.nickname}*/}
                        {/*            </div>*/}
                        {/*        ) : null*/}
                        {/*    })}*/}
                        {/*</div>*/}
                    </>
                ) : (
                    <div className={clsx(styles.modalQuest__content, styles.hidden)}>
                        {/*CONTENT HERE*/}
                        <div className={clsx(styles.textContainer, styles.congrats)}>
                            Aventura {CMS.getCurrentQuest().name.toUpperCase()} a fost finalizată!
                        </div>
                        
                        <div className={clsx(styles.textContainer, styles.soon)}>
                            Felicitări câștigătorului:
                        </div>
                        
                        <h3 className={clsx(styles.textContainer, styles.nextAdv)}>
                            {leader}
                        </h3>
                        
                        <div style={{marginTop: '44px'}}>
                            <Button
                                onClick={close}
                                text={'Închide'}
                            />
                        </div>
                    </div>
                )}
                <div className={clsx(styles.textContainer, styles.soon, "theme_white_dark_text")}>
                   Aventura {CMS.getCurrentQuest().name.replace(/\b\w/g, (char:any) => char.toUpperCase())} a fost finalizată!
                    Stai pe fază, urmează o nouă Aventură în curând!
                </div>
            </div>
        
        
        </Modal>
    )
}

export default QuestIsEndedModal