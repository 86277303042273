import {FC} from 'react';
import clsx from 'clsx';

interface Props {
    large?:boolean,
    count?:number,
    margin?:number,
}

const SwiperGamesPreloader:FC<Props> = ({large, count, margin}) => {

    const games = [];
    const defaultCount = count || 10;

    for (let i = 0; i < defaultCount; i++) {
        games.push(
            <div
                key={'swiper_games_preloader-'+i}
                style={{marginRight: margin}}
                className={clsx('swiper_games_preloader__col', large && 'large')}
            >
                <div className={clsx('swiper_games_preloader__item')}>
                    <div className="swiper_games_preloader__item__preview"/>
                </div>
            </div>
        );
    }

    return (
        <div className={'swiper_games_preloader'}>
            {games}
        </div>
    )
}

export default SwiperGamesPreloader