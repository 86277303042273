import React, {useEffect, useRef, useState} from "react";
import styles from "themes/default/styles/LuckyTicket.module.scss"
import DatePicker from 'react-datepicker';
import clsx from "clsx";
import ro from "date-fns/locale/ro";
import DateIcon from "@/template/svg/dateIcon";
import moment from "moment/moment";
import 'moment/locale/ro';
import Chevrone from "themes/default/svg/Chevrone";
import WinnersTable from "themes/default/cms-views/luckyTicket/elems/winners_table";

interface Props {
    data:any
    api_id:string
}

const LuckyTicketResults:React.FC<Props> = ({data}) => {
    // TODO : on mobile dates are swipper
    // TODO : you have to import some styles from global for datepicker
    
    const {
        extractions_section_title,
        start_date,
        end_date,
        extraction_week_day
    } = data
    const date_item_width:number = 68
    const standart_date_format:string = "YYYY-MM-DD"
    const extraction_day:number = Number(extraction_week_day)
    const dateRow = useRef<any>(null)
    const next_date_btn = useRef<any>(null)
    const prev_date_btn = useRef<any>(null)
    const picker = useRef<any>(null)
    const [offset, setOffset] = useState(0)
    const touchStartX = useRef(0);
    const touchEndX = useRef(0);
    const getClosestPastMonday = () => {
        const currentDate = moment();
        
        // Check if today is Monday
        if (currentDate.day() === extraction_day) {
            return currentDate.format(standart_date_format); // Return current date if today is Monday
        }
        
        const daysUntilMonday = (currentDate.day() + 6) % 7; // Calculate days since the previous Monday
        const closestPastMonday = currentDate.clone().subtract(daysUntilMonday, 'days');
        
        return closestPastMonday.format(standart_date_format);
    };
    
    const [selectedDate, setSelectedDate ] = useState<string>(getClosestPastMonday())
    
    const getDeployAmn = ():number => {
        if (!dateRow.current) return 0
        if(!next_date_btn.current) return 0
        
        const rect = (dateRow.current as HTMLElement).getBoundingClientRect();
        const btn_rect = (next_date_btn.current as HTMLElement).getBoundingClientRect();
        const width = rect.width - btn_rect.width * 2;
        return Math.floor(width / date_item_width);
    }
    
    const getMondays = ():Array<any> => {
        const startDate = moment(start_date);
        const endDate = moment(end_date);
        
        const mondays = [];
        
        while (startDate.isBefore(endDate)) {
            if (startDate.day() === extraction_day) {
                mondays.push(startDate.format(standart_date_format));
            }
            
            startDate.add(extraction_day, 'days');
        }
        
        return mondays.reverse();
    };
    
    const isDayBlocked = (date:Date):boolean => {
        return getMondays().includes(moment(date).format(standart_date_format))
    };
    
    const generateResponsiveListBounds = () => {
        const dates = getMondays();
        if (!dates.length) return;
        let list: Array<any> = [];
        
        if (getDeployAmn()) {
            list = dates.slice(offset, offset + getDeployAmn());
        }
        
        return list.length ? list : [];
    }
    
    const generatedList = generateResponsiveListBounds();
    const resulted_dates: Array<any> = generatedList && generatedList.length ? generatedList : [];
    
    const changeOffset = (increment:boolean) => {
        
        if(!Array.isArray(resulted_dates)) return

        if(increment) {
            setOffset((n:number) => n + 1)
            prev_date_btn.current.disabled = false
        }
        
        if(!increment) {
            setOffset((n:number) => n - 1)
            next_date_btn.current.disabled = false
        }
        
    }
    
    const setOffsetHandle = (date:string) => {
        if(!dateRow.current) return
        let index:number = 0
        index = getMondays().indexOf(date) - 1
        if(index > getMondays().length - getDeployAmn()) {
            index = getMondays().length - getDeployAmn()
        }
        
        if(index < 0) {
            index = 0
        }
        setOffset(index)
    }
    
    const handleTouchStart = (e:any) => {
        touchStartX.current = e.touches[0].clientX;
    };
    
    const handleTouchEnd = (e:any) => {
       touchEndX.current = e.changedTouches[0].clientX
       let speed = 1
       let bound_diff = 50
       let difference = touchStartX.current - touchEndX.current
       
       
       if(touchStartX.current > touchEndX.current) {
           if(difference > bound_diff) {
               if(offset + resulted_dates?.length >= getMondays().length) return
               setOffset((n:number) => n + speed)
               prev_date_btn.current.disabled = false
               if(offset >= getMondays().length - speed) {
                   setOffset(getMondays().length - speed)
               }
           }
           
       } else {
           if(difference < bound_diff * -1) {
               setOffset((n:number) => n - speed)
               next_date_btn.current.disabled = false
               if(offset <= 0 + speed) {
                   setOffset(0)
               }
           }
       }
       
       
       
       // if(touchStartX.current > touchEndX.current) {
       //     setOffset((n:number) => n - 1)
       //     next_date_btn.current.disabled = false
       // }
       //
       // if(touchStartX.current > touchEndX.current){
       //     setOffset((n:number) => n + 1)
       //     prev_date_btn.current.disabled = false
       // }
   }
    
    useEffect(() => {
        
        setOffsetHandle(getClosestPastMonday())
    }, [])
    
    
    return (
        <div
            className={styles.results}
            id={"lucky_ticket_results"}
        >
            <div className={styles.container}>
                <h4>
                    {extractions_section_title}
                </h4>
                
                <div className={clsx(styles.date_picker_container)}>
                    
                    <div ref={dateRow} className={styles.dates_row}>
                        <button
                            disabled={offset <= 0}
                            ref={prev_date_btn}
                            onClick={() => {
                                changeOffset(false)
                            }}
                            className={clsx(styles.chevron_left, styles.dir_button)}
                        >
                            <Chevrone/>
                        </button>
                        
                        <div className={clsx(styles.array, styles.for_desktop)}>
                            
                            {resulted_dates?.length ? resulted_dates?.map((d, i) => {
                                let is_selected = selectedDate === d
                                let local_date = moment(d).format('Do MMM').replace(".", "")
                                
                                return (
                                    <div
                                        key={"date_item: " + i}
                                        style={{
                                            maxWidth: date_item_width + "px",
                                            minWidth: date_item_width + "px"
                                        }}
                                        className={clsx(
                                            styles.date_item,
                                            is_selected && styles.selected
                                        )}
                                        onClick={() => {
                                            setSelectedDate(d)
                                        }}
                                    >
                                        {local_date}
                                    </div>
                                )
                            }) : null}
                        </div>
                        
                        <div
                            onTouchStart={handleTouchStart}
                            onTouchEnd={handleTouchEnd}
                            className={clsx(styles.array, styles.for_mobile)}
                        >
                            
                            <div
                                style={{
                                    transform: `translateX(${date_item_width * offset * -1}px)`
                                }}
                                className={clsx(styles.all_days)}
                            >
                                {getMondays()?.length ? getMondays()?.map((d, i) => {
                                    let is_selected = selectedDate === d
                                    let local_date = moment(d).format('Do MMM').replace(".", "")
                                    
                                    return (
                                        <div
                                            key={"date_item: " + i}
                                            style={{
                                                maxWidth: date_item_width + "px",
                                                minWidth: date_item_width + "px"
                                            }}
                                            className={clsx(
                                                styles.date_item,
                                                is_selected && styles.selected
                                            )}
                                            onClick={() => {
                                                setSelectedDate(d)
                                            }}
                                        >
                                            {local_date}
                                        </div>
                                    )
                                }) : null}
                            </div>
                            
                        </div>
                        
                        <button
                            disabled={offset + resulted_dates?.length >= getMondays().length}
                            ref={next_date_btn}
                            onClick={() => {
                                changeOffset(true)
                            }}
                            className={clsx(styles.chevron_right, styles.dir_button)}
                        >
                            <Chevrone/>
                        </button>
                    </div>
                    
                    <div
                        onClick={() => {
                            picker?.current?.setFocus()
                        }}
                        className={clsx(styles.date_picker, "lucky_ticket_date_picker")}
                    >
                        <DatePicker
                            locale={ro}
                            selected={moment(selectedDate, standart_date_format).toDate()}
                            onChange={(date: any) => {
                                setOffsetHandle(moment(date).format(standart_date_format))
                                setSelectedDate(moment(date).format(standart_date_format))
                            }}
                            ref={picker}
                            // showYearDropdown
                            yearDropdownItemNumber={15}
                            onFocus={(e) => e.target.readOnly = true}
                            dateFormat="dd MMMM yyyy"
                            shouldCloseOnSelect={false}
                            popperClassName={styles.core}
                            startDate={new Date()}
                            filterDate={isDayBlocked}
                        />
                        <DateIcon/>
                        <span>
                            Data extragerii
                        </span>
                    </div>
                    
                    
                    
                </div>
                
                <WinnersTable
                    data={data}
                    selectedDate={selectedDate}
                />
                
            </div>
        </div>
    )
}


export default LuckyTicketResults