import {FC} from 'react';
import Button from "themes/default/small_ui/button";
import strings from "utils/strings";
import styles from '../styles/mobileBottomBar.module.scss';
import {useDispatch} from 'react-redux';
import {openLoginModal,openSignupModal} from "store/modals/actions";
import useAuth from "hooks/auth";
import {useRouter} from 'next/router'

const MobileBottomBar:FC = () => {
    const dispatch = useDispatch();
    const auth = useAuth();
    const router = useRouter();
    const path = router.pathname.slice(1, 6);

    if (auth.isLoggedIn() || auth.isUpdating()) return null;
    
    
    return (
        <div style={{boxShadow: `${path === 'sport' ? "0px -2px 17px 5px rgba(0,0,0,0.25)" : ''} `}} className={styles.root}>

            <Button text={strings['conectare']} outlined onClick={() => {
                dispatch(openLoginModal(true))

                const root =  document.getElementsByTagName('html')[0];
                root.setAttribute('style', 'overflow-y: hidden');
            }}/>

            <Button text={strings['cont_nou']} onClick={() => {
                dispatch(openSignupModal(true))

                const root =  document.getElementsByTagName('html')[0];
                root.setAttribute('style', 'overflow-y: hidden');
            }}/>

        </div>
    )
};

export default MobileBottomBar





















// import React, {FC} from 'react';
// import Button from "@/template/small_ui/button";
// import strings from "utils/strings";
// import styles from 'themes/spins/styles/mobileBottomBar.module.scss';
// import {useDispatch, useSelector} from 'react-redux';
// import {openLoginModal, openSignupModal, openWheelModal} from "store/modals/actions";
// import useAuth from "hooks/auth";
// import {useRouter} from 'next/router'
// import Link from 'next/link';
// import Balance from "@/template/ui/balance/balance-view";
// import {openAccountSidebar, showTermsSideBar} from "@/AbsoluteComponents/store/common/actions";
// import clsx from 'clsx'
// import BurgherIonSpins from "themes/spins/svg/burgherIonSpins";
// import WheelSpins from 'themes/spins/svg/wheelSpin'
// import OffersIcon from "themes/spins/svg/offersIcon";
//
// const MobileBottomBar: FC = () => {
//     const dispatch = useDispatch();
//     const auth = useAuth();
//     const router = useRouter();
//     let isSportPage: boolean = router.pathname.search('sport') > -1;
//     let isGamePage: boolean = router.pathname.slice(1, 4) === 'joc';
//     const isOpen: boolean = useSelector((state: any) => state.common.openAccSidebar)
//     const enableSpin: string = useSelector((state: any) => state.common.enableSpinWheel);
//
//     const showSideBar = () => {
//         dispatch(openAccountSidebar(!isOpen))
//         // console.log(isOpen)
//         if (isOpen) {
//             dispatch(showTermsSideBar(false))
//         }
//     }
//
//     return !isGamePage ? (
//         <div className={clsx(styles.root, isSportPage && styles.isSport)}>
//
//             <div className={clsx('mobileMenu', 'themes_mobileMenu')}>
//                 {(auth.isLoggedIn() || auth.isUpdating()) && (
//                     <>
//                         <div style={{
//                             display: 'flex',
//                             alignItems: 'center'
//                         }}>
//                             <div onClick={showSideBar} className={clsx('mobileMenu__wrapper', 'mobileMenu__wrapper__mobile')}>
//                                 <span className={'theme__svg'}><BurgherIonSpins /></span>
//                             </div>
//                             <Link href={'/account/available-offers'} className={clsx('mobileMenu__wrapper', 'mobileMenu__wrapper__mobile')}>
//                                 <span className={'theme__svg'}><OffersIcon /></span>
//                             </Link>
//                             <div onClick={() => {
//                                 dispatch(openWheelModal(true))
//                             }}
//                                  className={clsx('mobileMenu__wrapper', 'mobileMenu__wrapper__mobile' )}>
//                                 <span  className={'theme__svg'}><WheelSpins /></span>
//                             </div>
//                         </div>
//
//                         <Balance/>
//                     </>
//                 )
//                 }
//
//                 {(!auth.isLoggedIn() && !auth.isUpdating()) && (
//                     <>
//                         <div onClick={showSideBar} className={clsx('mobileMenu__wrapper',  'mobileMenu__wrapper__mobile', 'mobilWheelBtnBottom', 'mobileNotLogInMenu')}>
//                             <BurgherIonSpins />
//                         </div>
//
//                         <div className={styles.guestButtons}>
//
//                             <Button text={strings['conectare']} outlined onClick={() => {
//                                 dispatch(openLoginModal(true))
//
//                                 const root = document.getElementsByTagName('html')[0];
//                                 root.setAttribute('style', 'overflow-y: hidden');
//                             }}/>
//
//                             <Button text={strings['cont_nou']} onClick={() => {
//                                 dispatch(openSignupModal(true))
//
//                                 const root = document.getElementsByTagName('html')[0];
//                                 root.setAttribute('style', 'overflow-y: hidden');
//                             }}/>
//
//                         </div>
//
//                         <div onClick={() => {
//                             dispatch(openWheelModal(true))
//                         }}
//                              className={clsx('mobileMenu__wrapper',  'mobileMenu__wrapper__mobile', 'mobileNotLogInMenu', 'mobilWheelBtnBottom')}
//                              style={{marginRight: 0,  marginLeft: 10}}>
//                             <WheelSpins/>
//                         </div>
//                     </>
//                 )}
//             </div>
//
//
//         </div>
//     ) : null
// };
//
// export default MobileBottomBar