import styles from "themes/default/styles/casinoSportToggler.module.scss";
import clsx from 'clsx';
import React, {FC} from 'react';


interface Props {
    setType(type: "casino" | "sport"):void
    offerType: "casino" | "sport"
}

const CasinoSportsToggler:FC<Props> = ({ offerType, setType }) => {

    let offerTypesArr = ["casino", "sport"]

    return (
        <div className={clsx(styles.main)}>

            {offerTypesArr.map((x: string, i:number) => {
                return (
                    <div
                        key={'offer' + i }
                        onClick={() => {
                            // @ts-ignore
                            setType(x)
                        }}
                        className={clsx(
                            styles.sides,
                            styles.left,
                            offerType === x && styles.active
                        )}
                    >
                        {x}
                    </div>
                )
            })}

            <div className={clsx(styles.selector, offerType === "sport" && styles.floated)}>

            </div>

        </div>
    )
}

export default CasinoSportsToggler