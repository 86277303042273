import { FC } from 'react';
import clsx from 'clsx';
import ArrowRight from 'themes/default/svg/arrow-right';

interface Props {
    leftArrow?: boolean,
    onClick?: Function,
    className?: string,
}

const CarouselArrow: FC<Props>  =  ({ leftArrow, className, onClick }) => {

    return (
        <div className={clsx(!leftArrow && 'right-direction', leftArrow && 'left-direction', 'carouselArrow')} onClick={() => onClick ? onClick() : null}>
            <ArrowRight/>
        </div>
    );

}

export default CarouselArrow;
